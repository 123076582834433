import React from 'react';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { Button, Grid } from '@material-ui/core';
import {
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  GITHUBEMU_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_NAME_REGEX,
  SPECIAL_RESOURCE_NAME_REGEX,
} from 'usg-types';
import { projectApiRef } from '../../../../api';
import {
  updateArtifactoryProperties,
  validateArtifactoryPropertiesPostUserInput,
} from '../../artifactory-github-workflow-access/ArtifactoryService';
import { EditButtonsProps } from '../types';
import { useTranslation } from '../../../../hooks/useTranslation';
import getTagOptions from '../../../../utils/getTagOptions';
import { useStyles } from '../../styles';

export const EditButtons = ({
  formik,
  idTokenData,
  devToolId,
  projectId,
  resourceId,
  apiLoader,
  showArtifactoryGithubWorkflowAccess,
  artifactoryProperties,
  setResourceData,
  resourceData,
  setOpenEditSucessMsg,
  setEditMode,
  setApiLoader,
  setArtifactoryProperties,
  setUserEmailErrorData,
  userEmailErrorData,
  existingArtifactoryProperties,
  isAddUserEnable,
  isAddUserLoading,
  isResourceIndexingEnabled,
  setResourcesToggleState,
}: EditButtonsProps) => {
  const classes = useStyles();

  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const { t } = useTranslation();

  const onSaveResource = async (values: any) => {
    setApiLoader(true);
    const dev_tool_ids = [
      JIRA_DEV_TOOL_ID,
      CONFLUENCE_DEV_TOOL_ID,
      JAMA_DEV_TOOL_ID,
    ];
    const nameParam = values.resourceName;
    const params: any = {
      idToken: idTokenData,
      key: values.resourceKey,
      name: dev_tool_ids.includes(devToolId)
        ? values.resourceName.trim()
        : nameParam,
      description: values.resourceDescription,
    };
    if (
      [ARTIFACTORY_DEV_TOOL_ID, ARTIFACTORY_SAAS_DEV_TOOL_ID].includes(
        devToolId,
      ) &&
      showArtifactoryGithubWorkflowAccess
    ) {
      const validation = validateArtifactoryPropertiesPostUserInput(
        artifactoryProperties,
      );
      if (!validation.isValid) {
        const errorMsg = validation.msg;
        errorApi.post(new Error(`${errorMsg}`));
        setApiLoader(false);
        return;
      }
    }
    const data: any = await projectApi.updateProjectResource(
      projectId,
      resourceId,
      params,
    );
    const resourceCode = data?.response?.status;
    if (resourceCode >= 400) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
      setApiLoader(false);
    }
    if (resourceCode === 200) {
      setResourceData(data?.response?.data);
      setApiLoader(false);
      const inputEmails = [];
      for (const email of values?.addResourceManagers) {
        inputEmails.push({ user_email: email.value.trim() });
      }
      const idToken = await authref.getIdToken();
      const resourceManagersparams = {
        resource_managers: inputEmails,
      };
      setApiLoader(true);
      const addResourceData: any = await projectApi.addGroupManagers(
        projectId,
        resourceId,
        idToken,
        resourceManagersparams,
      );
      const code = addResourceData?.status;
      if (code === 200 || code === 201) {
        setApiLoader(false);
        setOpenEditSucessMsg(true);
      } else {
        setApiLoader(false);
        setUserEmailErrorData({
          ...userEmailErrorData,
          message: addResourceData?.data?.error?.message,
        });
        const errorMsg = addResourceData?.data?.error?.message;
        errorApi.post(new Error(`${errorMsg}`));
      }
    }

    if (
      (devToolId === ARTIFACTORY_DEV_TOOL_ID ||
        devToolId === ARTIFACTORY_SAAS_DEV_TOOL_ID) &&
      showArtifactoryGithubWorkflowAccess
    ) {
      const artifactName =
        devToolId === ARTIFACTORY_DEV_TOOL_ID
          ? 'artifactory_self_hosted'
          : 'artifactory_saas';
      await updateArtifactoryProperties(
        resourceData,
        projectApi,
        idTokenData,
        projectId,
        artifactName,
        values.resourceKey,
        artifactoryProperties,
      );
    }

    const tagOperations = getTagOptions(isResourceIndexingEnabled);

    try {
      const tags = await projectApi.updateResourceTag(
        idTokenData,
        projectId,
        resourceId,
        {
          tags: tagOperations,
        },
      );

      if (tags?.error) {
        errorApi.post(new Error(`${tags?.error?.message}`));

        setResourcesToggleState(prevResourcesToggleState => ({
          ...prevResourcesToggleState,
          [resourceId]: { isEnabled: !isResourceIndexingEnabled },
        }));
      }
    } catch (err: any) {
      errorApi.post(new Error(err?.message || 'Failed to update tags'));
      setResourcesToggleState(prevResourcesToggleState => ({
        ...prevResourcesToggleState,
        [resourceId]: { isEnabled: !isResourceIndexingEnabled },
      }));
    }
  };

  const onCancelResource = () => {
    if (
      [ARTIFACTORY_DEV_TOOL_ID, ARTIFACTORY_SAAS_DEV_TOOL_ID].includes(
        resourceData?.dev_tool_id,
      ) &&
      showArtifactoryGithubWorkflowAccess
    ) {
      setArtifactoryProperties(existingArtifactoryProperties);
    }
  };

  const disableSaveBtn = (
    resourceName: string,
    resourceDescription: string,
  ) => {
    let minLength = 1;
    let maxLength = 256;
    let regex = RESOURCE_NAME_REGEX;
    let descriptionMaxLength = RESOURCE_DESCRIPTION_MAX_LENGTH;

    // update rules based on dev tool
    switch (devToolId) {
      case GITHUBEMU_DEV_TOOL_ID:
      case GITHUB_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.github;
        maxLength = RESOURCE_NAME_MAX_LENGTH.github;
        regex = SPECIAL_RESOURCE_NAME_REGEX.github;
        descriptionMaxLength = GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH;
        break;
      case CONFLUENCE_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.confluence;
        maxLength = RESOURCE_NAME_MAX_LENGTH.confluence;
        break;
      case JIRA_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.jira;
        maxLength = RESOURCE_NAME_MAX_LENGTH.jira;
        break;
      case ARTIFACTORY_DEV_TOOL_ID:
      case ARTIFACTORY_SAAS_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.artifactory;
        maxLength = RESOURCE_NAME_MAX_LENGTH.artifactory;
        regex = SPECIAL_RESOURCE_NAME_REGEX.artifactory;
        break;
      case JAMA_DEV_TOOL_ID:
        minLength = RESOURCE_NAME_MIN_LENGTH.jama;
        maxLength = RESOURCE_NAME_MAX_LENGTH.jama;
        regex = SPECIAL_RESOURCE_NAME_REGEX.jama;
        break;
      default:
        minLength = 1;
        maxLength = 256;
        regex = RESOURCE_NAME_REGEX;
        break;
    }

    // resource name length check
    const resourceNameLengthCheck =
      resourceName.length < minLength || resourceName.length > maxLength;

    // regex format check
    const resourceNameRegexCheck = !resourceName.match(regex);

    // description length check
    const resourceDescriptionLengthCheck =
      resourceDescription.length > descriptionMaxLength;

    return (
      resourceNameLengthCheck ||
      resourceNameRegexCheck ||
      resourceDescriptionLengthCheck ||
      apiLoader
    );
  };

  return (
    <Grid container spacing={2} className={classes.saveDivSection}>
      <Grid item xs={1}>
        <Button
          id="project-button-canceledit"
          variant="outlined"
          className={classes.noWrap}
          onClick={() => {
            formik.resetForm({});
            setEditMode(false);
            onCancelResource();
            setUserEmailErrorData({
              ...userEmailErrorData,
              message: '',
            });
          }}
        >
          {t('resource.edit.cancelButton')}
        </Button>
      </Grid>
      <Grid item xs={1} style={{ paddingLeft: '3%' }}>
        <Button
          id="edit-resource-submit-button"
          data-testid="edit-resource-submit-button"
          variant="contained"
          className={classes.noWrap}
          type="submit"
          disabled={
            disableSaveBtn(
              formik.values.resourceName,
              formik.values.resourceDescription,
            ) ||
            isAddUserLoading ||
            !isAddUserEnable
          }
          onClick={() => onSaveResource(formik.values)}
        >
          {t('resource.edit.saveButton')}
        </Button>
      </Grid>
    </Grid>
  );
};
