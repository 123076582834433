import React, { useEffect, useState } from 'react';
import {
  AlertNotification,
  PageLayoutWithTabs,
  PageTitle,
} from '@internal/sg-ui-kit';

import {
  identityApiRef,
  useApi,
  microsoftAuthApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { Grid, Typography, Button, Tooltip } from '@material-ui/core';
import { InfoCard, Avatar } from '@backstage/core-components';
import { UserNotificationSettingsCard } from '@internal/backstage-plugin-notifications';
import { projectApiRef } from '../../api';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ProfileProjectTable } from './ProfileProjectTable';
import { useAsyncFn } from 'react-use';
import { Progress } from '@backstage/core-components';
import { UserRoleList } from '../UserRoleList';
import { getRoles } from '@internal/sg-utils-common';
import { Role } from 'usg-types';
import { AchievementsPanel } from '../AchievementsPanel';
import { UserSettingsLanguageToggle } from './user-settings/UserSettingsLanguageToggle';
import { SidebarItemTag } from '@internal/sg-ui-kit';
import Stack from '@mui/material/Stack';
import { useTranslation } from '../../hooks/useTranslation';
import { useStyles } from './styles';

export function ProfilePageComponent() {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const { t } = useTranslation();

  const [profileData, setProfileData] = useState({} as any);
  const [userData, setUserData] = useState({} as any);
  const [token, setToken] = useState('');
  const [copyButtonLabel, setCopyButtonLabel] = useState('Copy');
  const [roles, setRoles] = useState<string[]>([]);
  const [coreDBRoles, setCoreDBRoles] = useState<Role[]>([]);
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);

  const handleUserRole = (user: any) => {
    const adminRoles = getRoles(user?.roles);
    setRoles(adminRoles);
  };

  const [{ loading, error }, fetchContent] = useAsyncFn(async () => {
    const profile = await identityApi.getProfileInfo();
    setProfileData(profile);

    const idToken = await authref.getIdToken();
    const params = {
      idToken: idToken,
    };
    const queryParams = {
      include: 'all',
    };
    setToken(idToken);

    try {
      const data: any = await projectApi.getUserDetails(params, queryParams);
      const resData = data?.response?.data;
      setUserData(resData);
      handleUserRole(resData);
    } catch (err) {
      const pError = err?.message;
      errorApi.post(new Error(`${pError}`));
    }
  }, []);

  const fetchDBRoles = async () => {
    try {
      setRolesLoading(true);
      const idToken = await authref.getIdToken();
      setToken(idToken);
      const res: any = await projectApi.getUserRoles(idToken);
      const nonDeletedRoles = res?.data?.roles.filter(
        (role: Role) => !role?.deleted_on,
      );
      setCoreDBRoles(nonDeletedRoles);
      setRolesLoading(false);
    } catch (err: any) {
      errorApi.post(new Error(err?.message));
      setRolesLoading(false);
    }
  };

  const process = async () => {
    await fetchDBRoles();
    await fetchContent();
  };
  useEffect(() => {
    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || rolesLoading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(`${error}`));
  }

  return (
    <PageLayoutWithTabs
      title={`${t('profile.pageTitle.welcome')}, ${profileData?.displayName}`}
      subtitle={t('profile.pageTitle.yourStargateProfile')}
      headerBackgroundImg="/assets/welcome_bg.webp"
    >
      <PageLayoutWithTabs.Route path="/" title={t('profile.layout.profile')}>
        <>
          <PageTitle
            customPageTitle={`${profileData?.displayName} | Profile | Stargate`}
          />
          <div className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InfoCard
                  variant="gridItem"
                  title={t('profile.layout.profile')}
                  className={classes.profileCard}
                >
                  <Grid item xs={12} md={12} lg={3}>
                    <Avatar
                      picture={profileData?.picture}
                      classes={{
                        avatarText: classes.avatarText,
                      }}
                      displayName={profileData?.displayName}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={9}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.displayName}
                      noWrap
                    >
                      {profileData?.displayName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.email}
                      noWrap
                    >
                      {profileData?.email}
                    </Typography>
                    {userData?.roles && (
                      <UserRoleList
                        curentUserEmail={profileData?.email}
                        roleList={coreDBRoles}
                        maxRolesVisible={2}
                      />
                    )}
                  </Grid>
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={8}>
                <InfoCard
                  variant="gridItem"
                  title={t('profile.infoCard.authorizationToken')}
                >
                  <div>
                    <div className={classes.copyToken}>
                      <div className={classes.tokenDiv}>
                        <Typography className={classes.token} id="id-token">
                          {token}
                        </Typography>
                      </div>
                      <Button
                        id="profile-button-copy-token"
                        variant="outlined"
                        style={{ marginLeft: '24px' }}
                        onClick={() => {
                          navigator.clipboard.writeText(token);
                          setCopyButtonLabel('COPIED');
                          setTimeout(() => {
                            setCopyButtonLabel('Copy');
                          }, 2000);
                        }}
                        disabled={copyButtonLabel === 'COPIED'}
                      >
                        {copyButtonLabel}
                        <FileCopyIcon
                          style={{ marginLeft: '8px' }}
                          fontSize="small"
                        />
                      </Button>
                    </div>
                  </div>
                  <AlertNotification
                    severity="warning"
                    alertMessage={t('profile.infoCard.alertMessage')}
                    alertTitle={t('profile.infoCard.attention')}
                  />
                </InfoCard>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InfoCard variant="gridItem" title="Achievements">
                  <AchievementsPanel />
                </InfoCard>
              </Grid>
            </Grid>

            <ProfileProjectTable
              data={userData?.projects?.filter((p: any) => !p.is_archived)}
              roles={roles}
            />
          </div>
        </>
      </PageLayoutWithTabs.Route>
      <PageLayoutWithTabs.Route
        path="settings"
        title={t('profile.layout.settings')}
      >
        <>
          <PageTitle customPageTitle="Settings | Stargate" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <InfoCard
                variant="gridItem"
                title={t('profile.infoCard.language')}
                cardClassName={classes.settingsCard}
              >
                <Tooltip
                  title={t('profile.infoCard.tooltipTitle')}
                  placement="right"
                >
                  <Stack direction="row" spacing={1}>
                    <Typography className={classes.tokenText}>
                      {t('profile.infoCard.language')}
                    </Typography>
                    <SidebarItemTag label="Alpha" />
                  </Stack>
                </Tooltip>
                <UserSettingsLanguageToggle />
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <UserNotificationSettingsCard
                originNames={{
                  'plugin:projects': 'Project Workspace',
                  'plugin:statuspage': 'Statuspage Incidents',
                }}
              />
            </Grid>
          </Grid>
        </>
      </PageLayoutWithTabs.Route>
    </PageLayoutWithTabs>
  );
}

export const ProfilePage = () => {
  return <ProfilePageComponent />;
};
