import React, { Dispatch, SetStateAction, useState } from 'react';

import { Button, DialogContent, Typography } from '@material-ui/core';

import { WarningMessage } from '../WarningMessage/WarningMessage';
import { ResourceSelectRow } from './ResourceSelectRow';
import { emptyAttachUserGroup, TUserGroupAttachment } from './types';

import { useTranslation } from '../../hooks/useTranslation';
import { useStyles } from './styles';

type AttachUserGroupContentProps = {
  userGroupAttachments: TUserGroupAttachment[];
  setDuplicateError: Dispatch<SetStateAction<string>>;
  setUserGroupAttachments: Dispatch<SetStateAction<TUserGroupAttachment[]>>;
  projectId: string;
  setAttachError: Dispatch<SetStateAction<string>>;
  isLoadingUserGroup: boolean;
  isGithubEMU: boolean;
  attachError: string;
  duplicateError: string;
  userGroups: any;
  resourceData: any;
};

export const AttachUserGroupContent = ({
  resourceData,
  userGroups,
  attachError,
  duplicateError,
  userGroupAttachments,
  isGithubEMU,
  isLoadingUserGroup,
  setDuplicateError,
  setUserGroupAttachments,
  projectId,
  setAttachError,
}: AttachUserGroupContentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);

  const addResourceHandler = () => {
    setUserGroupAttachments([...userGroupAttachments, emptyAttachUserGroup]);
    setAttachError('');
  };

  return (
    <DialogContent>
      <ResourceSelectRow
        resourceData={resourceData}
        userGroups={userGroups}
        userGroupAttachments={userGroupAttachments}
        isLoadingUserGroup={isLoadingUserGroup}
        setTotalUsersCount={setTotalUsersCount}
        setDuplicateError={setDuplicateError}
        setUserGroupAttachments={setUserGroupAttachments}
        projectId={projectId}
        setAttachError={setAttachError}
        attachError={attachError}
      />
      {userGroups?.length === 0 && (
        <div className={classes.errorMsg}>
          {t('resource.assignUserGroupsModal.noUserGroupsAvailable')}
        </div>
      )}
      <Button
        id="projects-button-addusergrouprow"
        fullWidth
        variant="outlined"
        className={classes.hyperButton}
        color="default"
        onClick={addResourceHandler}
        disabled={isLoadingUserGroup}
      >
        {t('resource.assignUserGroupsModal.addUserGroupRowButton')}
      </Button>
      {(attachError || duplicateError) && (
        <Typography
          style={{ marginTop: '8px', marginBottom: '8px', color: 'red' }}
        >
          Error: {attachError || duplicateError}
        </Typography>
      )}
      {isGithubEMU && <WarningMessage totalUsersCount={totalUsersCount} />}
    </DialogContent>
  );
};
