import { useIntroductionCard } from './useIntroductionCard';
import { useManagingProjectCard } from './useManagingProjectCard';
import { useStargatePolicyCard } from './useStargatePolicyCard';
import { useStargateResourceCard } from './useStargateResourceCard';

export const useCardTranslation = () => {
  return [
    useIntroductionCard(),
    useManagingProjectCard(),
    useStargateResourceCard(),
    useStargatePolicyCard(),
  ];
};
