import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useTranslation } from '../../../hooks/useTranslation';

export const ArtifactoryGitHubWorkflowAccessNoteSection = (props: {
  name: string;
}) => {
  const { name } = props;
  const { t } = useTranslation();
  return (
    <div style={{ margin: '20px' }}>
      <Typography component="div">
        {t('resource.githubWorkflowAccess.descriptionAccess', {
          /* @ts-ignore */
          name: name,
        })}
      </Typography>
      <Typography
        component="div"
        style={{ marginTop: '10px', fontWeight: 'bold' }}
      >
        {t('resource.githubWorkflowAccess.descriptionNote.text1')}
        <Link
          to="/actions-catalog/artifactory-access"
          style={{ textDecoration: 'underline', color: '#307FFD' }}
        >
          {t('resource.githubWorkflowAccess.descriptionNote.textLink2')}
        </Link>
        {t('resource.githubWorkflowAccess.descriptionNote.text3')}
      </Typography>
    </div>
  );
};
