import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  heading: {
    marginTop: '20px',
    '& th': {
      '&:first-child': {
        width: '58px !important',
      },
    },
    '& td': {
      padding: '9px 20px',
      '& > div > div': {
        width: '85px',
        fontSize: '12px',
        '& > div': {
          padding: '0px 3px 6px 0px !important',
          marginRight: '0px',
        },
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  support: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'center',
  },
  mainHeading: {
    textTransform: 'initial',
    letterSpacing: '0px',
  },
  formControl: {
    width: '207px',
  },
  applyBtn: {
    marginTop: '67px',
  },
  actionsMenu: {
    marginBottom: '48px',
    textTransform: 'initial',
    letterSpacing: '0px',
  },
  filterDivider: {
    height: '105px',
    margin: '10px 5px 0px 5px',
  },
  ClearDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  clearText: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: theme.palette.usgColors.others.mix.grey[200],
    fontWeight: 700,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  requestAccessBtn: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  },
  textField: {
    '& svg': {
      fontSize: '17px',
      marginTop: '1px',
    },
  },
  menuList: {
    fontSize: '12px',
  },
  closeBtn: {
    marginRight: '-16px',
  },
  pagination: {
    '& > div': {
      '& > div': {
        '& > div': {
          '& > div': {
            padding: '0px 16px !important',
          },
        },
      },
    },
  },
  actions: {
    marginTop: '34px',
  },
  supportBtn: {
    marginTop: '-11px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    justifyContent: 'right',
    paddingBottom: '20px',
    marginRight: '16px',
  },
  iconContainer: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '1px',
    paddingRight: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltip: {
    color: theme.palette.textContrast,
    backgroundColor: theme.palette.usgColors.secondary.silverGray,
  },
  tooltipArrow: {
    color: theme.palette.usgColors.secondary.silverGray,
  },
  hyperlink: {
    color: theme.palette.usgColors.others.mix.blue[200],
    textDecoration: 'underline',
  },
}));
