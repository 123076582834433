import React, { useEffect } from 'react';
import { Table, TableColumn } from '@backstage/core-components';
import EditIcon from '@material-ui/icons/Edit';
import { AppEnvKey, AppEnvs, ApplicationEnvValue } from './types';
import { Progress } from '@backstage/core-components';
import {
  appEnvValueString,
  appEnvValueArr,
  isAppEnvValueEmpty,
  isHiddenEnvironmentConfigKey,
  isOptionalEnvironmentConfigKey,
  normalizeName,
} from './FormHelpers';

import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Tabs,
  Tab,
  Chip,
} from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';
import AvailableEnvironmentEdit from './AvailableEnvironmentEdit/AvailableEnvironmentEdit';

type Group = { name: string; uuid: string }; // Define Group type

type EnvData = {
  [env: string]: {
    aad_readonly_groups?: Group[];
    aad_readwrite_groups?: Group[];
  };
};

type EditCallback = (
  envVarName: string,
  envName: string,
  mainEnvName: string,
) => void;

type EnvironmentConfigurationProps = {
  appEnvs: AppEnvs;
  mainEnvName?: string;
  showEditUI?: boolean;
  enableEditableButton?: boolean;
  onEdit?: EditCallback;
  onEnvChange?: (appEnvs: AppEnvs, mainEnvName: string) => void;
  handleCreateResource?: () => void;
  envEditOpen: boolean;
  setEnvEditOpen: any;
  fetchProjectData?: any;
  resourceId?: any;
  currentStargateProjectId?: any;
  setAppResIntialDataDuringUpdate?: any;
  appResIntialDataDuringUpdate?: any;
};

const useStyles = makeStyles((theme: USGTheme) =>
  createStyles({
    btnContainer: {
      background: theme.palette.usgColors?.secondary.lightGray,
      width: 'calc(100% + 16px)',
      margin: '-8px',
      marginBottom: '8px',
      marginTop: '16px',
    },
    newBtnContained: {
      '&:hover': {
        color: theme.palette.usgColors.primary.white,
        backgroundColor: theme.palette.usgColors.primary.blue,
      },
    },
    newBtnOutlined: {
      color: theme.palette.usgColors.primary.blue60,
      backgroundColor: theme.palette.usgColors.primary.white,
      boxShadow: 'none',
      border: `2px solid ${theme.palette.usgColors.primary.blue60}`,
      '&:hover': {
        color: theme.palette.usgColors.primary.white,
        backgroundColor: theme.palette.usgColors.primary.blue,
        border: `2px solid ${theme.palette.usgColors.primary.blue}`,
      },
    },
    btnItem: {
      background: theme.palette.usgColors.primary.white,
      padding: '16px 24px !important',
      borderRadius: '4px',
    },
    chip: {
      margin: theme.spacing(0.5),
      background: theme.palette.usgColors.others.blue[200],
      color: theme.palette.usgColors.others.blue[600],
      '& span': {
        textTransform: 'none',
      },
    },
    envValueButton: {
      position: 'relative',
      display: 'block',
      width: '100%',
      minHeight: '24px',
      lineHeight: '24px',
      border: 'none',
      background: 'transparent',
      margin: '0 0 0 -5px',
      padding: '0 5px',
      boxSizig: 'border-box',
      textAlign: 'left',
      borderRadius: 5,
      cursor: 'pointer',

      '& .edit-icon': {
        width: '5em',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: theme.palette.usgColors.primary.blue,
      },

      '&:disabled': {
        cursor: 'default',
        opacity: 0.75,
        backgroundColor: 'transparent',
        '& .edit-icon': {
          display: 'none',
        },
      },
    },
    errorBox: {
      marginTop: 10,
    },
    envTabs: {
      marginBottom: '24px',
      borderBottom: '2px solid #eeeeee',
      '& span': {
        flexDirection: 'row',
      },
    },
  }),
);

function getConflictMessages(envData: EnvData, parentEnv: string): string[] {
  const conflictMessages: string[] = [];

  // Helper function to get inherited groups
  function getInheritedGroups(
    env: string,
    groupType: 'aad_readonly_groups' | 'aad_readwrite_groups',
  ): Group[] {
    if (envData[env]?.[groupType]) {
      return envData[env][groupType] as Group[];
    }
    return parentEnv && env !== parentEnv
      ? getInheritedGroups(parentEnv, groupType)
      : [];
  }

  for (const env of Object.keys(envData)) {
    // Get groups (inherited if missing)
    const aad_readonly_groups = getInheritedGroups(env, 'aad_readonly_groups');
    const aad_readwrite_groups = getInheritedGroups(
      env,
      'aad_readwrite_groups',
    );

    // Extract group names
    const readonlyNames = new Set(aad_readonly_groups.map(group => group.name));
    const readwriteNames = new Set(
      aad_readwrite_groups.map(group => group.name),
    );

    // Find conflicting groups
    const conflictingGroups = [...readonlyNames].filter(name =>
      readwriteNames.has(name),
    );

    if (conflictingGroups.length > 0) {
      conflictMessages.push(
        `In ${env} env. ${conflictingGroups.join(
          ', ',
        )} can't be in read-write and read-only groups both`,
      );
    }
  }

  return conflictMessages;
}

export function errorMessagesForAppEnvs(
  appEnvs: AppEnvs,
  mainEnvName: string,
): string[] {
  const mainEnvs = appEnvs[mainEnvName] as {
    [key: string]: ApplicationEnvValue;
  };
  if (mainEnvs === undefined || Object.keys(mainEnvs).length === 0) {
    return [
      `Environment configurations for the ${normalizeName(
        mainEnvName,
      )} are empty`,
    ];
  }

  const conflicts = getConflictMessages(appEnvs, mainEnvName);
  if (conflicts && conflicts.length) {
    return conflicts;
  }

  return Object.keys(mainEnvs)
    .filter(
      envVarName =>
        !isOptionalEnvironmentConfigKey(envVarName) &&
        !isHiddenEnvironmentConfigKey(envVarName) &&
        isAppEnvValueEmpty(mainEnvs[envVarName]),
    )
    .map(
      envVarName =>
        `Empty "${envVarName}" value in the ${normalizeName(
          mainEnvName,
        )} environment`,
    );
}

export function EnvironmentValueTableCell({
  rowData,
  envName,
  mainEnvName,
  disabled,
  showEditUI,
  onEdit,
  appEnvs,
}: {
  rowData: any;
  envName: string;
  mainEnvName: string;
  disabled?: boolean;
  showEditUI?: boolean;
  onEdit?: EditCallback;
  appEnvs?: any;
}) {
  const classes = useStyles();
  const handleClick = () => {
    if (onEdit && !disabled) {
      onEdit(rowData.name, envName, mainEnvName);
    }
  };

  if (showEditUI) {
    return (
      <button
        onClick={handleClick}
        disabled={disabled}
        className={classes.envValueButton}
      >
        {appEnvValueString(rowData[envName]) &&
          appEnvValueArr(rowData[envName]).map(cluster => (
            <Chip key={cluster} label={cluster} className={classes.chip} />
          ))}

        <span className="edit-icon">
          <Box
            width={10}
            height={10}
            borderRadius="50%"
            bgcolor="red"
            marginRight="10px"
            display={
              envName !== mainEnvName &&
              Object.keys(appEnvs[envName]).length > 0 &&
              appEnvs[envName][rowData.name]
                ? 'block'
                : 'none'
            }
          />
          <EditIcon />
        </span>
      </button>
    );
  }

  // If user does not have edit permissions, just show values without any edit UI elements
  return (
    <Typography variant="body2" component="p">
      {appEnvValueString(rowData[envName]) &&
        appEnvValueArr(rowData[envName]).map(cluster => (
          <Chip key={cluster} label={cluster} className={classes.chip} />
        ))}
    </Typography>
  );
}

function getKeysExcluding(obj: any, excludeKey: string): string[] {
  return Object.keys(obj).filter(key => key !== excludeKey);
}

export default function EnvironmentConfiguration({
  appEnvs,
  mainEnvName,
  showEditUI,
  enableEditableButton,
  onEdit,
  onEnvChange,
  envEditOpen,
  setEnvEditOpen,
  fetchProjectData,
  resourceId,
  currentStargateProjectId,
  setAppResIntialDataDuringUpdate,
  appResIntialDataDuringUpdate,
}: EnvironmentConfigurationProps) {
  const classes = useStyles();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const envNames = envNamesFromAppEnvs(appEnvs, mainEnvName || '');
  const envVarNames = envVarNamesFromAppEnvs(appEnvs, envNames);

  useEffect(() => {
    if (selectedTabIndex >= envNames.length) {
      setSelectedTabIndex(0);
    }
  }, [envNames, selectedTabIndex]);

  if (!mainEnvName) {
    return (
      <Typography variant="overline" component="p" color="error">
        Main environment could not be determined
      </Typography>
    );
  }

  const handleEnvChange = (envs: AppEnvs, mainEnv: string) => {
    if (onEnvChange) {
      onEnvChange(envs, mainEnv);
    }
    setEnvEditOpen(false);
  };

  const newColumns: TableColumn[] = [
    { title: 'Name', field: 'name', width: '30%' },
    {
      title: 'Details',
      width: '70%',
      field: envNames[selectedTabIndex],
      render: (row: any) => {
        return (
          <EnvironmentValueTableCell
            rowData={row}
            envName={envNames[selectedTabIndex]}
            mainEnvName={mainEnvName}
            onEdit={onEdit}
            showEditUI={showEditUI}
            disabled={!enableEditableButton}
            appEnvs={appEnvs}
          />
        );
      },
    },
  ];

  const tableData: any[] = envVarNames
    .filter(key => !isHiddenEnvironmentConfigKey(key.toString()))
    .map(envVarName =>
      envNames.reduce(
        (row, envName) => {
          const envValues = (appEnvs[envName] || {}) as {
            [key in AppEnvKey]?: ApplicationEnvValue;
          };
          if (Object.hasOwn(envValues, envVarName)) {
            return { ...row, [envName]: envValues[envVarName] };
          }
          const mainEnvValues = (appEnvs[mainEnvName] || {}) as {
            [key in AppEnvKey]?: ApplicationEnvValue;
          };
          return { ...row, [envName]: mainEnvValues[envVarName] || '' };
        },
        { id: envVarName, name: envVarName } as any,
      ),
    );
  const errorMessages = errorMessagesForAppEnvs(appEnvs, mainEnvName);
  const handleTabChange = (_event: any, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  if (selectedTabIndex >= envNames.length) {
    return <Progress />;
  }

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              id="environment-tabs"
              value={selectedTabIndex}
              onChange={handleTabChange}
              className={classes.envTabs}
            >
              {envNames.map(env => (
                <Tab
                  id={`smc-env-${env}-tab`}
                  key={env}
                  label={
                    <>
                      <Box
                        width={10}
                        height={10}
                        borderRadius="50%"
                        bgcolor="red"
                        marginRight="10px"
                        display={
                          env !== mainEnvName &&
                          getKeysExcluding(appEnvs[env], 'aad_user_email')
                            .length > 0
                            ? 'block'
                            : 'none'
                        }
                      />
                      {env}
                    </>
                  }
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
      </Box>

      <Table
        columns={newColumns}
        data={tableData as {}[]}
        options={{
          paging: false,
          filtering: false,
          search: false,
          toolbar: undefined,
        }}
      />

      {errorMessages.length > 0 && (
        <Box className={classes.errorBox}>
          {errorMessages.map(errorMessage => (
            <Typography variant="overline" component="p" color="error">
              {errorMessage}
            </Typography>
          ))}
        </Box>
      )}

      <AvailableEnvironmentEdit
        appEnvs={appEnvs}
        open={envEditOpen}
        mainEnvName={mainEnvName}
        onApply={handleEnvChange}
        onClose={() => setEnvEditOpen(false)}
        fetchProjectData={fetchProjectData}
        resourceId={resourceId}
        currentStargateProjectId={currentStargateProjectId}
        setAppResIntialDataDuringUpdate={setAppResIntialDataDuringUpdate}
        appResIntialDataDuringUpdate={appResIntialDataDuringUpdate}
      />
    </>
  );
}

function envNamesFromAppEnvs(
  appEnvs: AppEnvs,
  configurationBaseKey: string,
): string[] {
  const envs = Object.keys(appEnvs);
  envs.sort((e1, e2) => {
    if (e1 === e2) {
      return 0;
    }
    if (e1 === configurationBaseKey) {
      return -1;
    }
    if (e2 === configurationBaseKey) {
      return 1;
    }
    return e1 < e2 ? -1 : 1;
  });
  return envs;
}

function envVarNamesFromAppEnvs(appEnvs: AppEnvs, envs: string[]): AppEnvKey[] {
  const envVarNames: AppEnvKey[] = [];
  envs.forEach(env => {
    const envVars = appEnvs[env];
    if (envVars !== undefined) {
      Object.keys(envVars).forEach(rawEnvVarName => {
        const envVarName = rawEnvVarName as AppEnvKey;
        if (envVarNames.indexOf(envVarName) < 0) {
          envVarNames.push(envVarName);
        }
      });
    }
  });
  envVarNames.sort();
  return envVarNames;
}
