import React from 'react';

import Stack from '@mui/material/Stack';

import { usePermissions } from '../../../../hooks/usePermissions';
import { ProjectDetails } from '../types';
import { EnvironmentTabsComponent } from './EnvironmentTabs';
import NamespaceVault from './NamespaceVault/NamespaceVaultComponent';
import { ViewEditResourceContext } from '../context';

import { PageTitleBreadcrumbs } from '../../PageTitleBreadcrumbs';

import { useStyles } from '../../styles';

type NewVaultPageComponentProps = {
  projectData: ProjectDetails;
};

export const NewVaultPageComponent = ({
  projectData,
}: NewVaultPageComponentProps) => {
  const classes = useStyles();

  const permissionsData = usePermissions();
  const userDetailsAndPermissions = {
    data: permissionsData.userData,
    email: permissionsData?.userData?.email,
    roles: permissionsData.roles,
    permissions: permissionsData.permissions,
    isAdmin: permissionsData.isAdmin,
    isProjectOwner: permissionsData.isProjectOwner,
    isROAdmin: permissionsData.isROAdmin,
    isAnyAdmin: permissionsData.isAnyAdmin,
    isResourceCreator: permissionsData.isResourceCreator,
    isResourceManager: permissionsData.isResourceManager,
    refresh: permissionsData.refresh,
  };

  const resourceData = {
    name: projectData?.resources[0].name,
    id: projectData?.resources[0].id.toString(),
  };

  return (
    <ViewEditResourceContext.Provider
      value={{
        projectDetails: projectData,
        userDetailsAndPermissions,
      }}
    >
      <PageTitleBreadcrumbs
        projectData={projectData}
        resourceData={resourceData}
      />
      <div className={classes.container}>
        <Stack direction="column" spacing={2}>
          <NamespaceVault resourceData={projectData?.resources[0]} />
          <EnvironmentTabsComponent />
        </Stack>
      </div>
    </ViewEditResourceContext.Provider>
  );
};
