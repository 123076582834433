import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  resourceFilterSection: {
    marginBottom: '10px',
    alignItems: 'center',
  },
  lblNote: {
    float: 'left',
  },
  noteText: {
    fontSize: '12px',
  },
  downloadIcon: {
    marginLeft: 'auto',
    '& svg': {
      float: 'right',
      color: theme.palette.usgColors.others.mix.blue[200],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.usgColors.secondary.blue,
      },
    },
  },
  downloadProgress: {
    marginLeft: 'auto',
    float: 'right',
    color: theme.palette.usgColors.others.mix.blue[200],
  },
  noteDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    justifyContent: 'space-between',
  },
  monthDate: {
    top: '-1px',
    position: 'relative',
    '& input': {
      width: '230px',
      height: '39px',
      padding: '10px',
      fontSize: '16px',
    },
  },
  footerStyle: {
    borderTop: `1px solid ${theme.palette.usgColors.others.mix.grey[2]}`,
    borderBottom: `1px solid ${theme.palette.usgColors.others.mix.grey[2]}`,
    '& td': {
      fontSize: '13px',
      fontWeight: 700,
      color: theme.palette.textContrast,
    },
  },
  applyBtn: {
    marginTop: '25px',
    maxWidth: 'fit-content',
  },
  totalDiv: {
    display: 'flex',
    marginBottom: '10px',
  },
  totalText: {
    paddingRight: '50px',
  },
  totalTitle: {
    color: theme.palette.usgColors.others.mix.blue[200],
    fontWeight: 700,
  },
  checkColor: {
    color: theme.palette.usgColors.others.mix.blue[200],
  },
  tooltip: {
    color: theme.palette.textContrast,
    backgroundColor: theme.palette.usgColors.secondary.silverGray,
  },
  tooltipArrow: {
    color: theme.palette.usgColors.secondary.silverGray,
  },
}));
