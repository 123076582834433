import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandLessOutlined from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';
import Stack from '@mui/material/Stack';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useAsyncFn } from 'react-use';
import { projectApiRef } from '../../api';
import { AntSwitch, CanaryFeatureFlagged } from '@internal/sg-ui-kit';
import { getNonExpiredRoles } from 'sg-utils-frontend';
import { CHANGE_ROLE_ACCESS_DISABLE, Role } from 'usg-types';
import { has } from 'lodash';
import { DateTime } from 'luxon';
import { usePermissions } from '../../hooks/usePermissions';
import { useStyles } from './styles';

interface Props {
  roleList?: Array<Role>;
  maxRolesVisible?: number;
  curentUserEmail: string;
}

interface RoleChecked extends Role {
  checked: boolean;
}

export const UserRoleList: React.FC<Props> = ({
  roleList,
  maxRolesVisible = 2,
  curentUserEmail,
}: any) => {
  const [roles, setRoles] = useState(roleList);
  const [showAll, setShowAll] = useState(false);
  const [assumedRoles, setAssumedRoles] = useState<Array<RoleChecked>>([]);
  const classes = useStyles();
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const [isChangeRoleOpen, setIsChangeRoleOpen] = useState(false);
  const { refresh } = usePermissions(false);

  // only show non expired roles on profile page
  const nonExpiredRoles = getNonExpiredRoles(roles);
  const rolesToShow = showAll
    ? nonExpiredRoles
    : nonExpiredRoles.slice(0, maxRolesVisible);

  useEffect(() => {
    const newArray = roles.map((role: any) => {
      return {
        ...role,
        checked:
          !has(role, 'expired_on') ||
          role.expired_on === null ||
          role.expired_on === undefined ||
          DateTime.fromISO(role?.expired_on) > DateTime.now(),
      };
    });
    setAssumedRoles(newArray);
  }, [roles]);

  const [{ loading, error }, changeRole] = useAsyncFn(async () => {
    const idToken = await authref.getIdToken();

    const newRoles = assumedRoles.map(roleData => {
      let expired_on_date = DateTime.now();
      if (roleData.checked) {
        // if role is checked, set future date(after 24 hrs) to activate
        expired_on_date = expired_on_date.plus({ days: 1 });
      } else {
        // if role is not checked, set previous date to drop it.
        expired_on_date = expired_on_date.minus({ days: 1 });
      }
      return {
        expired_on: expired_on_date.toISO(),
        role: roleData.role,
      };
    });

    const changeRoleParams = {
      roles: newRoles,
    };

    const res = await projectApi.changeUserRole(changeRoleParams, idToken);

    if (res?.status === 200) {
      const nonDeletedRoles = res?.data.roles.filter(
        (role: Role) => !role?.deleted_on,
      );
      setRoles(nonDeletedRoles);
      setIsChangeRoleOpen(false);
      await refresh();
    }
  }, [assumedRoles]);

  if (error) {
    errorApi.post(error);
  }

  const changeRoleHandler = () => {
    setIsChangeRoleOpen(true);
  };

  const closeChangeRoleDialog = () => {
    setIsChangeRoleOpen(false);
  };

  const toggleSelectedRoles = (index: number) => {
    setAssumedRoles(preArray =>
      preArray.map((role, itemIndex) => {
        if (itemIndex === index) {
          return { ...role, checked: !role.checked };
        }
        return role;
      }),
    );
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={8} direction="column">
        <div>
          {rolesToShow.map((userRole: any) => (
            <Chip
              key={userRole.role}
              size="small"
              label={userRole.role}
              className={classes.chip}
            />
          ))}
        </div>
        {nonExpiredRoles.length > maxRolesVisible && (
          <Button
            id="show-more"
            className={classes.button}
            endIcon={showAll ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </Grid>
      <CanaryFeatureFlagged
        flag={CHANGE_ROLE_ACCESS_DISABLE}
        withoutValues={[curentUserEmail]}
      >
        <Grid item xs={4} className={classes.buttonContainer}>
          {roles.length > 0 && (
            <Button
              id="change-role"
              className={classes.button}
              onClick={changeRoleHandler}
              disabled={loading}
            >
              Change Role
            </Button>
          )}
        </Grid>
      </CanaryFeatureFlagged>
      {isChangeRoleOpen && (
        <Dialog
          open={isChangeRoleOpen}
          onClose={closeChangeRoleDialog}
          aria-labelledby="alert-dialog-title"
          className={classes.roleDialog}
          role="alertdialog"
        >
          <DialogTitle id="alert-dialog-title">
            Assume / Drop Roles
            <DialogContentText>
              Note: The roles assumed will be eligible for 24 hours, after that
              you will need to assume the roles again if required.
            </DialogContentText>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h4" component="h4">
              Eligible Roles
            </Typography>
            {assumedRoles.map((assumedRole: RoleChecked, index) => {
              return (
                <Stack
                  key={`${assumedRole.role}-${index}`}
                  direction="row"
                  alignItems="center"
                  className={classes.row}
                >
                  <Typography
                    variant="h5"
                    component="span"
                    className={classes.rowTitle}
                  >
                    {assumedRole.role}
                  </Typography>
                  <AntSwitch
                    checked={assumedRole.checked}
                    onChange={() => toggleSelectedRoles(index)}
                  />
                </Stack>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button
              id="role-cancel-btn"
              variant="outlined"
              size="small"
              onClick={closeChangeRoleDialog}
              disabled={loading}
            >
              CANCEL
            </Button>
            <Button
              id="role-change-btn"
              variant="contained"
              size="small"
              onClick={changeRole}
              disabled={loading}
            >
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
};
