import project from './en/project-en.json';
import projects from './en/projects-en.json';
import resource from './en/resource-en.json';
import common from './en/common-en.json';
import usergroups from './en/usergroups-en.json';
import profile from './en/profile-en.json';
import createproject from './en/createproject-en.json';
import imports from './en/imports-en.json';
import contracts from './en/contracts-en.json';

export const en = {
  project,
  projects,
  resource,
  common,
  usergroups,
  profile,
  createproject,
  imports,
  contracts,
};
