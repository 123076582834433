import { TagOperation } from '../api';
import { GENAI_INDEX_ENABLED } from '../common/SGConstants';

export default function getTagOptions(isEnabled: boolean) {
  const tagOperations: TagOperation[] = [
    {
      op: 'remove',
      value: { [GENAI_INDEX_ENABLED]: isEnabled ? ['false'] : ['true'] },
    },
    {
      op: 'add',
      value: { [GENAI_INDEX_ENABLED]: isEnabled ? ['true'] : ['false'] },
    },
  ];

  return tagOperations;
}
