import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_KEY_MIN_LENGTH,
  RESOURCE_KEY_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
} from 'usg-types';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStyles } from '../styles';

interface Props {
  onSubmitResource: (values: any) => Promise<void>;
  keyDirty: boolean;
  projectData: any;
  onResourceKeyClick: () => void;
  nameDirty: boolean;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
  handleCreateResource: () => void;
}

export const CreateJamaResourceForm = ({
  onSubmitResource,
  keyDirty,
  projectData,
  onResourceKeyClick,
  nameDirty,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
  handleCreateResource,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          jama_resource_key: projectData?.key ?? '',
          jama_resource_name: '',
          jama_resource_description: '',
        }}
        onSubmit={onSubmitResource}
      >
        {formik => (
          <Form>
            <Paper className={classes.formContainer}>
              <Grid item xs={6} className={classes.selectedResourceItem}>
                <Typography
                  variant="subtitle2"
                  className={classes.selectedResourceText}
                >
                  {t('resource.create.jama.title')}
                </Typography>
                <InputLabel
                  htmlFor="jama_resource_key"
                  style={{
                    color:
                      (formik.values.jama_resource_key.length <
                        RESOURCE_KEY_MIN_LENGTH.jama &&
                        keyDirty === true) ||
                      (formik.values.jama_resource_key.length >
                        RESOURCE_KEY_MAX_LENGTH.jama &&
                        keyDirty === true)
                        ? 'red'
                        : 'black',
                  }}
                  className={classes.inputLabelText}
                >
                  {t('resource.view.jama.projectKeyLabel')}*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="jama_resource_key"
                  name="jama_resource_key"
                  required
                  error={
                    (formik.values.jama_resource_key.length <
                      RESOURCE_KEY_MIN_LENGTH.jama &&
                      keyDirty === true) ||
                    !formik.values.jama_resource_key.match('^[a-zA-Z0-9]*$') ||
                    (formik.values.jama_resource_key.length >
                      RESOURCE_KEY_MAX_LENGTH.jama &&
                      keyDirty === true)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.jama_resource_key}
                  onClick={onResourceKeyClick}
                  fullWidth
                  helperText={t('resource.create.jama.projectKeyHelperText', {
                    /* @ts-ignore */
                    min: RESOURCE_KEY_MIN_LENGTH.jama,
                    max: RESOURCE_KEY_MAX_LENGTH.jama,
                  })}
                />

                <br />
                <br />
                <InputLabel
                  htmlFor="jama_resource_name"
                  style={{
                    color:
                      (formik.values.jama_resource_name.length <
                        RESOURCE_NAME_MIN_LENGTH.jama &&
                        nameDirty === true) ||
                      (formik.values.jama_resource_name.length >
                        RESOURCE_NAME_MAX_LENGTH.jama &&
                        nameDirty === true)
                        ? 'red'
                        : 'black',
                  }}
                  className={classes.inputLabelText}
                >
                  {t('resource.view.jama.projectNameLabel')}*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="jama_resource_name"
                  name="jama_resource_name"
                  required
                  error={
                    (formik.values.jama_resource_name.length <
                      RESOURCE_NAME_MIN_LENGTH.jama &&
                      nameDirty === true) ||
                    (!formik.values.jama_resource_name.match(
                      SPECIAL_RESOURCE_NAME_REGEX.jama,
                    ) &&
                      nameDirty) ||
                    (formik.values.jama_resource_name.length >
                      RESOURCE_NAME_MAX_LENGTH.jama &&
                      nameDirty === true)
                  }
                  onChange={formik.handleChange}
                  value={formik.values.jama_resource_name}
                  onClick={onResourceNameClick}
                  fullWidth
                  helperText={t('resource.view.jama.projectNameHelperText', {
                    /* @ts-ignore */
                    min: RESOURCE_NAME_MIN_LENGTH.jama,
                    max: RESOURCE_NAME_MAX_LENGTH.jama,
                  })}
                />

                <br />
                <br />
                <InputLabel
                  htmlFor="jama_project_description"
                  className={classes.inputLabelText}
                >
                  {t('resource.view.jama.descriptionLabel')}
                </InputLabel>
                <TextField
                  type="text"
                  id="jama_resource_description"
                  name="jama_resource_description"
                  onChange={formik.handleChange}
                  value={formik.values.jama_resource_description}
                  minRows={4}
                  multiline
                  fullWidth
                  error={
                    formik.values.jama_resource_description.length >
                      RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
                  }
                  helperText={t('resource.view.jama.descriptionHelperText', {
                    /* @ts-ignore */
                    max: RESOURCE_DESCRIPTION_MAX_LENGTH,
                  })}
                  placeholder={t('resource.view.jama.descriptionPlaceholder')}
                  onClick={onDescriptionClick}
                />
              </Grid>
            </Paper>
            <Paper className={classes.btnContainer}>
              <Grid item xs={12} className={classes.btnItem}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      id="cancel-jama"
                      variant="outlined"
                      onClick={handleCreateResource}
                      className={classes.newBtnOutlined}
                    >
                      {t('common.form.cancelLabel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      id="submit-jama"
                      disabled={
                        formik.values.jama_resource_name.length <
                          RESOURCE_NAME_MIN_LENGTH.jama ||
                        formik.values.jama_resource_key.length <
                          RESOURCE_KEY_MIN_LENGTH.jama ||
                        formik.values.jama_resource_key.length >
                          RESOURCE_KEY_MAX_LENGTH.jama ||
                        !formik.values.jama_resource_key.match(
                          '^[a-zA-Z0-9]*$',
                        ) ||
                        !formik.values.jama_resource_name.match(
                          SPECIAL_RESOURCE_NAME_REGEX.jama,
                        ) ||
                        formik.values.jama_resource_name.length >
                          RESOURCE_NAME_MAX_LENGTH.jama ||
                        apiLoader ||
                        formik.values.jama_resource_description.length >
                          RESOURCE_DESCRIPTION_MAX_LENGTH
                      }
                      variant="contained"
                      className={classes.newBtnContained}
                    >
                      {t('common.form.submitLabel')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
