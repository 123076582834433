import React from 'react';

import { Link } from '@backstage/core-components';

import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

const MAX_TEXT_LENGTH = 30;

export type Props = {
  to: string;
  text: string;
  isLast: boolean;
};

// arrange format url for breadcrumb link
const formatUrl = (rawUrl: string): string => {
  let splitted = rawUrl.split('/');

  // get the index of the segment which starts with question mark (query param)
  const questionIndex = splitted.findIndex(e => e.includes('?'));

  // if no question mark in url or question mark is already at the end of url
  // then we just return the url
  if (questionIndex === -1 || questionIndex === splitted.length - 1) {
    // query params does not exists in url
    return splitted.join('/');
  }

  // if the question mark segment (query param) not in the end then we can exclude it
  // coz usually this is caused by appendix of previous link in breadcrumbs and not really useful for the next one
  splitted = splitted.map(s => {
    const questionMarkIndex = s.indexOf('?');
    if (questionMarkIndex === -1) {
      return s;
    }
    return s.substring(0, questionMarkIndex);
  });

  return splitted.join('/');
};

const truncateText = (text: string) => {
  if (text?.length > MAX_TEXT_LENGTH) {
    return `${text.slice(0, MAX_TEXT_LENGTH)}...`;
  }
  return text;
};

export const BreadcrumbLink = ({ to, text, isLast }: Props) => {
  const classes = useStyles();
  return (
    <Link to={formatUrl(to)} color="inherit" className={classes.link}>
      <Typography
        variant="caption"
        className={classes.text}
        style={{ fontWeight: isLast ? 'bold' : 'regular' }}
      >
        {truncateText(text)}
      </Typography>
    </Link>
  );
};
