import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  container: {
    padding: theme.spacing(5),
    gridArea: 'pageContent',
  },
  light: {
    backgroundColor: theme.palette.usgColors?.primary?.white,
  },
}));
