import { getTechDocsLink } from 'sg-utils-frontend';
import { useTranslation } from '../../../useTranslation';

export const useStargatePolicyCard = () => {
  const { t } = useTranslation();
  return {
    title: t('techdocs.cards.stargatePolicy.title'),
    description: t('techdocs.cards.stargatePolicy.description'),
    image: 'image4.webp',
    documents: [
      {
        title: t('techdocs.cards.stargatePolicy.securityPolicies'),
        link: getTechDocsLink('security-banner'),
      },
      {
        title: t('techdocs.cards.stargatePolicy.dataRetention'),
        link: getTechDocsLink('data-retention'),
      },
      {
        title: t('techdocs.cards.stargatePolicy.confidentialityAgreement'),
        link: getTechDocsLink('confidentiality-agreement'),
      },
    ],
  };
};
