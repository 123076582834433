import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  RESOURCE_NAME_MAX_LENGTH,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  RESOURCE_GITHUB_TRANSLATION_PREFIX,
} from 'usg-types';
import { Link } from '@backstage/core-components';
import { getTechDocsLink } from 'sg-utils-frontend';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStyles } from '../styles';

interface Props {
  onSubmitResource: (values: any) => Promise<void>;
  nameDirty: boolean;
  projectData: any;
  onResourceNameClick: () => void;
  descriptionDirty: boolean;
  onDescriptionClick: () => void;
  apiLoader: boolean;
  handleCreateResource: () => void;
}

export const CreateGithubEMUResourceForm = ({
  onSubmitResource,
  nameDirty,
  projectData,
  onResourceNameClick,
  descriptionDirty,
  onDescriptionClick,
  apiLoader,
  handleCreateResource,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          githubemu_organization_name: projectData?.key
            ? projectData?.key.toLowerCase()
            : '',
          githubemu_organization_description: '',
        }}
        onSubmit={(values: any) => {
          const lowercaseValues = {
            ...values,
            githubemu_organization_name:
              values.githubemu_organization_name.toLowerCase(),
          };
          onSubmitResource(lowercaseValues);
        }}
      >
        {({ handleChange, values }) => (
          <Form>
            <Paper className={classes.formContainer}>
              <Grid item xs={6} className={classes.selectedResourceItem}>
                <Typography
                  variant="subtitle2"
                  className={classes.selectedResourceText}
                >
                  {t('resource.create.githubEmu.githubOrganization')}
                </Typography>
                <p>
                  {t('resource.create.githubEmu.note')}{' '}
                  <Link
                    className={classes.hyperLink}
                    to="/news?id=EMU-migration-announcement"
                    relative="path"
                    target="_blank"
                  >
                    {t('resource.create.githubEmu.learnMoreBenefitsLink')}
                  </Link>
                </p>
                <p>
                  {t('resource.create.githubEmu.secondNote')}{' '}
                  <Link
                    className={classes.hyperLink}
                    to={getTechDocsLink('vpn')}
                    relative="path"
                    target="_blank"
                  >
                    {t('common.link.learnMore')}
                  </Link>
                  .
                </p>
                <InputLabel
                  htmlFor="githubemu_organization_name"
                  style={{
                    color:
                      (values.githubemu_organization_name?.length <
                        RESOURCE_NAME_MIN_LENGTH.github &&
                        nameDirty) ||
                      (values.githubemu_organization_name?.length >
                        RESOURCE_NAME_MAX_LENGTH.github &&
                        nameDirty)
                        ? 'red'
                        : 'black',
                  }}
                  className={classes.inputLabelText}
                >
                  {t(
                    `${RESOURCE_GITHUB_TRANSLATION_PREFIX}.organizationNameLabel`,
                  )}
                  *
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="githubemu_organization_name"
                  name="githubemu_organization_name"
                  required
                  error={
                    (values.githubemu_organization_name?.length <
                      RESOURCE_NAME_MIN_LENGTH.github &&
                      nameDirty) ||
                    (!values.githubemu_organization_name?.match(
                      '^([a-z0-9]+-)*[a-z0-9]+$',
                    ) &&
                      nameDirty) ||
                    (values.githubemu_organization_name?.length >
                      RESOURCE_NAME_MAX_LENGTH.github &&
                      nameDirty)
                  }
                  onChange={handleChange}
                  value={values.githubemu_organization_name?.toLowerCase()}
                  onClick={onResourceNameClick}
                  fullWidth
                  helperText={t(
                    `${RESOURCE_GITHUB_TRANSLATION_PREFIX}.organizationNameHelperText`,
                    {
                      /* @ts-ignore */
                      min: RESOURCE_NAME_MIN_LENGTH.github,
                      max: RESOURCE_NAME_MAX_LENGTH.github,
                    },
                  )}
                />

                <br />
                <br />
                <InputLabel
                  htmlFor="githubemu_organization_description"
                  className={classes.inputLabelText}
                >
                  {t(`${RESOURCE_GITHUB_TRANSLATION_PREFIX}.descriptionLabel`)}
                </InputLabel>
                <TextField
                  type="text"
                  id="githubemu_organization_description"
                  name="githubemu_organization_description"
                  onChange={handleChange}
                  value={values.githubemu_organization_description}
                  minRows={4}
                  multiline
                  fullWidth
                  error={
                    values.githubemu_organization_description.length >
                      GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH && descriptionDirty
                  }
                  helperText={t(
                    `${RESOURCE_GITHUB_TRANSLATION_PREFIX}.descriptionHelperText`,
                    {
                      /* @ts-ignore */
                      max: GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
                    },
                  )}
                  placeholder={t(
                    `${RESOURCE_GITHUB_TRANSLATION_PREFIX}.descriptionPlaceholder`,
                  )}
                  onClick={onDescriptionClick}
                />
              </Grid>
            </Paper>
            <Paper className={classes.btnContainer}>
              <Grid item xs={12} className={classes.btnItem}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      id="cancel-githubemu"
                      variant="outlined"
                      onClick={handleCreateResource}
                      className={classes.newBtnOutlined}
                    >
                      {t('common.form.cancelLabel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      id="submit-githubemu"
                      disabled={
                        values.githubemu_organization_name?.length <
                          RESOURCE_NAME_MIN_LENGTH.github ||
                        values.githubemu_organization_name?.length >
                          RESOURCE_NAME_MAX_LENGTH.github ||
                        !values.githubemu_organization_name
                          ?.toLowerCase()
                          .match('^([a-z0-9]+-)*[a-z0-9]+$') ||
                        values.githubemu_organization_description.length >
                          GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH ||
                        apiLoader
                      }
                      variant="contained"
                      className={classes.newBtnContained}
                    >
                      {t('common.form.submitLabel')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
