import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  dialog: {
    position: 'absolute',
    left: 10,
    top: 50,
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      color: theme.palette.usgColors.primary.blue,
    },
  },
  hyperLink: {
    color: theme.palette.usgColors.primary.blue,
    textDecoration: 'underline',
  },
  copyLabel: {
    marginTop: '0px',
    marginBottom: '8px',
    marginLeft: '24px',
    color: theme.palette.usgColors.primary.gray,
  },
  labelHeader: {
    marginBottom: '4px',
    marginTop: '24px',
  },
  deleteButton: {
    textAlign: 'right',
    padding: 0,
  },
  viewTag: {
    fontSize: 12,
    marginTop: '0',
    overflowWrap: 'break-word',
  },
  enviText: {
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'capitalize',
    paddingBottom: '5px',
  },
  btnAlign: {
    textAlign: 'end',
  },
  tableCell: {
    padding: '8px',
    fontWeight: 'bold',
    color: theme.palette.textContrast,
  },
  selectMenu: {
    paddingLeft: '0 !important',
  },
  tableHeaderRow: {
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
  },
  tableHeaderCell: {
    padding: '8px',
    fontWeight: 'bold',
    border: 'none',
    color: theme.palette.grey[700],
  },
  tableFooterNavigation: {
    padding: '0px 24px',
  },
  tableFooterAction: {
    color: theme.palette.primary.main,
  },
  userChip: {
    '& div > div > div > span': {
      textTransform: 'inherit',
    },
    '& span': {
      textTransform: 'lowercase',
    },
  },
  errorUserChip: {
    width: '75%',
    '& div > div > div > span': {
      textTransform: 'inherit',
    },
    color: theme.palette.usgColors.secondary.darkRed,
  },
  errorChip: {
    '& svg': {
      color: theme.palette.usgColors.secondary.darkRed,
      '&:hover': {
        color: theme.palette.usgColors.secondary.darkRed,
      },
    },
    textTransform: 'inherit',
    color: theme.palette.usgColors.secondary.darkRed,
    border: `1px solid ${theme.palette.usgColors.secondary.darkRed}`,
  },

  selectedChip: {
    '& span': {
      textTransform: 'inherit',
      marginTop: '-2px',
    },
  },
  addGroupManager: {
    width: '75%',
  },
  shareableUrl: {
    padding: '15px 0px 40px',
    display: 'flex',
    alignItems: 'end',
  },
  errorMsg: {
    display: 'flex',
    color: theme.palette.usgColors.secondary.darkRed,
    alignItems: 'center',
    '& svg': {
      marginRight: '10px',
    },
  },
  archivedChip: {
    backgroundColor: theme.palette.usgColors.others.mix.blue[600],
    color: theme.palette.usgColors.others.mix.blue[500],
    paddingBottom: '2px',
    marginBottom: 0,
    border: 'aliceblue',
    height: '29px',
  },
  saveDivSection: {
    padding: '50px 0px 40px',
  },
  resourceManagerGrid: {
    width: '75% !important',
  },
  dialogCloseIcon: {
    right: '12px',
    top: '12px',
    color: `${theme.palette.grey[800]} !important`,
  },
  dialogCancelButton: {
    justifyContent: 'flex-start',
    marginLeft: '20px',
    marginBottom: '10px',
  },
  enabledBtn: {
    color: theme.palette.usgColors.primary.blue50,
  },
  disabledBtn: {
    color: theme.palette.usgColors.secondary.grey50,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  copyResourceUrl: {
    marginLeft: '24px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  inProgressStatus: {
    color: theme.palette.status.warning,
  },
  successStatus: {
    color: theme.palette.status.ok,
  },
  failedStatus: {
    color: theme.palette.status.error,
  },
}));
