import React from 'react';

import { Tabs } from '@internal/sg-ui-kit';
import { Alert } from '@material-ui/lab';

import {
  GITHUBEMU_DEV_TOOL_ID,
  TABS_USERGROUPS_TRANSLATION_PREFIX,
  VAULT_DEV_TOOL_ID,
} from 'usg-types';
import { UserGroupsTab } from '../../UserGroupsTab/UserGroupsTab';
import { SortedUserGroupsArrayType } from '../types';
import { VaultViewEditTab } from './VaultViewEditTab';
import { useTranslation } from '../../../../hooks/useTranslation';
import { usePermissions } from '../../../../hooks/usePermissions';

type AssignableTabsProps = {
  unarchiveBtn: boolean;
  fetchAssignedUserGroups: () => Promise<void>;
  assignedUserGroups: any[];
  resourceData: any;
  resourceRoles: any;
  projectId: string;
  ugLoading: boolean;
  sortedUserGroupsArray: SortedUserGroupsArrayType;
  resourceManagerCheck: boolean;
  fetchContent?: any;
  setIsContentVisible: any;
  isContentVisible: boolean;
  resourceManagerData?: any;
  isAddUserEnable?: any;
  setIsAddUserEnable?: any;
  setIsAddUserLoading?: any;
  formik?: any;
  isAddUserLoading?: any;
  resourceManagerList?: any;
  fetchResourceManagers?: any;
  userEmail?: any;
  value: number;
  setValue: (value: number) => void;
};

export const AssignableTabs = ({
  unarchiveBtn,
  fetchAssignedUserGroups,
  assignedUserGroups,
  resourceData,
  resourceRoles,
  projectId,
  ugLoading,
  sortedUserGroupsArray,
  resourceManagerCheck,
  fetchContent,
  setIsContentVisible,
  isContentVisible,
  resourceManagerData,
  isAddUserEnable,
  setIsAddUserEnable,
  setIsAddUserLoading,
  formik,
  isAddUserLoading,
  resourceManagerList,
  fetchResourceManagers,
  userEmail,
  value,
  setValue,
}: AssignableTabsProps) => {
  const {
    isProjectOwner,
    isAnyAdmin,
    isAdmin,
    isResourceManager,
    isResourceCreator,
    refresh,
  } = usePermissions(false);
  const { t } = useTranslation();

  if (resourceData?.dev_tool_id === VAULT_DEV_TOOL_ID) {
    // console.log(3, resourceManagerData);
    // return <Typography>vault view edit tab</Typography>;
    return (
      <VaultViewEditTab
        resourceData={{ ...resourceData, roles: [...resourceRoles] }}
        projectId={projectId}
        attachableUserGroups={sortedUserGroupsArray}
        fetchContent={fetchContent}
        setIsContentVisible={setIsContentVisible}
        isContentVisible={isContentVisible}
        isAdmin={isAdmin}
        isProjectOwner={isProjectOwner}
        isResourceManager={isResourceManager}
        isResourceCreator={isResourceCreator}
        resourceManagerData={resourceManagerData}
        isAddUserEnable={isAddUserEnable}
        setIsAddUserEnable={setIsAddUserEnable}
        setIsAddUserLoading={setIsAddUserLoading}
        formik={formik}
        isAddUserLoading={isAddUserLoading}
        resourceManagerList={resourceManagerList}
        fetchResourceManagers={fetchResourceManagers}
        userEmail={userEmail}
        refresh={refresh}
        value={value}
        setValue={setValue}
      />
    );
  }
  return (
    <Tabs
      tabs={[
        {
          label: t(`${TABS_USERGROUPS_TRANSLATION_PREFIX}.title`),
          value: 'assigned_usergroups',
          content: (
            <div>
              {unarchiveBtn ? (
                <Alert severity="info" id="no-permission-alert">
                  <div>
                    {t(
                      `${TABS_USERGROUPS_TRANSLATION_PREFIX}.unavailableForArchived`,
                    )}
                  </div>
                </Alert>
              ) : (
                <UserGroupsTab
                  fetchUserGroupResources={fetchAssignedUserGroups}
                  attachedUserGroups={assignedUserGroups}
                  resourceData={{ ...resourceData, roles: [...resourceRoles] }}
                  projectId={projectId}
                  attachableUserGroups={sortedUserGroupsArray}
                  loadingFetchUserGroupResources={ugLoading}
                  isAdmin={isAnyAdmin}
                  isOwner={isProjectOwner(Number(projectId))}
                  isResourceArchived={unarchiveBtn}
                  resourceManagerCheck={resourceManagerCheck}
                  isGithubEMU={
                    resourceData.dev_tool_id === GITHUBEMU_DEV_TOOL_ID
                  }
                />
              )}
            </div>
          ),
        },
      ]}
    />
  );
};
