import { getTechDocsLink } from 'sg-utils-frontend';
import { useTranslation } from '../../../useTranslation';

export const useStargateResourceCard = () => {
  const { t } = useTranslation();
  return {
    title: t('techdocs.cards.stargateResources.title'),
    description: t('techdocs.cards.stargateResources.description'),
    image: 'image3.webp',
    documents: [
      {
        title: t('techdocs.cards.stargateResources.tools'),
        link: getTechDocsLink('list-of-tools'),
      },
      {
        title: t('techdocs.cards.stargateResources.serviceCatalog'),
        link: getTechDocsLink('service-catalog'),
      },
      {
        title: t('techdocs.cards.stargateResources.ipWhitelisting'),
        link: getTechDocsLink('ip-whitelisting'),
      },
    ],
  };
};
