import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  root: {
    color: theme.palette.usgColors.primary.white,
  },
  container: {
    backgroundColor: '#06181d',
    opacity: '0.96',
    width: '480px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 6),
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    '& h6,h3,button': {
      color: theme.palette.usgColors.primary.white,
      '&:hover': {
        color: theme.palette.usgColors.primary.white,
      },
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logoIcon: {
    width: '56px',
    height: '56px',
    marginRight: theme.spacing(1.5),
  },
  title: {
    fontWeight: 600,
  },
  subtitle: {
    padding: theme.spacing(4, 0),
    borderBottom: '1px solid rgba(255, 255, 255, .2)',
  },
  textLine: {
    margin: theme.spacing(5, 0, 3),
  },
  copyrightIcon: {
    width: '14px',
    height: '14px',
    color: '#9b9c9d',
    marginRight: theme.spacing(0.5),
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  date: {
    color: '#9b9c9d',
  },
  button: {
    backgroundColor: 'rgba(88, 89, 91, 0.3)',
    width: '384px',
    padding: theme.spacing(2, 3),
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '64px',
    textTransform: 'none',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: 'rgba(88, 89, 91, 0.5)',
    },
  },
  arrowIcon: {
    width: '24px',
    height: '24px',
  },
  authIcon: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(2),
  },
  panel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '320px',
    '& h6': {
      textTransform: 'uppercase',
      marginInline: 'auto',
    },
  },
}));
