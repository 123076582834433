import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  title: {
    paddingLeft: '5%',
    paddingRight: '5%',
    fontFamily: 'ToyotaType',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  popoverDownButton: {
    paddingLeft: '5px',
    paddingTop: '10px',
  },
  popover: {
    marginTop: '8px',
  },
  listItemMain: {
    maxWidth: '420px',
    fontFamily: 'ToyotaType',
  },
  contentCopy: {
    color: theme.palette.usgColors.primary.blue60,
  },
  divider: {
    width: '90% !important',
    marginLeft: '5% !important',
  },
}));
