import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  successBoxIcon: {
    color: '#009C0D',
    background: 'transparent',
  },
  successBoxHeading: {
    fontSize: '14px !important',
  },
  successBoxSubHeading: {
    fontSize: '14px !important',
  },
  successBoxMsgItem: {
    paddingLeft: '16px',
  },
  enabledBtn: {
    color: theme.palette.usgColors.primary.blue50,
  },
  disabledBtn: {
    color: theme.palette.usgColors.secondary.grey50,
  },
}));
