import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'catalog-manager',
});

export const registerServiceRouteRef = createRouteRef({
  id: 'register-service',
});

export const editServiceRouteRef = createRouteRef({
  id: 'edit-service',
  params: ['serviceId'],
});
