import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
    position: 'relative',
    paddingTop: '8px',
    '& label': {
      marginTop: '10px',
    },
    '& .v5-MuiTextField-root, .MuiOutlinedInput-root, .MuiFormHelperText-root':
      {
        width: '50%',
      },
  },
  invalidField: {
    color: `${theme.palette.usgColors.others.mix.red[300]} !important`,
  },
  buttons: {
    marginTop: '30px',
    '& button': {
      marginRight: '10px',
    },
  },
  helperText: {
    marginLeft: 0,
    marginRight: '14px',
    width: '50%',
    marginTop: '10px',
    fontSize: '12px',
  },
}));
