import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  emailItem: {
    background: theme.palette.usgColors.others.blue[200],
    color: theme.palette.usgColors.others.blue[600],
    borderRadius: '12px',
    padding: '6px 14px 10px',
    fontSize: '12px',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mailsContainer: {
    alignItems: 'center',
  },

  resourceManagersEditModeContainer: {
    marginBottom: '40px',
  },
  resourceManagersNonEditModeContainer: {
    marginBottom: '10px',
  },
  grid20: {
    maxWidth: '20%',
    flexBasis: '18% ',
  },
  resourceManagerHeadingContainer: {
    paddingBottom: '0 ',
  },
  labelHeader: {
    marginBottom: '0px',
    marginTop: '24px',
  },
  noManagers: {
    marginTop: 0,
  },
  resourceManagerGrid: {
    width: '75% ',
  },
}));
