import React, { useEffect } from 'react';
import { Progress } from '@backstage/core-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const SMCTempRedirect = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const redirectUrl = searchParams.get('redirect');
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true }); // Redirect if `redirect` param exists
    } else {
      navigate('/', { replace: true }); // Otherwise, navigate to home
    }
  }, [searchParams, navigate]);

  return <Progress />;
};
