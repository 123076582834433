import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  mtfujiWizard: {
    paddingBottom: '40px',
  },
  pendingCardMediaHeadings: {
    '& h3, & h4': {
      color: theme.palette.usgColors.primary.white,
    },
  },
  mtfujiContent: {
    padding: '20px 0 !important',
  },

  mtfujiContentProjSel: {
    padding: '20px 40px !important',
    '& h3': {
      paddingBottom: '20px',
    },
  },

  muiAlertMessage: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },

  code: {
    padding: '0.2em 0.4em',
    margin: '0',
    fontSize: '85%',
    backgroundColor: '#8f939942',
    borderRadius: '6px',
  },

  overviewItemCode: {
    padding: '0.2em 0.4em',
    fontSize: 'inherit',
    overflowX: 'scroll',
    minWidth: '100%',
    width: '0px',
    display: 'inline-block',
    whiteSpace: 'normal',
  },

  snippetCode: {
    display: 'block',
    width: 'auto',
    overflowX: 'auto',
    overflowY: 'visible',
    padding: '12px',
  },

  muiCardRoot: {
    height: '100%',
  },

  muiCollapseRootCardContent: {
    padding: '0 24px 24px',
  },

  overviewItem: {
    paddingBottom: '0px',
    '&.last-item': {
      paddingBottom: '24px',
    },
    '& .overview-item-value': {
      whiteSpace: 'pre',
      overflowWrap: 'break-word',
      wordBreak: 'keep-all',
      wordWrap: 'break-word',
    },
  },

  mtfujiFieldArrayTable: {
    '& tbody': {
      '& td': {
        borderBottom: '0',
        '& p': {
          marginTop: '0',
        },
      },
      '& tr:nth-of-type(even)': {
        backgroundColor: '#f7f7f7',
      },
      '& tr td:not(:last-child) > div': {
        marginTop: '10px',
      },
      '& tr td:last-child > div': {
        marginTop: '-15px',
      },
    },
  },

  submitButton: {
    '&:hover': {
      backgroundColor: '#0e4295',
    },
  },
  dialogGrid: {
    boxSizing: 'border-box',
    padding: '10px',
  },
  appSettingContainer: {
    paddingBottom: '20px',
    borderBottom: `1px solid ${theme.palette.usgColors.secondary.grey50}`,
    marginBottom: '20px',
  },
  formContainer: {
    padding: '16px 16px 24px 16px',
    width: 'calc(100% + 16px)',
    margin: '-8px',
    marginBottom: '85px',
  },
  bottomNav: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: 'calc(100% + 16px)',
    margin: '-8px',
    marginBottom: '1px',
    marginTop: '20px',
    padding: '10px 0',
    zIndex: 1,
  },
  envText: {
    lineHeight: '40px',
  },
  envTable: {
    paddingTop: '0 !important',
  },
  editEnvContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  iconContainer: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '1px',
    paddingRight: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '26px',
  },

  tooltip: {
    color: theme.palette.textContrast,
    backgroundColor: theme.palette.usgColors.secondary.silverGray,
  },
  tooltipArrow: {
    color: theme.palette.usgColors.secondary.silverGray,
  },
  root: {
    padding: theme.spacing(3),
    maxWidth: 900,
    margin: 'auto',
    backgroundColor: '#fff',
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 600,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  editLink: {
    float: 'right',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  dividerVertical: {
    height: 'auto', // Ensures full height inside flex container
    alignSelf: 'stretch', // Stretches within its parent grid container
    width: 1,
    backgroundColor: theme.palette.usgColors.secondary.grey50,
    margin: '0 16px',
  },
}));
