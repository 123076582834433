import React from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  GITHUBEMU_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
} from 'usg-types';
import { projectApiRef } from '@internal/plugin-projects';
import { useForm } from 'react-hook-form';
import { definitions } from '../api';
import { useStyles } from './styles';

type NamespaceAutocompletionResponseProjectType =
  definitions['handlers.NamespaceAutocompletionResponseProject'];

type GithubOrganizationInput = {
  organization_name: string;
  organization_description: string;
};

type AllowedDevTools = typeof GITHUBEMU_DEV_TOOL_ID | typeof GITHUB_DEV_TOOL_ID;

export type CreateGithubOrgDialogProps = {
  open: boolean;
  handleDialogClose: any;
  stargateProject: NamespaceAutocompletionResponseProjectType;
  devTool: AllowedDevTools;
};

export const CreateGithubOrgDialog = (props: CreateGithubOrgDialogProps) => {
  const { open, handleDialogClose, stargateProject, devTool } = props;
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const classes = useStyles();
  const defaultValues: GithubOrganizationInput = {
    organization_name: stargateProject.key.toLowerCase(),
    organization_description: `Stargate workspace ${stargateProject.name} repositories`,
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
    getFieldState,
  } = useForm<GithubOrganizationInput>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = async ({
    organization_name,
    organization_description,
  }: GithubOrganizationInput) => {
    try {
      const idToken = await authref.getIdToken();

      const resp: any = await projectApi.createNewProjectResource(
        stargateProject.id.toString(),
        {
          idToken,
          key: organization_name.toLowerCase(),
          name: organization_name.toLowerCase(),
          description: organization_description,
          dev_tool_id: devTool,
        },
      );

      if (resp && resp.error) {
        throw new Error(resp.error.message);
      }

      handleDialogClose(resp);
      reset();
    } catch (err) {
      errorApi.post(
        new Error(`${err?.message || 'Failed to create project resource'}`),
      );
    }
  };

  // return (
  //   <Dialog
  //     open={open}
  //     onClose={() => handleDialogClose({ name: "", key: "" })}
  //     maxWidth="sm"
  //   >
  //     <DialogTitle>
  //       <div className={classes.dialogHeader}>
  //         <Typography variant="h3">{t("dialog.title")}</Typography>
  //         <Tooltip
  //           classes={{
  //             tooltip: classes.tooltip,
  //             arrow: classes.tooltipArrow,
  //           }}
  //           arrow
  //           placement="top"
  //           title={t("dialog.tooltipCloseButton")}
  //         >
  //           <IconButton
  //             id="create-github-org-close-icon-btn"
  //             aria-label="close"
  //             className={classes.iconContainer}
  //             onClick={() => handleDialogClose()}
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //         </Tooltip>
  //       </div>
  //     </DialogTitle>
  //     <DialogContent id="ug-create-confirmed-dialog" dividers>
  //       <div style={{ paddingBottom: "24px" }}>
  //         <form onSubmit={handleSubmit(onSubmit)}>
  //           <div>
  //             <p>
  //               {t("form.confirmationText", {
  //                 platform: devTool === GITHUBEMU_DEV_TOOL_ID ? "EMU" : "Enterprise",
  //               })}
  //             </p>
  //           </div>
  //           <div>
  //             <InputLabel error={!isValid} htmlFor="organization_name">
  //               {t("form.organizationNameLabel")}
  //             </InputLabel>
  //             <TextField
  //               style={{ paddingBottom: "0%" }}
  //               type="text"
  //               id="organization_name"
  //               defaultValue={defaultValues?.organization_name}
  //               error={!isValid && getFieldState("organization_name").isTouched}
  //               fullWidth
  //               helperText={t("form.organizationNameHelperText", {
  //                 min: RESOURCE_NAME_MIN_LENGTH.github,
  //                 max: RESOURCE_NAME_MAX_LENGTH.github,
  //               })}
  //               {...register("organization_name", {
  //                 required: true,
  //                 pattern: /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/,
  //                 minLength: RESOURCE_NAME_MIN_LENGTH.github,
  //                 maxLength: RESOURCE_NAME_MAX_LENGTH.github,
  //               })}
  //             />
  //           </div>
  //           <div style={{ marginTop: "16px" }}>
  //             <InputLabel htmlFor="organization_description">
  //               {t("form.descriptionLabel")}
  //             </InputLabel>
  //             <TextField
  //               type="text"
  //               id="organization_description"
  //               defaultValue={defaultValues?.organization_description}
  //               minRows={4}
  //               multiline
  //               fullWidth
  //               helperText={t("form.descriptionHelperText", {
  //                 max: GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  //               })}
  //               placeholder={t("form.descriptionPlaceholder")}
  //               {...register("organization_description", {
  //                 maxLength: GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
  //               })}
  //             />
  //           </div>
  //           <div style={{ paddingTop: "5%" }}>
  //             <Grid container spacing={2}>
  //               <Grid item>
  //                 <Button
  //                   id="create-github-org-cancel-button"
  //                   variant="outlined"
  //                   type="reset"
  //                   onClick={() => handleDialogClose()}
  //                 >
  //                   {t("buttons.cancel")}
  //                 </Button>
  //               </Grid>

  //               <Grid item>
  //                 <Button
  //                   id="create-github-org-submit-button"
  //                   variant="contained"
  //                   type="submit"
  //                   disabled={!isValid}
  //                 >
  //                   {t("buttons.create")}
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         </form>
  //       </div>
  //     </DialogContent>
  //   </Dialog>
  // );

  return (
    <Dialog
      open={open}
      onClose={() => handleDialogClose({ name: '', key: '' })}
      maxWidth="sm"
    >
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography variant="h3">Create Github Organization</Typography>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              id="create-github-org-close-icon-btn"
              aria-label="close"
              className={classes.iconContainer}
              onClick={() => handleDialogClose()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent id="ug-create-confirmed-dialog" dividers>
        <div style={{ paddingBottom: '24px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <p>
                This will create a new organization on Stargate GitHub{' '}
                {devTool === GITHUBEMU_DEV_TOOL_ID ? 'EMU' : 'Enterprise'}.
              </p>
            </div>
            <div>
              <InputLabel error={!isValid} htmlFor="organization_name">
                Github Organization Name*
              </InputLabel>

              <TextField
                style={{ paddingBottom: '0%' }}
                type="text"
                id="organization_name"
                defaultValue={defaultValues?.organization_name}
                error={getFieldState('organization_name').invalid}
                fullWidth
                helperText={`Provide a unique name for your GitHub organization. Only letters, numbers and single hyphens allowed. Must not end with hyphen. No. of Characters allowed Min=${RESOURCE_NAME_MIN_LENGTH.github}, Max=${RESOURCE_NAME_MAX_LENGTH.github}`}
                {...register('organization_name', {
                  required: true,
                  pattern: /^[a-z0-9]+(-[a-z0-9]+)*$/,
                  maxLength: RESOURCE_NAME_MAX_LENGTH.github,
                  minLength: RESOURCE_NAME_MIN_LENGTH.github,
                })}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <InputLabel htmlFor="organization_description">
                Description
              </InputLabel>
              <TextField
                type="text"
                id="organization_description"
                defaultValue={defaultValues?.organization_description}
                minRows={4}
                multiline
                fullWidth
                error={getFieldState('organization_description').invalid}
                helperText={`Provide a brief description for your GitHub organization to explain what it is for. The description shows up on your organization's profile page. Number of characters allowed Max = ${GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH}`}
                placeholder="Example: GitHub organization for developing vehicle software component."
                {...register('organization_description', {
                  maxLength: GITHUB_RESOURCE_DESCRIPTION_MAX_LENGTH,
                })}
              />
            </div>
            <div style={{ paddingTop: '5%' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    id="create-github-org-cancel-button"
                    variant="outlined"
                    type="reset"
                    onClick={() => handleDialogClose()}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    id="create-github-org-submit-button"
                    variant="contained"
                    type="submit"
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
