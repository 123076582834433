import React, { useState } from 'react';

import { Content, WarningPanel } from '@backstage/core-components';
import { SearchType } from '@backstage/plugin-search';
import {
  SearchBar,
  SearchResult,
  SearchResultPager,
  useSearch,
} from '@backstage/plugin-search-react';
import { Grid, List, makeStyles, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { searchResultsData } from 'sg-utils-frontend';
import { USGTheme } from 'usg-new-theme';
import { DEFAULT_DEBOUNCE_TIME, SEARCH_INPUT_MAX_LENGTH } from 'usg-types';

import { CatalogSearchResultListItem } from '@internal/plugin-catalog-fork';
import { useTranslation } from '../../useTranslation';
import { Link, PageLayout, PageTitle } from '@internal/sg-ui-kit';

interface DocumentType {
  type: string;
  document: any;
  duplicates: any;
}

const useStyles = makeStyles((theme: USGTheme) => ({
  searchBox: {
    border: `2px solid ${theme.palette.usgColors.primary.blue}`,
    borderRadius: '15px',
    padding: '5px',
  },
  infoBox: {
    marginTop: '5px',
    padding: '5px',
  },
  chevronRightIcon: {
    marginTop: '2px',
    color: theme.palette.usgColors.primary.blue60,
    '&:hover': {
      color: theme.palette.usgColors.secondary.blue,
    },
  },
  warningPanelLinkText: {
    color: theme.palette.usgColors.primary.blue60,
    '&:hover': {
      color: theme.palette.usgColors.secondary.blue,
    },
  },
  warningPanelLink: {
    '&:hover h6, &:hover svg': {
      color: `${theme.palette.usgColors.secondary.blue} !important`,
    },
  },
  searchItemSection: {
    minHeight: 'calc(100vh - 305px)',
  },
}));
interface ITabMapping {
  [key: string]: string;
}

const WarningPanelComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.infoBox}>
      <WarningPanel
        severity="info"
        title={t('search.warningPanel.title')}
        defaultExpanded
      >
        <Link
          to="/projects"
          icon={<ChevronRightIcon className={classes.chevronRightIcon} />}
          spacing={0}
          className={classes.warningPanelLink}
        >
          <Typography
            variant="subtitle1"
            className={classes.warningPanelLinkText}
          >
            {t('search.warningPanel.subtitle')}
          </Typography>
        </Link>
      </WarningPanel>
    </div>
  );
};

const SearchHeader = () => {
  const classes = useStyles();
  const [showResourceInfo] = useState(true);
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <div className={classes.searchBox}>
        <SearchBar
          debounceTime={DEFAULT_DEBOUNCE_TIME}
          id="search-bar-text-field"
          placeholder={`${t(
            'search.searchbar.placeholder.searchInStargateMaxCharacter',
          )} ${SEARCH_INPUT_MAX_LENGTH} ${t(
            'search.searchbar.placeholder.characters',
          )}`}
          inputProps={{ maxLength: SEARCH_INPUT_MAX_LENGTH }}
        />
      </div>
      {showResourceInfo && <WarningPanelComponent />}
      <SearchType.Tabs
        types={[
          {
            value: 'techdocs',
            name: t('search.tabs.documentation'),
          },
          {
            value: 'news',
            name: t('search.tabs.newsAndUpdate'),
          },
        ]}
      />
    </Grid>
  );
};

const SearchContent = () => {
  return (
    <Grid item xs={12}>
      <SearchResult>
        {({ results }) => (
          <List>
            {searchResultsData(results)
              .filter((r: { type: string }) => r.type !== 'service-catalog')
              .map(({ type, document, duplicates }: DocumentType) => (
                <CatalogSearchResultListItem
                  key={document.location}
                  result={document}
                  type={type}
                  duplicates={duplicates}
                  section="main"
                />
              ))}
          </List>
        )}
      </SearchResult>
      <SearchResultPager />
    </Grid>
  );
};

export const SearchPage = () => {
  const ALL_TAB = 'All';
  const DOCUMENTATION_TAB = 'Documentation';
  const NEWS_AND_UPDATES_TAB = 'News & Updates';
  const TabMapping: ITabMapping = {
    news: NEWS_AND_UPDATES_TAB,
    techdocs: DOCUMENTATION_TAB,
  };

  const { types } = useSearch();

  const selectedTab = TabMapping[types[0]] ?? ALL_TAB;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <PageLayout
      title={t('search.page.title')}
      subtitle={t('search.page.subtitle')}
      headerBackgroundImg="assets/welcome_bg.webp"
    >
      <PageTitle customPageTitle={`Search | ${selectedTab} | Stargate`} />
      <Content className={classes.searchItemSection}>
        <Grid container direction="row">
          <SearchHeader />
          <SearchContent />
        </Grid>
      </Content>
    </PageLayout>
  );
};

export const searchPage = <SearchPage />;
