import React from 'react';

import { DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import { useStyles } from './styles';

type DialogHeaderProps = {
  handleClose: () => void;
};

export const DialogHeader = ({ handleClose }: DialogHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogTitle>
      <div className={classes.dialogHeader}>
        <Typography variant="h4">
          {t('resource.assignUserGroupsModal.title')}
        </Typography>
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.tooltipArrow,
          }}
          arrow
          placement="top"
          title="Close Button"
        >
          <IconButton
            id="assign-user-group-dialog-close"
            aria-label="close"
            className={classes.iconContainer}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
    </DialogTitle>
  );
};
