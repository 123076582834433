import React from 'react';

import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';

type ResourceRoleSelectProps = {
  role: string;
  usergroup_id: string;
  userGroupIndex: number;
  handleResourceTypeChange: (event: any, i: number) => Promise<void>;
  resourceData: any;
};

export const ResourceRoleSelect = ({
  role,
  usergroup_id,
  userGroupIndex,
  handleResourceTypeChange,
  resourceData,
}: ResourceRoleSelectProps) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" fullWidth style={{ marginLeft: '16px' }}>
      <Typography
        variant="button"
        component="div"
        style={{ marginBottom: '8px', textTransform: 'none' }}
        id="role-label"
      >
        {t('resource.assignUserGroupsModal.roleColumnTitle')}
      </Typography>
      <Select
        labelId="role-label"
        id="select-role"
        name="role"
        value={role}
        onChange={e => handleResourceTypeChange(e, userGroupIndex)}
        displayEmpty
        disabled={!usergroup_id}
      >
        {resourceData?.roles?.map((r: any) => {
          const { display_name, name } = r;

          return (
            <MenuItem id={`role_${name}`} key={`role_${name}`} value={name}>
              {display_name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
