import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { TableColumn } from '@backstage/core-components';
import Chip from '@mui/material/Chip';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import Papa from 'papaparse';

import { PageLayout, PageTitle, Tabs } from '@internal/sg-ui-kit';
import { formatDate, parseEmailFromUserRef } from 'sg-utils-frontend';

import { projectApiRef } from '@internal/plugin-projects';
import { BulkImportTable } from './Components/BulkImportTable';
import { useExportUsers } from '../../hooks/useExportUsers';
import { useTranslation } from '../../hooks/useTranslation';

import { useStyles } from './styles';

type TFilterData = {
  size: string;
  page: string;
  bulk_upload_ids: [number];
};

type TTableState = {
  page: number;
  pageSize: number;
  total: number | undefined;
};

export const BulkImportStatusPage = () => {
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const [nextPage, setNextPage] = useState(2);
  const { projectId, groupId, importId } = useParams();
  const { downloadFile } = useExportUsers();
  const [tableState, setTableState] = useState<TTableState>({
    page: 1,
    pageSize: 20,
    total: undefined,
  });
  const [invalidTableState, setInvalidTableState] = useState<TTableState>({
    page: 1,
    pageSize: 20,
    total: undefined,
  });
  const [breadcrumbsValues, setBreadcrumbsValues] = useState<{
    projectName: string;
    userGroupName: string;
  }>({
    projectName: '...',
    userGroupName: '...',
  });
  const [umrId, setUmrId] = useState<number>();
  const [createdBy, setCreatedBy] = useState<string>();
  const { t } = useTranslation();

  // Back to Projects list page
  const breadcrumbs = [
    {
      path: `projects/${projectId}?tab=user-groups`,
      display: breadcrumbsValues.projectName, // check to add project name
    },
    {
      path: `usergroup/${groupId}`,
      display: breadcrumbsValues.userGroupName, // check to add user group name
    },
    {
      path: `imports/${importId}`,
      display: 'Group Membership Requests',
    },
  ];
  const [{}, fetchBreadcrumbs] = useAsyncFn(async () => {
    const idToken = await authref.getIdToken();
    if (projectId && groupId) {
      const project = await projectApi.getProjectByID(projectId, idToken, {
        manipulators: [],
      });
      const groupParams = { idToken };
      const groupRes: any = await projectApi.getGroupByIDFromProject(
        groupId,
        projectId,
        groupParams,
      );
      setBreadcrumbsValues({
        projectName: project.name || '...',
        userGroupName: groupRes.data.name || '...',
      });
    }
  }, []);

  const getUMRId = async () => {
    if (!umrId) {
      const idToken = await authref.getIdToken();
      const getImport = await projectApi.getImportById(importId!, idToken);
      if (getImport && getImport.data.error) {
        throw new Error(getImport.data.error.message);
      }
      const umr_id = parseInt(getImport.data.umr_id, 10);
      setUmrId(umr_id);
      setCreatedBy(parseEmailFromUserRef(getImport.data.created_by));
      setTableState({
        ...tableState,
        total: getImport.data.total_users,
      });
    }
  };
  // To get all list of pending user membership requests
  const [{ value: fetchedData = [] as any, loading, error }, fetchContent] =
    useAsyncFn(
      async (isRefreshed: boolean = false) => {
        const idToken = await authref.getIdToken();
        const params = {
          idToken: idToken,
        };

        try {
          await getUMRId();
          if (umrId) {
            const filterData: TFilterData = {
              size: tableState.pageSize.toString(),
              page: tableState.page.toString(),
              bulk_upload_ids: [umrId], // check to add this or add a filter based on membership request id
            };
            const requestsData =
              (await projectApi.getAllUserMembershipRequestsData(
                filterData,
                params,
              )) as any;

            if (!isRefreshed) {
              setNextPage(requestsData?.response?.data?.next_page);
            }
            return requestsData?.response?.data?.requests;
          }
        } catch (err) {
          errorApi.post(new Error(`${err}`));
        }
        return [];
      },
      [tableState.page, tableState.pageSize, umrId],
    );

  const [
    {
      value: fetchedInvalidEmails = [] as any,
      loading: loadingInvalidEmails,
      error: errorFetchingInvalidEmails,
    },
    fetchInvalidEmails,
  ] = useAsyncFn(async () => {
    try {
      const idToken = await authref.getIdToken();
      const invalidDataRes = (await projectApi.getInvalidEmailsOfImport(
        importId!,
        idToken,
      )) as any;
      return invalidDataRes?.data;
    } catch (err) {
      errorApi.post(new Error(`${err}`));
    }
    return [];
  }, []);

  useEffect(() => {
    fetchContent();
    fetchInvalidEmails();
  }, [tableState.page, tableState.pageSize, fetchContent, fetchInvalidEmails]);

  useEffectOnce(() => {
    fetchBreadcrumbs();
  });
  // list of all fields columns
  const feedColumns: Array<TableColumn<any>> = [
    {
      field: 'id',
      title: t('imports.tabs.imports.columns.id'),
      width: '90',
    },

    {
      field: 'user_email',
      title: t('imports.tabs.imports.columns.userEmail'),
      width: '200',
    },

    {
      field: 'status',
      title: t('imports.tabs.imports.columns.status'),
      width: '150',
      render: (params: any) => {
        let colorCode: any = 'primary';
        switch (params.status) {
          case 'APPROVED':
            colorCode = 'success';
            break;
          case 'REJECTED':
            colorCode = 'error';
            break;
          case 'PENDING':
            colorCode = 'warning';
            break;
          default:
            colorCode = 'primary';
        }
        const status: string = params.status?.toLowerCase() || '';

        return (
          <Chip
            label={t(`imports.tabs.imports.status`, {
              /* @ts-ignore */
              context: status,
            })}
            color={colorCode}
            variant="outlined"
          />
        );
      },
    },
    {
      field: 'sent',
      title: t('imports.tabs.imports.columns.sent'),
      width: '150',
    },

    {
      field: 'requested_on',
      title: t('imports.tabs.imports.columns.requestedOn'),
      width: '200',
      render: (params: any) => {
        return <>{formatDate(params.requested_on, false, true)}</>;
      },
    },
  ];

  const feedInvalidEmailsColumns: Array<TableColumn<any>> = [
    {
      field: 'email',
      title: t('imports.tabs.invalidEmails.columns.userEmail'),
      width: '200',
    },

    {
      field: 'status',
      title: t('imports.tabs.invalidEmails.columns.status'),
      width: '150',
      render: () => {
        return <Chip label="UNSENT" color="error" variant="outlined" />;
      },
    },
    {
      field: 'reason',
      title: t('imports.tabs.invalidEmails.columns.reason'),
      width: '150',
    },

    {
      field: 'requested_on',
      title: t('imports.tabs.invalidEmails.columns.requestedOn'),
      width: '200',
      render: (params: any) => {
        return <>{formatDate(params.created_at, false, true)}</>;
      },
    },
  ];
  const getSpecificKeysForExport = (data: Array<any>) => {
    if (Array.isArray(data)) {
      return data.map(request => {
        return {
          id: request.id,
          user_email: request.user_email,
          user_group_id: request.user_group_id,
          project_id: request.project_id,
          user_group_name: request.user_group_name,
          status: request.status,
          action: request.action,
          sent: request.sent,
          sent_on: request.sent_on,
        };
      });
    }
    return [];
  };
  const [{ loading: loadingExportAllData }, exportAllData] =
    useAsyncFn(async () => {
      // make api call to get all data
      // use papaparse to convert data to csv
      if (umrId) {
        const idToken = await authref.getIdToken();
        const params = {
          idToken: idToken,
        };
        const filterData: TFilterData = {
          size: '500',
          page: '1',
          bulk_upload_ids: [umrId], // check to add this or add a filter based on membership request id
        };
        const requestsData = (await projectApi.getAllUserMembershipRequestsData(
          filterData,
          params,
        )) as any;
        let requestsArr = requestsData?.response?.data?.requests;
        if (requestsData?.response?.data?.next_page) {
          const numberOfPages = requestsData?.response?.data?.next_page;
          for (let i = 2; i <= numberOfPages; i++) {
            const fetchedRequests =
              (await projectApi.getAllUserMembershipRequestsData(
                { ...filterData, page: i.toString() },
                params,
              )) as any;
            const requestsList = fetchedRequests?.response?.data?.requests;
            requestsArr = [...requestsArr, ...requestsList];
          }
        }
        requestsArr = getSpecificKeysForExport(requestsArr);
        const csv = Papa.unparse(requestsArr, { quotes: true });
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        downloadFile(csvData, `bulk_import_${importId}.csv`);
      }
    }, [umrId]);

  const [{ loading: loadingExportInvalidEmails }, exportInvalidEmails] =
    useAsyncFn(async () => {
      const csv = Papa.unparse(fetchedInvalidEmails, { quotes: true });
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      downloadFile(csvData, `invalid_emails_import_${importId}.csv`);
    }, [fetchedInvalidEmails]);

  const handleRowChange = (e: any) => {
    const pageSize = Number(e.target.value);
    const page = 1;
    setTableState({ ...tableState, pageSize, page });
  };
  const handlePageChange = (_: any, newPage: number) => {
    const page = newPage + 1;
    setTableState({ ...tableState, page });
  };

  const handleInvalidPageChange = (_: any, newPage: number) => {
    const page = newPage + 1;
    setInvalidTableState({ ...tableState, page });
  };

  if (error || errorFetchingInvalidEmails) {
    errorApi.post(new Error(`${error || errorFetchingInvalidEmails}`));
  }

  const tabs = [
    {
      label: t('imports.tabs.imports.label'),
      value: 'imports',
      testId: 'bulk-import-imports-tab',
      content: (
        <BulkImportTable
          key="imports"
          fetchedData={fetchedData}
          loading={loading}
          feedColumns={feedColumns}
          exportAllData={exportAllData}
          loadingExportAllData={loadingExportAllData}
          fetchContent={fetchContent}
          count={tableState.total ?? 0}
          tableState={tableState}
          handlePageChange={handlePageChange}
          handleRowChange={handleRowChange}
          nextPage={!!nextPage}
          tableTitle={t('imports.tabs.imports.label')}
        />
      ),
    },
    {
      label: t('imports.tabs.invalidEmails.label'),
      value: 'invalid_emails',
      testId: 'bulk-import-invalid-emails-tab',
      content: (
        <BulkImportTable
          key="invalidEmails"
          fetchedData={fetchedInvalidEmails}
          loading={loadingInvalidEmails}
          feedColumns={feedInvalidEmailsColumns}
          exportAllData={exportInvalidEmails}
          loadingExportAllData={loadingExportInvalidEmails}
          fetchContent={fetchInvalidEmails}
          count={fetchedInvalidEmails?.length}
          tableState={invalidTableState}
          handlePageChange={handleInvalidPageChange}
          tableTitle={t('imports.tabs.invalidEmails.label')}
        />
      ),
    },
  ];
  return (
    <PageLayout
      type="entity"
      title={t('imports.pageTitle')}
      subtitle={createdBy ? `Created by: ${createdBy}` : ''}
      backToLink={breadcrumbs}
    >
      <div className={classes.container}>
        <PageTitle
          customPageTitle={`Bulk Import Status | ${`${breadcrumbsValues.userGroupName} | User Groups | Stargate`}`}
        />
        <Tabs tabs={tabs} />
      </div>
    </PageLayout>
  );
};
