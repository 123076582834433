import React, { useEffect, useState } from 'react';
import { USGTheme } from 'usg-new-theme';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { LogoutIcon } from './LogoutIcon';
import { SidebarAvatar } from './SidebarAvatar';

import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { SidebarContext, SidebarItem } from '@backstage/core-components';

const useStyles = makeStyles((theme: USGTheme) => ({
  linkContainer: {
    width: '170px',
    // TODO: remove when customize settings page
    // pointerEvents: 'none',
    '& h6': {
      whiteSpace: 'normal',
    },
  },
  logoutIconContainer: {
    height: '100%',
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.navigation.color,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.navigation.selectedColor,
    },
  },
}));

type Props = {
  to: string;
};

export const SidebarSettings = ({ to }: Props) => {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const [profileData, setProfileData] = useState({} as any);

  useEffect(
    () => {
      (async () => {
        const profile = await identityApi.getProfileInfo();
        setProfileData(profile);
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { isOpen } = React.useContext(SidebarContext);
  const signOut = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      identityApi.signOut();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <Grid
      container
      spacing={0}
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item className={classes.linkContainer}>
        <SidebarItem
          text={profileData?.displayName}
          to={to}
          icon={() => (
            <SidebarAvatar
              picture={profileData?.picture}
              displayName={profileData?.displayName}
            />
          )}
        />
      </Grid>
      {isOpen && (
        <span
          id="signout-button"
          onClick={signOut}
          className={classes.logoutIconContainer}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          <LogoutIcon />
        </span>
      )}
    </Grid>
  );
};
