import React from 'react';
import { Table } from '@backstage/core-components';
import { CircularProgress, TablePagination } from '@material-ui/core';

import { EmptyDataMessage } from '@internal/sg-ui-kit';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { useStyles } from '../styles';

const DEFAULT_TABLE_PAGE_SIZE_OPTIONS: number[] = [20, 50, 100, 500]; // umr limits to 500
interface BulkImportTableProps {
  fetchedData: any;
  feedColumns: any;
  loading: boolean;
  exportAllData: () => void;
  loadingExportAllData: boolean;
  fetchContent: (isRefreshed: boolean) => void;
  count: number;
  tableState: {
    page: number;
    pageSize: number;
  };
  handlePageChange: (_: any, page: number) => void;
  handleRowChange?: (e: any) => void;
  nextPage?: boolean;
  tableTitle: string;
  key: string;
}

export const BulkImportTable = ({
  fetchedData,
  feedColumns,
  loading,
  exportAllData,
  loadingExportAllData,
  fetchContent,
  count,
  tableState,
  handlePageChange,
  handleRowChange,
  nextPage,
  tableTitle,
  key,
}: BulkImportTableProps) => {
  const classes = useStyles();

  return (
    <Table
      key={`${key}-${tableState.pageSize}`} // workaround to force re-render on page size change
      title={tableTitle}
      data={fetchedData || []}
      columns={feedColumns}
      isLoading={loading}
      options={{
        pageSize: tableState.pageSize,
        paging: true,
        toolbar: true,
        search: false,
        tableLayout: 'auto',
        showFirstLastPageButtons: false,
        emptyRowsWhenPaging: false,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: <EmptyDataMessage />,
        },
      }}
      actions={[
        {
          icon: () =>
            loadingExportAllData ? (
              <CircularProgress size={20} />
            ) : (
              <FileDownloadOutlinedIcon />
            ),
          tooltip: 'Export',
          onClick: () => exportAllData(),
          isFreeAction: true,
          disabled: loadingExportAllData || fetchedData.length === 0,
        },
        {
          icon: () => <AutorenewIcon />,
          tooltip: 'Refresh',
          onClick: () => fetchContent(true),
          isFreeAction: true,
        },
      ]}
      components={{
        Pagination: props => (
          <TablePagination
            {...props}
            className={classes.pagination}
            rowsPerPageOptions={DEFAULT_TABLE_PAGE_SIZE_OPTIONS}
            count={count}
            page={tableState.page - 1}
            onPageChange={handlePageChange}
            rowsPerPage={tableState.pageSize}
            onRowsPerPageChange={handleRowChange || null}
            backIconButtonProps={{ disabled: tableState.page === 1 }}
            nextIconButtonProps={{
              disabled: !nextPage || undefined,
            }}
          />
        ),
      }}
    />
  );
};
