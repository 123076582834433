import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';

import { SEARCH_INPUT_MAX_LENGTH } from 'usg-types';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  searchResultItemText: {
    color: theme.palette.textContrast,
  },
}));

type Props = {
  result: any;
  section: string;
};

export const TechDocsSearchResultListItem = ({ result, section }: Props) => {
  const classes = useStyles();

  return (
    <Link to={result.document.location}>
      <Typography variant={section === 'sub_section' ? 'body1' : 'h4'}>
        {result.document.title}
      </Typography>
      <Typography variant="body2" className={classes.searchResultItemText}>
        {result.document.text.length > SEARCH_INPUT_MAX_LENGTH
          ? `${result.document.text.slice(0, SEARCH_INPUT_MAX_LENGTH)}...`
          : result.document.text}
      </Typography>
    </Link>
  );
};
