import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
    minHeight: 'calc(100vh - 310px)',
  },
  color: {
    color: theme.palette.usgColors.primary.blue,
    height: '23px',
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  dialog: {
    position: 'absolute',
    left: 10,
    top: 50,
  },
  resourceText: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 700,
  },
  resourceTextLink: {
    marginTop: '12px',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 700,
  },
  infoIcon: {
    display: 'flex',
    paddingBottom: '20px',
    '& svg': {
      marginLeft: '5px',
      color: theme.palette.usgColors.primary.blue,
    },
  },
  resourceOpacity: {
    opacity: 0.5,
    paddingBottom: '15px !important',
    display: 'flex',
    alignItems: 'center',
  },
  accessResouceText: {
    paddingTop: '18px',
  },
  resourceName: {
    padding: '18px 9px 18px 0px',
    fontSize: 12,
    fontWeight: 700,
  },
  resourceTitle: {
    width: 163,
  },
  resourceGrid: {
    paddingBottom: '15px !important',
    display: 'flex',
    alignItems: 'center',
  },
  roleType: {
    width: '120px',
    pointerEvents: 'none',
    '& input': {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  editRoleType: {
    width: '120px',
    '& div>div': {
      textTransform: 'capitalize',
      fontSize: '14px',
    },
  },
  resourceLink: {
    width: '20px',
    '&:hover': {
      color: theme.palette.usgColors.primary.blue,
      textDecoration: 'underline',
    },
  },
  addBtn: {
    margin: '16px 0px',
  },
  userChip: {
    '& div > div > div > span': {
      textTransform: 'inherit',
    },
  },
  errorUuserChip: {
    '& div > div > div > span': {
      textTransform: 'inherit',
    },
    color: theme.palette.usgColors.secondary.darkRed,
  },
  selectedChip: {
    '& span': {
      textTransform: 'inherit',
      marginTop: '-2px',
    },
  },
  usersTable: {
    width: '96%',
    marginLeft: '0px',
    marginBottom: '10px',
  },
  table: {
    '& th': {
      color: theme.palette.textSubtle,
      fontWeight: '700',
    },
  },
  userTableTitle: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      position: 'absolute',
      right: '43px',
    },
  },
  tableEdit: {
    width: '20%',
  },
  deleteIcon: {
    cursor: 'pointer',
  },
  disableRow: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  openLink: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
    color: theme.palette.usgColors.others.mix.blue[96],
  },
  deleteActions: {
    justifyContent: 'center',
    paddingBottom: '20px',
  },
  addUserPopup: {
    justifyContent: 'left',
    marginLeft: '15px',
    marginBottom: '15px',
  },
  emptyRecords: {
    height: '200px',
    '& td': {
      textAlign: 'center',
      fontWeight: 300,
    },
  },
  tootipForRoles: {
    '& span': {
      textTransform: 'capitalize',
      fontSize: '11px',
    },
  },
  tooltipHeading: {
    fontSize: '12px',
    paddingBottom: '8px',
  },
  deleteUserIcon: {
    '& tbody td:last-child': {
      padding: '5px 115px !important',
    },
  },
  resourcePaper: {
    padding: '0px 20px 20px',
    marginTop: '20px',
  },
  btnAlign: {
    textAlign: 'end',
  },
  addUserSection: {
    paddingBottom: '20px',
    width: '600px',
  },
  clearBtn: {
    marginRight: '20px',
  },
  azureLink: {
    color: 'blue',
  },
  emptyData: {
    padding: '80px',
    fontWeight: 900,
    fontSize: '16px',
  },
  icon: {
    width: '44px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'rgba(196, 196, 196, 0.8)',
    boxShadow: '0 0px 0px 6px rgba(196, 196, 196, 0.4)',
    margin: '6px',
    color: theme.palette.usgColors.primary.white,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '15px',
  },
  hyperLink: {
    color: theme.palette.usgColors.primary.blue,
    textDecoration: 'underline',
  },
  detachBtn: {
    background: 'transparent',
    color: theme.palette.usgColors.secondary.blue,
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    marginLeft: '40px',
  },
  changeRoleBtn: {
    background: 'transparent',
    color: theme.palette.usgColors.secondary.blue,
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    marginLeft: '25px',
  },
  resourceEnvLink: {
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 400,
    width: '50%',
  },
  rowDiv: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.usgColors.others.mix.grey[4],
    borderRadius: '4px',
    padding: '8px 24px 8px 16px',
    justifyContent: 'space-between',
    margin: '16px 0px 16px',
    alignItems: 'center',
  },
  rowSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  roleText: {
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  hyperlink: {
    color: theme.palette.usgColors.others.mix.blue[200],
    textDecoration: 'underline',
  },
  projectInfoIcon: {
    marginLeft: 10,
  },
  tooltip: {
    color: theme.palette.textContrast,
    backgroundColor: theme.palette.usgColors.secondary.silverGray,
    maxWidth: '100%',
  },
  tooltipArrow: {
    color: theme.palette.usgColors.secondary.silverGray,
  },
  unauthorizedProjectDiv: {
    display: 'flex',
  },
  actionBtn: {
    background: 'transparent',
    color: theme.palette.usgColors.primary.blue60,
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    marginLeft: '40px',
  },
}));
