import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Progress } from '@backstage/core-components';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { usePermissions } from '@internal/plugin-projects';
import { Grid } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { Form, Formik } from 'formik';
import { sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { sortByEnvironment } from 'sg-utils-frontend';

import {
  devToolSupportsDynamicGroup,
  getAllRoles,
  isDynamicGroup,
  isResourceUserGroupAssignable,
} from 'sg-utils-frontend';
import {
  ALL_ROLES,
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  VAULT_DEV_TOOL_ID,
  DEV_TOOLS_TAG_MAP,
} from 'usg-types';
import { projectApiRef } from '../../../api';
import { ArtifactoryGitHubWorkflowAccessViewTable } from '../artifactory-github-workflow-access/ArtifactoryGitHubWorkflowAccessViewTable';
import { fetchAndSetArtifactoryProperties } from '../artifactory-github-workflow-access/ArtifactoryService';
import ViewEditResourceForm from '../view-edit-components';

import { AssignableTabs } from './AssignableTabs';
import { EditArchiveButton } from './Buttons/EditArchiveButton';
import { EditButtons } from './Buttons/EditButtons';
import { UnarchiveButton } from './Buttons/UnarchiveButton';
import { DialogArchiveResource } from './Dialogs/DialogArchiveResource';
import { DialogResourceErrorMessage } from './Dialogs/DialogResourceErrorMessage';
import { DialogSuccessfulUnarchived } from './Dialogs/DialogSuccessfulUnarchived';
import { DialogUnarchive } from './Dialogs/DialogUnarchive';
import { ResourceManagers } from './ResourceManagers';
import { ResourceUrl } from './ResourceUrl';
import {
  ADMIN_ONLY_DEV_TOOL_IDS,
  COMMON_EDITABLE_DEV_TOOL_IDS,
  SortedUserGroupsArrayType,
  ViewEditResourcePageComponentProps,
} from './types';
import { PageTitleBreadcrumbs } from '../PageTitleBreadcrumbs';
import { useTranslation } from '../../../hooks/useTranslation';
import ResourceRoverIndexing from './ResourceRoverIndexing';
import { useProjectSettings } from '../../../hooks';
import { useStyles } from '../styles';

export const NewViewEditResourceComponent = ({
  projectId,
  resourceId,
  projectData,
}: ViewEditResourcePageComponentProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const [value, setValue] = useState(0);
  const [dataData, setdataData] = useState<any>(undefined);
  const [resourceData, setResourceData] = useState(null as any);
  const [resourceRoles, setResourceRoles] = useState([] as any);
  const [environmentsData, setEnvironmentsData] = useState([] as any);
  const [editMode, setEditMode] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState(
    t('resource.view.shareableUrl'),
  );
  const [resourceID, setResourceID] = useState('');
  const [nameDirty, setNameDirty] = useState(false);
  const [descriptionDirty, setDescriptionDirty] = useState(false);
  const [idTokenData, setIdTokenData] = useState([] as any);
  const [openEditSucessMsg, setOpenEditSucessMsg] = useState(false);
  const [enableResourceBtn, setEnableResourceBtn] = useState(false);
  const [openArcive, setOpenArchive] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [unarchiveBtn, setUnarchiveBtn] = useState(false);
  const [unArchiveOpen, setUnArchiveOpen] = useState(false);
  const [unArchiveSuccessDialog, setUnArchiveSuccessDialog] = useState(false);
  const [assignedUserGroups, setAssignedUserGroups] = useState([]);
  const [supportsDynamicGroups, setSupportsDynamicGroups] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [existingArtifactoryProperties, setExistingArtifactoryProperties] =
    useState(null as any);
  const [artifactoryProperties, setArtifactoryProperties] = useState(
    null as any,
  );
  const [isLoadingArtifactoryProperties, setIsLoadingArtifactoryProperties] =
    useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [resourceManagerCheck, setResourceManagerCheck] = useState(false);
  const [resourceManagerData, setResourceManagerData] = useState([]);
  const [userEmailErrorData, setUserEmailErrorData] = React.useState({
    message: '',
  });
  const {
    isAdmin,
    roles,
    isProjectOwner,
    isLoading: isLoadingPermissions,
    error: permissionsError,
    userData: permissionUserData,
  } = usePermissions();

  const [groupsWithExcludedTags, setGroupsWithExcludedTags] = useState<any>([]);
  const [isAddUserEnable, setIsAddUserEnable] = useState(true);
  const [isAddUserLoading, setIsAddUserLoading] = useState(false);
  const readOnlyResources = [VAULT_DEV_TOOL_ID, MTFUJI_DEV_TOOL_ID];
  const {
    isRoverIndexingEnabled,
    resourcesStatus,
    resourcesToggleState,
    setResourcesToggleState,
  } = useProjectSettings(projectId, {
    with_resources: true,
  });

  const [{ loading: fetchResourceManagersLoading }, fetchResourceManagers] =
    useAsyncFn(async (email, specificResourceId: 0) => {
      const token = await authref.getIdToken();
      const finalResourceId = specificResourceId
        ? specificResourceId
        : resourceId;
      const res: any = await projectApi.getGroupManagersOfUserGroup(
        projectId,
        finalResourceId,
        token,
      );
      if (res?.status === 200) {
        const project_resource_managers = res?.data?.project_resource_managers;
        const sortedEmails: any = sortBy(project_resource_managers, [
          'user_email',
        ]);
        setResourceManagerData(sortedEmails);
        const resourceManagerRole = res?.data?.project_resource_managers?.some(
          (obj: any) => obj.user_email === email,
        );
        if (resourceManagerRole) {
          setEnableResourceBtn(resourceManagerRole);
          setResourceManagerCheck(resourceManagerRole);
        } else {
          setResourceManagerCheck(false);
        }
      } else {
        const errorMsg = res?.data?.error?.message;
        errorApi.post(new Error(`${errorMsg}`));
        navigate(`/projects/${projectId}`);
      }
    }, []);

  // To get perticluar project deatils based on projectId
  const [{ loading, error }, fetchContent] = useAsyncFn(async () => {
    if (!projectData) return;
    const idToken = await authref.getIdToken();

    setIdTokenData(idToken);
    try {
      const data = projectData;

      const Arr = [];
      if (data?.resources[0]?.linkedResources) {
        const copyLinkedResources = JSON.parse(
          JSON.stringify(data.resources[0].linkedResources),
        );
        Arr.push(copyLinkedResources);

        const copyDataResources = JSON.parse(
          JSON.stringify(data?.resources[0]),
        );
        delete copyDataResources.linkedResources;
        Arr[0]?.push(copyDataResources);
        if (data?.resources[0]?.dev_tool_id === VAULT_DEV_TOOL_ID) {
          sortByEnvironment(Arr[0]);
        }
        data.resources[0].linkedResources = Arr[0];
      }

      setdataData(data);

      const currentResource = data?.resources[0];

      const userId = sessionStorage.getItem('userId') || '{}';

      if (userId) {
        // getting Development tools data
        let devtoold: any = await projectApi.getDevelopmentToolsData(idToken);
        devtoold = devtoold.development_tools;
        devtoold = devtoold.find(
          (dt: any) => dt.id === currentResource?.dev_tool_id,
        );
        const resRoles = devtoold?.roles;
        setResourceData(currentResource);
        setResourceRoles(resRoles ? resRoles : []);
        setSupportsDynamicGroups(devToolSupportsDynamicGroup(devtoold));
        try {
          const userData = permissionUserData;
          setUserEmail(userData?.email);
          setEnableResourceBtn(isAdmin || isProjectOwner(Number(projectId)));
          if (!devtoold.enable_users_environment) {
            await fetchResourceManagers(userData?.email, 0);
          }
          if (currentResource?.deleted_on) {
            setUnarchiveBtn(true);
          }
          const allRoles = getAllRoles(userData, projectId);
          if (allRoles && !allRoles.some(role => ALL_ROLES.includes(role))) {
            navigate(`/projects/${projectId}`);
          }
        } catch (err) {
          errorApi.post(new Error(`${err?.message}`));
        }
        if (
          currentResource?.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID ||
          currentResource?.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID
        ) {
          const artifactName =
            currentResource?.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID
              ? 'artifactory_self_hosted'
              : 'artifactory_saas';
          // Call fetchAndSetArtifactoryProperties with the necessary parameters
          await fetchAndSetArtifactoryProperties(
            data,
            projectId,
            authref,
            projectApi,
            artifactName,
            setArtifactoryProperties,
            setExistingArtifactoryProperties,
            setIsLoadingArtifactoryProperties,
          );
        }
      }

      setEnvironmentsData(Arr[0]);
    } catch (err) {
      errorApi.post(new Error(`${err?.message}`));
    }
  }, [isLoadingPermissions]);

  // get assigned user groups
  const [{ loading: ugLoading }, fetchAssignedUserGroups] =
    useAsyncFn(async () => {
      try {
        const token = await authref.getIdToken();
        const res: any = await projectApi.getUserGroupsOfResource(
          projectId,
          resourceId,
          token,
          {},
        );

        let userGroupsData = res?.data.user_groups_roles;

        const groupIdToObjectMapping: any = {};
        const userGroupsArray = dataData?.user_groups;
        userGroupsArray.forEach((group: any) => {
          if (!(group.id in groupIdToObjectMapping))
            groupIdToObjectMapping[group.id] = group;
        });

        userGroupsData = userGroupsData.map((group: any) => {
          const groupData = groupIdToObjectMapping[group.id];

          if (!groupData) {
            throw new Error(
              `Couldn't find group ${group.id} under project ${projectId}`,
            );
          }

          return {
            ...group,
            name: groupData.name,
            rule_based: groupData.rule_based,
            archived: groupData.deleted_on ? true : false,
          };
        });
        setAssignedUserGroups(userGroupsData);
      } catch (err) {
        errorApi.post(new Error(`${err?.message}`));
        navigate(`/projects/${projectId}`);
      }
    }, [dataData, roles, userEmail]);

  // get all user groups with excluded tags so we can filter them out
  const getGroupsWithExcludedTags = useCallback(async () => {
    const devtool = resourceData?.dev_tool_id;
    const { excluded, allowed } =
      DEV_TOOLS_TAG_MAP[devtool] || DEV_TOOLS_TAG_MAP.default;

    // Get the source project id of attached usergroups
    const sourceProjectsList: [] =
      dataData?.user_groups?.reduce((acc: any, ug: any) => {
        if (
          ug.sharing_properties.source_project_id !== Number(projectId) &&
          !acc.includes(ug.sharing_properties.source_project_id)
        ) {
          acc.push(ug.sharing_properties.source_project_id);
        }
        return acc;
      }, []) || [];

    const params = {
      project_id_filters: [...sourceProjectsList, Number(projectId)],
      entity_type_filters: ['USER_GROUP'],
      tag_filters: [
        {
          tag_key: 'platform-limit-tool',
          tag_values: excluded,
        },
      ],
      include_tags_in_response: true,
      size: 100,
    };

    let currentPage = 1;
    const excludedGroups: any[] = [];
    let hasNextPage = true;

    while (hasNextPage) {
      const res: any = await projectApi.getTagsOfEntity({
        ...params,
        page: currentPage,
      });

      if (res?.error) {
        errorApi.post(new Error(`${res?.error?.message}`));
        break;
      }

      const taggedEntities = res?.data.tagged_entities ?? [];
      excludedGroups.push(...taggedEntities);

      hasNextPage = !!res?.data?.next_page;
      currentPage++;
    }

    return excludedGroups.filter(
      (tag: any) => !tag.tags['platform-limit-tool'].includes(allowed),
    );
  }, [resourceData, projectId, projectApi, errorApi, dataData?.user_groups]);

  useEffect(() => {
    (async () => {
      if (!isLoadingPermissions) {
        await fetchContent();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPermissions]);

  useEffect(() => {
    (async () => {
      if (dataData) fetchAssignedUserGroups();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataData]);

  useEffect(() => {
    (async () => {
      if (resourceData?.dev_tool_id) {
        try {
          const getGroupsWithExcludedTagsRes =
            await getGroupsWithExcludedTags();
          setGroupsWithExcludedTags(getGroupsWithExcludedTagsRes);
        } catch (err) {
          errorApi.post(new Error(`${err?.message}`));
        }
      }
    })();
  }, [resourceData, getGroupsWithExcludedTags, errorApi]);

  const sortedUserGroupsArray: SortedUserGroupsArrayType = useMemo(() => {
    const userGroupsArray = dataData?.user_groups;

    let filteredUserGroup = userGroupsArray?.filter((ug: any) => {
      return (
        !ug.deleted_on &&
        !assignedUserGroups?.some((fug: any) => fug.id === ug.id)
      );
    });

    if (supportsDynamicGroups) {
      filteredUserGroup = filteredUserGroup?.filter((el: any) => {
        return groupsWithExcludedTags?.every((f: any) => {
          return f.entity_id !== el.id;
        });
      });
    } else {
      filteredUserGroup = filteredUserGroup?.filter(
        (ug: any) => !isDynamicGroup(ug),
      );
    }

    return filteredUserGroup;
  }, [
    dataData,
    supportsDynamicGroups,
    assignedUserGroups,
    groupsWithExcludedTags,
  ]);

  const onDescriptionClick = () => {
    setDescriptionDirty(true);
  };

  if (
    !isContentVisible &&
    (loading ||
      ugLoading ||
      fetchResourceManagersLoading ||
      isLoadingPermissions)
  ) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(new Error(`${error?.message}`));
    navigate(`/projects/${projectId}`);
  }

  if (permissionsError) {
    errorApi.post(permissionsError);
  }

  const enableCommonForUnArchiveBtn = (dev_tool_id: any) => {
    let editable_tools = COMMON_EDITABLE_DEV_TOOL_IDS;
    if (isAdmin) {
      editable_tools = [...editable_tools, ...ADMIN_ONLY_DEV_TOOL_IDS];
    }
    return unarchiveBtn && isAdmin && editable_tools.includes(dev_tool_id);
  };

  const resourceManagerList = [] as any;
  resourceManagerData.map((res: any) =>
    resourceManagerList.push({
      value: res.user_email,
      isValid: true,
    }),
  );

  if (dataData === undefined || !resourceData) {
    return <Progress />;
  }

  return (
    <>
      <PageTitleBreadcrumbs
        projectData={dataData}
        resourceData={resourceData}
      />
      {apiLoader ? <Progress /> : ''}
      <div className={classes.container}>
        <Stack direction="column" spacing={2}>
          <Formik
            initialValues={{
              resourceKey: resourceData?.key,
              resourceName: resourceData?.name,
              resourceDescription: resourceData?.description
                ? resourceData.description
                : '',
              addResourceManagers: resourceManagerList,
            }}
            onSubmit={() => {}}
            enableReinitialize
          >
            {formik => {
              return (
                <>
                  <Form
                    onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={
                          resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID
                            ? 9
                            : 12
                        }
                      >
                        <ViewEditResourceForm
                          classes={classes}
                          devToolId={resourceData?.dev_tool_id}
                          formik={formik}
                          isEdit={editMode}
                          resourceData={resourceData}
                          roles={roles}
                          resourceSpecificProps={{
                            artifactoryProperties,
                            copyButtonLabel,
                            dataData,
                            descriptionDirty,
                            environmentsData,
                            nameDirty,
                            onDescriptionClick,
                            projectId,
                            resourceID,
                            setArtifactoryProperties,
                            setCopyButtonLabel,
                            setEnvironmentsData,
                            setNameDirty,
                            setResourceID,
                            showArtifactoryGithubWorkflowAccess: true,
                          }}
                        />
                      </Grid>
                      {enableCommonForUnArchiveBtn(resourceData?.dev_tool_id) &&
                        resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID && (
                          <UnarchiveButton
                            setUnArchiveOpen={setUnArchiveOpen}
                          />
                        )}

                      {!editMode &&
                        enableResourceBtn &&
                        !unarchiveBtn &&
                        resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID && (
                          <EditArchiveButton
                            devToolId={resourceData?.dev_tool_id ?? 0}
                            isAdmin={isAdmin}
                            projectId={projectId}
                            setEditMode={setEditMode}
                            isProjectOwner={isProjectOwner}
                            readOnlyResources={readOnlyResources}
                            resourceManagerCheck={resourceManagerCheck}
                            setOpenArchive={setOpenArchive}
                          />
                        )}
                    </Grid>
                    {!readOnlyResources.includes(resourceData?.dev_tool_id) &&
                      resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID && (
                        <ResourceManagers
                          projectId={projectId}
                          editMode={editMode}
                          resourceManagerData={resourceManagerData}
                          isAddUserEnable={isAddUserEnable}
                          setIsAddUserEnable={setIsAddUserEnable}
                          setIsAddUserLoading={setIsAddUserLoading}
                        />
                      )}
                    {isRoverIndexingEnabled && (
                      <ResourceRoverIndexing
                        resourceId={resourceId}
                        editMode={editMode}
                        resourcesStatus={resourcesStatus}
                        resourcesToggleState={resourcesToggleState}
                        setResourcesToggleState={setResourcesToggleState}
                      />
                    )}

                    {editMode &&
                      resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID && (
                        <EditButtons
                          formik={formik}
                          idTokenData={idTokenData}
                          devToolId={resourceData?.dev_tool_id ?? ''}
                          projectId={projectId}
                          resourceId={resourceId}
                          apiLoader={apiLoader}
                          showArtifactoryGithubWorkflowAccess
                          artifactoryProperties={artifactoryProperties}
                          setResourceData={setResourceData}
                          resourceData={resourceData}
                          setApiLoader={setApiLoader}
                          setOpenEditSucessMsg={setOpenEditSucessMsg}
                          setEditMode={setEditMode}
                          setArtifactoryProperties={setArtifactoryProperties}
                          existingArtifactoryProperties={
                            existingArtifactoryProperties
                          }
                          setUserEmailErrorData={setUserEmailErrorData}
                          userEmailErrorData={userEmailErrorData}
                          isAddUserEnable={isAddUserEnable}
                          isAddUserLoading={isAddUserLoading}
                          isResourceIndexingEnabled={
                            resourcesToggleState[resourceId]?.isEnabled
                          }
                          setResourcesToggleState={setResourcesToggleState}
                        />
                      )}
                    {!editMode &&
                      resourceData?.dev_tool_id !== VAULT_DEV_TOOL_ID && (
                        <ResourceUrl
                          resourceUrl={resourceData?.url ?? ''}
                          projectId={projectId}
                          resourceId={resourceId}
                          copyButtonLabel={copyButtonLabel}
                          setCopyButtonLabel={setCopyButtonLabel}
                        />
                      )}

                    {!editMode &&
                      [
                        ARTIFACTORY_DEV_TOOL_ID,
                        ARTIFACTORY_SAAS_DEV_TOOL_ID,
                      ].includes(resourceData?.dev_tool_id) && (
                        <Grid item xs={9}>
                          <ArtifactoryGitHubWorkflowAccessViewTable
                            name={resourceData?.name}
                            artifactoryProperties={artifactoryProperties}
                            isLoading={isLoadingArtifactoryProperties}
                          />
                        </Grid>
                      )}
                  </Form>
                </>
              );
            }}
          </Formik>
          {!editMode && isResourceUserGroupAssignable(resourceData) && (
            <AssignableTabs
              unarchiveBtn={unarchiveBtn}
              value={value}
              setValue={setValue}
              fetchAssignedUserGroups={fetchAssignedUserGroups}
              assignedUserGroups={assignedUserGroups}
              resourceData={resourceData}
              resourceRoles={resourceRoles}
              projectId={projectId}
              ugLoading={ugLoading}
              sortedUserGroupsArray={sortedUserGroupsArray}
              resourceManagerCheck={resourceManagerCheck}
              fetchContent={fetchContent}
              setIsContentVisible={setIsContentVisible}
              isContentVisible={isContentVisible}
              resourceManagerData={resourceManagerData}
              isAddUserEnable={isAddUserEnable}
              setIsAddUserEnable={setIsAddUserEnable}
              setIsAddUserLoading={setIsAddUserLoading}
              isAddUserLoading={isAddUserLoading}
              resourceManagerList={resourceManagerList}
              fetchResourceManagers={fetchResourceManagers}
              userEmail={userEmail}
            />
          )}
          <DialogUnarchive
            projectId={projectId}
            resourceId={resourceId}
            idTokenData={idTokenData}
            unArchiveOpen={unArchiveOpen}
            setUnArchiveSuccessDialog={setUnArchiveSuccessDialog}
            setUnArchiveOpen={setUnArchiveOpen}
          />
          <DialogSuccessfulUnarchived
            unArchiveSuccessDialog={unArchiveSuccessDialog}
            setUnArchiveSuccessDialog={setUnArchiveSuccessDialog}
            navigate={navigate}
            projectId={projectId}
          />
          <DialogResourceErrorMessage
            devToolId={resourceData?.dev_tool_id ?? ''}
            openEditSucessMsg={openEditSucessMsg}
            fetchContent={fetchContent}
            setEditMode={setEditMode}
            setOpenEditSucessMsg={setOpenEditSucessMsg}
            fetchResourceManagers={fetchResourceManagers}
            userEmail={userEmail}
          />
          <DialogArchiveResource
            openArcive={openArcive}
            projectId={projectId}
            navigate={navigate}
            resourceId={resourceId}
            idTokenData={idTokenData}
            setOpenArchive={setOpenArchive}
          />
        </Stack>
      </div>
    </>
  );
};
