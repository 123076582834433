import { Config } from '@backstage/config';
import { userJourneyTypes } from 'usg-types';
import { generateHeaders } from './common-utils';
import { NotFoundError } from '@backstage/errors';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

export interface NPSData {
  user_email: string;
  nps_rating: string;
  feedback: string;
  user_journey_id: userJourneyTypes;
  user_id?: string;
  nps_source?: string;
}

export interface UserData {
  email: string;
}

export interface UserResData {
  email: string;
  nps_last_updated: string;
  nps_skip_date?: string;
}

export interface CommonApiInterface {
  submitUserData(token: any, idToken: string, userData: UserData): Promise<any>;
  submitNPSData(idToken: string, npsData: NPSData): Promise<any>;
  getUserData(token: any, idToken: string, email: string): Promise<UserResData>;
  updateUserNpsSkipData(idToken: string, email: string): Promise<any>;
}

export const commonApiRef = createApiRef<CommonApiInterface>({
  id: 'fronend.common-api',
});

type CommonApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

export class CommonApi implements CommonApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private backendUrl: string;

  constructor(options: CommonApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  public async submitUserData(token: any, idToken: string, userData: UserData) {
    // const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/welcome-page/users`;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(userData),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }

  public async submitNPSData(idToken: string, npsData: NPSData) {
    const token = (await this.identityApi.getCredentials()).token;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const npsSubmitURL = `${this.backendUrl}/api/welcome-page/nps`;
    const response = await fetch(npsSubmitURL, {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(npsData),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }

  public async getUserData(token: any, idToken: string, email: string) {
    const url = `${this.backendUrl}/api/welcome-page/users/${email}`;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();

    if (response.status === 404) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new Error(data.error.message);
    }
    return data;
  }

  public async updateUserNpsSkipData(idToken: string, email: string) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/welcome-page/users/nps/${email}`;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }
}
