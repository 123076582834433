import { useState } from 'react';
import { createGlobalState, useEffectOnce } from 'react-use';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';

export interface TCompany {
  id: number;
  name: string;
  short_name: string;
  email_domains: string[];
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
}

const useGlobalCompanies = createGlobalState<TCompany[]>([]);

export function useCompaniesApi() {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const [allCompanies, setAllCompanies] = useGlobalCompanies();
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchCompanies = async (idToken: string, queryParams?: Object) => {
    try {
      const companiesRes: any = await projectApi.getCompanyData(
        idToken,
        queryParams,
      );
      if (companiesRes && companiesRes.error) {
        throw new Error(companiesRes.error.message);
      }
      return companiesRes;
    } catch (err) {
      errorApi.post(new Error(err?.message || 'Failed to load companies data'));
      return { companies: [] };
    }
  };

  const appendCompanies = async () => {
    const idToken = await authref.getIdToken();

    // fetch first page to get the number of pages in next_page
    const companiesRes = await fetchCompanies(idToken);
    let companiesArr = companiesRes?.companies;

    if (companiesRes?.hasOwnProperty('next_page')) {
      const queryParams = {
        page: 2, // since we fetched the first page we start from the second page
      };
      const numberOfPages = companiesRes?.next_page;
      // we loop through the page and fetch each one
      for (let i = 2; i <= numberOfPages; i++) {
        const fetchedCompanies = await fetchCompanies(idToken, queryParams);
        const companiesList = fetchedCompanies?.companies;
        companiesArr = [...companiesArr, ...companiesList];
        queryParams.page = fetchedCompanies.page + 1;
      }
    }
    return companiesArr;
  };

  useEffectOnce(() => {
    if (allCompanies?.length === 0) {
      setIsLoading(true);
      (async () => {
        try {
          const companiesArr = await appendCompanies();
          setAllCompanies(companiesArr);
          setIsSuccess(true);
        } catch (err) {
          setMessage(err?.message);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setIsLoading(false);
      setIsSuccess(true);
    }
  });

  return {
    allCompanies,
    isSuccess,
    message,
    isLoading,
  };
}
