import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useAsyncFn } from 'react-use';

import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  commonHideOwnerResources,
  devToolSupportsDynamicGroup,
  isAttachResourcesRoleRequired,
  isDynamicGroup as isDynamicGroupFn,
  isGithubEMUDynamicGroup as isGithubEMUDynamicGroupFn,
  transformResourceName,
} from 'sg-utils-frontend';
import {
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  DEV_TOOLS_TAG_MAP,
  GITHUBEMU_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  NON_SELECTABLE_RESOURCE_TYPES,
  VAULT_DEV_TOOL_ID,
  TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX,
} from 'usg-types';

import { projectApiRef } from '../../api';
import { WarningMessage } from '../WarningMessage/WarningMessage';
import { AttachedResourcesByDevTool } from '../../pages/AttachedUserGroupDetailPage/ResourcesTab';
import { useTranslation } from '../../hooks/useTranslation';
import { Progress } from '@backstage/core-components';
import { useRemoveArtifactorySelfHosted } from '../../hooks/useRemoveArtifactorySelfHosted';
import { useStyles } from './styles';

type ResourceObject = {
  attachedResources: {
    config: {};
    dev_tool_id: number;
    dev_tool_name: string;
    environment: string;
    generated_resource_key: string | number;
    id: number;
    key: string;
    name: string;
    role: string;
    url: string;
  }[];
  dev_tool_id: number;
  dev_tool_name: string;
};
type SortedResourcesArrayType = ResourceObject[];
type TResourceAttachment = {
  devtool_id: string;
  resource_id: string;
  role: string;
};

export type Success = {
  name: string;
};

export type Fail = {
  name: string;
  status: number;
  message: string;
};

export type ResourceResultState = { success: Success[]; fail: Fail[] };

const emptyResourceAttachment = {
  devtool_id: '',
  resource_id: '',
  role: '',
};

const initialResourceAttachments = [emptyResourceAttachment];

const AttachResourceComponent = (props: {
  isVisible: boolean;
  handleClose: () => void;
  projectId: string;
  groupId: string;
  resources: AttachedResourcesByDevTool;
  devTools: any;
  groupData: any;
  isAdmin: boolean;
  isOwner: boolean;
  handleSuccess: () => void;
  setResourcesResult: React.Dispatch<React.SetStateAction<ResourceResultState>>;
  totalUsersCount: number;
}) => {
  const {
    isVisible,
    handleClose,
    projectId,
    groupId,
    resources,
    handleSuccess,
    setResourcesResult,
    devTools,
    groupData,
    isAdmin,
    isOwner,
    totalUsersCount,
  } = props;
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authRef = useApi(microsoftAuthApiRef);
  const { t } = useTranslation();

  const isDynamicGroup = isDynamicGroupFn(groupData);
  const isDynamicGroupForGithubEMU = isGithubEMUDynamicGroupFn(groupData);

  const [attachError, setAttachError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');

  const [resourceAttachments, setResourceAttachments] = useState<
    TResourceAttachment[]
  >(initialResourceAttachments);
  const [isWarningMessageDisplayed, setIsWarningMessageDisplayed] =
    useState<boolean>(false);

  // To filter Artifactory(attachedResources) where RClass !== 'virtual'
  const removeVirtualArtifactoryResource = (array: any[]) => {
    return array
      .map(element => {
        if (
          element.dev_tool_id === ARTIFACTORY_DEV_TOOL_ID ||
          element.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID
        ) {
          const newElement = element;
          const artifactoryAttachedResources =
            element?.attachedResources.filter((elementTwo: any) => {
              return !NON_SELECTABLE_RESOURCE_TYPES.includes(
                elementTwo.config?.rclass,
              );
            });
          return {
            ...newElement,
            attachedResources: artifactoryAttachedResources,
          };
        }
        return element;
      })
      .filter(e => e.attachedResources.length !== 0);
  };

  const filterResourcesBasedOnTags = useCallback(
    (resourcesArray: any[], tags: any) => {
      // if no tags are provided, return the original array
      if (!tags) return resourcesArray;
      // if the platform-limit-tool tag is provided and empty, return empty array
      const platformLimitTool = tags['platform-limit-tool'];
      if (!platformLimitTool || platformLimitTool.length === 0) return [];

      return resourcesArray.filter(element => {
        const devToolExclusion = platformLimitTool.includes(
          (DEV_TOOLS_TAG_MAP[element.dev_tool_id] || DEV_TOOLS_TAG_MAP.default)
            .allowed,
        );
        return devToolExclusion;
      });
    },
    [],
  );

  const sortedResourcesArray: SortedResourcesArrayType = useMemo(() => {
    const tempArray: any[] = [];
    for (const key in resources) {
      if (Object.prototype.hasOwnProperty.call(resources, key)) {
        if (isDynamicGroup) {
          const foundTool = devTools.find(
            (dt: any) => dt.id === resources[key]?.dev_tool_id,
          );
          if (!devToolSupportsDynamicGroup(foundTool)) {
            continue;
          }
        }
        tempArray.push(resources[key]);
      }
    }
    const filteredArray = removeVirtualArtifactoryResource(tempArray);
    const newFilteredArray = filterResourcesBasedOnTags(
      filteredArray,
      groupData?.tags,
    );

    const finalSortedArray = newFilteredArray.sort(
      (a: ResourceObject, b: ResourceObject) => {
        if (a.dev_tool_name < b.dev_tool_name) {
          return -1;
        }
        if (a.dev_tool_name > b.dev_tool_name) {
          return 1;
        }
        return 0;
      },
    );
    return finalSortedArray;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, devTools, groupData]);

  const resourceNameData = (dev_tool_id: number, data: any) => {
    let tempData = data;
    if (
      dev_tool_id === ARTIFACTORY_DEV_TOOL_ID ||
      dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID
    ) {
      tempData = data?.filter((resource: any) => {
        // if no config is provided then true
        if (resource?.config) {
          return !NON_SELECTABLE_RESOURCE_TYPES.includes(
            resource.config?.rclass,
          );
        }
        return true;
      });
    }
    const finalSortedArray = tempData?.sort((a: any, b: any) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );
    return finalSortedArray?.map((r: any) => {
      return (
        <MenuItem
          id={`resource_id_${r.id}-${r.environment}`}
          key={`resource_id_${r.id}-${r.environment}`}
          value={r.id}
          title={r.name}
          className={classes.resourceName}
        >
          {r.name} {r.environment !== 'prod' && `(${r.environment})`}
        </MenuItem>
      );
    });
  };

  const [{ loading: reqLoading = false as boolean }, attachResources] =
    useAsyncFn(async (request: any) => {
      const token = await authRef.getIdToken();
      const isSuccess: Success[] = [];
      const isFail: Fail[] = [];

      const promises = request?.map(async (resource: any) => {
        const paramsdata = {
          idToken: token,
          userGroupRoles: [
            {
              op: 'add',
              value: [{ id: parseInt(groupId, 10), role: resource.role }],
            },
          ],
        };
        const res = await projectApi.assignUserGroupToResources(
          paramsdata,
          projectId,
          resource.id,
        );
        if (res?.status === 200) {
          isSuccess.push({ name: resource.name });
        } else {
          isFail.push({
            name: resource.name,
            status: res?.status,
            message: res?.data?.message,
          });
        }
      });
      await Promise.allSettled(promises).catch(e => {
        setAttachError(e?.message);
      });

      handleSuccess();
      setResourcesResult({ success: isSuccess, fail: isFail });
      setResourceAttachments(initialResourceAttachments);
      setIsWarningMessageDisplayed(false);
      handleClose();
    }, []);

  const hasDuplicateResourceAttachmentIds = (
    _resourceAttachments: TResourceAttachment[],
  ) => {
    const seenResourceAttachmentsSet = new Set();

    for (const resourceAttachment of _resourceAttachments) {
      const { resource_id: resourceId } = resourceAttachment;

      if (seenResourceAttachmentsSet.has(resourceId)) {
        return true;
      }

      seenResourceAttachmentsSet.add(resourceId);
    }

    return false;
  };

  const hasGithubEMUResourceSelected = (
    _resourceAttachments: TResourceAttachment[],
  ) => {
    return _resourceAttachments.some(
      ({ devtool_id }) => parseInt(devtool_id, 10) === GITHUBEMU_DEV_TOOL_ID,
    );
  };

  const handleResourceTypeChange = (event: any, i: number) => {
    const updatedResourceAttachmentRequest = [...resourceAttachments];
    updatedResourceAttachmentRequest[i] = {
      ...updatedResourceAttachmentRequest[i],
      [event.target.name]: event.target.value,
    };
    if (event.target.name === 'devtool_id') {
      updatedResourceAttachmentRequest[i] = {
        ...updatedResourceAttachmentRequest[i],
        resource_id: '',
        role: '',
      };

      if (hasGithubEMUResourceSelected(updatedResourceAttachmentRequest)) {
        setIsWarningMessageDisplayed(true);
      } else {
        setIsWarningMessageDisplayed(false);
      }
    }

    if (event.target.name === 'resource_id') {
      setDuplicateError('');

      if (hasDuplicateResourceAttachmentIds(updatedResourceAttachmentRequest)) {
        setDuplicateError(
          t(
            `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.duplicateResourceError`,
          ),
        );
      }
    }
    setResourceAttachments(updatedResourceAttachmentRequest);
  };

  useEffect(() => {
    setTimeout(() => {
      setAttachError('');
      setResourceAttachments(initialResourceAttachments);
      setIsWarningMessageDisplayed(false);
    }, 1000);
  }, [isVisible]);

  const displayResourcesMenuItem = (resource: ResourceObject) => {
    if (isAdmin) {
      // display all resources
      return (
        <MenuItem
          id={`dev_tool_id_${resource.dev_tool_id}`}
          key={`dev_tool_id_${resource.dev_tool_id}`}
          value={resource.dev_tool_id}
        >
          {transformResourceName(resource.dev_tool_name)}
        </MenuItem>
      );
    } else if (isOwner) {
      // no mt.fuji (SMC) only in this case
      // once feature-flag for vault new ui is removed then only mt. fuji is not shown for owner
      // hence, 'else' below would not be needed when that time come
      return (
        MTFUJI_DEV_TOOL_ID !== resource.dev_tool_id && (
          <MenuItem
            id={`dev_tool_id_${resource.dev_tool_id}`}
            key={`dev_tool_id_${resource.dev_tool_id}`}
            value={resource.dev_tool_id}
          >
            {transformResourceName(resource.dev_tool_name)}
          </MenuItem>
        )
      );
    }
    // no mt.fuji (SMC) and vault resources
    return (
      !commonHideOwnerResources(resource.dev_tool_id) && (
        <MenuItem
          id={`dev_tool_id_${resource.dev_tool_id}`}
          key={`dev_tool_id_${resource.dev_tool_id}`}
          value={resource.dev_tool_id}
        >
          {transformResourceName(resource.dev_tool_name)}
        </MenuItem>
      )
    );
  };

  const resourceSelectRow = () => {
    return (
      <TransitionGroup>
        {resourceAttachments.map((rf, currentIndex) => (
          <Collapse>
            <div
              // The key attr below should remain in this div element and not moved to Collapse above
              // even this could bring warning
              // If Collapse above has key it will trigger transition group to make unnecessary animation
              key={rf.resource_id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '16px',
              }}
            >
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginRight: '16px' }}
              >
                <Typography
                  variant="button"
                  component="div"
                  style={{ marginBottom: '8px', textTransform: 'none' }}
                  id="resource-type-label"
                >
                  {t(
                    `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.resourceTypeFieldLabel`,
                  )}
                </Typography>
                <Select
                  labelId="resource-type-label"
                  id="select-resource-type"
                  name="devtool_id"
                  value={rf.devtool_id}
                  onChange={e => handleResourceTypeChange(e, currentIndex)}
                  displayEmpty
                  disabled={sortedResourcesArray.length === 0}
                >
                  {sortedResourcesArray?.map((element: ResourceObject) => {
                    return displayResourcesMenuItem(element);
                  })}
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <Typography
                  variant="button"
                  component="div"
                  style={{ marginBottom: '8px', textTransform: 'none' }}
                  id="resource-name-label"
                >
                  {t(
                    `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.resourceNameFieldLabel`,
                  )}
                </Typography>
                <Select
                  labelId="resource-name-label"
                  value={rf.resource_id}
                  name="resource_id"
                  onChange={e => handleResourceTypeChange(e, currentIndex)}
                  displayEmpty
                  disabled={!rf.devtool_id}
                >
                  {resourceNameData(
                    Number(rf.devtool_id),
                    resources[rf.devtool_id]?.attachedResources,
                  )}
                </Select>
              </FormControl>
              {devTools.find((dt: any) => dt.id === rf.devtool_id)?.roles
                .length !== 0 && (
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginLeft: '16px' }}
                >
                  <Typography
                    variant="button"
                    component="div"
                    style={{ marginBottom: '8px', textTransform: 'none' }}
                    id="resource-role-label"
                  >
                    {t(
                      `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.resourceRoleFieldLabel`,
                    )}
                  </Typography>
                  <Select
                    labelId="resource-role-label"
                    id="select-resource-role"
                    name="role"
                    value={rf.role}
                    onChange={e => handleResourceTypeChange(e, currentIndex)}
                    displayEmpty
                    disabled={!rf.resource_id && !rf.resource_id}
                  >
                    {devTools
                      .find((dt: any) => dt.id === rf.devtool_id)
                      ?.roles.map((r: any) => {
                        return (
                          <MenuItem
                            id={`role_${r.name}`}
                            key={`role_${r.name}`}
                            value={r.name}
                          >
                            {r.display_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}

              <IconButton
                id="projects-button-remove-resource"
                disabled={reqLoading}
                className={classes.deleteIcon}
                onClick={() => {
                  const updatedResourceAttachmentRequest = [
                    ...resourceAttachments,
                  ].filter((_, index) => index !== currentIndex);
                  setResourceAttachments(updatedResourceAttachmentRequest);
                  if (
                    !hasGithubEMUResourceSelected(
                      updatedResourceAttachmentRequest,
                    )
                  ) {
                    setIsWarningMessageDisplayed(false);
                  }

                  if (
                    !hasDuplicateResourceAttachmentIds(
                      updatedResourceAttachmentRequest,
                    )
                  ) {
                    setDuplicateError('');
                  }
                  setAttachError('');
                }}
              >
                <DeleteIcon
                  className={[
                    reqLoading ? classes.disabledIconColor : classes.iconColor,
                  ].join(' ')}
                />
              </IconButton>
            </div>
          </Collapse>
        ))}
      </TransitionGroup>
    );
  };

  const addResourceHandler = () => {
    setResourceAttachments([...resourceAttachments, emptyResourceAttachment]);
    setAttachError('');
  };
  const handleCloseAssignResource = () => {
    handleClose();
    setResourceAttachments(initialResourceAttachments);
    setDuplicateError('');
    setIsWarningMessageDisplayed(false);
  };

  const collectDynamicGroupEnabledDevtools = (): string[] => {
    const names: string[] = devTools
      .filter((obj: any) => devToolSupportsDynamicGroup(obj))
      .map((obj: { name: string }) => transformResourceName(obj.name));
    const uniqueNames = Array.from(new Set(names));
    return uniqueNames;
  };

  // check if all roles are selected properly with required data
  const isRoleSelected = resourceAttachments.every(
    ({ devtool_id, resource_id, role }) => {
      // For non vault or when New vault ui feature flag is disabled then use the default checking for role requirement
      if (devtool_id.toString() !== VAULT_DEV_TOOL_ID.toString()) {
        return isAttachResourcesRoleRequired(devtool_id, resource_id, role);
      }

      // for new vault ui feature flag enabled and the resource is vault then role and ug must exists
      return resource_id !== '' && role !== '';
    },
  );
  const isAssignResourceDisable =
    resourceAttachments.length === 0 ||
    !isRoleSelected ||
    reqLoading ||
    duplicateError !== '';

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isVisible}
      onClose={() => {
        handleCloseAssignResource();
      }}
    >
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography variant="h3">
            {t(
              `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.assignResourcesButton`,
            )}
          </Typography>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              id="projects-button-close-assign-resource"
              aria-label="close"
              className={classes.iconContainer}
              onClick={() => {
                handleCloseAssignResource();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: '16px' }}>
          {t(
            `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.notes.firstNote`,
          )}
          <br />
          {t(
            `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.notes.secondNote`,
            {
              /* @ts-ignore */
              resourceName: transformResourceName('Mt. Fuji'),
            },
          )}
          <br />
          {t(
            `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.notes.thirdNote`,
          )}
          {isDynamicGroup && (
            <>
              <br />
              {t(
                `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.notes.dynamicGroupNote`,
              )}{' '}
              {collectDynamicGroupEnabledDevtools().join(', ')}.
            </>
          )}
        </div>
        {resourceSelectRow()}
        {sortedResourcesArray.length === 0 && (
          <div className={classes.errorMsg}>
            {t(
              `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.noAvailableResources`,
            )}
          </div>
        )}
        <Button
          fullWidth
          variant="outlined"
          className={classes.hyperButton}
          id="projects-button-add-resource"
          color="default"
          onClick={addResourceHandler}
          disabled={reqLoading}
        >
          {t(
            `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.addResourceButton`,
          )}{' '}
          +
        </Button>
        {(attachError || duplicateError) && (
          <Typography
            style={{ marginTop: '8px', marginBottom: '8px', color: 'red' }}
          >
            Error: {attachError || duplicateError}
          </Typography>
        )}
        {!isDynamicGroupForGithubEMU && isWarningMessageDisplayed && (
          <WarningMessage totalUsersCount={totalUsersCount} isAttachResource />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          size="small"
          id="projects-button-cancel-assign-resource"
          onClick={() => {
            handleClose();
            setDuplicateError('');
            setResourceAttachments([
              {
                devtool_id: '',
                resource_id: '',
                role: '',
              },
            ]);
          }}
          disabled={reqLoading}
        >
          {t('common.form.cancelLabel')}
        </Button>
        <Button
          variant="contained"
          size="small"
          id="projects-button-assign-resource"
          onClick={() => {
            const accessible_resources = resourceAttachments.map(
              ({ resource_id, role }) => {
                return { id: resource_id, role };
              },
            );

            attachResources(accessible_resources);
          }}
          disabled={isAssignResourceDisable}
        >
          {t(
            `${TABS_ASSIGNED_RESOURCES_TRANSLATION_PREFIX}.attachResources.assignResourceButton`,
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AttachResource = (props: {
  isVisible: boolean;
  handleClose: () => void;
  projectId: string;
  groupId: string;
  resources: AttachedResourcesByDevTool;
  devTools: any;
  groupData: any;
  isAdmin: boolean;
  isOwner: boolean;
  handleSuccess: () => void;
  setResourcesResult: React.Dispatch<React.SetStateAction<ResourceResultState>>;
  totalUsersCount: number;
}) => {
  const { isArtifactorySelfHostedRemoved } = useRemoveArtifactorySelfHosted();
  const { resources, ...otherProps } = props;

  if (isArtifactorySelfHostedRemoved === null) {
    return <Progress />;
  }

  if (isArtifactorySelfHostedRemoved) {
    const newResources = { ...resources };
    delete newResources[ARTIFACTORY_DEV_TOOL_ID];

    const newProps = {
      ...otherProps,
      resources: newResources,
    };
    return <AttachResourceComponent {...newProps} />;
  }
  return <AttachResourceComponent {...props} />;
};
