import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStyles } from '../styles';

export const ArtifactoryGitHubWorkflowAccessHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Typography variant="h4" className={classes.labelHeader}>
      {t('resource.githubWorkflowAccess.title')}
    </Typography>
  );
};
