import React from 'react';
import { USG_COLOR_SCHEME } from 'usg-new-theme';
import { sidebarConfig, Avatar } from '@backstage/core-components';
import { useStyles } from './styles';

type Props = {
  picture?: string;
  displayName?: string;
};

export const SidebarAvatar = ({ picture, displayName }: Props) => {
  const { iconSize } = sidebarConfig;
  const classes = useStyles();
  const avatarStyles = React.useMemo(
    () => ({
      height: iconSize,
      width: iconSize,
      fontSize: '10px',
      fontWeight: 600,
      background: USG_COLOR_SCHEME.primary.gray,
    }),
    [iconSize],
  );

  return (
    <Avatar
      picture={picture}
      customStyles={avatarStyles}
      displayName={displayName}
      classes={{
        avatarText: classes.avatarText,
      }}
    />
  );
};
