import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  container: {
    height: '100%',
  },
  arrowIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  articleContent: {
    maxWidth: '800px',
    margin: '5px 0',
  },
  viewAllLink: {
    color: theme.palette.depLink,
    '&:hover': {
      color: theme.palette.usgColors.secondary.blue,
    },
  },
  articleDate: {
    fontWeight: 400,
    display: 'block',
    marginBottom: theme.spacing(0.5),
  },
  articleTitle: {
    fontWeight: 700,
    display: 'block',
    marginBottom: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  articleImage: {
    width: '70px',
    height: '70px',
  },
  linkContainer: {
    '&:hover': {
      '& $articleTitle': {
        color: theme.palette.usgColors.primary.gray,
      },
    },
  },
}));
