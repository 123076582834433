import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Box,
  Button,
  Grid,
  Typography,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { InfoCard, Link, Progress } from '@backstage/core-components';
import { projectApiRef } from '@internal/plugin-projects';
import {
  BinaryFeatureFlagged,
  CloseIcon,
  PageLayout,
  PageTitle,
} from '@internal/sg-ui-kit';

import {
  usePermissions,
  useProject,
  useProjectSettings,
  useRoverIndexingEnabledResourceIds,
} from '../../hooks';
import { ProjectOwners } from '../../components/ProjectOwners';
import { ROOT_URL_LABEL_BREADCRUMBS } from '../../components/ViewEditResources/ViewEditResourcePage/types';
import { ROVER_INDEXING } from 'usg-types';
import getTagOptions from '../../utils/getTagOptions';
import cn from 'classnames';
import { useStyles } from './styles';

function RoverSettingDialog({
  isRoverIndexingEnabled,
  setIsRoverIndexingEnabled,
  isDialogOpen,
  setIsDialogOpen,
}: {
  isRoverIndexingEnabled: boolean;
  setIsRoverIndexingEnabled: Dispatch<SetStateAction<boolean>>;
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const { projectId } = useParams() as { projectId: string };
  const errorApi = useApi(errorApiRef);
  const authRef = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const { fetchRoverIndexingEnabledResources } =
    useRoverIndexingEnabledResourceIds(projectId);
  const { isProjectOwner, isAdmin, isROAdmin } = usePermissions();

  const classes = useStyles();

  const handleOnProjectSettingConfirm = async () => {
    setIsDialogOpen(false);

    try {
      const token = await authRef.getIdToken();

      const updateProjectSettingsResponse: any =
        await projectApi.updateProjectSettings(
          projectId,
          {
            genai_index_enabled: !isRoverIndexingEnabled,
          },
          token,
        );
      const isGenAIIndexEnabled =
        updateProjectSettingsResponse.genai_index_enabled;

      setIsRoverIndexingEnabled(isGenAIIndexEnabled);

      if (!isGenAIIndexEnabled) {
        const needToDisabledIndexResourceIds =
          await fetchRoverIndexingEnabledResources();
        const tagOperations = getTagOptions(isGenAIIndexEnabled);

        const tags = await projectApi.updateProjectResourcesTags(
          token,
          projectId,
          needToDisabledIndexResourceIds,
          {
            tags: tagOperations,
          },
        );

        if (tags?.failedRequests.length > 0) {
          errorApi.post(
            new Error(
              `Failed to update tags for ${tags?.failedRequests.length} resources`,
            ),
          );
        }
      }
    } catch (err: any) {
      errorApi.post(
        new Error(err?.message || 'Failed to update project setting'),
      );
    }
  };

  if (!isProjectOwner(Number(projectId)) && !isAdmin && !isROAdmin) {
    navigate(`/projects/${projectId}`);
  }

  return (
    <Dialog
      open={isDialogOpen}
      role="alertdialog"
      aria-labelledby="rover-setting-dialog-title"
    >
      <DialogTitle
        id="rover-setting-dialog-title"
        className={classes.roverSettingDialogTitle}
        disableTypography
      >
        <Typography variant="h3">
          {isRoverIndexingEnabled ? 'Disable' : 'Enable'} Rover GenAI Indexing
        </Typography>
        <IconButton
          id="close-rover-setting-button"
          aria-label="close"
          onClick={() => {
            setIsDialogOpen(false);
          }}
          size="medium"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {isRoverIndexingEnabled ? (
          <>
            By clicking confirm, i accept to remove all indexing my project
            resources for Rover GenAI for this workspace.
          </>
        ) : (
          <>
            By clicking <b>Confirm</b>, you acknowledge and agree to index your
            project workspace resources for Rover GenAI.
            <br />
            <br />
            This means that Rover GenAI Chat will process and index the contents
            of your project workspace, making it searchable for you and project
            members who have access to these resources. <br />
            <br />
            <b>By opting in, you agree to the following conditions:</b>
            <br />
            <br />
            1. Rover GenAI will have access to the indexed contents of this
            project workspace.
            <br />
            2. Only users with appropriate access permissions (as per Stargate
            RBAC) will be able to search and retrieve indexed content.
            <br />
            3. You can customize which resources are indexed and exclude
            specific spaces.
            <br />
            4. You will receive notifications regarding the indexing progress,
            including completion and any failures.
            <br />
            <br />
            Currently Rover indexes only Confluence resources.
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel-rover-setting-button"
          variant="outlined"
          size="small"
          onClick={() => setIsDialogOpen(false)}
        >
          Cancel
        </Button>
        <Button
          id="confirm-rover-setting-button"
          variant="contained"
          size="small"
          onClick={handleOnProjectSettingConfirm}
          name="confirm-rover-setting-button"
          role="button"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const ProjectSettings = () => {
  const { projectId } = useParams() as { projectId: string };
  const navigate = useNavigate();
  const { project, isFetchingProject, error } = useProject(projectId);
  const { isRoverIndexingEnabled, setIsRoverIndexingEnabled } =
    useProjectSettings(projectId);

  const classes = useStyles();

  const [isRoverSettingDialogOpen, setIsRoverSettingDialogOpen] =
    useState(false);

  if (isFetchingProject) {
    return <Progress />;
  }

  if (error || !project) {
    navigate('/projects');
  }

  const { id, name, owners } = project;

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: id,
      display: name,
    },
    {
      path: 'settings',
      display: 'Project Settings',
    },
  ];

  return (
    <>
      <PageLayout
        type="entity"
        title={project.name}
        headerAdditionalControls={<ProjectOwners owners={owners || []} />}
        backToLink={breadcrumbs}
      >
        <PageTitle customPageTitle={`Project Setting | ${name} | Stargate`} />

        <Grid container spacing={2} className={classes.container}>
          <BinaryFeatureFlagged withFlag={ROVER_INDEXING}>
            <Grid item xs={12} md={4}>
              <InfoCard variant="gridItem" title="GenAI Settings">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 3,
                  }}
                >
                  <Stack>
                    <Typography>Rover GenAI</Typography>
                    <Typography variant="caption" className={classes.text}>
                      Enable/Disable Rover GenAI
                    </Typography>
                  </Stack>
                  <Switch
                    id="index-resource-project-toggle"
                    checked={isRoverIndexingEnabled}
                    onChange={() => setIsRoverSettingDialogOpen(true)}
                    color="primary"
                    name="index-resource-project"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Box>
                <Typography variant="body2">
                  To select specific resources to be indexed for the selected
                  resource category{' '}
                  <Link to={`/projects/${projectId}`}>Click here</Link>
                </Typography>
                <Typography
                  variant="caption"
                  className={cn(classes.text, classes.italicText)}
                >
                  Please note that it might take 24 hours for resource indexing
                  change to get reflected on Rover. You will receive a
                  notification once all your resources have finished indexing,
                  you can track the granular progress on the resource page.
                </Typography>
              </InfoCard>
            </Grid>
          </BinaryFeatureFlagged>
        </Grid>
      </PageLayout>
      <RoverSettingDialog
        isRoverIndexingEnabled={isRoverIndexingEnabled}
        setIsRoverIndexingEnabled={setIsRoverIndexingEnabled}
        isDialogOpen={isRoverSettingDialogOpen}
        setIsDialogOpen={setIsRoverSettingDialogOpen}
      />
    </>
  );
};
