import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  pinBarDesign: {
    padding: '0px 26px 20px',
    color: theme.palette.usgColors.secondary.slateGray,
    cursor: 'pointer',
    fontSize: '14px',
    '&:hover': {
      color: theme.palette.usgColors.primary.white,
      textDecoration: 'underline',
    },
  },
  content: {
    width: '120px',
    marginLeft: '58px',
  },
  pinBar: {
    display: 'flex',
    '& svg': {
      fontSize: '1.25rem',
    },
    '& h4': {
      width: '120px',
      fontSize: '14px',
      marginLeft: '25px',
      color: theme.palette.usgColors.secondary.slateGray,
    },
  },
}));
