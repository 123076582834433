import React from 'react';

import { Link } from '@backstage/core-components';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Button, FormHelperText } from '@material-ui/core';

import { ResourceUrlProps } from './types';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStyles } from '../styles';

export const ResourceUrl = ({
  resourceUrl,
  projectId,
  resourceId,
  copyButtonLabel,
  setCopyButtonLabel,
}: ResourceUrlProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const copiedLabel = t('resource.view.shareableUrlCopied');
  const shareableUrlLabel = t('resource.view.shareableUrl');

  return (
    <>
      <div className={classes.shareableUrl}>
        <Link
          to={`${resourceUrl}`}
          target="_blank"
          style={{
            fontWeight: 'bold',
            textDecorationLine: 'underline',
            color: '#1059CD',
            marginBottom: '28px',
          }}
        >
          <span style={{ display: 'flex' }}>
            {resourceUrl}
            <OpenInNewIcon fontSize="small" style={{ marginLeft: '8px' }} />
          </span>
        </Link>
        <div className={classes.copyResourceUrl}>
          <Button
            id="project-button-copyresourceurl"
            className={classes.noWrap}
            variant="outlined"
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.protocol}//${window.location.hostname}/projects/${projectId}?resource=${resourceId}&path=`,
              );
              setCopyButtonLabel(copiedLabel);
              setTimeout(() => {
                setCopyButtonLabel(shareableUrlLabel);
              }, 2000);
            }}
            disabled={copyButtonLabel === copiedLabel}
          >
            {copyButtonLabel}{' '}
            <FileCopyIcon style={{ marginLeft: '8px' }} fontSize="small" />
          </Button>
          <FormHelperText>
            {t('resource.view.shareableUrlHelperText')}
          </FormHelperText>
        </div>
      </div>
    </>
  );
};
