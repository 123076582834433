import React from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppEnvs } from '../types';
import Button from '@material-ui/core/Button';
import { Autocomplete } from '@material-ui/lab';
import { projectApiRef } from '@internal/plugin-projects';
import {
  NON_PRODUCTION_ENVIRONMENTS,
  PRODUCTION_ENVIRONMENTS,
} from '../Constants';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useStyles } from './styles';

interface UserGroupRole {
  id: number;
  role: 'read-write' | 'read-only';
}

interface Group {
  name: string;
  uuid: string;
}

function isProdEnvValid(prodEnv: string | null): boolean {
  return prodEnv !== null && prodEnv.length > 0;
}

function areNonProdEnvsValid(nonProdEnvs: string[]): boolean {
  return nonProdEnvs.length > 0 && nonProdEnvs.length <= 7;
}

export default function AvailableEnvironmentEdit({
  open,
  appEnvs,
  mainEnvName,
  onApply,
  onClose,
  fetchProjectData,
  resourceId,
  currentStargateProjectId,
  setAppResIntialDataDuringUpdate,
  appResIntialDataDuringUpdate,
}: {
  open: boolean;
  appEnvs: AppEnvs;
  mainEnvName: string;
  onApply: (appEnvs: AppEnvs, mainEnvName: string) => void;
  onClose: () => void;
  fetchProjectData?: any;
  resourceId?: any;
  currentStargateProjectId?: any;
  setAppResIntialDataDuringUpdate?: any;
  appResIntialDataDuringUpdate?: any;
}) {
  const appEnvNames = Object.getOwnPropertyNames(appEnvs);
  const projectApi = useApi(projectApiRef);
  const authApi = useApi(microsoftAuthApiRef);
  const classes = useStyles();
  const errorApi = useApi(errorApiRef);
  const [prodEnvName, setProdEnvName] = React.useState<string | null>(
    appEnvNames.find(name => PRODUCTION_ENVIRONMENTS.includes(name)) || null,
  );
  const [nonProdEnvNames, setNonProdEnvNames] = React.useState(
    appEnvNames.filter(name => !PRODUCTION_ENVIRONMENTS.includes(name)),
  );

  const handleProdEnvChange = (envName: string | null) => {
    setProdEnvName(envName);
  };

  const handleNonProdEnvChange = (envNames: string[]) => {
    setNonProdEnvNames(envNames);
  };

  const setGroupsToAddedArchivedEnv = async (
    addedArchivedEnv: { id: { toString: () => string }; environment: string },
    mainUserGroupsData: any[],
  ) => {
    const token = await authApi.getIdToken();

    try {
      const res: any = await projectApi.getUserGroupsOfResource(
        currentStargateProjectId.toString(),
        addedArchivedEnv.id.toString(),
        token,
        {},
      );
      if (res?.status !== 200) {
        throw Error(
          `Error fetching groups attached to environment: ${res?.data.message}`,
        );
      } else {
        const userGroups = res.data.user_groups_roles;

        const aad_readwrite_groups: Group[] = [];
        const aad_readonly_groups: Group[] = [];

        userGroups.forEach(({ id, role }: UserGroupRole) => {
          const foundItem = mainUserGroupsData.find(
            (item: any) => item.id === id,
          );

          const groupObj: any = {
            name: foundItem ? foundItem.name : '',
            uuid: id.toString(),
          };

          if (role === 'read-write') {
            aad_readwrite_groups.push(groupObj);
          } else if (role === 'read-only') {
            aad_readonly_groups.push(groupObj);
          }
        });
        const finalObjResp: any = {};
        if (aad_readwrite_groups && aad_readwrite_groups.length) {
          finalObjResp.aad_readwrite_groups = aad_readwrite_groups;
        }
        if (aad_readonly_groups && aad_readonly_groups.length) {
          finalObjResp.aad_readonly_groups = aad_readonly_groups;
        }
        return finalObjResp;
      }
    } catch (err) {
      errorApi.post(new Error(err?.message));
      return {};
    }
  };

  const handleApply = async () => {
    let mainUserGroupsData: any = [];
    let delEnvs = [];

    if (prodEnvName === null) {
      return;
    }

    if (resourceId) {
      try {
        const projDataRes = await fetchProjectData(resourceId, true);

        if (projDataRes && projDataRes.error) {
          errorApi.post(new Error(projDataRes.error?.message));
        }
        const envs = projDataRes.resources[0].linkedResources || [];
        mainUserGroupsData = projDataRes?.user_groups || [];
        delEnvs = envs.filter((item: any) => 'deleted_on' in item);
      } catch (err) {
        errorApi.post(new Error(err?.message));
      }
    }

    const updatedAppResIntialDataDuringUpdate = JSON.parse(
      JSON.stringify(appResIntialDataDuringUpdate || {}),
    );
    const combinedData: any = {};

    const addedArchivedNonProdEnvs = delEnvs.filter(
      (obj: { environment: string }) =>
        nonProdEnvNames.includes(obj.environment),
    );
    for (let i = 0; i < addedArchivedNonProdEnvs.length; i++) {
      if (
        addedArchivedNonProdEnvs[i].environment &&
        addedArchivedNonProdEnvs[i].environment.toString()
      ) {
        const dataKey: string =
          addedArchivedNonProdEnvs[i].environment.toString() || '';
        const groupsRes = await setGroupsToAddedArchivedEnv(
          addedArchivedNonProdEnvs[i],
          mainUserGroupsData,
        );
        combinedData[dataKey] = groupsRes;
        updatedAppResIntialDataDuringUpdate.configuration[dataKey] = groupsRes;
      }
    }

    const newAppEnvs: AppEnvs = Object.fromEntries([
      [prodEnvName, appEnvs[mainEnvName] || {}],
      ...nonProdEnvNames.map(envName => {
        const specificData = JSON.parse(JSON.stringify(appEnvs[envName] || {}));
        if (combinedData[envName]) {
          if (
            combinedData[envName].aad_readonly_groups &&
            combinedData[envName].aad_readonly_groups.length
          ) {
            specificData.aad_readonly_groups =
              combinedData[envName].aad_readonly_groups;
          }
          if (
            combinedData[envName].aad_readwrite_groups &&
            combinedData[envName].aad_readwrite_groups.length
          ) {
            specificData.aad_readwrite_groups =
              combinedData[envName].aad_readwrite_groups;
          }
        }
        return [envName, specificData || {}];
      }),
    ]);
    setAppResIntialDataDuringUpdate(updatedAppResIntialDataDuringUpdate);
    onApply(newAppEnvs, prodEnvName);
  };

  const prodEnvNameValid = isProdEnvValid(prodEnvName);
  const nonProdEnvNamesValid = areNonProdEnvsValid(nonProdEnvNames);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography variant="h3">Manage Environments</Typography>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              id="dialog-edit-env-close-icon-btn"
              aria-label="close"
              className={classes.iconContainer}
              onClick={() => onClose()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent id="ug-available-environments-edit-dialog" dividers>
        <div style={{ paddingBottom: '24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Production environment</Typography>
              <Autocomplete
                value={prodEnvName}
                options={PRODUCTION_ENVIRONMENTS}
                onChange={(_, value) => handleProdEnvChange(value)}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Prod env"
                      error={!prodEnvNameValid}
                      helperText={
                        !prodEnvNameValid &&
                        'Production environment cannot be empty'
                      }
                      fullWidth
                    />
                  );
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Non-production environments</Typography>
              <Autocomplete
                value={nonProdEnvNames}
                options={NON_PRODUCTION_ENVIRONMENTS}
                multiple
                onChange={(_, value) => handleNonProdEnvChange(value)}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Non-prod envs"
                      error={!nonProdEnvNamesValid}
                      helperText={
                        !nonProdEnvNamesValid &&
                        'Non-production environments cannot be empty or contain more than 7 values.'
                      }
                      fullWidth
                    />
                  );
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    id="mtfuji-btn-cancel-env-edit-dialog"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    id="mtfuji-btn-apply-env-edit-dialog"
                    onClick={() => handleApply()}
                    disabled={!prodEnvNameValid || !nonProdEnvNamesValid}
                  >
                    Stage Changes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}
