import React from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';

import { getTechDocsLink } from 'sg-utils-frontend';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { useStyles } from '../../../../styles';

type DialogSuccessFailedProps = {
  isOpen: boolean;
  handleSuccessClose: () => void;
};

export const DialogSuccessAssignUserGroups = ({
  isOpen,
  handleSuccessClose,
}: DialogSuccessFailedProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleSuccessClose}
      maxWidth="xs"
      style={{ top: '5' }}
      className={classes.dialog}
    >
      <DialogContent>
        <Typography variant="body2">
          <Box fontWeight={700}>
            {`${t(
              'resource.view.vault.environmentsSection.dialogs.successAssignUserGroup.content',
            )} `}
            <Link
              className={classes.hyperLink}
              target="_blank"
              href={getTechDocsLink('dev-tool-sync')}
            >
              {`${t(
                'resource.view.vault.environmentsSection.dialogs.successAssignUserGroup.link',
              )} `}
            </Link>
          </Box>
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          variant="contained"
          id="btn-assign-resource-modal-close"
          size="small"
          onClick={handleSuccessClose}
        >
          {`${t(
            'resource.view.vault.environmentsSection.dialogs.buttons.close',
          )} `}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
