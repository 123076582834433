import React, { useEffect, useState } from 'react';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { isResponseStatus2XX } from '@internal/sg-utils-common';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { projectApiRef } from '../../../../../../api';
import { useViewEditResourceContext } from '../../../context';
import { SuccessBar } from '../../../Snackbar/SuccessBar';
import { DialogArchiveConfirmation } from '../Dialogs/DialogArchiveConfirmation';
import { DialogUnArchiveConfirmation } from '../Dialogs/DialogUnArchiveConfirmation';
import { ResourceUrl } from './ResourceUrl';

import { useStyles as useEnvironmentStyles } from './styles';

import { useTranslation } from '../../../../../../hooks/useTranslation';
import { useStyles } from '../../../../styles';

export const EnvironmentViewComponent = (props: any) => {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const {
    environments,
    selectedTabIndex,
    setEnvironments,
    formik,
    isAddManagerEnabled,
    setManagers,
    isEdit,
    setIsEdit,
  } = props;
  const resourceContext = useViewEditResourceContext();
  const userPermissions = resourceContext.userDetailsAndPermissions;
  const { t } = useTranslation();

  const ENVIRONMENT_ARCHIVED_MESSAGE = t(
    'resource.view.vault.environmentsSection.successBar.message',
  );

  const classes = useStyles();
  const environmentClass = useEnvironmentStyles();
  const [isArchived, setIsArchived] = useState<boolean>(false);
  const [isOpenArchiveConfirmationDialog, setIsOpenArchiveConfirmationDialog] =
    useState<boolean>(false);
  const [isSuccessBoxOpen, setIsSuccessBoxOpen] = useState<boolean>(false);
  const [successBoxMsg, setSuccessBoxMsg] = useState<string>(
    ENVIRONMENT_ARCHIVED_MESSAGE,
  );

  const [
    isOpenUnArchiveConfirmationDialog,
    setIsOpenUnArchiveConfirmationDialog,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (environments.length === 0 || !environments[selectedTabIndex]) {
      return;
    }
    setIsArchived(environments[selectedTabIndex]?.deleted_on);
  }, [environments, selectedTabIndex]);

  const disableEditResourceManager = (resourceID: any) => {
    if (
      !resourceContext.userDetailsAndPermissions.isProjectOwner(
        Number(resourceContext.projectDetails.id),
      ) &&
      !resourceContext.userDetailsAndPermissions.isAdmin
    ) {
      return !resourceContext.userDetailsAndPermissions.isResourceManager(
        Number(resourceID),
      );
    }
    return false;
  };

  const onSaveNewResourceManagers = async (values: any) => {
    const inputEmails = values.addResourceManagers.map(
      (e: { value: string }) => {
        return { user_email: e.value.trim() };
      },
    );

    const idToken = await authref.getIdToken();
    const resourceManagersparams = {
      resource_managers: inputEmails,
    };

    const addResourceData: any = await projectApi.addGroupManagers(
      resourceContext.projectDetails.id,
      environments[selectedTabIndex].id,
      idToken,
      resourceManagersparams,
    );
    const code = addResourceData?.status;
    if (isResponseStatus2XX(code)) {
      formik.resetForm({});
      setIsSuccessBoxOpen(true);
      setSuccessBoxMsg(
        t(
          'resource.view.vault.environmentsSection.successBar.resourceManagersUpdated',
        ),
      );
      const newResourceManagers =
        addResourceData.data.project_resource_managers ?? [];
      setManagers(newResourceManagers);
    } else if (code >= 400) {
      const errorMsg = addResourceData?.data?.error?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
    setIsEdit(false);
  };

  const editModeButtons = () => {
    return (
      <Stack direction="row" sx={{ mt: 0.5 }}>
        <Button
          id="cancel-edit-resource-managers"
          variant="text"
          onClick={() => {
            formik.resetForm({});
            setIsEdit(false);
          }}
        >
          <Typography
            variant="button"
            color="textPrimary"
            sx={{ fontWeight: 'bold' }}
          >
            {t(
              'resource.view.vault.environmentsSection.dialogs.buttons.cancel',
            )}
          </Typography>
        </Button>
        <Divider
          orientation="vertical"
          variant="inset"
          flexItem
          sx={{ mr: 2, ml: 2 }}
        />
        <Button
          id="save-resource-managers"
          variant="text"
          disabled={!isAddManagerEnabled}
        >
          <Typography
            variant="button"
            sx={{ fontWeight: 'bold' }}
            onClick={() => {
              return onSaveNewResourceManagers(formik.values);
            }}
            className={
              isAddManagerEnabled ? classes.enabledBtn : classes.disabledBtn
            }
          >
            {t('resource.view.vault.environmentsSection.dialogs.buttons.save')}
          </Typography>
        </Button>
      </Stack>
    );
  };

  const nonEditModeButtons = () => {
    return (
      <Stack direction="row" sx={{ mt: 0.5 }}>
        <Button
          id="archive-unarchive-environment"
          variant="text"
          onClick={() => {
            if (isArchived) {
              setIsOpenUnArchiveConfirmationDialog(true);
            } else {
              setIsOpenArchiveConfirmationDialog(true);
            }
          }}
          disabled={!userPermissions.isAdmin}
        >
          <Typography
            variant="button"
            sx={{ fontWeight: 'bold' }}
            className={
              userPermissions.isAdmin ? classes.enabledBtn : classes.disabledBtn
            }
          >
            {isArchived
              ? t('resource.view.vault.environmentsSection.unarchive')
              : t('resource.view.vault.environmentsSection.archive')}
          </Typography>
        </Button>
        <Divider
          orientation="vertical"
          variant="inset"
          flexItem
          sx={{ mr: 2, ml: 2 }}
        />
        <Button
          id="edit-resource-managers"
          variant="text"
          disabled={
            isArchived ||
            disableEditResourceManager(environments[selectedTabIndex].id)
          }
          onClick={() => {
            setIsEdit(true);
          }}
        >
          <Typography
            variant="button"
            sx={{ fontWeight: 'bold' }}
            className={
              !(
                isArchived ||
                disableEditResourceManager(environments[selectedTabIndex].id)
              )
                ? classes.enabledBtn
                : classes.disabledBtn
            }
          >
            {t('resource.view.vault.environmentsSection.dialogs.buttons.edit')}
          </Typography>
        </Button>
      </Stack>
    );
  };

  const onHandleArchive = async () => {
    try {
      const idToken = await authref.getIdToken();
      const data: any = await projectApi.archiveProjectResource(
        resourceContext.projectDetails.id,
        environments[selectedTabIndex].id,
        {
          idToken: idToken,
        },
      );
      if (data && data.error) {
        throw new Error(data.error.message);
      }

      const updated = environments.map(
        (e: { id: number; deleted_on: boolean }) => {
          if (e.id !== environments[selectedTabIndex].id) {
            return e;
          }
          e.deleted_on = true;
          return e;
        },
      );
      setEnvironments(updated);
    } catch (err) {
      errorApi.post(new Error(err?.message || 'Failed to archive'));
    }
  };

  const handleUnArchiveDialog = async () => {
    const idToken = await authref.getIdToken();
    const data: any = await projectApi.unArchiveResources(
      resourceContext.projectDetails.id,
      Number(environments[selectedTabIndex].id),
      {
        idToken: idToken,
      },
    );
    const code = data?.response?.status;
    if (code === 200) {
      const updated = environments.map(
        (e: { id: number; deleted_on: boolean }) => {
          if (e.id === environments[selectedTabIndex].id) {
            e.deleted_on = false;
          }
          return e;
        },
      );
      setEnvironments(updated);
    } else if (code >= 400) {
      const errorMsg = data?.response?.data?.message;
      errorApi.post(new Error(`${errorMsg}`));
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Grid item>
        <ResourceUrl environment={environments[selectedTabIndex]} />
      </Grid>
      <Grid item>{isEdit ? editModeButtons() : nonEditModeButtons()}</Grid>
      <DialogArchiveConfirmation
        isOpen={isOpenArchiveConfirmationDialog}
        setIsOpen={setIsOpenArchiveConfirmationDialog}
        onConfirm={onHandleArchive}
      />
      <DialogUnArchiveConfirmation
        isOpen={isOpenUnArchiveConfirmationDialog}
        setIsOpen={setIsOpenUnArchiveConfirmationDialog}
        onConfirm={handleUnArchiveDialog}
      />
      <SuccessBar
        isOpen={isSuccessBoxOpen}
        setIsSuccessBoxOpen={setIsSuccessBoxOpen}
      >
        <Grid container>
          <Grid item xs={1}>
            <CheckCircleIcon className={environmentClass.successBoxIcon} />
          </Grid>
          <Grid item className={environmentClass.successBoxMsgItem} xs={11}>
            <Typography
              variant="h5"
              className={environmentClass.successBoxHeading}
              sx={{ fontWeight: 'bold' }}
            >
              {t('resource.view.vault.environmentsSection.successBar.success')}
            </Typography>
            <Typography
              variant="subtitle1"
              className={environmentClass.successBoxSubHeading}
            >
              {successBoxMsg}
            </Typography>
          </Grid>
        </Grid>
      </SuccessBar>
    </Grid>
  );
};
