export const PROJECT_MGMT_NO_MGMT = 'NO_MANAGEMENT';
export const PROJECT_MGMT_TYPE_OWNERS = 'PROJECT_OWNERS';
export const PROJECT_MGMT_TYPE_OWNERS_END_USERS = 'PROJECT_OWNERS_END_USERS';
export const PROJECT_PARTICIPANT_COMPANY_WOVEN = 11;
export const GITHUB_COPILOT_TAG_KEY = 'copilot';
export const GITHUB_COPILOT_TAG_VALUE = 'enabled';

export const PROJECT_TEMPLATE_TAGS: any = {
  'sg-lob-woven': {
    pattern: /^\d{3}$/,
    helpLink:
      'https://hcm44.sapsf.com/sf/home?bplte_company=toyotareseP2&_s.crb=8L4qVDzA5%2f4CDM9XtGO3ibf%2bLvHuPU2BQX3U26u3DMc%3d',
  },
  'sg-project-code-woven': {
    pattern: /^[a-z0-9-]+$/,
    helpLink:
      'https://myhub.woven.toyota/content/page/66d916a8d7a46757b3e48c2a',
    maxLength: 63,
  },
};

export const PROJECT_KEY_REGEX = /^[A-Z][A-Z0-9]*$/;

export const GENAI_INDEX_ENABLED = 'genai-index-enabled';
