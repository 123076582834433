import { getTechDocsLink } from 'sg-utils-frontend';
import { useTranslation } from '../../../useTranslation';

export const useIntroductionCard = () => {
  const { t } = useTranslation();

  return {
    title: t('techdocs.cards.introduction.title'),
    description: t('techdocs.cards.introduction.description'),
    image: 'image1.webp',
    documents: [
      {
        title: t('techdocs.cards.introduction.welcomePage'),
        link: getTechDocsLink('stargate-welcome-guides'),
      },
      {
        title: t('techdocs.cards.introduction.gettingStarted'),
        link: getTechDocsLink('welcome-to-stargate'),
      },
      {
        title: t('techdocs.cards.introduction.architecture'),
        link: getTechDocsLink('architecture'),
      },
      {
        title: t('techdocs.cards.introduction.whoUseStargate'),
        link: getTechDocsLink('when-to-use-Stargate'),
      },
    ],
  };
};
