import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Autocomplete } from '@material-ui/lab';
import { definitions } from '../api';

type NamespaceAutocompletionResponseProjectType =
  definitions['handlers.NamespaceAutocompletionResponseProject'];

export type ProjectSelectorProps = {
  stargateProject?: NamespaceAutocompletionResponseProjectType | null;
  onSelect: (v: NamespaceAutocompletionResponseProjectType) => void;
  projectOptions: NamespaceAutocompletionResponseProjectType[];
  autocompletionsLoading: boolean;
  enableEditableButton: boolean;
};

export const ProjectSelector = (props: ProjectSelectorProps) => {
  const {
    stargateProject,
    onSelect,
    projectOptions,
    autocompletionsLoading,
    enableEditableButton,
  } = props;

  let el = (
    <Autocomplete
      onChange={(_: React.ChangeEvent<{}>, v: any) => onSelect(v)}
      value={stargateProject}
      loading={autocompletionsLoading}
      options={projectOptions}
      getOptionLabel={o => (o.name ? o.name : '')}
      getOptionSelected={(o, v) => (o.id && v.id ? o.id === v.id : false)}
      renderInput={params => (
        <TextField
          {...params}
          id={`${params.id}`}
          label="Stargate Project"
          placeholder="Select a Stargate Project from the list"
          helperText="Select the Stargate project workspace to use for your application namespaces from the list."
        />
      )}
      disabled={enableEditableButton}
      disableClearable
    />
  );
  if (!autocompletionsLoading && projectOptions.length === 0) {
    el = (
      <Alert severity="error">
        Only Stargate project owners can create Stargate Multicloud application
        namespaces.
      </Alert>
    );
  }

  return (
    <Grid container spacing={3} id="mtfuji_wizard_project_selector">
      <Grid item xs={12}>
        {el}
      </Grid>
    </Grid>
  );
};
