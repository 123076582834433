import React, { ReactElement } from 'react';
import { useLocation } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import { ContentHeader } from '@backstage/core-components';
import {
  PageLayout,
  Content,
  PageTitle,
  NavigationLinkComponent,
} from '@internal/sg-ui-kit';

type LayoutProps = {
  title: string;
  children: ReactElement;
  className?: string;
};

export const Layout = (props: LayoutProps) => {
  return (
    <PageLayout
      type="entity"
      title="Stargate Multicloud"
      subtitle="Kubernetes Platform"
    >
      <Content>
        <ContentHeader title={props.title} />
        <div className={props.className ?? ''}>{props.children}</div>
      </Content>
    </PageLayout>
  );
};

type FormLayoutProps = LayoutProps & {
  operation: string;
  projectId: string;
  projectName: string;
  applicationName?: string;
  subtitle?: string;
  contentClass?: string;
  hideHeader?: boolean;
};

const CREATE_SMC_OPERATION = 'create';
const ROOT_URL_LABEL_BREADCRUMBS = 'Project Workspace';

const getBreadcrumbs = (
  id: string,
  name: string,
  appName: string,
  url: string,
) => {
  return [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: `${id}?tab=resources`,
      display: name,
    },
    {
      isAbsolute: true,
      path: url,
      display: appName,
    },
  ];
};

type SMCPageTitleProps = {
  operation: string;
  projectName: string;
  applicationName?: string;
};

const SMCPageTitle = ({
  operation,
  projectName,
  applicationName,
}: SMCPageTitleProps) => {
  if (operation === CREATE_SMC_OPERATION) {
    return (
      <PageTitle
        customPageTitle={
          projectName
            ? `Create Resource in ${projectName} | Stargate`
            : `Create Resource | Stargate`
        }
      />
    );
  }
  return (
    <PageTitle
      customPageTitle={`${applicationName} | Resources | ${projectName} | Stargate`}
    />
  );
};

export const FormLayout = (props: FormLayoutProps) => {
  const {
    operation,
    projectId,
    projectName,
    applicationName,
    contentClass,
    hideHeader,
  } = props;

  const location = useLocation();
  const backToLink =
    applicationName !== undefined
      ? getBreadcrumbs(
          projectId,
          projectName,
          applicationName,
          location.pathname,
        )
      : undefined;

  return (
    <>
      {!hideHeader ? (
        <>
          <header>
            <Content className={contentClass}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item container direction="column">
                  {backToLink && (
                    <Grid item>
                      <NavigationLinkComponent
                        backToLink={backToLink}
                        isLightBackground
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Typography id="page-title" variant="h4">
                      {`Stargate Multicloud ${props.title}`}
                    </Typography>
                    {props.subtitle && (
                      <Typography id="page-subtitle" variant="caption">
                        {props.subtitle}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Content>
          </header>

          <SMCPageTitle
            operation={operation}
            projectName={projectName}
            applicationName={applicationName}
          />

          <Content className={contentClass}>
            <div className={props.className}>{props.children}</div>
          </Content>
        </>
      ) : (
        <>
          <Content className={contentClass}>
            <div className={props.className}>{props.children}</div>
          </Content>
        </>
      )}
    </>
  );
};
