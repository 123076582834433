import { Config } from '@backstage/config';
import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { generateHeaders } from 'sg-utils-frontend';

export const serviceCatalogApiRef = createApiRef<ServiceCatalogApi>({
  id: 'plugin.catalog-manager',
});

type ServiceCatalogApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
  gacApi: any;
};

class ServiceCatalogApiError extends Error {
  statusCode: number;
  constructor(message: string, statusCode: number) {
    super(message);
    this.name = 'ServiceCatalogApiError';
    this.statusCode = statusCode;
  }
}

export interface ServiceCatalogApiInterface {
  registerService: (
    params: ServiceRegistrationParams | APIServiceRegistrationParams,
    type: string,
  ) => Promise<Response>;
  fetchService: (
    serviceId: string,
    type: string,
    filter?: ServiceFilter,
  ) => Promise<Service | APIService>;
  updateService: (
    params: ServiceUpdateParams | APIServiceUpdateParams,
    serviceId: string,
    type: string,
  ) => Promise<boolean>;
}

export class ServiceCatalogApi implements ServiceCatalogApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private gacApi: any;
  private backendUrl: string;

  constructor(options: ServiceCatalogApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.gacApi = options.gacApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  private appendFilterSearchParams(filter: ServiceFilter, url: URL): URL {
    if (filter.project_id) {
      url.searchParams.append('project_id', filter.project_id.toLocaleString());
    }

    if (filter.project_key) {
      url.searchParams.append('project_key', filter.project_key);
    }

    if (filter.status) {
      url.searchParams.append('status', filter.status);
    }

    return url;
  }

  public async registerService(
    params: ServiceRegistrationParams | APIServiceRegistrationParams,
    type: string = 'service',
  ): Promise<Response> {
    const requestUrl = new URL(
      `${this.backendUrl}/api/catalog-manager/service`,
    );

    requestUrl.searchParams.set('type', type);

    const token = (await this.identityApi.getCredentials()).token;
    const gacToken = await this.gacApi.getIdToken();

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: gacToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new ServiceCatalogApiError(
        data?.error?.message || response.statusText,
        response.status,
      );
    }
    return data;
  }

  public async fetchService(
    serviceId: string,
    type: string = 'service',
    filter?: ServiceFilter,
  ): Promise<Service | APIService> {
    const requestUrl = new URL(
      `${this.backendUrl}/api/catalog-manager/services/${serviceId}`,
    );

    requestUrl.searchParams.set('type', type);

    const token = (await this.identityApi.getCredentials()).token;
    const gacToken = await this.gacApi.getIdToken();

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: gacToken,
      },
    );

    if (filter) {
      this.appendFilterSearchParams(filter, requestUrl);
    }

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new ServiceCatalogApiError(
        data?.error?.message || response.statusText,
        response.status,
      );
    }
    return data as Service;
  }

  public async updateService(
    params: ServiceUpdateParams | APIServiceUpdateParams,
    serviceId: string,
    type: string = 'service',
  ): Promise<boolean> {
    const requestUrl = new URL(
      `${this.backendUrl}/api/catalog-manager/services/${serviceId}`,
    );

    requestUrl.searchParams.set('type', type);

    const token = (await this.identityApi.getCredentials()).token;
    const gacToken = await this.gacApi.getIdToken();

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: gacToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'PATCH',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new ServiceCatalogApiError(
        data?.error?.message || response.statusText,
        response.status,
      );
    }
    return true;
  }
}
