import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  errorIcon: {
    margin: '10px auto',
    width: '2em',
    height: 'auto',
    color: theme.palette.usgColors.primary.red,
  },
  errorModal: {
    backgroundColor: theme.palette.usgColors.others.mix.red[0],
    textAlign: 'center',
  },
}));
