import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTechDocsReaderPage } from '@backstage/plugin-techdocs-react';
import { useNavigate } from 'react-router-dom';
import LanguageIcon from '@material-ui/icons/Language';
import { getLocalStorageWithExpiry, setTechDocsLang } from 'sg-utils-frontend';
import {
  detectLanguage,
  getNewPath,
  LanguageType,
} from './LanguageSelectorHelpers';
import { useStyles } from './styles';

type LanguageSelectorProps = {
  currentPath?: string;
};

const LanguageSelector = ({
  currentPath = window.location.pathname,
}: LanguageSelectorProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    getLocalStorageWithExpiry('locale') || 'en',
  );
  const navigate = useNavigate();
  const classes = useStyles();
  const { entityMetadata } = useTechDocsReaderPage();

  const supportedLanguages: {
    name: string;
    lang: LanguageType;
    by: string;
  }[] = useMemo(
    () => [
      {
        name: 'English',
        lang: 'en',
        by: entityMetadata?.value?.metadata?.name || '', // Taking name here as a split by type because we don't use '/en' for english
      },
      {
        name: 'Japanese',
        lang: 'ja',
        by: '/ja',
      },
    ],
    [entityMetadata],
  );

  const handleLanguageSwitchHelper = (language: string) => {
    setSelectedLanguage(language);
    setTechDocsLang(language);
    document.getElementById('test-lang')?.click();
  };

  const handleLanguageSwitch = useCallback(
    (language?: LanguageType) => {
      // when it is triggered by user click
      if (language) {
        const path = getNewPath(language, supportedLanguages, currentPath);
        navigate(path);
      } else {
        // when it is triggered by page load
        const detectedLanguage = detectLanguage(
          supportedLanguages,
          currentPath,
        );
        const isEnInURL = currentPath.split('/').includes('en');
        if (detectedLanguage.lang === 'en' && isEnInURL) {
          const splitPath = currentPath.split('/en');
          navigate(splitPath.join(''));
        }
        handleLanguageSwitchHelper(
          detectLanguage(supportedLanguages, currentPath).lang,
        );
      }
    },
    [navigate, supportedLanguages, currentPath],
  );

  useEffect(() => {
    handleLanguageSwitch();
  }, [handleLanguageSwitch]);

  return (
    <div className={classes.selector}>
      <LanguageIcon fontSize="medium" className={classes.icon} />
      <ul className={classes.list}>
        {supportedLanguages.map((element, index) => {
          return (
            <React.Fragment key={element.name}>
              <li
                aria-hidden="true"
                id={`li-${element.lang}`}
                className={
                  selectedLanguage === element.lang
                    ? classes.activeList
                    : classes.inActiveList
                }
                onClick={() => handleLanguageSwitch(element.lang)}
                title={element.name}
                key={element.name}
              >
                {element.lang.toLocaleUpperCase()}{' '}
              </li>{' '}
              {index === supportedLanguages.length - 1 ? `` : `|`}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default LanguageSelector;
