import React from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
  DialogContent,
  Dialog,
  Typography,
  Tooltip,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { useForm } from 'react-hook-form';
import { definitions } from '../api';
import { useStyles } from './styles';

type NamespaceAutocompletionResponseProject =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ApplicationEnvAADGroup = definitions['handlers.AADGroup'];

type UserGroupInput = {
  user_group_name: string;
  project_description: string;
};

type CreateUserGroupDialogProps = {
  open: boolean;
  handleDialogClose: (
    selectedTargetForm?: string,
    data?: ApplicationEnvAADGroup,
  ) => void;
  stargateProject: NamespaceAutocompletionResponseProject;
  groupType: string;
  appName: string;
};

export const getDefaultReadWriteGroupName = (appName: string) => {
  return `${appName}-rw`;
};

export const getDefaultReadOnlyGroupName = (appName: string) => {
  return `${appName}-ro`;
};

export const CreateUserGroupDialog = (props: CreateUserGroupDialogProps) => {
  const { open, handleDialogClose, stargateProject, groupType, appName } =
    props;
  const errorApi = useApi(errorApiRef);
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);

  const defaultGroupValues: Record<string, UserGroupInput> = {
    developer_group: {
      // the default app name already prefixes the project key
      // but if users create their own group names
      // the form needs to prefix the project key
      // so for the form's default value we need
      // to remove the prefix before we pass it in
      user_group_name: getDefaultReadWriteGroupName(appName).startsWith(
        `${stargateProject.key.toLowerCase()}-`,
      )
        ? getDefaultReadWriteGroupName(appName).substring(
            `${stargateProject.key}-`.length,
          )
        : getDefaultReadWriteGroupName(appName),
      project_description: `Grants members read-write access to ${appName} namespaces`,
    },
    readonly_group: {
      user_group_name: getDefaultReadOnlyGroupName(appName).startsWith(
        `${stargateProject.key.toLowerCase()}-`,
      )
        ? getDefaultReadOnlyGroupName(appName).substring(
            `${stargateProject.key}-`.length,
          )
        : getDefaultReadOnlyGroupName(appName),
      project_description: `Grants members read-only access to ${appName} namespaces`,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
    getFieldState,
  } = useForm<UserGroupInput>({
    mode: 'onChange',
    defaultValues: defaultGroupValues[groupType],
  });

  const isValidName = (name: string) => {
    return stargateProject.key.length + name.length <= 31;
  };

  const onSubmit = async (data: UserGroupInput) => {
    const idToken = await authref.getIdToken();
    const userData: any = await projectApi.getUserDetails({ idToken: idToken });
    // Create the User Group
    try {
      const userGroupResp: any = await projectApi.addProjectUserGroupData(
        {
          members: [''],
          name: `${stargateProject.key.toLowerCase()}-${data.user_group_name.toLowerCase()}`,
          description: data.project_description,
          accessible_resources: [],
        },
        stargateProject.id.toString(),
        idToken,
      );

      const userGroupResDataData = await userGroupResp.json();
      if (userGroupResDataData && userGroupResDataData.error) {
        throw new Error(userGroupResDataData.error.message);
      }

      if (!userGroupResp.ok) {
        throw new Error(userGroupResp.statusText);
      }

      userGroupResDataData.id = userGroupResDataData.id?.toString();
      userGroupResDataData.uuid = userGroupResDataData.id?.toString();
      // Add the user to the newly created User Group
      const params = {
        idToken: idToken,
        user_memberships: [
          {
            user_email: userData.response.data.email,
            user_group_id: Number(userGroupResDataData?.id),
            action: 'ADD',
          },
        ],
        project_id: stargateProject.id,
      };
      const membershipResp: any =
        await projectApi.createUserMembershipRequestsV3Data(
          params,
          stargateProject.id.toString(),
        );
      if (membershipResp.response?.status >= 400) {
        const errorMsg = membershipResp?.response?.data?.message;
        throw new Error(errorMsg);
      }

      // both creation and membership addition successful
      handleDialogClose(groupType, userGroupResDataData);
      reset();
    } catch (err) {
      errorApi.post(new Error(`${err.message}`));
    }
  };

  // return (
  //   <Dialog open={open} onClose={() => handleDialogClose()} maxWidth="sm">
  //     <DialogTitle>
  //       <div className={classes.dialogHeader}>
  //         <Typography variant="h3">{t("dialog.title")}</Typography>
  //         <Tooltip
  //           classes={{
  //             tooltip: classes.tooltip,
  //             arrow: classes.tooltipArrow,
  //           }}
  //           arrow
  //           placement="top"
  //           title={t("dialog.tooltipCloseButton")}
  //         >
  //           <IconButton
  //             id="create-user-grp-close-icon-btn"
  //             aria-label="close"
  //             className={classes.iconContainer}
  //             onClick={() => handleDialogClose()}
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //         </Tooltip>
  //       </div>
  //     </DialogTitle>
  //     <DialogContent id="ug-create-confirmed-dialog" dividers>
  //       <div style={{ paddingBottom: "24px" }}>
  //         <form onSubmit={handleSubmit(onSubmit)}>
  //           <div>
  //             <InputLabel error={!isValid} htmlFor="user_group_name">
  //               {t("form.userGroupNameLabel")}
  //             </InputLabel>
  //             <TextField
  //               style={{ paddingBottom: "0%" }}
  //               type="text"
  //               id="user_group_name"
  //               defaultValue={defaultGroupValues[groupType]?.user_group_name}
  //               error={!isValid && getFieldState("user_group_name").isTouched}
  //               fullWidth
  //               InputProps={{
  //                 startAdornment: (
  //                   <InputAdornment
  //                     style={{
  //                       textTransform: "lowercase",
  //                       marginLeft: "0",
  //                     }}
  //                     position="end"
  //                   >
  //                     {`${stargateProject.key.toLowerCase()}-`}
  //                   </InputAdornment>
  //                 ),
  //               }}
  //               helperText={t("form.userGroupNameHelperText", {
  //                 projectKey: `${stargateProject.key.toLowerCase()}-`,
  //               })}
  //               {...register("user_group_name", {
  //                 required: true,
  //                 pattern: /^[a-z0-9]+(-[a-z0-9]+)*$/,
  //                 validate: isValidName,
  //               })}
  //             />
  //           </div>
  //           <div style={{ marginTop: "16px" }}>
  //             <InputLabel htmlFor="project_description">
  //               {t("form.descriptionLabel")}
  //             </InputLabel>
  //             <TextField
  //               type="text"
  //               id="project_description"
  //               defaultValue={defaultGroupValues[groupType]?.project_description}
  //               minRows={4}
  //               multiline
  //               fullWidth
  //               helperText={t("form.descriptionHelperText")}
  //               placeholder={t("form.descriptionPlaceholder")}
  //               {...register("project_description")}
  //             />
  //           </div>
  //           <div style={{ paddingTop: "5%" }}>
  //             <Grid container spacing={2}>
  //               <Grid item>
  //                 <Button
  //                   id="create-ug-cancel-button"
  //                   variant="outlined"
  //                   type="reset"
  //                   onClick={() => {
  //                     reset();
  //                     handleDialogClose();
  //                   }}
  //                 >
  //                   {t("buttons.cancel")}
  //                 </Button>
  //               </Grid>

  //               <Grid item>
  //                 <Button
  //                   id="create-ug-submit-button"
  //                   variant="contained"
  //                   type="submit"
  //                   disabled={!isValid || loadingFlag}
  //                 >
  //                   {t("buttons.create")}
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         </form>
  //       </div>
  //     </DialogContent>
  //   </Dialog>
  // );

  return (
    <Dialog open={open} onClose={() => handleDialogClose()} maxWidth="sm">
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography variant="h3">Create User Group</Typography>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              id="create-user-grp-close-icon-btn"
              aria-label="close"
              className={classes.iconContainer}
              onClick={() => handleDialogClose()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent id="ug-create-confirmed-dialog" dividers>
        <div style={{ paddingBottom: '24px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <InputLabel error={!isValid} htmlFor="user_group_name">
                User Group Name*
              </InputLabel>
              <TextField
                style={{ paddingBottom: '0%' }}
                type="text"
                id="user_group_name"
                defaultValue={defaultGroupValues[groupType]?.user_group_name}
                error={!isValid && getFieldState('user_group_name').isTouched}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      style={{
                        textTransform: 'lowercase',
                        marginLeft: '0',
                      }}
                      position="end"
                    >
                      {`${stargateProject.key.toLowerCase()}-`}
                    </InputAdornment>
                  ),
                }}
                helperText={`Provide a unique name for your user group. Only lower case letters, numbers and single-hyphens are allowed. Does not end with hyphen. Max. no. of characters allowed = 32 (including project key - ${`${stargateProject.key.toLowerCase()}-`})`}
                {...register('user_group_name', {
                  required: true,
                  pattern: /^[a-z0-9]+(-[a-z0-9]+)*$/,
                  validate: isValidName,
                })}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <InputLabel htmlFor="project_description">Description</InputLabel>
              <TextField
                type="text"
                id="project_description"
                defaultValue={
                  defaultGroupValues[groupType]?.project_description
                }
                minRows={4}
                multiline
                fullWidth
                helperText="Provide a brief description of the user group you are creating. "
                placeholder="Example : To manage users who need developer rights to various software tools and are a part of the TPM project."
                {...register('project_description')}
              />
            </div>
            <div style={{ paddingTop: '5%' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    id="create-ug-cancel-button"
                    variant="outlined"
                    type="reset"
                    onClick={() => {
                      reset();
                      handleDialogClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    id="create-ug-submit-button"
                    variant="contained"
                    type="submit"
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
