import {
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  VAULT_DEV_TOOL_ID,
  DataItem,
} from 'usg-types';
/**
 * checks if devTool supports dynamic groups
 *
 * @param devTool
 * @returns { Boolean }
 */
export const devToolSupportsDynamicGroup = (devTool: any): boolean => {
  return Boolean(devTool?.dynamic_group_supported);
};

/**
 * returns the "required" validation of a role for a resource
 *
 * @param devtool_id
 * @param resource_ug_id
 * @param role
 * @returns { Boolean }
 */

export const isAttachResourcesRoleRequired = (
  devtool_id: any,
  resource_ug_id: any,
  role: any,
): boolean => {
  return (
    ([VAULT_DEV_TOOL_ID].includes(devtool_id) ? role === '' : role !== '') &&
    resource_ug_id !== ''
  );
};

/**
 * checks if role is disabled for a devTool
 *
 * @param idx
 * @returns { Boolean }
 */

export const commonDisableRoleSelection = (idx: string) => {
  return [MTFUJI_DEV_TOOL_ID, VAULT_DEV_TOOL_ID].includes(parseInt(idx, 10));
};

/**
 * checks if resource is assignable by owners
 *
 * @param dev_tool_id
 * @returns { Boolean }
 */

export const commonHideOwnerResources = (dev_tool_id: any) => {
  const devtoolsToHideFromOwners = [MTFUJI_DEV_TOOL_ID, VAULT_DEV_TOOL_ID];
  return devtoolsToHideFromOwners.includes(parseInt(dev_tool_id, 10));
};

/**
 * checks if resource is allowed to be assigned user groups
 *
 * Condition:
 * 1. Resource is not an Artifactory Self-Hosted / SaaS Virtual Repository
 *
 * @param resourceData
 * @returns { Boolean }
 */
export const isResourceUserGroupAssignable = (resourceData: any) => {
  const isArtifactoryResource =
    resourceData?.dev_tool_id?.toString() ===
      ARTIFACTORY_DEV_TOOL_ID.toString() ||
    resourceData?.dev_tool_id?.toString() ===
      ARTIFACTORY_SAAS_DEV_TOOL_ID.toString();
  return !(isArtifactoryResource && resourceData?.config?.rclass === 'virtual');
};

/**
 * Sorts the data as per environment order 1st prod, then stg and then dev
 *
 * @param data
 */
export const sortByEnvironment = (data: DataItem[]) => {
  const environmentOrder: {
    prod: number;
    stg: number;
    dev: number;
  } = {
    prod: 1,
    stg: 2,
    dev: 3,
  };

  // Sort the array based on the custom environment order
  return data.sort((a, b) => {
    return environmentOrder[a.environment] - environmentOrder[b.environment];
  });
};

/**
 * Determines whether to display a "Coming Soon" message for a resource.
 *
 * This function checks if a given resource (identified by `resourceID`) is
 * disabled (not present in `enabledDevTools`) and if the user is not a
 * resource creator for that resource within the specified project.
 *
 * @param {number} resourceID - The unique identifier of the resource (typically a dev tool ID).
 * @param {number} projectId - The unique identifier of the project.
 * @param {number[]} enabledDevTools - An array of dev tool IDs that are enabled.
 * @param {(project_id: number, dev_tool_id: number) => boolean} isResourceCreator -
 *   A function that checks if the user is a resource creator for a given project and dev tool.
 *
 * @returns {boolean} - Returns `true` if the "Coming Soon" message should be shown, otherwise `false`.
 */
export const showComingSoonText = (
  resourceID: number,
  projectId: number,
  enabledDevTools: number[],
  isResourceCreator: (project_id: number, dev_tool_id: number) => boolean,
) => {
  // show for the disabled devtools that are not in resource creator list
  return (
    ![...enabledDevTools].includes(resourceID) &&
    !isResourceCreator(projectId, resourceID)
  );
};
