import React, { useMemo } from 'react';
import { Tabs } from '@internal/sg-ui-kit';

import { CloudUsage, CloudLicenseUsage } from '../../components';
import { usePermissions } from '@internal/plugin-projects';
import { useTranslation } from '../../hooks/useTranslation';
import { useStyles } from './styles';

interface CatalogUsagePageProps {
  projectId?: string;
}

export const CatalogUsagePage = ({ projectId }: CatalogUsagePageProps) => {
  const classes = useStyles();
  const { isProjectOwner } = usePermissions();

  const { t } = useTranslation();

  const type = new URLSearchParams(window.location.search).get('usageType');

  const tabs = useMemo(() => {
    const tabsToRender = [
      {
        label: t('catalog.tabs.cloud'),
        value: 'cloud-usage',
        content: <CloudUsage projId={projectId} />,
      },
    ];

    if (projectId && isProjectOwner(parseInt(projectId, 10))) {
      tabsToRender.push({
        label: t('catalog.tabs.license'),
        value: 'cloud-license-usage',
        content: <CloudLicenseUsage projId={projectId} />,
      });
    }

    return tabsToRender;
  }, [isProjectOwner, projectId, t]);

  return (
    <div className={classes.container}>
      <div>
        <div>
          <Tabs
            tabs={tabs}
            initialActiveTabIndex={type === 'license' ? 1 : 0}
          />
        </div>
      </div>
    </div>
  );
};
