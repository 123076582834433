import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography } from '@material-ui/core';
import { ApplicationRequestProps, ServiceAccountAdmin } from './types';
import AddIcon from '@material-ui/icons/Add';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';

type EnvironmentAdmins = {
  [env: string]: ServiceAccountAdmin[];
};

// props schema
export type ServiceAccountAdminProps = {
  currentEnvName: string;
  disabled?: boolean;
  value: ServiceAccountAdmin[];
  onChange: (value: ServiceAccountAdmin[]) => void;
};

export const ServiceAccountAdmins = (props: ServiceAccountAdminProps) => {
  const {
    currentEnvName,
    disabled: isDisabled,
    value,
    onChange: handleOnChange,
  } = props;

  const [currentRowIndex, setCurrentRowIndex] = useState<number>(0);

  const parentForm = useFormContext<ApplicationRequestProps>();

  const { control, register, getValues, setFocus, getFieldState } =
    useForm<EnvironmentAdmins>({
      defaultValues: { admins: value },
      mode: 'onChange',
      reValidateMode: 'onChange',
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: false,
    });

  const defaultNamespace = [
    parentForm.getValues('app_name'),
    currentEnvName,
  ].join('-');

  const { fields, insert, remove, append } = useFieldArray({
    control,
    name: `admins`,
  });

  useEffect(() => {
    setFocus(`admins.${currentRowIndex}.name`);
  }, [currentRowIndex, setFocus, fields.length]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">Service Account Admins</Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer
                  sx={{ maxHeight: 440, height: 440, overflowY: 'scroll' }}
                >
                  <Table
                    size="small"
                    id="mtfuji-field-array-table"
                    stickyHeader
                    className="mtfuji-field-array-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Namespace</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell style={{ width: 160 }}>
                          <IconButton
                            id="mtfuji-field-array-table-button-append-row"
                            aria-label="add"
                            onClick={() => {
                              append({ namespace: '', name: '' });
                              setCurrentRowIndex(fields.length);
                            }}
                            disabled={isDisabled}
                          >
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((field, index) => (
                        <TableRow key={field.id}>
                          <TableCell>
                            <TextField
                              id={`mtfuji-field-array-input-${index}-namespace`}
                              type="text"
                              fullWidth
                              placeholder={defaultNamespace}
                              {...register(`admins.${index}.namespace`, {
                                onChange: e => {
                                  const newAdmins: ServiceAccountAdmin[] =
                                    getValues('admins').map((f, i) => {
                                      return {
                                        namespace:
                                          index === i
                                            ? e.target.value
                                            : f.namespace,
                                        name: f.name,
                                      };
                                    });
                                  handleOnChange(newAdmins);
                                },
                              })}
                              disabled={isDisabled}
                              helperText={
                                getFieldState(`admins.${index}.namespace`).error
                                  ?.message || '\u00A0'
                              }
                              error={
                                getFieldState(`admins.${index}.namespace`)
                                  .error !== undefined
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              id={`mtfuji-field-array-input-${index}-name`}
                              type="text"
                              fullWidth
                              {...register(`admins.${index}.name`, {
                                required: 'Name is required',
                                pattern: {
                                  value: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/,
                                  message: 'Invalid name for service account',
                                },
                                onChange: e => {
                                  const newAdmins: ServiceAccountAdmin[] =
                                    getValues('admins').map((f, i) => {
                                      return {
                                        namespace: f.namespace,
                                        name:
                                          index === i ? e.target.value : f.name,
                                      };
                                    });
                                  handleOnChange(newAdmins);
                                },
                              })}
                              disabled={isDisabled}
                              helperText={
                                getFieldState(`admins.${index}.name`).error
                                  ?.message || '\u00A0'
                              }
                              error={
                                getFieldState(`admins.${index}.name`).error !==
                                undefined
                              }
                            />
                          </TableCell>
                          <TableCell style={{ width: 160 }}>
                            <Grid container style={{ marginTop: -15 }}>
                              <Grid item>
                                <IconButton
                                  id={`mtfuji-field-array-button-${index}-insert-row`}
                                  aria-label="add"
                                  onClick={() => {
                                    insert(index + 1, {
                                      namespace: '',
                                      name: '',
                                    });
                                    setCurrentRowIndex(index + 1);
                                  }}
                                  disabled={isDisabled}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  id={`mtfuji-field-array-button-${index}-remove-row`}
                                  aria-label="delete"
                                  disabled={isDisabled}
                                  onClick={() => {
                                    const isLastItem =
                                      fields.length - 1 === index;
                                    const isFirstItem = index === 0;

                                    remove(index);

                                    if (isFirstItem) {
                                      setCurrentRowIndex(0);
                                    } else if (isLastItem) {
                                      setCurrentRowIndex(fields.length - 2);
                                    } else {
                                      setCurrentRowIndex(index);
                                    }
                                    handleOnChange(getValues('admins'));
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="overline"
                component="p"
                color="textSecondary"
              >
                Specify Kubernetes service accounts to grant admin access to the
                current environment. If the namespace field is left empty, it
                will default to the current environment.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
