export const ARTIFACTORY_SAAS_NAME = 'SaaS';

export const ARTIFACTORY_SAAS_PROD_HOST = 'artifactory.stargate.toyota';
export const ARTIFACTORY_SAAS_NON_PROD_HOST =
  'artifactory.test.stargate.toyota';

export const ARTIFACTORY_PROD_HOST = 'artifactory-ha.tmc-stargate.com';
export const ARTIFACTORY_NON_PROD_HOST = 'artifactory-ha.stg.tmc-stargate.com';
export const GITHUB_HOST = 'github.com';

export const HIDDEN_ENVIRONMENT_CONFIG_KEYS = [
  'aad_readonly_group_id',
  'aad_developer_group_id',
  'aad_user_email',
];

export const OPTIONAL_ENVIRONMENT_CONFIG_KEYS = [
  'aad_user_email',
  'user_labels',
  'resource_quotas',
  'service_account_admins',
];

export const DEFAULT_CONFIG_ENVIRONMENTS = ['prod', 'dev', 'stage'];

export const RESOURCE_QUOTA_KEYS = [
  'requests.cpu',
  'limits.cpu',
  'requests.memory',
  'limits.memory',
  'requests.storage',
  'requests.ephemeral-storage',
  'limits.ephemeral-storage',
  'requests.nvidia.com/gpu',
  'limits.nvidia.com/gpu',
];

export const PRODUCTION_ENVIRONMENTS = [
  'prod',
  'production',
  'prd',
  'apps-prod',
];

export const NON_PRODUCTION_ENVIRONMENTS = [
  'dev',
  'stage',
  'staging',
  'stg',
  'test',
  'qa',
  'acceptance',
  'uat',
  'non-prod',
  'pre-prod',
  'canary',
  'prod-canary',
  'apps',
  'beta',
];

export const AAD_CACHE_PREFIX = 'aad-uuid-cache';

export const SLACK_CHANNEL_URL =
  'https://woven-by-toyota.slack.com/archives/C054V6JJDQV';
