import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

const LETTER_SPACING = 0;
const FONT_WEIGHT = 400;

export const useStyles = makeStyles<USGTheme>(theme => ({
  container: {
    padding: '40px',
    '& label': {
      marginTop: '10px',
    },
    '& .v5-MuiTextField-root, .MuiOutlinedInput-root, .MuiFormHelperText-root':
      {
        width: '50%',
      },
  },
  text: {
    color: theme.palette.usgColors.primary.black,
    fontWeight: FONT_WEIGHT,
    letterSpacing: LETTER_SPACING,
  },
  italicText: {
    fontStyle: 'italic',
  },
  roverSettingDialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
