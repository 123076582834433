import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Grid,
  Typography,
  Chip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { definitions } from '../api';
import { StyledTextField, ControlAutocomplete } from './FormHelpers';
import { ApplicationRequestProps } from './types';
import { USGTheme } from 'usg-new-theme';

// Schemas
export type NamespaceRequestSpec = definitions['handlers.NamespaceRequestSpec'];
export type ApiConfigCluster = definitions['config.MountFujiCluster'];

// props schema
export type ActiveClustersProps = {
  defaultClusters: {
    id: string;
    label: string;
    name_prefix: string;
    provider: string;
    region: string;
  }[];
  autocompletionsLoading: boolean;
  clusterOptions: ApiConfigCluster[];
  validationSetter: (isValid: boolean) => void;
  smcView?: boolean;
};

const useStyles = makeStyles((theme: USGTheme) =>
  createStyles({
    appClusterNote: {
      fontStyle: 'italic',
    },
    appClusterNoteContainer: {
      paddingBottom: '0 !important',
    },
    root: {
      padding: theme.spacing(3),
      maxWidth: 900,
      margin: 'auto',
      backgroundColor: '#fff',
    },
    section: {
      marginBottom: theme.spacing(3),
    },
    title: {
      fontWeight: 600,
    },
    chip: {
      margin: theme.spacing(0.5),
      background: theme.palette.usgColors.others.blue[200],
      color: theme.palette.usgColors.others.blue[600],
      marginLeft: 0,
      '& span': {
        fontSize: '12px',
        fontWeight: 400,
      },
    },
    editLink: {
      float: 'right',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    gridItem: {
      padding: theme.spacing(1),
    },

    dividerVertical: {
      height: 'auto', // Ensures full height inside flex container
      alignSelf: 'stretch', // Stretches within its parent grid container
      width: 1,
      backgroundColor: theme.palette.usgColors.secondary.grey50,
      margin: '0 16px',
    },
  }),
);
export const ActiveClusters = (props: ActiveClustersProps) => {
  const {
    defaultClusters,
    autocompletionsLoading,
    clusterOptions,
    validationSetter,
    smcView,
  } = props;
  const classes = useStyles();
  const {
    setValue,
    control,
    getFieldState,
    formState: { isValid },
  } = useFormContext<ApplicationRequestProps>();

  useEffect(() => {
    validationSetter(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const [activeClustersValue, setActiveClustersValue] =
    useState<ApiConfigCluster[]>(defaultClusters);

  const onClusterChange = (_: React.ChangeEvent<{}>, v: ApiConfigCluster[]) => {
    setActiveClustersValue(v);
    setValue('active_clusters', v, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <>
      {!smcView ? (
        <>
          <Grid container spacing={3} id="mtfuji_wizard_active_clusters">
            <Grid item xs={12} className={classes.appClusterNoteContainer}>
              <Typography variant="overline" className={classes.appClusterNote}>
                Stargate Multicloud offers AWS or GCP clusters in multiple
                regions to select from. The three environment namespaces every
                application has, will be created in every region you select.
                Your prod namespace will be on a separate cluster in the same
                region as your non-prod namespaces.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ControlAutocomplete
                name="active_clusters"
                rules={{ required: true }}
                control={control}
                multiple
                options={clusterOptions}
                loading={autocompletionsLoading}
                filterSelectedOptions
                onChange={onClusterChange}
                value={activeClustersValue}
                getOptionLabel={o => (o.label ? o.label : '')}
                getOptionSelected={(o, v) => o.id === v.id}
                renderInput={params => (
                  <StyledTextField
                    {...params}
                    label="Active clusters *"
                    placeholder="Clusters"
                    helperText="Select one or more clusters to run your application on."
                    error={getFieldState('active_clusters').invalid}
                    InputLabelProps={{
                      style: { fontWeight: 700, fontSize: '14px' },
                      shrink: true,
                    }}
                  />
                )}
              />
              <Typography variant="overline" className={classes.appClusterNote}>
                Tip: Select one cluster initially. You can add more later when
                your application is ready to go multi-region or multi-cloud.
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Typography variant="subtitle2">Active Clusters</Typography>

          {activeClustersValue.map(cluster => (
            <Chip
              key={cluster.id}
              label={cluster.label}
              className={classes.chip}
            />
          ))}
        </>
      )}
    </>
  );
};
