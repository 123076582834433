import { makeStyles } from '@material-ui/core/styles';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  unreadCountChip: {
    position: 'absolute',
    top: -10,
    right: -18,
    backgroundColor: theme.palette.usgColors.primary.red,
    color: 'white',
    height: 18,
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
}));
