import React, { useEffect } from 'react';
import { CodeSnippet, LinkButton, Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';

export type PreviewProps = {
  loading: boolean;
  error: Error | undefined;
  createProcess?: boolean;
  updateProcess?: boolean;
  setSmcView: any;
};

export const Preview = (props: PreviewProps) => {
  const { loading, error, createProcess, updateProcess, setSmcView } = props;

  let progressEL = <></>;
  if (loading) {
    progressEL = (
      <>
        <Progress />
        {updateProcess && (
          <Alert severity="info" key="pendingEl">
            Your application namespace is being updated. This usually takes
            between 5 and 7 minutes.
          </Alert>
        )}
        {createProcess && (
          <Alert severity="info" key="pendingEl">
            Your application namespace is being created. This usually takes
            between 5 and 7 minutes.
          </Alert>
        )}
      </>
    );
  }

  const slackChannelUrl =
    'https://woven-by-toyota.slack.com/archives/C054V6JJDQV';
  const errorEl = (
    <Alert
      severity="error"
      key="errorEl"
      style={{ marginTop: 10, overflow: 'scroll' }}
    >
      Unfortunately, there was an error when updating your application.
      <CodeSnippet
        text={JSON.stringify({ message: error?.message }, null, 2)}
        language="json"
        showCopyCodeButton
      />
      Please provide the above error to the Stargate Multicloud team in Slack
      and we will help!
      <br />
      <br />
      <LinkButton color="secondary" variant="contained" to={slackChannelUrl}>
        Get help on Slack
      </LinkButton>
    </Alert>
  );

  useEffect(
    () => {
      if (loading) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
        if (updateProcess) {
          setSmcView(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading],
  );

  return (
    <div id="mtfuji_wizard_preview">
      {progressEL}

      {error ? errorEl : <></>}
    </div>
  );
};
