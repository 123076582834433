type BaseCsvData = {
  [key: string]: string | number;
};

type RawRepositoryData = {
  name: string;
  usage_bytes: number;
  usage_gigabytes: number;
  usage_gigabytes_rounded: number;
  network_usage_gigabytes_rounded?: number;
  cost: number;
  cost_rounded: number;
};

type ArtifactorySelfHostedCsvData = BaseCsvData & {
  name: string;
  network_storage_gb: number;
};

type ArtifactorySaaSCsvData = BaseCsvData & {
  name: string;
  network_storage_gb: number;
  network_usage_gb: number;
  cost: number;
  cost_rounded: number;
};

type CsvData =
  | BaseCsvData
  | ArtifactorySaaSCsvData
  | ArtifactorySelfHostedCsvData;

const artifactorySelfHostedTransformer = (
  data: RawRepositoryData[],
): ArtifactorySelfHostedCsvData[] => {
  return data.map(repoData => ({
    name: repoData.name,
    network_storage_gb: repoData.usage_gigabytes_rounded,
  }));
};

const artifactorySaaSTransformer = (
  data: RawRepositoryData[],
): ArtifactorySelfHostedCsvData[] => {
  return data.map(repoData => ({
    name: repoData.name,
    network_storage_gb: repoData.usage_gigabytes_rounded,
    network_usage_gb: repoData.network_usage_gigabytes_rounded || 0,
    cost: repoData.cost,
    cost_rounded: repoData.cost_rounded,
  }));
};

export const transformToCsv = (
  data: BaseCsvData[] | RawRepositoryData[],
  tool: string,
): CsvData[] => {
  switch (tool) {
    case 'AS':
      return artifactorySelfHostedTransformer(data as RawRepositoryData[]);
    case 'ARTIFACTORY_SAAS':
      return artifactorySaaSTransformer(data as RawRepositoryData[]);
    default:
      return data as CsvData[];
  }
};
