import { format, compareAsc, compareDesc, isDate, isEqual } from 'date-fns';
import { SortDirections } from 'usg-types';

export const formatDate = (
  date: string,
  isLongMonthName: boolean,
  withTime: boolean = false,
) => {
  const formattedDate = isLongMonthName ? 'MMMM d, yyyy' : 'MMM d, yyyy';
  const finalFormat = withTime ? `${formattedDate} HH:mm` : formattedDate;
  return format(new Date(date), finalFormat);
};

export const dateComparer = (
  date1: Date,
  date2: Date,
  sortDirection: SortDirections = SortDirections.ASC,
): number => {
  const compareFunction =
    sortDirection === SortDirections.ASC ? compareAsc : compareDesc;

  if (!isDate(date1) || !isDate(date2) || isEqual(date1, date2)) {
    return 0;
  }

  return compareFunction(date1, date2);
};

// string should be in string format, put string to make it comptible with our db string format
// it's by default format of date from postreSQL DB, date format is YYYY-MM-DD
export const daysDiffFromPresent = (inputDate: string): number => {
  const currentDate = new Date();
  const pastDate = new Date(inputDate);
  const diff = Math.abs(currentDate.getTime() - pastDate.getTime());
  const diffDays = diff / (1000 * 3600 * 24);
  return diffDays;
};
