import React from 'react';
import {
  Table,
  StatusOK,
  StatusPending,
  StatusError,
  StatusAborted,
  StatusRunning,
  TableColumn,
  Progress,
} from '@backstage/core-components';
import Alert from '@material-ui/lab/Alert';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Layout } from '../Layout';
import { mtfujiApiRef, NamespacesResponse } from '../../fetcher';
import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { KubeconfigDialog } from '../Kubeconfig';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import AnalyticsIcon from '@material-ui/icons/Dashboard';
import LogsIcon from '@material-ui/icons/FindInPage';
import RepositoryIcon from '@material-ui/icons/Code';
import { useNavigate } from 'react-router-dom';
import { Link, Tooltip, styled } from '@material-ui/core';
import { PageTitle } from '@internal/sg-ui-kit';
import { useTranslation } from '../../hooks/useTranslation';

type NamespaceTableProps = {
  namespaces: NamespacesResponse[];
  showActions: boolean;
  showSearch: boolean;
};

const NewButton = styled(Button)<ButtonProps>(() => ({
  minWidth: '175px',
  backgroundColor: '#fff',
  color: '#1059CD',
  border: '1px solid #1059CD',
  marginRight: '10px',
  '&:hover': {
    color: '#fff',
  },
}));

export const NamespaceTable = (props: NamespaceTableProps) => {
  const { namespaces, showActions, showSearch } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns: TableColumn[] = [
    {
      title: '',
      field: 'state',
      render: (row: any): React.ReactNode => stateIcon(row.state),
      width: '10px',
    },
    {
      title: t('namespaces.applicationTitle'),
      field: 'application_name',
      render: showActions
        ? (row: any): React.ReactNode =>
            linkToAppNSDetails(row.stargate_project_id, row.application_name)
        : undefined,
    },
    { title: t('namespaces.clusterColumnName'), field: 'cluster_name' },
    { title: t('namespaces.namespaceColumnName'), field: 'namespace_name' },
  ];

  function linkToAppNSDetails(sgProjId: number, appName: string) {
    const href = `/mtfuji/project/${sgProjId}/application/${appName}`;
    return (
      <Link href="#" onClick={() => navigate(href)}>
        {appName}
      </Link>
    );
  }

  function stateIcon(state: string) {
    let inner = <></>;
    switch (state) {
      case 'applied': {
        inner = <StatusOK />;
        break;
      }
      case 'pending_create': {
        inner = <StatusRunning />;
        break;
      }
      case 'pending_change': {
        inner = <StatusPending />;
        break;
      }
      case 'pending_delete': {
        inner = <StatusError />;
        break;
      }
      default: {
        inner = <StatusAborted />;
      }
    }

    return (
      <Tooltip title={state}>
        <span>{inner}</span>
      </Tooltip>
    );
  }

  function wavefrontUrl(d: NamespacesResponse) {
    return (
      `https://longboard.wavefront.com/dashboards/Kubernetes-Containers-Mt-Fuji#_v01(p:(` +
      `cluster_name:(d:Label_2,f:TAG_KEY,k:cluster,l:Cluster,m:(Label_2:'${d.cluster_name}')),` +
      `container:(ala:!t,d:Label,f:TAG_KEY,k:container_name,l:Container,m:(Label:'*')),` +
      `namespace:(d:Label_2,f:TAG_KEY,k:namespace,l:Namespace,m:(Label_2:'mtfuji-${d.namespace_name}')),` +
      `node_name:(ala:!t,d:Label,f:TAG_KEY,k:nodename,l:Node,m:(Label:'*')),` +
      `pod_name:(ala:!t,d:Label,f:TAG_KEY,k:pod_name,l:Pod,m:(Label:'*'))` +
      `))`
    );
  }

  function stackdriverUrl(d: NamespacesResponse) {
    return `https://console.cloud.google.com/logs/query?project=${d.application_name.substring(
      0,
      25,
    )}-logs`;
  }

  function githubUrl(d: NamespacesResponse) {
    return `https://${d.repository_host || 'github.tri-ad.tech'}/${
      d.repository_owner
    }/${d.repository_name}`;
  }

  return (
    <Table
      options={{
        search: showSearch,
        paging: false,
        actionsColumnIndex: columns.length,
      }}
      columns={columns}
      data={namespaces as {}[]}
      actions={
        !showActions
          ? []
          : [
              {
                icon: AnalyticsIcon,
                tooltip: t('namespaces.tooltips.wavefront'),
                onClick: (_, rowData) => {
                  window.open(
                    wavefrontUrl(rowData as NamespacesResponse),
                    '_blank',
                  );
                },
              },
              {
                icon: LogsIcon,
                tooltip: t('namespaces.tooltips.stackdriver'),
                onClick: (_, rowData) => {
                  window.open(
                    stackdriverUrl(rowData as NamespacesResponse),
                    '_blank',
                  );
                },
              },
              {
                icon: RepositoryIcon,
                tooltip: t('namespaces.tooltips.githubEnterprise'),
                onClick: (_, rowData) => {
                  window.open(
                    githubUrl(rowData as NamespacesResponse),
                    '_blank',
                  );
                },
              },
            ]
      }
    />
  );
};

export const Namespaces = () => {
  const createNamespacePath = '/mtfuji/new';
  const navigate = useNavigate();
  const mtfujiApi = useApi(mtfujiApiRef);
  const { value, loading, error } = useAsync(mtfujiApi.getNamespaces, []);
  const [kubeConfOpen, setKubeConfOpen] = React.useState(false);
  const { t } = useTranslation();

  const openKubeconfigDialog = () => {
    setKubeConfOpen(true);
  };

  const closeKubeconfigDialog = () => {
    setKubeConfOpen(false);
  };

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      <PageTitle customPageTitle={t('namespaces.pageTitle')} />
      <Layout title={t('namespaces.layoutTitle')}>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <NewButton
                  id="mtfuji_namespaces_create_new_button"
                  variant="contained"
                  color="inherit"
                  endIcon={<AddIcon />}
                  onClick={() => navigate(createNamespacePath)}
                >
                  {t('namespaces.newButton')}
                </NewButton>
                <Button
                  id="mtfuji_namespaces_download_kubeconfig_button"
                  variant="contained"
                  color="default"
                  endIcon={<GetAppIcon />}
                  onClick={openKubeconfigDialog}
                >
                  {t('namespaces.kubeconfigButton')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} id="mtfuji_namespaces_list">
            <NamespaceTable namespaces={value || []} showActions showSearch />
            <KubeconfigDialog
              open={kubeConfOpen}
              close={closeKubeconfigDialog}
            />
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};
