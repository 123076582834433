import React, { ReactElement } from 'react';
import { useLocation } from 'react-router';

import { ContentHeader } from '@backstage/core-components';
import { PageLayout, Content, PageTitle } from '@internal/sg-ui-kit';
import { useTranslation } from '../../hooks/useTranslation';

type LayoutProps = {
  title: string;
  children: ReactElement;
  className?: string;
};

export const Layout = (props: LayoutProps) => {
  const { t } = useTranslation();
  return (
    <PageLayout
      type="entity"
      title={t('namespaces.pageLayoutTitle')}
      subtitle={t('namespaces.pageLayoutSubtitle')}
    >
      <Content>
        <ContentHeader title={props.title} />
        <div className={props.className ?? ''}>{props.children}</div>
      </Content>
    </PageLayout>
  );
};

type FormLayoutProps = LayoutProps & {
  operation: string;
  projectId: string;
  projectName: string;
  applicationName?: string;
};

const CREATE_SMC_OPERATION = 'create';

const getBreadcrumbs = (
  rootLabel: string,
  id: string,
  name: string,
  appName: string,
  url: string,
) => {
  return [
    {
      path: 'projects',
      display: rootLabel,
    },
    {
      path: `${id}?tab=resources`,
      display: name,
    },
    {
      isAbsolute: true,
      path: url,
      display: appName,
    },
  ];
};

type SMCPageTitleProps = {
  operation: string;
  projectName: string;
  applicationName?: string;
};

const SMCPageTitle = ({
  operation,
  projectName,
  applicationName,
}: SMCPageTitleProps) => {
  const { t } = useTranslation();

  if (operation === CREATE_SMC_OPERATION) {
    return (
      <PageTitle
        customPageTitle={
          projectName
            ? t('namespaces.customPageTitleWithProjectName', {
                /* @ts-ignore */
                projectName,
              })
            : t('namespaces.customPageTitleNoProjectName')
        }
      />
    );
  }
  return (
    <PageTitle
      customPageTitle={t('namespaces.smcPageTitleNonCreate', {
        /* @ts-ignore */
        applicationName,
        projectName,
      })}
    />
  );
};

export const FormLayout = (props: FormLayoutProps) => {
  const { operation, projectId, projectName, applicationName } = props;
  const { t } = useTranslation();

  const location = useLocation();

  const ROOT_URL_LABEL_BREADCRUMBS = t('namespaces.rootUrlBreadcrumbsLabel');

  return (
    <PageLayout
      type="entity"
      title={t('namespaces.pageLayoutTitle')}
      subtitle={t('namespaces.pageLayoutSubtitle')}
      backToLink={
        applicationName !== undefined
          ? getBreadcrumbs(
              ROOT_URL_LABEL_BREADCRUMBS,
              projectId,
              projectName,
              applicationName,
              location.pathname,
            )
          : undefined
      }
    >
      <SMCPageTitle
        operation={operation}
        projectName={projectName}
        applicationName={applicationName}
      />
      <Content>
        <ContentHeader title={props.title} />
        <div className={props.className}>{props.children}</div>
      </Content>
    </PageLayout>
  );
};
