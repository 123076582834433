import { useCallback, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { REMOVE_SELF_HOSTED_ARTIFACTORY } from 'usg-types';

export const useRemoveArtifactorySelfHosted = () => {
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const [isArtifactorySelfHostedRemoved, setIsArtifactorySelfHostedRemoved] =
    useState<boolean | null>(null);

  const getFeatureFlag = useCallback(async () => {
    const removeSelfHostedArtifactoryFlag = await featureFlagsApi.getBinaryFlag(
      REMOVE_SELF_HOSTED_ARTIFACTORY,
    );

    setIsArtifactorySelfHostedRemoved(removeSelfHostedArtifactoryFlag.data);
  }, [featureFlagsApi]);

  useEffect(() => {
    getFeatureFlag();
  }, [getFeatureFlag]);

  return { isArtifactorySelfHostedRemoved };
};
