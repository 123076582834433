import { useCallback, useEffect, useState } from 'react';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { projectApiRef } from '../../../../api';
import { ProjectDetails } from '../types';
import {
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  VAULT_DEV_TOOL_ID,
} from 'usg-types';

const VALID_RESOURCE_IDS = [
  GITHUB_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  ARTIFACTORY_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  VAULT_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
];

const isResourceValid = (project: ProjectDetails) => {
  // in case project data is empty then no need to check
  if (project === null) {
    return false;
  }

  // project must have at least 1 resource
  if (project.resources === null || project.resources.length === 0) {
    return false;
  }

  // the resource of our interest is the first resource
  const currentResource = project.resources[0];
  // if no dev_tool_id in the resource or the dev_tool_id is not valid then set the resource as not valid
  if (
    currentResource.dev_tool_id === null ||
    !VALID_RESOURCE_IDS.includes(currentResource.dev_tool_id)
  ) {
    return false;
  }
  return true;
};

const useProjectHook = (projectId: string, resourceId: string) => {
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const [projectData, setProjectData] = useState<ProjectDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getProjectData = useCallback(async () => {
    const idToken = await authref.getIdToken();
    const params = {
      manipulators: [
        'resources',
        `resource_${resourceId}`,
        'is_combined',
        'user_groups',
      ],
    };

    try {
      const data = await projectApi.getProjectByID(projectId, idToken, params);

      // check the resource validity in the project data
      if (isResourceValid(data)) {
        // it is valid then we can go for it
        setProjectData(data);
      }
    } catch (error) {
      errorApi.post(new Error(`${error?.message}`));
    } finally {
      setLoading(false);
    }
  }, [projectId, resourceId, authref, errorApi, projectApi]);

  useEffect(() => {
    if (projectId === '' || resourceId === '') {
      return;
    }
    getProjectData();
  }, [projectId, resourceId, getProjectData]);

  return { id: projectId, data: projectData, loading };
};

export default useProjectHook;
