import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  avatarText: {
    color: theme.palette.usgColors.primary.white,
    fontWeight: 'bold',
    fontSize: '18px',
  },
}));
