/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { TechDocsReaderPageContent } from '@internal/plugin-techdocs-fork';
import { PageLayout } from '@internal/sg-ui-kit';
import LanguageSelector from './LanguageSelector';
import { Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../useTranslation';
import { useStyles } from './styles';

const DefaultTechDocsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <PageLayout
      title={`${t('techdocs.title')}`}
      headerBackgroundImg="assets/welcome_bg.webp"
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.toolBarContainer}
      >
        <Grid item>
          <Link to="/docs" color="inherit" className={classes.selector}>
            <span className={classes.backToLink}>
              <ArrowBackIcon fontSize="medium" className={classes.icon} />
              <span className={classes.backToLinkText}>
                {t('techdocs.backToDocumentation')}
              </span>
            </span>
          </Link>
        </Grid>
        <Grid item>
          <LanguageSelector />
        </Grid>
      </Grid>
      <TechDocsReaderPageContent withSearch={false} />
    </PageLayout>
  );
};

export const techDocsPage = <DefaultTechDocsPage />;
