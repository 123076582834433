import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAsyncFn } from 'react-use';

import { DateTime } from 'luxon';

import { Progress } from '@backstage/core-components';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { usePermissions } from '@internal/plugin-projects';
import { PageLayout, PageTitle } from '@internal/sg-ui-kit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { projectApiRef } from '../../api';
import { ROOT_URL_LABEL_BREADCRUMBS } from '../ViewEditResources/ViewEditResourcePage/types';
import { ProjectOwners } from '../ProjectOwners';
import { ProjectContractsTable } from './ProjectContractsTable';
import { useTranslation } from '../../hooks/useTranslation';
import { useStyles } from './styles';

export const ProjectContractsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const authref = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);
  const { projectId } = useParams() as { projectId: string };
  const { t } = useTranslation();

  const [projectData, setProjectData] = useState({} as any);
  const [contractData, setContractData] = useState([]);
  const [filteredContractData, setFilteredContractData] = useState([]);
  const [contractStatusFilter, setContractStatusFilter] = useState('');
  const [toBeDeletedContract, setToBeDeletedContract] = useState({} as any);
  const [openRemove, setOpenRemove] = useState(false);
  const [isProjectAccessible, setIsProjectAccessible] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  const {
    isAnyAdmin,
    isProjectOwner,
    isLoading: rolesCheckLoading,
    error: permissionsError,
  } = usePermissions();

  const checkContarctCompanyExpiryMistmatch = (
    contract: any,
    projectCompanyMapping: any,
  ) => {
    if (!contract.assigned_companies) return false;
    let isContaractMismatched = false;

    for (const company of contract?.assigned_companies) {
      isContaractMismatched =
        DateTime.fromISO(contract.ended_on) >
        DateTime.fromISO(projectCompanyMapping[company.id]);
      if (isContaractMismatched) {
        setShowWarning(true);
        break;
      }
    }
    return isContaractMismatched;
  };

  const [{ loading, error }, fetchProjectContracts] =
    useAsyncFn(async (): Promise<any> => {
      const token = await authref.getIdToken();
      setIsProjectAccessible(isAnyAdmin || isProjectOwner(Number(projectId)));

      const params = {
        manipulators: ['participant_companies'],
      };
      try {
        const projectDataRes = await projectApi.getProjectByID(
          projectId,
          token,
          params,
        );
        setProjectData(projectDataRes);

        const contractDataRes = await projectApi.getProjectLegalContracts(
          token,
          projectId,
          {
            include_assigned_companies: true,
          },
        );

        const companyIDExpiryMapping =
          projectDataRes?.participant_companies.reduce(
            (mapping: any, company: any) => {
              mapping[company.company_id] = company?.ended_on;
              return mapping;
            },
            {},
          );

        const contracts = contractDataRes?.legal_contracts.map(
          (contract: any) => {
            return {
              ...contract,
              is_mismatch: checkContarctCompanyExpiryMistmatch(
                contract,
                companyIDExpiryMapping,
              ),
            };
          },
        );

        setContractData(contracts);
        setFilteredContractData(contracts);
      } catch (err: any) {
        errorApi.post(new Error(`${err?.message}`));
      }
    }, [rolesCheckLoading]);

  const handleContractStatusChange = (event: any) => {
    setContractStatusFilter(event.target.value);
    const filter = event.target.value;
    let filteredContracts = [];
    switch (filter) {
      case 'active':
        filteredContracts = contractData.filter((contract: any) => {
          return DateTime.fromISO(contract?.ended_on) > DateTime.now();
        });
        break;
      case 'expired':
        filteredContracts = contractData.filter((contract: any) => {
          return DateTime.fromISO(contract?.ended_on) < DateTime.now();
        });
        break;
      default:
        filteredContracts = structuredClone(contractData);
    }
    setFilteredContractData(filteredContracts);
  };

  const clearFilter = () => {
    setContractStatusFilter('');
    setFilteredContractData(structuredClone(contractData));
  };

  const handleAssignContract = () => {
    navigate(`/projects/${projectId}/contracts/assign`);
  };

  const handleRemoveContract = async (contract: any) => {
    setToBeDeletedContract(contract);
    setOpenRemove(true);
  };

  const onCancelRemoveDialog = () => {
    setOpenRemove(false);
    setToBeDeletedContract({});
  };

  const onConfirmRemoveDialog = async () => {
    try {
      const token = await authref.getIdToken();
      await projectApi.archiveProjectLegalContractById(
        token,
        projectId,
        toBeDeletedContract?.id,
      );
      setToBeDeletedContract({});
      setOpenRemove(false);
      await fetchProjectContracts();
    } catch (err) {
      errorApi.post(
        new Error(
          'Failed to delete legal contract for the project. Please try again..',
        ),
      );
    }
  };

  useEffect(() => {
    if (!rolesCheckLoading) {
      fetchProjectContracts();
    }
  }, [fetchProjectContracts, rolesCheckLoading]);

  if (!rolesCheckLoading && !isAnyAdmin && !isProjectOwner(Number(projectId))) {
    navigate(`/projects/${projectId}`);
  }

  if (loading || rolesCheckLoading) {
    return <Progress />;
  }

  if (error) {
    errorApi.post(
      new Error('Failed to load Project contracts. Please try again..'),
    );
  }

  if (permissionsError) {
    errorApi.post(permissionsError);
  }

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: projectData?.id,
      display: projectData?.name,
    },
    {
      path: 'contracts',
      display: 'Contracts',
    },
  ];

  return (
    <PageLayout
      type="entity"
      title={projectData?.name || null}
      headerAdditionalControls={
        <ProjectOwners owners={projectData?.owners || null} />
      }
      backToLink={breadcrumbs}
    >
      <PageTitle
        customPageTitle={`Contracts | ${projectData?.name} | Stargate`}
      />
      <div className={classes.container}>
        <Typography variant="h1">
          {t('contracts.contractsListPage.pageTitle')}
        </Typography>
        {showWarning && (
          <Alert severity="warning" className={classes.info}>
            <span>
              {t('contracts.contractsListPage.expiryMismatchWarning')}
            </span>
          </Alert>
        )}
        <Alert severity="info" className={classes.info}>
          <span>{t('contracts.contractsListPage.infoMessage')}</span>
        </Alert>
        <Grid container alignItems="flex-end" className={classes.filters}>
          <Grid item xs={2}>
            <FormControl variant="outlined" fullWidth>
              <Typography
                variant="button"
                component="div"
                id="contract-status"
                className={classes.filtername}
              >
                {t('contracts.contractsListPage.contractField.fieldLabel')}
              </Typography>
              <Select
                labelId="contract-status"
                id="contract-status-select"
                value={contractStatusFilter}
                onChange={handleContractStatusChange}
                displayEmpty
              >
                <MenuItem
                  id="projects-menuitem-contractstatus-default"
                  disabled
                  value=""
                >
                  {t('contracts.contractsListPage.contractField.select')}
                </MenuItem>
                <MenuItem
                  id="projects-menuitem-contractstatus-active"
                  value="active"
                >
                  {t('contracts.contractsListPage.contractField.active')}
                </MenuItem>
                <MenuItem
                  id="projects-menuitem-contractstatus-expired"
                  value="expired"
                >
                  {t('contracts.contractsListPage.contractField.expired')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Button
            id="projects-button-contract-clear"
            onClick={clearFilter}
            disabled={contractStatusFilter === ''}
            style={{ marginTop: '24px' }}
            className={classes.button}
          >
            {t('contracts.contractsListPage.contractField.clear')}
          </Button>
          {isProjectAccessible && (
            <Grid item className={classes.assignButton}>
              <Button
                id="assign-contract-button"
                variant="contained"
                onClick={handleAssignContract}
                disabled={!isProjectAccessible}
              >
                {t('contracts.contractsListPage.assignContractButtonLabel')}
              </Button>
            </Grid>
          )}
        </Grid>
        <ProjectContractsTable
          contractData={filteredContractData}
          loading={loading}
          projectId={projectId}
          onRemoveContract={handleRemoveContract}
        />
        <Dialog open={openRemove} onClose={onCancelRemoveDialog} maxWidth="xs">
          <DialogContent
            id="contract-remove-confirmation-dialog"
            style={{ fontWeight: 'bold' }}
          >
            {t('contracts.contractsListPage.removeContractDialog.message', {
              /* @ts-ignore */
              name: toBeDeletedContract?.name,
            })}
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              id="projects-button-contract-remove-cancel"
              variant="outlined"
              size="small"
              onClick={onCancelRemoveDialog}
            >
              {t('contracts.contractsListPage.removeContractDialog.cancel')}
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={onConfirmRemoveDialog}
              id="dialog-contract-remove-button"
            >
              {t('contracts.contractsListPage.removeContractDialog.remove')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </PageLayout>
  );
};
