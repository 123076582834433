import { useCallback } from 'react';
import {
  configApiRef,
  errorApiRef,
  identityApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { commonApiRef, daysDiffFromPresent } from 'sg-utils-frontend';
import { createGlobalState, useEffectOnce } from 'react-use';

interface User {
  email: string;
  nps_last_updated: string;
  nps_skip_date?: string;
}
const useUserData = createGlobalState<User>();

export const useNPSPreopened = () => {
  const authref = useApi(microsoftAuthApiRef);
  const configApi = useApi(configApiRef);
  const errorApi = useApi(errorApiRef);
  const identityApi = useApi(identityApiRef);
  const commonApi = useApi(commonApiRef);
  const skipBtnFreqDays = configApi.getNumber('nps.skipBtnFrequencyDays');
  const feedbackGapDays = configApi.getNumber('nps.frequencyDays');

  const [userDBData, setUserDBData] = useUserData();

  const getNPSLastUpdated = useCallback(async () => {
    const idToken = await authref.getIdToken();
    const token = (await identityApi.getCredentials()).token;

    try {
      const loggedInUser = await authref.getProfile();
      const email = loggedInUser?.email || '';
      const data = await commonApi.getUserData(token, idToken, email);
      setUserDBData(data);
    } catch (error) {
      errorApi.post(new Error(`${error?.message}`));
    }
  }, [authref, errorApi, identityApi, commonApi, setUserDBData]);

  useEffectOnce(() => {
    if (!userDBData) {
      (async () => {
        await getNPSLastUpdated();
      })();
    }
  });

  const isNpsPopupOpen = (
    lastFeedbackDate: string | undefined,
    lastSkipDate: string | undefined,
  ) => {
    // If user has answered, NPS does not show up for 90 days
    if (
      !lastFeedbackDate ||
      daysDiffFromPresent(lastFeedbackDate) < feedbackGapDays
    ) {
      return false;
    }

    // if user has not answred yet but skiped only, NPS shows up after 7 days
    if (lastSkipDate && daysDiffFromPresent(lastSkipDate) < skipBtnFreqDays) {
      return false;
    }
    return true;
  };

  const refresh = async () => {
    await getNPSLastUpdated();
  };

  return {
    loading: !userDBData,
    isNpsPreopened: isNpsPopupOpen(
      userDBData?.nps_last_updated,
      userDBData?.nps_skip_date,
    ),
    refresh,
  };
};
