import React from 'react';

import { PageLayout, PageTitle } from '@internal/sg-ui-kit';

import { ROOT_URL_LABEL_BREADCRUMBS } from './ViewEditResourcePage/types';
import {
  ProjectOwners,
  ProjectOwnerProps,
} from '../ProjectOwners/ProjectOwners';

type ProjectData = ProjectOwnerProps & {
  id: string;
  name: string;
};

type ResourceData = {
  id: string;
  name: string;
};

type PageTitleBreadcrumbsProps = {
  projectData: ProjectData;
  resourceData: ResourceData;
};

export const PageTitleBreadcrumbs = ({
  projectData,
  resourceData,
}: PageTitleBreadcrumbsProps) => {
  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      path: `${projectData.id}?tab=resources`,
      display: projectData.name,
    },
    {
      path: `manageresource/${resourceData.id}`,
      display: resourceData.name,
    },
  ];

  return (
    <>
      <PageLayout
        type="entity"
        title={projectData.name || ''}
        headerAdditionalControls={<ProjectOwners owners={projectData.owners} />}
        backToLink={breadcrumbs}
        children={undefined}
      />
      <PageTitle
        customPageTitle={`${resourceData.name} | Resources | ${projectData.name} | Stargate`}
      />
    </>
  );
};
