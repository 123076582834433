import React from 'react';
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  DialogContent,
  Dialog,
  Typography,
  Tooltip,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  RESOURCE_NAME_MAX_LENGTH,
  RESOURCE_DESCRIPTION_MAX_LENGTH,
  RESOURCE_NAME_MIN_LENGTH,
  SPECIAL_RESOURCE_NAME_REGEX,
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
} from 'usg-types';
import { projectApiRef } from '@internal/plugin-projects';
import { useForm } from 'react-hook-form';
import { definitions } from '../api';
import { useStyles } from './styles';

type NamespaceAutocompletionResponseProject =
  definitions['handlers.NamespaceAutocompletionResponseProject'];

type ArtifactoryInput = {
  repo_name: string;
  description: string;
};

type allowedDevTools =
  | typeof ARTIFACTORY_SAAS_DEV_TOOL_ID
  | typeof ARTIFACTORY_DEV_TOOL_ID;
export type CreateArtifactoryDialogProps = {
  stargateProject: NamespaceAutocompletionResponseProject;
  open: boolean;
  handleDialogClose: (data?: any) => void;
  devTool: allowedDevTools;
};
export const CreateArtifactoryDialog = (
  props: CreateArtifactoryDialogProps,
) => {
  const { open, handleDialogClose, stargateProject, devTool } = props;
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const authRef = useApi(microsoftAuthApiRef);
  const classes = useStyles();

  const artifactoryInstanceName =
    devTool === ARTIFACTORY_SAAS_DEV_TOOL_ID
      ? 'Artifactory (SaaS)'
      : 'Artifactory (Self-hosted) (deprecated)';

  const defaultValues: ArtifactoryInput = {
    repo_name: stargateProject.key.toLowerCase(),
    description: `Default Container registry for ${stargateProject.key}`,
  };

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
    getFieldState,
  } = useForm<ArtifactoryInput>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const onSubmit = async ({
    repo_name: name,
    description,
  }: ArtifactoryInput) => {
    const idToken = await authRef.getIdToken();
    try {
      const resp: any = await projectApi.createNewProjectResource(
        stargateProject.id.toString(),
        {
          idToken,
          key: name.toLowerCase(),
          name,
          description: description,
          dev_tool_id: devTool,
          config: {
            packageType: 'docker',
            rclass: 'local',
            ...(devTool === ARTIFACTORY_SAAS_DEV_TOOL_ID
              ? {
                  rclass: 'federated',
                  sites: ['jp', 'us'],
                }
              : {}),
          },
        },
      );
      if (resp && resp.error) {
        throw new Error(resp.error.message);
      }
      handleDialogClose(resp);
      reset();
    } catch (err) {
      errorApi.post(
        new Error(`${err?.message || 'Failed to create project resource'}`),
      );
    }
  };

  // return (
  //   <Dialog open={open} onClose={() => handleDialogClose()} maxWidth="sm">
  //     <DialogTitle>
  //       <div className={classes.dialogHeader}>
  //         <Typography variant="h3">
  //           {t("dialog.title", { artifactoryInstanceName })}
  //         </Typography>
  //         <Tooltip
  //           classes={{
  //             tooltip: classes.tooltip,
  //             arrow: classes.tooltipArrow,
  //           }}
  //           arrow
  //           placement="top"
  //           title={t("dialog.tooltipCloseButton")}
  //         >
  //           <IconButton
  //             id="create-container-registry-close-icon-btn"
  //             aria-label="close"
  //             className={classes.iconContainer}
  //             onClick={() => handleDialogClose()}
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //         </Tooltip>
  //       </div>
  //     </DialogTitle>
  //     <DialogContent id="rt-create-confirmed-dialog" dividers>
  //       <div style={{ paddingBottom: "24px" }}>
  //         <form onSubmit={handleSubmit(onSubmit)}>
  //           <div>
  //             <p>{t("form.confirmationText", { artifactoryInstanceName })}</p>
  //           </div>
  //           <div>
  //             <InputLabel error={!isValid} htmlFor="repo_name">
  //               {t("form.repoNameLabel", { artifactoryInstanceName })}
  //             </InputLabel>
  //             <TextField
  //               style={{ paddingBottom: "0%" }}
  //               type="text"
  //               id="repo_name"
  //               error={!isValid && getFieldState("repo_name").isTouched}
  //               fullWidth
  //               helperText={t("form.repoNameHelperText", {
  //                 artifactoryInstanceName,
  //                 min: RESOURCE_NAME_MIN_LENGTH.artifactory,
  //                 max: RESOURCE_NAME_MAX_LENGTH.artifactory,
  //               })}
  //               {...register("repo_name", {
  //                 required: true,
  //                 pattern: SPECIAL_RESOURCE_NAME_REGEX.artifactory,
  //                 minLength: RESOURCE_NAME_MIN_LENGTH.artifactory,
  //                 maxLength: RESOURCE_NAME_MAX_LENGTH.artifactory,
  //               })}
  //             />
  //           </div>
  //           <div style={{ marginTop: "16px" }}>
  //             <InputLabel htmlFor="description">
  //               {t("form.descriptionLabel")}
  //             </InputLabel>
  //             <TextField
  //               type="text"
  //               id="description"
  //               maxRows={4}
  //               minRows={4}
  //               multiline
  //               fullWidth
  //               helperText={t("form.descriptionHelperText", {
  //                 max: RESOURCE_DESCRIPTION_MAX_LENGTH,
  //               })}
  //               placeholder={t("form.descriptionPlaceholder")}
  //               {...register("description", {
  //                 maxLength: RESOURCE_DESCRIPTION_MAX_LENGTH,
  //               })}
  //             />
  //           </div>
  //           <div style={{ paddingTop: "5%" }}>
  //             <Grid container spacing={2}>
  //               <Grid item>
  //                 <Button
  //                   id="create-container-registry-cancel-button"
  //                   variant="outlined"
  //                   type="reset"
  //                   onClick={() => handleDialogClose()}
  //                 >
  //                   {t("buttons.cancel")}
  //                 </Button>
  //               </Grid>

  //               <Grid item>
  //                 <Button
  //                   id="create-container-registry-submit-button"
  //                   variant="contained"
  //                   type="submit"
  //                   disabled={!isValid}
  //                 >
  //                   {t("buttons.create")}
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </div>
  //         </form>
  //       </div>
  //     </DialogContent>
  //   </Dialog>
  // );

  return (
    <Dialog open={open} onClose={() => handleDialogClose()} maxWidth="sm">
      <DialogTitle>
        <div className={classes.dialogHeader}>
          <Typography variant="h3">
            Create Container Registry {artifactoryInstanceName}
          </Typography>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
            arrow
            placement="top"
            title="Close Button"
          >
            <IconButton
              id="create-container-registry-close-icon-btn"
              aria-label="close"
              className={classes.iconContainer}
              onClick={() => handleDialogClose()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent id="rt-create-confirmed-dialog" dividers>
        <div style={{ paddingBottom: '24px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <p>
                This will create a repository on {artifactoryInstanceName}{' '}
                instance.
              </p>
            </div>
            <div>
              <InputLabel error={!isValid} htmlFor="repo_name">
                {artifactoryInstanceName} Repo Name*
              </InputLabel>
              <TextField
                style={{ paddingBottom: '0%' }}
                type="text"
                id="repo_name"
                error={!isValid && getFieldState('repo_name').isTouched}
                fullWidth
                helperText={`Provide a unique name for your ${artifactoryInstanceName} repo. Names should consist only of letters, numbers and single hyphens, must not start with a number, and must not end with a hyphen or 'cache'. Number of characters allowed: Minimum=${RESOURCE_NAME_MIN_LENGTH.artifactory}, Maximum=${RESOURCE_NAME_MAX_LENGTH.artifactory}`}
                {...register('repo_name', {
                  required: true,
                  pattern: SPECIAL_RESOURCE_NAME_REGEX.artifactory,
                  minLength: RESOURCE_NAME_MIN_LENGTH.artifactory,
                  maxLength: RESOURCE_NAME_MAX_LENGTH.artifactory,
                })}
              />
            </div>
            <div style={{ marginTop: '16px' }}>
              <InputLabel htmlFor="description">Description</InputLabel>
              <TextField
                type="text"
                id="description"
                maxRows={4}
                minRows={4}
                multiline
                fullWidth
                helperText={`Provide a brief description for your ${artifactoryInstanceName} repo. Number of characters allowed Max = ${RESOURCE_DESCRIPTION_MAX_LENGTH}`}
                placeholder="Example : Repository for storing the artifacts for development within the One-click delivery automation solutions project."
                {...register('description', {
                  maxLength: RESOURCE_DESCRIPTION_MAX_LENGTH,
                })}
              />
            </div>
            <div style={{ paddingTop: '5%' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    id="create-container-registry-cancel-button"
                    variant="outlined"
                    type="reset"
                    onClick={() => handleDialogClose()}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    id="create-container-registry-submit-button"
                    variant="contained"
                    type="submit"
                    disabled={!isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
