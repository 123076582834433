import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Divider,
  Grid,
  Typography,
  CardContent,
} from '@material-ui/core';
import { useCardTranslation } from './useCardTranslation';
import { useStyles } from '../styles';

export const FeaturedCardSection = () => {
  const classes = useStyles();

  return (
    <Grid container item spacing={4}>
      {useCardTranslation().map(card => (
        <Grid item key={card.title} xs={12} sm={6} lg={3}>
          <Card variant="outlined" className={classes.card}>
            <CardContent className={classes.content}>
              <div className={classes.imageContainer}>
                <img
                  src={`assets/techdocs/${card.image}`}
                  alt={card.title}
                  height={100}
                />
              </div>
              <Typography variant="h4">{card.title}</Typography>
              <Typography variant="caption" className={classes.caption}>
                {card.description}
              </Typography>
              <Divider />
              <div>
                {card.documents.map((doc, index) => (
                  <React.Fragment key={doc.title}>
                    <Link to={doc.link} className={classes.titleLink}>
                      {doc.title}
                    </Link>
                    {index !== card.documents.length - 1 && (
                      <span className={classes.bar}>|</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
