import React from 'react';
import { Grid, Switch } from '@material-ui/core';
import { RESOURCE_INDEXING_STATUS } from '../../../api';
import { ResourceStatus } from '../../../hooks/useProjectSettings';
import { ResourceState } from '../../../hooks/useRoverIndexingEnabledResourceIds';
import { useStyles } from '../styles';

export default function ResourceRoverIndexing({
  resourceId,
  editMode,
  resourcesStatus,
  resourcesToggleState,
  setResourcesToggleState,
}: {
  resourceId: string;
  editMode: boolean;
  resourcesStatus: ResourceStatus;
  resourcesToggleState: ResourceState;
  setResourcesToggleState: React.Dispatch<React.SetStateAction<ResourceState>>;
}) {
  const classes = useStyles();

  return (
    <Grid>
      <h4 className={classes.labelHeader}>Rover Indexed</h4>
      {editMode && (
        <Switch
          id={`index-resource-${resourceId}-toggle`}
          checked={!!resourcesToggleState[resourceId]?.isEnabled}
          onChange={() =>
            setResourcesToggleState({
              ...resourcesToggleState,
              [resourceId]: {
                isEnabled: !resourcesToggleState[resourceId]?.isEnabled,
              },
            })
          }
          color="primary"
          name="index-resource"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      )}
      {!!resourcesToggleState[resourceId]?.isEnabled ? (
        <>
          {' '}
          {resourcesStatus[resourceId] ===
            RESOURCE_INDEXING_STATUS.IN_PROGRESS && (
            <span className={classes.inProgressStatus}>In progress</span>
          )}
          {resourcesStatus[resourceId] === RESOURCE_INDEXING_STATUS.SUCCESS && (
            <span className={classes.successStatus}>Success</span>
          )}
          {resourcesStatus[resourceId] === RESOURCE_INDEXING_STATUS.FAILED && (
            <span className={classes.failedStatus}>Failed</span>
          )}
        </>
      ) : (
        <>{!editMode ? <span>N/A</span> : null}</>
      )}
    </Grid>
  );
}
