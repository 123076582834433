import { Config } from '@backstage/config';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { NotFoundError } from '@backstage/errors';
import { definitions } from './components/CreateResources/create-resource-forms/api';
import { stringify as stringifyQueryString } from 'qs';
import { generateHeaders } from 'sg-utils-frontend';

export const projectApiRef = createApiRef<ProjectsApiInterface>({
  id: 'plugin.projects',
});

type ProjectsApiOptions = {
  configApi: Config;
  identityApi: IdentityApi;
};

type AllProjectRequestParams = {
  name?: string;
  owner_email?: string;
  ip_owner_company_id?: string;
  participant_company_id?: string;
  search_resources?: string;
  page?: string;
  size?: string;
  order_by?: string;
  order?: string;
  signal?: AbortSignal;
  //  include_archived?: boolean
};
type GetUserMembershipRequestData = {
  user_group_id?: string;
  project_id?: string;
  user_email?: string;
  include_other_emails_of_user?: boolean;
  requester_email?: string;
  reviewer_email?: string;
  status?: string;
  action?: string;
  sent?: string;
  process_state?: string;
  page?: string;
  size?: string;
  order_by?: string;
  order?: string;
  bulk_upload_ids?: [number];
};
type UserMembershipData = {
  idToken: string;
  request_ids: Array<number>;
};
type IRequestAccessData = {
  project_id: number;
  idToken: string;
  members: Array<string>;
  reason: string;
};
export type TGetProjectByIdParams = {
  manipulators: string[];
};

type CreateUserMembershipRequests = {
  idToken: string;
  user_memberships: any[];
  project_id: number;
};

type TCreateBulkUserMemberships = {
  user_memberships: any[];
  project_id: string;
  user_group_id: string;
  import_file_name: string;
};

enum order_by_enum {
  'id',
  'name',
  'created_on',
  'updated_on',
}

enum order {
  'asc',
  'desc',
}

export interface IProjectResourceData {
  idToken: string;
  key: string;
  name: string;
  description?: string;
  dev_tool_id: number;
  environments?: any[];
  config?: object;
}

export interface ProjectResourceAsPerEnv {
  idToken: string;
  environments: any[];
}

export interface createVaultData {
  idToken: string;
  project_id: number;
  maintainer_group_id: string;
  developer_group_id: string;
}
enum filter {
  'jira',
  'confluence',
  'github',
  'ghe',
}
export interface ILicenseData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  filter: filter;
}
export interface IArtifactoryStorageData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
  order_by?: string;
  order?: string;
}
export interface IObsLogsData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
}
export interface IObsMetricsData {
  start_time: string;
  end_time: string;
  page: number;
  size: number;
  show_cost: boolean;
}
export interface RowData {
  project_id: number;
  user_email: String;
  enable_self_service: boolean;
  url_link: String;
  instructions: String;
  created_by: String;
  created_on: Date;
  modified_on: Date;
  modified_by: String;
}
export interface INoCache {
  no_cache: string;
}

export interface upsertParams {
  user_email: String;
  enable_self_service: boolean;
  url_link: String;
  instructions: String;
}

export interface groupRestriction {
  allowed_company_id: Array<Number>;
}
export interface UpdateUserGroupBody {
  description: string;
  accessible_resources: Array<Number>;
  group_restriction: groupRestriction;
  allow_sharing_to_all: Boolean;
  shareable_allowed_projects: Array<Number>;
  group_managers: Array<Object>;
}

type addProjectUserGroup = {
  members: Array<string>;
  name: string;
  description?: string;
  accessible_resources: Array<number> | Array<{ id: number; role: string }>;
};

type GetProjectMembers = {
  startIndex?: number;
  count?: number;
  email_search_strings?: Array<string>;
  email_exclude_domains?: Array<string>;
  include_inactive?: boolean;
  order_by?: string;
};

type archiveProjectUserGroup = {
  id: string;
  idToken: string;
};

type archiveProjectResource = {
  idToken: string;
};

type updateProjectUserGroup = {
  id: string;
  description?: string;
  accessible_resources: Array<number> | Array<{ id: number; role: string }>;
  group_managers?: Array<{ user_email: string }>;
  group_restriction?: groupRestriction;
};
type EditProjectById = {
  idToken: string;
  name: string;
  description?: string;
  management_type: string;
  owners: Array<object>;
  ip_owner_companies: Array<object>;
  participant_companies: Array<object>;
};

type createProjectData = {
  key: string;
  name: string;
  description?: string;
  management_type: string;
  owners: Array<{
    user_email: string;
    role: string;
  }>;

  ip_owner_companies: Array<{
    company_id: number;
    started_on: string;
    percentage: number;
    ended_on?: string;
  }>;
  participant_companies: Array<{
    company_id: number;
    started_on: string;
    ended_on?: string;
  }>;
  project_template_id: number;
  tags?: {
    [key: string]: Array<string>;
  };
};

type updateProjectResource = {
  name: string;
  key: string;
  description?: string;
};

type userQueryParams = {
  include?: string;
};
type IToken = {
  idToken: string;
};

type roleData = {
  roles: Array<{
    expired_on?: string | null;
    role: string;
  }>;
};

type artifactoryAccessData = {
  artifactory_name: string;
  artifact_repo_key: string;
};

type userGroupRoles = {
  op: string;
  value: Array<object>;
};

export interface GroupManagerData {
  resource_managers: Array<{
    user_email: string;
  }>;
}

export interface ILegalContractParamData {
  include_archived?: boolean;
  include_assigned_companies?: boolean;
  name?: string;
  no_cache?: boolean;
}

export interface ILegalContractData {
  name: string;
  contract_type: string;
  description?: string;
  contract_url: string;
  started_on: string;
  ended_on: string;
  assigned_company_ids: Array<number>;
}

export interface GetDevelopmentToolsData {
  name?: string;
  page?: string;
  size?: string;
  order_by?: order_by_enum;
  order?: order;
}

export interface AssignUserGroupToResourceData {
  idToken: Object;
  userGroupRoles: Array<userGroupRoles>;
}

export interface CreatorRolesData {
  project_resource_creators?: Array<{
    op: string;
    value: Array<{
      dev_tool_id: number;
      resource_creators: Array<{
        user_email: string;
      }>;
    }>;
  }>;
  project_user_group_creators?: Array<{
    op: string;
    value: Array<{
      user_email: string;
    }>;
  }>;
}

export interface TagsOfEntityData {
  project_id_filters: Array<number>;
  entity_type_filters: Array<string>;
  tag_filters: Array<{
    tag_key: string;
    tag_values?: Array<string>;
  }>;
  include_tags_in_response?: boolean;
  page?: number;
  size?: number;
  order?: string;
  no_cache?: boolean;
}

export interface IGroupsOfResourceParamData {
  include_archived?: boolean;
  no_cache?: boolean;
}

type OperationType = 'add' | 'remove' | 'delete';

export interface TagOperation {
  op: OperationType;
  value: {
    [key: string]: string[];
  };
}
export interface ITagsParamData {
  tags: TagOperation[];
}

export interface IUserGroupTagsData {
  [key: string]: string[];
}

export enum management_type {
  'NO_MANAGEMENT' = 'NO_MANAGEMENT',
  'PROJECT_OWNERS' = 'PROJECT_OWNERS',
  'PROJECT_OWNERS_END_USERS' = 'PROJECT_OWNERS_END_USERS',
}

export type ApplicationRequest = definitions['handlers.ApplicationRequest'];

export type ProjectSettingsRequestBody = {
  genai_index_enabled: boolean;
};

export enum RESOURCE_INDEXING_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'ERROR',
}

export type ProjectSettingResourceStatus =
  RESOURCE_INDEXING_STATUS[keyof RESOURCE_INDEXING_STATUS];

type ProjectSettingResource = {
  id: string;
  status: ProjectSettingResourceStatus;
};

type ProjectSettingsResponse = {
  id?: number;
  genai_index_enabled: boolean;
  resources: ProjectSettingResource[];
};

export type ProjectSettingsRequestParams = {
  with_resources: boolean;
};

function objectToURLSearchParams(obj: {
  [key: string]: string | number | boolean;
}): URLSearchParams {
  const searchParams = new URLSearchParams();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      const paramValue = typeof value === 'string' ? value : value.toString();

      searchParams.append(key, paramValue);
    }
  }

  return searchParams;
}

export interface ProjectsApiInterface {
  getAllProjects(
    params: AllProjectRequestParams,
    idToken: string,
  ): Promise<Response>;
  getAllUserMembershipRequestsData(
    params: GetUserMembershipRequestData,
    idToken: Object,
  ): Promise<Response>;
  cancelUserMembership(params: UserMembershipData): Promise<Response>;
  approveUserMembership(params: UserMembershipData): Promise<Response>;
  rejectUserMembership(params: UserMembershipData): Promise<Response>;
  holdUserMembership(params: UserMembershipData): Promise<Response>;
  retryUserMembership(params: UserMembershipData): Promise<Response>;
  resumeUserMembership(params: UserMembershipData): Promise<Response>;
  getProjectByID(
    id: string,
    idToken: string,
    params: TGetProjectByIdParams,
  ): Promise<any>;
  getGroupByIDFromProject(
    id: string,
    projectId: string,
    idToken: Object,
  ): Promise<Response>;
  getProjectResources(id: any, params: any): Promise<Response>;
  getProjectResourceByID(id: any): Promise<Response>;
  getProjectUserGroupsByID(id: any): Promise<Response>;
  getProjectRequests(): Promise<Response>;
  getDevelopmentToolsData(
    idToken: string,
    paramsData?: GetDevelopmentToolsData,
  ): Promise<Response>;
  getUserGroups(id: string, idToken: string): Promise<Response>;
  getAttachableSourceProjects(id: string, idToken: String): Promise<Response>;
  getCompanyData(idToken: Object, paramsData?: Object): Promise<Response>;
  createUserMembershipRequestsV2Data(
    params: CreateUserMembershipRequests,
    projectId: string,
  ): Promise<Response>;
  createUserMembershipRequestsV3Data(
    params: CreateUserMembershipRequests,
    projectId: string,
  ): Promise<Response>;
  requestAccess(params: IRequestAccessData): Promise<any>;
  createNewProjectResource(
    project_id: string,
    params: IProjectResourceData,
  ): Promise<Response>;
  createNewProjectResourceSMC(
    project_id: string,
    params: ApplicationRequest,
  ): Promise<Response>;
  updateProjectResourceSMC(
    project_id: string,
    resource_id: string,
    params: ApplicationRequest,
  ): Promise<Response>;
  createNewVault(nameSpace: string, params: createVaultData): Promise<Response>;
  addProjectUserGroupData(
    params: addProjectUserGroup,
    projectId: string,
    idToken: string,
  ): Promise<Response>;
  archiveUserGroups(
    id: string,
    params: archiveProjectUserGroup,
  ): Promise<Response>;
  archiveProjectResource(
    id: string,
    resourceId: string,
    params: archiveProjectResource,
  ): Promise<Response>;
  updateUserGroups(
    id: string,
    idToken: string,
    paramsData: UpdateUserGroupBody,
  ): Promise<any>;
  updateResourceTag(
    gacIdToken: string,
    projectId: string,
    resourceId: string,
    tags: ITagsParamData,
  ): Promise<any>;
  updateProjectResourcesTags(
    gacIdToken: string,
    projectId: string,
    resourcesId: string[],
    tags: ITagsParamData,
  ): Promise<any>;
  updateProjectResource(
    pid: string,
    rid: string,
    paramsData: updateProjectResource,
  ): Promise<any>;
  getSpecificResource(
    projectId: number,
    resourceId: number,
    idToken: string,
  ): Promise<any>;

  getUserGroupPerProject(id: any, idToken: string, email: string): Promise<any>;
  editById(id: any, paramsData: EditProjectById): Promise<any>;
  createProject(
    paramsData: createProjectData,
    idToken: string,
    isNotUsingBeta: boolean,
  ): Promise<any>;
  getUserDetails(
    idToken: Object,
    queryParams?: userQueryParams,
  ): Promise<Response>;
  changeUserRole(params: roleData, idToken: string): Promise<any>;
  getUserRoles(idToken: string): Promise<any>;
  getUserMemberList(
    projectId: string,
    groupName: string,
    idToken: string,
    count: number,
    startIndex: number,
    orderBy: string,
    reverse: any,
  ): Promise<any>;
  unArchiveUserGroups(
    projectId: string,
    paramsData: archiveProjectUserGroup,
  ): Promise<any>;
  unArchiveResources(
    projectId: string,
    resourceId: number,
    paramsData: IToken,
  ): Promise<any>;
  attachUserGroup(
    idToken: Object,
    targetProjectId: string,
    userGroupId: string,
  ): Promise<any>;
  detachUserGroup(
    idToken: Object,
    targetProjectId: string,
    userGroupId: string,
  ): Promise<any>;
  updateUserGroup(
    targetProjectId: string,
    userGroupId: string,
    paramsData: updateProjectUserGroup,
    idToken: string,
  ): Promise<any>;
  getGroupManagers(userGroupId: string, idToken: Object): Promise<any>;
  getGitHubOrgWebhookSecret(idToken: string, orgName: string): Promise<any>;
  getProjectMembers(
    id: string,
    idToken: string,
    isProjectMembersNewAPIEnabled: boolean,
    params: GetProjectMembers,
  ): Promise<any>;
  upsertOptOutData(projectId: number, params: any): Promise<RowData>;
  getOptOutData(project_id: number): Promise<RowData>;
  attachAllowableProjects(
    ug_id: string,
    idToken: string,
    params: INoCache,
  ): Promise<any>;
  getUserGroup(ug_id: string, idToken: string, params: INoCache): Promise<any>;
  attachDestinationProjects(
    ug_id: string,
    idToken: string,
    params: INoCache,
  ): Promise<any>;
  getProjectUserGroupData(
    project_id: string,
    ug_id: string,
    idToken: string,
    params: INoCache,
  ): Promise<any>;
  getArtifactoryAccessProperties(
    id: any,
    idToken: string,
    artifactory_data: artifactoryAccessData,
  ): Promise<any>;

  updateArtifactoryAccessProperties(
    id: any,
    idToken: string,
    artifactory_data: artifactoryAccessData,
    req_body: any,
  ): Promise<any>;

  getProjectLegalContracts(
    idToken: string,
    id: string,
    paramsData: ILegalContractParamData,
  ): Promise<any>;
  createProjectLegalContract(
    idToken: string,
    id: string,
    paramsData: ILegalContractData,
  ): Promise<any>;
  getProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
    paramsData: ILegalContractParamData,
  ): Promise<any>;
  updateProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
    paramsData: ILegalContractData,
  ): Promise<any>;
  archiveProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
  ): Promise<any>;
  assignUserGroupToResources(
    paramsData: AssignUserGroupToResourceData,
    targetProjectId: string,
    resourceId: string,
  ): Promise<any>;
  getUserGroupsOfResource(
    targetProjectId: string,
    resourceId: string,
    idToken: string,
    paramsData: IGroupsOfResourceParamData,
  ): Promise<any>;
  getAllUsersWithCreatorRoleInProject(
    project_id: string,
    idToken: string,
  ): Promise<any>;
  updateUsersCreatorRoleInProject(
    project_id: string,
    idToken: string,
    creator_roles_data: CreatorRolesData,
  ): Promise<any>;
  getGroupManagersOfUserGroup(
    project_id: string,
    resource_id: string,
    idToken: string,
  ): Promise<any>;
  addGroupManagers(
    project_id: string,
    resource_id: string,
    idToken: string,
    paramsData: GroupManagerData,
  ): Promise<any>;
  checkIfUserIsValid(
    project_id: string,
    user_email: string,
    idToken: string,
  ): Promise<any>;
  getUser(
    project_id: string,
    user_email: string,
    idToken: string,
  ): Promise<any>;
  getUserGroupPlatformLimitTags(
    project_id: string,
    user_group_id: string,
    idToken: string,
  ): Promise<any>;
  updateUserGroupTags(
    project_id: string,
    user_group_id: string,
    idToken: string,
    tags: ITagsParamData,
  ): Promise<any>;
  getTagsOfEntity(paramsData: TagsOfEntityData): Promise<any>;
  createNewProjectResourceAsPerEnv(
    projectId: number,
    resourceId: number,
    params: ProjectResourceAsPerEnv,
  ): Promise<any>;
  getImportById(importId: string, idToken: string): Promise<any>;
  getImportsByUserGroupId(userGroupId: string, idToken: string): Promise<any>;
  getAvailableProjectTemplates(idToken: string): Promise<any>;
  addInvalidEmailsOfImport(emails: object[], importId: string): Promise<any>;
  getInvalidEmailsOfImport(importId: string, idToken: string): Promise<any>;
  createBulkUserMemberships(
    params: TCreateBulkUserMemberships,
    idToken: string,
  ): Promise<any>;
  getProjectSettings: (
    projectId: string,
    idToken: string,
    params: ProjectSettingsRequestParams,
  ) => Promise<ProjectSettingsResponse>;
  updateProjectSettings: (
    projectId: string,
    requestBody: ProjectSettingsRequestBody,
    idToken: string,
  ) => Promise<ProjectSettingsResponse>;
}

export class ProjectsApi implements ProjectsApiInterface {
  private configApi: Config;
  private identityApi: IdentityApi;
  private backendUrl: string;

  constructor(options: ProjectsApiOptions) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
    this.backendUrl = this.configApi.getString('backend.baseUrl');
  }

  /**
   * Save project resource corresponding to project id
   * @param params as IProjectResourceData
   * @returns promise
   */
  public async createNewProjectResourceAsPerEnv(
    projectId: number,
    resourceId: number,
    params: ProjectResourceAsPerEnv,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${projectId}/resources/${resourceId}/environments`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    return data;
  }

  /**
   * Save project resource corresponding to project id
   * @param params as IProjectResourceData
   * @returns promise
   */
  public async createNewProjectResource(
    projectId: string,
    params: IProjectResourceData,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/createNewProjectResource/${projectId}`,
    );

    const newParams = JSON.parse(JSON.stringify(params));
    if (params.environments?.length) {
      newParams.environment = newParams.environments[0].environment;
      delete newParams.environments;
    }
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(newParams),
    });
    const data = await response.json();

    if (response.status < 400 && data && !data.error && response.ok) {
      let remainingData;
      if (params.environments?.length && params.environments.length > 1) {
        const remainingEnvironments = JSON.parse(
          JSON.stringify(params.environments),
        );
        remainingEnvironments.shift();
        if (data?.id) {
          remainingData = await this.createNewProjectResourceAsPerEnv(
            Number(projectId),
            data.id,
            { idToken: params.idToken, environments: remainingEnvironments },
          );
        }
      }

      if (remainingData) {
        data.moreData = remainingData;
      }
    }
    return data;
  }

  public async createNewProjectResourceSMC(
    projectId: string,
    params: ApplicationRequest,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/createNewProjectResource/${projectId}/smc`,
    );

    const idToken = params.idToken;
    if (params.idToken) {
      delete params.idToken;
    }

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
  }

  public async updateProjectResourceSMC(
    projectId: string,
    resourceId: string,
    params: ApplicationRequest,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/updateProjectResource/${projectId}/smc/${resourceId}`,
    );

    const idToken = params.idToken;
    if (params.idToken) {
      delete params.idToken;
    }

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
  }

  public async getProjectResources(id: any, params: any): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/getProjectResources/${id}/resources`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  private removeKeysFromConfiguration(data: any) {
    const keysToRemove = [
      'aad_developer_group_id',
      'aad_readonly_group_id',
      'aad_readonly_groups',
      'aad_readwrite_groups',
    ];

    if (!data || !data.config || !data.config.configuration) {
      return;
    }

    const config = data.config.configuration;

    // Iterate over all keys in the configuration object
    Object.keys(config).forEach(key => {
      if (typeof config[key] === 'object' && config[key] !== null) {
        // Remove specified keys from each configuration object
        keysToRemove.forEach(removeKey => {
          if (removeKey in config[key]) {
            delete config[key][removeKey];
          }
        });
      }
    });
  }

  public async getSpecificResource(
    projectId: number,
    resourceId: number,
    idToken: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/${projectId}/resources/${resourceId}`;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    if (data && !data.error) {
      this.removeKeysFromConfiguration(data);
    }
    return data;
  }

  public async getProjectResourceTags(
    projectId: string,
    resourceId: string,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${projectId}/resources/${resourceId}/tags`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async updateResourceTag(
    gacIdToken: string,
    projectId: string,
    resourceId: string,
    tags: ITagsParamData,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/${projectId}/resources/${resourceId}/tags`;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken,
      },
    );

    const response = await fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(tags),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }

  public async updateProjectResourcesTags(
    gacIdToken: string,
    projectId: string,
    resourceIds: string[],
    tags: ITagsParamData,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/${projectId}/resources/tags`;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken,
      },
    );

    const response = await fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify({ ...tags, resourceIds }),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }

  public async updateProjectResource(
    projectId: string,
    resourceId: string,
    params: updateProjectResource,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/updateProjectResources/${projectId}/resources/${resourceId}`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async archiveProjectResource(
    id: string,
    resourceId: string,
    params: archiveProjectResource,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/archiveProjectResources/${id}/resources/${resourceId}`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
  }

  public async createNewVault(
    nameSpace: string,
    params: createVaultData,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/createVaultNamespace/${nameSpace}`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getUserDetails(idToken: IToken, queryParams?: userQueryParams) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${this.backendUrl}/api/projects/me`);
    requestUrl.search = stringifyQueryString(queryParams);

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken.idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error?.message);
    }

    // status 206 means it's only partial data content received from core and noren has timed out the request.
    if (response.status === 206) {
      throw new Error(
        'Get user details(/me) request is timed out. (Http code: 206)',
      );
    }

    if (!response.ok) {
      throw new Error(data.error?.message || data.response?.data?.message);
    }

    return data;
  }

  public async changeUserRole(params: roleData, idToken: string) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/me/roles`;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data?.error?.message || response.statusText}`);
    }

    return data;
  }

  public async getUserRoles(idToken: string) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/me/roles`;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data?.error?.message || response.statusText}`);
    }

    return data;
  }

  public async getAllProjects(
    params: AllProjectRequestParams,
    idToken: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${this.backendUrl}/api/projects/all`);
    requestUrl.search = stringifyQueryString(params);

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      signal: params.signal,
    });
    const data = await response.json();
    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async createUserMembershipRequestsV3Data(
    params: CreateUserMembershipRequests,
    projectId: string,
  ): Promise<Response> {
    const backendUrl = this.configApi.getString('backend.baseUrl');
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${backendUrl}/api/projects/${projectId}/requests/user-group-memberships`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw data.error;
    }
    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  // We no longer need createUserMembershipRequestsV2Data after the new user validation member is enabled permanently
  // Now we use createUserMembershipRequestsV3Data
  // However, we kept this endpoint for now in case something bad happen and we need to rollback
  // TODO: in the future, we can remove this endpoint along with the endpoint in project-backend in another ticket
  public async createUserMembershipRequestsV2Data(
    params: CreateUserMembershipRequests,
    projectId: string,
  ): Promise<Response> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${projectId}/requests/user-memberships`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw data.error;
    }
    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getAllUserMembershipRequestsData(
    params: GetUserMembershipRequestData,
    idToken: Object,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/getAllUserMembershipRequests`,
    );
    requestUrl.search = stringifyQueryString(params);

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async requestAccess(params: IRequestAccessData) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/request-access`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async cancelUserMembership(params: UserMembershipData) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/cancelUserMemberships`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async approveUserMembership(params: UserMembershipData) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/approveUserMemberships`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async rejectUserMembership(params: UserMembershipData) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/rejectUserMemberships`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async holdUserMembership(params: UserMembershipData) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/holdUserMemberships`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async retryUserMembership(params: UserMembershipData) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/retryUserMemberships`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async resumeUserMembership(params: UserMembershipData) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/resumeUserMemberships`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getProjectByID(
    id: string,
    idToken: string,
    params: TGetProjectByIdParams,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const queryParams = new URLSearchParams();
    queryParams.set(
      'manipulators',
      params.manipulators.filter((m: string) => m !== '').join(','),
    );

    const newUrl = `${this.backendUrl}/api/projects/get/${encodeURIComponent(
      id,
    )}?${queryParams}`;

    const response = await fetch(newUrl, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (response.status === 403) {
      return {
        status: 403,
      };
    }

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getGroupByIDFromProject(
    id: string,
    projectId: string,
    gacToken: { idToken: string },
  ) {
    const backstageToken = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/${encodeURIComponent(
      projectId,
    )}/group/${encodeURIComponent(id)}`;

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${backstageToken}`,
      },
      {
        gacIdToken: gacToken.idToken,
      },
    );

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(`${data?.error?.message || response.statusText}`);
    }

    if (data?.error) {
      throw new NotFoundError(data.error.message);
    }

    return data;
  }

  public async addProjectUserGroupData(
    params: addProjectUserGroup,
    projectId: string,
    idToken: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/addProjectUserGroup/${projectId}`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    return response;
  }

  public async getUserGroupPerProject(
    id: string,
    idToken: string,
    email: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/getGroups/${id}/groups/membership/${email}`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async getUserMemberList(
    projectId: string,
    groupName: string,
    idToken: string,
    count: any,
    startIndex: any,
    orderBy: any,
    reverse: any,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    let url = `${this.backendUrl}/api/projects/${projectId}/userGroups/${groupName}/members`;
    const params = new URLSearchParams();
    params.set('count', count);
    params.set('startIndex', startIndex);
    params.set('order_by', orderBy);
    params.set('reverse', reverse);
    url += `?${params}`;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async archiveUserGroups(id: string, params: archiveProjectUserGroup) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/archiveUserGroups/${id}`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async unArchiveUserGroups(
    id: string,
    params: archiveProjectUserGroup,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/unArchiveUserGroups/${id}`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async unArchiveResources(
    projetId: string,
    resourceId: number,
    params: IToken,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/unArchivResources/${projetId}/resource/${resourceId}`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async updateUserGroups(
    id: string,
    idToken: string,
    params: UpdateUserGroupBody,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/updateUserGroups/${id}`,
    );
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getUserGroups(id: string, idToken: string) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/${encodeURIComponent(
      id,
    )}/usergroups`;

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(idToken),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }

  // TODO: add unit test
  public async getAttachableSourceProjects(id: string, idToken: string) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/${encodeURIComponent(
      id,
    )}/attachable-source-projects`;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(url, {
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data?.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async editById(id: string, params: EditProjectById) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/editById/${id}`,
    );
    // requestUrl.search = stringifyQueryString(params);
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getProjectResourceByID(id: string) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/all/${encodeURIComponent(
      id,
    )}/resources`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async getProjectUserGroupsByID(id: string) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/all/${encodeURIComponent(
      id,
    )}/user-groups`;
    const response = await fetch(url, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }

  public async getUserGroupPlatformLimitTags(
    project_id: string,
    group_id: string,
    idToken?: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/${project_id}/user-groups/${group_id}/tags`;
    const tag = 'platform-limit-tool';
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken ?? undefined,
      },
    );
    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const res = await response.json();
    if (res?.data?.tags?.[tag]) {
      const requestedTag: any = { status: res?.status, data: { tags: {} } };
      requestedTag.data.tags[tag] = res?.data.tags[tag];
      return requestedTag;
    }

    return res;
  }

  public async updateUserGroupTags(
    project_id: string,
    group_id: string,
    idToken: string,
    tags: ITagsParamData,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/${project_id}/user-groups/${group_id}/tags`;
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(tags),
    });

    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }

  public async getTagsOfEntity(params: TagsOfEntityData) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = `${this.backendUrl}/api/projects/tags/entities`;
    const headers = generateHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });

    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });

    const data = await response.json();

    return data;
  }

  public async getProjectRequests() {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = `${this.backendUrl}/api/requests`;
    const response = await fetch(requestUrl, {
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();

    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }

    return data;
  }
  public async getDevelopmentToolsData(
    idToken: string,
    params: GetDevelopmentToolsData,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/getDevelopmentToolsData`,
    );
    requestUrl.search = stringifyQueryString(params);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return data;
  }

  public async getCompanyData(idToken: string, paramsData?: Object) {
    const token = (await this.identityApi.getCredentials()).token;
    let requestUrl = `${this.backendUrl}/api/projects/companies`;

    if (paramsData) {
      const searchParams = new URLSearchParams();
      Object.entries(paramsData).forEach(param => {
        const [key, value] = param;
        searchParams.set(key, value);
      });
      if (searchParams.toString()) {
        requestUrl += `?${searchParams}`;
      }
    }

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl, {
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    return await response.json();
  }

  public async createProject(
    params: createProjectData,
    idToken: string,
    isNotUsingBeta: boolean,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(`${this.backendUrl}/api/projects/createProject`);

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    headers.set('use-beta', isNotUsingBeta ? 'false' : 'true');

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new NotFoundError(data.error.message);
    }

    if (!response.ok) {
      throw new NotFoundError(response.statusText);
    }
    return data;
  }

  public async attachUserGroup(
    idToken: Object,
    targetProjectId: string,
    userGroupId: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/${targetProjectId}/attachUserGroup/${userGroupId}`,
    );
    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });
    const data = await response.json();
    return data;
  }

  public async detachUserGroup(
    idToken: Object,
    targetProjectId: string,
    userGroupId: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/${targetProjectId}/detachUserGroup/${userGroupId}`,
    );
    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });
    const data = await response.json();
    return data;
  }

  public async updateUserGroup(
    targetProjectId: string,
    userGroupId: string,
    params: updateProjectUserGroup,
    idToken: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${targetProjectId}/userGroup/${userGroupId}`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(params),
    });
    const data = await response.json();

    return data;
  }
  public async assignUserGroupToResources(
    paramsData: AssignUserGroupToResourceData,
    targetProjectId: string,
    resourceId: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${targetProjectId}/resources/${resourceId}/user-groups`,
    );
    try {
      const response = await fetch(requestUrl.toString(), {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          ...(token && { Authorization: `Bearer ${token}` }),
        },
        body: JSON.stringify(paramsData),
      });
      const data = await response.json();

      if (data && data.error) {
        throw new Error(data.error.message);
      }

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return data;
    } catch (e) {
      return { status: 500, data: { message: e.message } };
    }
  }

  public async getUserGroupsOfResource(
    targetProjectId: string,
    resourceId: string,
    idToken: string,
    paramsData: IGroupsOfResourceParamData,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${targetProjectId}/resources/${resourceId}/user-groups`,
    );
    requestUrl.search = stringifyQueryString(paramsData);

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getGroupManagers(userGroupId: string, idToken: Object) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/getGroup/${userGroupId}/managers`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(idToken),
    });

    const data = await response.json();
    return data;
  }

  public async getGitHubOrgWebhookSecret(
    idToken: string,
    orgName: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/github/webhook/org`,
    );

    const response = await fetch(requestUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify({
        id_token: idToken,
        org_name: orgName,
      }),
    });

    const data = await response.json();
    if (data && data.error) {
      if (data.reason) {
        throw new Error(data.reason);
      } else {
        throw new Error('Please try again.');
      }
    }
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getProjectMembers(
    id: string,
    idToken: string,
    isProjectMembersNewAPIEnabled: boolean,
    params: GetProjectMembers,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    let requestUrl;
    if (isProjectMembersNewAPIEnabled) {
      requestUrl = new URL(`${this.backendUrl}/api/projects/${id}/members/new`);
    } else {
      requestUrl = new URL(`${this.backendUrl}/api/projects/${id}/members`);
    }

    requestUrl.search = stringifyQueryString(params);

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async upsertOptOutData(projectId: number, params: upsertParams) {
    const token = (await this.identityApi.getCredentials()).token;
    const pluginId = 'selfServiceOptOutSettings';
    const requestUrl = new URL(
      `${this.backendUrl}/api/${pluginId}/settings/${projectId}/selfservice`,
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return data;
  }

  public async getOptOutData(projectId: number) {
    const token = (await this.identityApi.getCredentials()).token;
    const pluginId = 'selfServiceOptOutSettings';
    const requestUrl = new URL(
      `${this.backendUrl}/api/${pluginId}/settings/${projectId}/selfservice`,
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });

    const data = await response.json();
    return data;
  }

  public async attachAllowableProjects(
    ug_id: string,
    idToken: string,
    paramsData: INoCache,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/user-groups/${ug_id}/attach-allowable-projects`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();
    return data;
  }

  public async getUserGroup(
    ug_id: string,
    idToken: string,
    paramsData: INoCache,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/user-groups/${ug_id}`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async attachDestinationProjects(
    ug_id: string,
    idToken: string,
    paramsData: INoCache,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/user-groups/${ug_id}/attach-destination-projects`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async getProjectUserGroupData(
    project_id: string,
    ug_id: string,
    idToken: string,
    paramsData: INoCache,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${project_id}/user-groups/${ug_id}`,
    );
    requestUrl.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return data;
  }

  public async getProjectLegalContracts(
    idToken: string,
    id: string,
    paramsData: ILegalContractParamData,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/${id}/legal-contracts`,
    );

    url.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async createProjectLegalContract(
    idToken: string,
    id: string,
    paramsData: ILegalContractData,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/${id}/legal-contracts`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(paramsData),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
    paramsData: ILegalContractParamData,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/${id}/legal-contracts/${contractId}`,
    );

    url.search = stringifyQueryString(paramsData);
    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async updateProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
    paramsData: ILegalContractData,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/${id}/legal-contracts/${contractId}`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(paramsData),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async archiveProjectLegalContractById(
    idToken: string,
    id: string,
    contractId: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/${id}/legal-contracts/${contractId}/archive`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();

    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getArtifactoryAccessProperties(
    id: string,
    idToken: string,
    artifactory_data: artifactoryAccessData,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/artifactory-access/properties/${id}/${artifactory_data.artifactory_name}/${artifactory_data.artifact_repo_key}`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();
    return data;
  }

  public async updateArtifactoryAccessProperties(
    id: string,
    idToken: string,
    artifactory_data: artifactoryAccessData,
    req_body: any,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/artifactory-access/edit/properties/${id}/${artifactory_data.artifactory_name}/${artifactory_data.artifact_repo_key}`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(req_body),
    });

    const data = await response.json();
    return data;
  }

  public async getAllUsersWithCreatorRoleInProject(
    project_id: string,
    idToken: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${project_id}/roles/creators`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();
    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async updateUsersCreatorRoleInProject(
    project_id: string,
    idToken: string,
    creator_roles_data: CreatorRolesData,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${project_id}/roles/creators`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'PATCH',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(creator_roles_data),
    });

    const data = await response.json();
    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async getGroupManagersOfUserGroup(
    project_id: string,
    resource_id: string,
    idToken: string,
  ) {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${project_id}/resources/${resource_id}/managers`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }

  public async addGroupManagers(
    project_id: string,
    resource_id: string,
    idToken: string,
    paramsData: GroupManagerData,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const url = new URL(
      `${this.backendUrl}/api/projects/${project_id}/resources/${resource_id}/managers`,
    );

    const headers = generateHeaders(
      {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );

    const response = await fetch(url.toString(), {
      method: 'PUT',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(paramsData),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }

  public async checkIfUserIsValid(
    project_id: string,
    user_email: string,
    idToken: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${project_id}/users/${user_email}/valid`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'HEAD',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.status;
  }

  public async getUser(
    project_id: string,
    user_email: string,
    idToken: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${project_id}/users/${user_email}`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }

  public async getImportById(importId: string, idToken: string): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/imports/${importId}`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }

  public async getImportsByUserGroupId(
    userGroupId: string,
    idToken: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/group/${userGroupId}/imports`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }
  public async addInvalidEmailsOfImport(
    emails: object[],
    importId: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;

    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/imports/${importId}/invalid`,
    );

    const headers = generateHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(emails),
    });
    const data = await response.json();

    return { status: response?.status, data: data };
  }

  public async getInvalidEmailsOfImport(
    importId: string,
    idToken: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/imports/${importId}/invalid`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }

  public async getAvailableProjectTemplates(idToken: string): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects//available-templates`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });
    const data = await response.json();
    if (data && data.error) {
      throw new Error(data.error.message);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return data;
  }

  public async createBulkUserMemberships(
    params: TCreateBulkUserMemberships,
    idToken: string,
  ): Promise<any> {
    const token = (await this.identityApi.getCredentials()).token;
    const { project_id, user_group_id, user_memberships, import_file_name } =
      params;
    const requestUrl = new URL(
      `${this.backendUrl}/api/projects/${project_id}/user-groups/${user_group_id}/requests/bulk-user-memberships`,
    );

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken: idToken,
      },
    );
    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify({ user_memberships, import_file_name }),
    });
    const data = await response.json();
    return { status: response?.status, data: data };
  }

  public async getProjectSettings(
    projectId: string,
    gacIdToken: string,
    params: ProjectSettingsRequestParams,
  ) {
    const requestParam = objectToURLSearchParams(params);
    const requestUrl = new URL(
      `${this.backendUrl}/api/rover/stargate/projects/${projectId}?${requestParam}`,
    );

    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'GET',
      credentials: 'include',
      headers: Object.fromEntries(headers),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error?.message || response.statusText);
    }
    return data;
  }

  public async updateProjectSettings(
    projectId: string,
    requestBody: ProjectSettingsRequestBody,
    gacIdToken: string,
  ): Promise<ProjectSettingsResponse> {
    const requestUrl = new URL(
      `${this.backendUrl}/api/rover/stargate/projects/${projectId}`,
    );

    const token = (await this.identityApi.getCredentials()).token;

    const headers = generateHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      {
        gacIdToken,
      },
    );

    const response = await fetch(requestUrl.toString(), {
      method: 'POST',
      credentials: 'include',
      headers: Object.fromEntries(headers),
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error?.message || response.statusText);
    }
    return data;
  }
}
