import { getTechDocsLink } from 'sg-utils-frontend';
import { useTranslation } from '../../../useTranslation';

export const useManagingProjectCard = () => {
  const { t } = useTranslation();
  return {
    title: t('techdocs.cards.managingStargate.title'),
    description: t('techdocs.cards.managingStargate.description'),
    image: 'image2.webp',
    documents: [
      {
        title: t('techdocs.cards.managingStargate.overview'),
        link: getTechDocsLink('admin-overview'),
      },
      {
        title: t('techdocs.cards.managingStargate.userGroup'),
        link: getTechDocsLink('user-groups'),
      },
      {
        title: t('techdocs.cards.managingStargate.creatingProject'),
        link: getTechDocsLink('creating-project'),
      },
      {
        title: t('techdocs.cards.managingStargate.stargateApi'),
        link: getTechDocsLink('api-overview'),
      },
      {
        title: t('techdocs.cards.managingStargate.dynamicUserGroups'),
        link: getTechDocsLink('automatic-dynamic-groups'),
      },
    ],
  };
};
