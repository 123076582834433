import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles((theme: USGTheme) => ({
  container: {
    padding: theme.spacing(5),
  },
  pagination: {
    '& > div': {
      '& > div': {
        '& > div': {
          '& > div': {
            padding: '0px 16px !important',
          },
        },
      },
    },
  },
}));
