import {
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  JIRA_DEV_TOOL_ID,
} from 'usg-types';
import { ResourceState } from '../../../hooks/useRoverIndexingEnabledResourceIds';

export const ADMIN_ONLY_DEV_TOOL_IDS: number[] = [];
export const COMMON_EDITABLE_DEV_TOOL_IDS = [
  JIRA_DEV_TOOL_ID,
  CONFLUENCE_DEV_TOOL_ID,
  ARTIFACTORY_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  JAMA_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
];

export type UserGroupObject = {
  id: number;
  name: string;
  rule_based: boolean;
  role: string;
};

export type SortedUserGroupsArrayType = UserGroupObject[];

export interface ViewEditResourcePageComponentProps {
  projectId: string;
  resourceId: string;
  projectData: any;
}

export type UnarchiveButtonProps = {
  setUnArchiveOpen: (value: boolean) => void;
};

export type EditArchiveButtonProps = {
  devToolId: number;
  projectId: string;
  isAdmin: boolean;
  setEditMode: (value: boolean) => void;
  isProjectOwner: any;
  readOnlyResources: number[];
  resourceManagerCheck: boolean;
  setOpenArchive: (value: boolean) => void;
};

export type ResourceManagersProps = {
  projectId: string;
  editMode: boolean;
  resourceManagerData: any[];
  setIsAddUserLoading: any;
  setIsAddUserEnable: any;
  isAddUserEnable: boolean;
  addBottomMargin?: boolean;
};

export type ResourceUrlProps = {
  resourceUrl: string;
  projectId: string;
  resourceId: string;
  copyButtonLabel: string;
  setCopyButtonLabel: (value: string) => void;
};

type EmailErrorData = {
  message: string;
};

export type EditButtonsProps = {
  formik: any;
  idTokenData: string;
  devToolId: number;
  projectId: string;
  resourceId: string;
  apiLoader: boolean;
  showArtifactoryGithubWorkflowAccess: boolean;
  artifactoryProperties: any;
  setResourceData: any;
  resourceData: any;
  setOpenEditSucessMsg: (value: boolean) => void;
  setEditMode: (value: boolean) => void;
  setApiLoader: (value: boolean) => void;
  setArtifactoryProperties: any;
  existingArtifactoryProperties: any;
  setUserEmailErrorData: (err: EmailErrorData) => void;
  userEmailErrorData: EmailErrorData;
  isAddUserLoading: boolean;
  isAddUserEnable: boolean;
  isResourceIndexingEnabled: boolean;
  setResourcesToggleState: React.Dispatch<React.SetStateAction<ResourceState>>;
};

export type DialogUnarchiveProps = {
  unArchiveOpen: boolean;
  setUnArchiveOpen: (value: boolean) => void;
  projectId: string;
  resourceId: string;
  idTokenData: string;
  setUnArchiveSuccessDialog: (value: boolean) => void;
};

export type DialogSuccessfulUnarchivedProps = {
  unArchiveSuccessDialog: boolean;
  setUnArchiveSuccessDialog: (value: boolean) => void;
  navigate: any;
  projectId: string;
};

export type DialogResourceErrorMessageProps = {
  devToolId: number;
  openEditSucessMsg: boolean;
  fetchContent: () => Promise<void>;
  setEditMode: (value: boolean) => void;
  setOpenEditSucessMsg: (value: boolean) => void;
  fetchResourceManagers: (
    email: string,
    specificResourceId: any,
  ) => Promise<void>;
  userEmail: string;
};

export type DialogArchiveResourcesProps = {
  openArcive: boolean;
  projectId: string;
  navigate: any;
  resourceId: string;
  idTokenData: string;
  setOpenArchive: (value: boolean) => void;
};

export type SuccessBarProps = {
  isOpen: boolean;
  children: React.ReactElement;
  setIsSuccessBoxOpen: (value: boolean) => void;
};

export const ROOT_URL_LABEL_BREADCRUMBS = 'Project Workspace';
export type IpOwnerCompany = {
  company_id: number;
  started_on: string;
  percentage: number;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  ended_on?: string;
};

export type ParticipantCompany = {
  company_id: number;
  started_on: string;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
};

export type UserGroup = {
  id: number;
  name: string;
  accessible_resources: number[];
  accessible_resources_roles: AccessibleResourcesRole[];
  sharing_properties?: SharingProperties;
  shareable_allowed_projects: any[];
  allow_sharing_to_all: boolean;
  rule_based: boolean;
  allowed_companies: any[];
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  description?: string;
  deleted_by?: string;
  deleted_on?: string;
};

export type AccessibleResourcesRole = {
  id: number;
  role: string;
};

export type SharingProperties = {
  source_project_id: number;
  shared_to_project_ids: number[];
};

export type Resource = {
  id: number;
  dev_tool_id: number;
  key: string;
  name: string;
  description: string;
  config?: any;
  url: string;
  environment: string;
  generated_resource_key: string;
  created_by: string;
  created_on: string;
  updated_by?: string;
  updated_on?: string;
  deleted_by?: any;
  deleted_on?: any;
  development_tool_name?: string;
  linkedResources?: Resource[];
};

export type ProjectDetails = {
  id: string;
  name: string;
  key: string;
  management_type: string;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  owners: { user_email: string; role: any }[];
  ip_owner_companies: IpOwnerCompany[];
  participant_companies: ParticipantCompany[];
  user_groups: UserGroup[];
  resources: Resource[];
};

type UserDetailsAndPermissions = {
  data: any;
  email: string;
  roles: any[];
  permissions: any;
  isAdmin: boolean;
  isProjectOwner: (value: number) => boolean;
  isROAdmin: boolean;
  isAnyAdmin: boolean;
  isResourceCreator: (resourceId: number, devToolId: number) => boolean;
  isResourceManager: (resourceId: number) => boolean;
  refresh: () => Promise<void>;
};

export type ViewEditResourceData = {
  projectDetails: ProjectDetails;
  userDetailsAndPermissions: UserDetailsAndPermissions;
};
