import { useState } from 'react';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { projectApiRef } from '@internal/plugin-projects';
import { CONFLUENCE_DEV_TOOL_ID } from 'usg-types';
import { GENAI_INDEX_ENABLED } from '../common/SGConstants';

const DEFAULT_PAGE = 1;
const MAX_SIZE = 100;

export type ResourceState = {
  [key: string]: {
    isEnabled: boolean;
  };
};

export default function useRoverIndexingEnabledResourceIds(projectId: string) {
  const authApi = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const [resourcesToggleState, setResourcesToggleState] =
    useState<ResourceState>({});

  const fetchResources = async (idToken: string, nextPage: number) => {
    try {
      const roverIndexingEnabledResourcesResponse: any =
        await projectApi.getProjectResources(projectId, {
          idToken,
          dev_tool_id: CONFLUENCE_DEV_TOOL_ID,
          tag: `${GENAI_INDEX_ENABLED}:true`,
          size: MAX_SIZE,
          page: nextPage,
          include_archived: false,
        });
      const { next_page, resources } =
        roverIndexingEnabledResourcesResponse.response.data;

      const resourceStates: ResourceState = {};
      const resourceIds: string[] = resources.map((resource: any) => {
        resourceStates[resource.id] = {
          isEnabled: true,
        };
        return resource.id;
      });

      setResourcesToggleState(prevResourcesToggleState => {
        const newState = {
          ...prevResourcesToggleState,
          ...resourceStates,
        };

        return newState;
      });

      // Return the resourceIds fetched in this call
      return {
        nextPage: next_page,
        resourceIds: resourceIds,
      };
    } catch (err: any) {
      errorApi.post(
        new Error(
          err?.message ||
            'Failed to fetch resources that enabled rover indexing',
        ),
      );
      return {
        nextPage: undefined,
        resourceIds: [],
      };
    }
  };

  const fetchRoverIndexingEnabledResources = async () => {
    const idToken = await authApi.getIdToken();
    const resourcesResponse = await fetchResources(idToken, DEFAULT_PAGE);
    let accumulatedResourceIds = resourcesResponse.resourceIds;
    let nextPage = resourcesResponse.nextPage;

    while (nextPage) {
      const nextPageResourcesResponse = await fetchResources(idToken, nextPage);
      accumulatedResourceIds = [
        ...accumulatedResourceIds,
        ...nextPageResourcesResponse.resourceIds,
      ];
      nextPage = nextPageResourcesResponse.nextPage;
    }

    return accumulatedResourceIds;
  };

  return {
    resourcesToggleState,
    setResourcesToggleState,
    fetchRoverIndexingEnabledResources,
  };
}
