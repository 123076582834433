import React from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  ADMIN_ONLY_DEV_TOOL_IDS,
  COMMON_EDITABLE_DEV_TOOL_IDS,
  EditArchiveButtonProps,
} from '../types';
import { useTranslation } from '../../../../hooks/useTranslation';

export const EditArchiveButton = ({
  devToolId,
  projectId,
  isAdmin,
  setEditMode,
  isProjectOwner,
  readOnlyResources,
  resourceManagerCheck,
  setOpenArchive,
}: EditArchiveButtonProps) => {
  const { t } = useTranslation();

  const enableCommonEditBtn = (dev_tool_id: any) => {
    let editable_tools = COMMON_EDITABLE_DEV_TOOL_IDS;
    if (isAdmin) {
      editable_tools = [...editable_tools, ...ADMIN_ONLY_DEV_TOOL_IDS];
    }
    return editable_tools.includes(dev_tool_id);
  };

  const onOpenArchiveDialog = () => {
    setOpenArchive(true);
  };

  return (
    <>
      <Grid item xs={3} style={{ textAlign: 'right' }}>
        {enableCommonEditBtn(devToolId) || resourceManagerCheck ? (
          <Button
            id="edit-resource-button"
            data-testid="edit-resource-button"
            variant="contained"
            onClick={() => {
              setEditMode(true);
            }}
          >
            {t('resource.view.editButton')}
          </Button>
        ) : (
          ''
        )}
        {isAdmin ||
        (isProjectOwner(Number(projectId)) &&
          !readOnlyResources.includes(devToolId)) ||
        resourceManagerCheck ? (
          <Button
            id="archive-resource-button"
            variant="outlined"
            onClick={() => onOpenArchiveDialog()}
            style={{ marginLeft: '8px' }}
          >
            {t('resource.view.archiveButton')}
          </Button>
        ) : null}
      </Grid>
    </>
  );
};
