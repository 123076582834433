import { makeStyles } from '@material-ui/core';
import { USGTheme } from 'usg-new-theme';

export const useStyles = makeStyles<USGTheme>(theme => ({
  tag: {
    color: theme.palette.usgColors.secondary.green,
    fontWeight: 400,
    backgroundColor: 'rgba(0, 164, 14, 0.1)',
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  content: {
    '& h1, h2, h3, h4, h5, h6': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& a': {
      color: theme.palette.usgColors.secondary.blue,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  button: {
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.usgColors.secondary.blue,
    },
  },
  icon: {
    color: theme.palette.usgColors.primary.blue,
  },
  expandedIcon: {
    transform: 'rotate(180deg)',
  },
}));
