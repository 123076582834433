import project from './ja/project-ja.json';
import projects from './ja/projects-ja.json';
import resource from './ja/resource-ja.json';
import common from './ja/common-ja.json';
import usergroups from './ja/usergroups-ja.json';
import profile from './ja/profile-ja.json';
import createproject from './ja/createproject-ja.json';
import imports from './ja/imports-ja.json';
import contracts from './ja/contracts-ja.json';

export const ja = {
  project,
  projects,
  resource,
  common,
  usergroups,
  profile,
  createproject,
  imports,
  contracts,
};
