import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Input,
  Select,
  MenuItem,
  IconButton,
  TableBody,
  Button,
  TableFooter,
  TablePagination,
  Box,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NextIcon from '@material-ui/icons/NavigateNext';
import PreviousIcon from '@material-ui/icons/NavigateBefore';
import { ArtifactoryGitHubWorkflowAccessNoteSection } from './ArtifactoryGitHubWorkflowAccessNoteSection';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStyles } from '../styles';

const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

/* Add more options as needed */
const TABLE_OPTIONS = {
  githubTypes: [
    {
      value: 'EMU',
      label: 'EMU',
    },
    {
      value: 'Enterprise',
      label: 'Enterprise',
    },
  ],
  permissions: [
    {
      value: 'Read-only',
      label: 'Read-Only',
    },
    {
      value: 'Read-Write',
      label: 'Read-Write',
    },
    {
      value: 'Read-Write-Delete',
      label: 'Read-Write-Delete',
    },
  ],
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        id="projects-iconbutton-artsaasfirstpage"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        disableRipple
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        id="projects-iconbutton-artsaasback"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        disableRipple
      >
        <PreviousIcon />
      </IconButton>
      <IconButton
        id="projects-iconbutton-artsaasnext"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        disableRipple
      >
        <NextIcon />
      </IconButton>
      <IconButton
        id="projects-iconbutton-artsaaslastpage"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        disableRipple
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

/**
 * ArtifactoryGitHubWorkflowAccessEditTable Component
 *
 * This component renders a table for managing GitHub Workflow access configuration
 * for the Resource Self Hosted Artifactory.
 *
 * @param {Object} props - The component props
 * @param {string} props.name - The name of the component
 * @param {Array} props.artifactoryProperties - The array of artifactory properties
 * @param {Function} props.setArtifactoryProperties - The function to update artifactory properties
 * @returns {JSX.Element} - Returns the JSX element for the component
 */
export const BaseArtifactoryTable = (props: {
  name: string;
  artifactoryProperties: any;
  setArtifactoryProperties?: (artifactoryProperties: any) => void;
  editMode?: boolean;
}) => {
  const { name, artifactoryProperties, setArtifactoryProperties, editMode } =
    props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE_OPTIONS[0]);
  const [rowsToDisplay, setRowsToDisplay] = useState(
    artifactoryProperties?.slice(page, rowsPerPage) || [],
  );

  const classes = useStyles();
  const { t } = useTranslation();

  // Pagination handlers
  useEffect(() => {
    const startingIndex = rowsPerPage * page;
    const endingIndex = startingIndex + rowsPerPage;

    setRowsToDisplay(
      artifactoryProperties
        ? artifactoryProperties.slice(startingIndex, endingIndex)
        : [],
    );
  }, [page, rowsPerPage, artifactoryProperties]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const goToLastPage = (ap: any) =>
    setPage(Math.max(0, Math.ceil(ap.length / rowsPerPage) - 1));

  const getRealIndex = (i: number): number => page * rowsPerPage + i;
  // Artifactory CRUD handlers
  const artifactoryPropsHandleDelete = (index: number) => {
    if (setArtifactoryProperties) {
      const filteredProperties = artifactoryProperties.filter(
        (_item: any, itemIndex: number) => {
          return itemIndex !== getRealIndex(index);
        },
      );

      setArtifactoryProperties(filteredProperties);
      if (filteredProperties.length % rowsPerPage === 0) {
        goToLastPage(filteredProperties);
      }
    }
  };

  const artifactoryPropsHandleRepoOrgChange = (
    index: number,
    newName: string,
  ) => {
    if (setArtifactoryProperties) {
      const updatedProperties = artifactoryProperties.map(
        (item: any, itemIndex: number) =>
          itemIndex === getRealIndex(index) ? { ...item, name: newName } : item,
      );

      setArtifactoryProperties(updatedProperties);
    }
  };

  const artifactoryPropsHandleTypeChange = (
    index: number,
    newValue: string,
  ) => {
    if (setArtifactoryProperties) {
      const updatedProperties = artifactoryProperties.map(
        (item: any, itemIndex: number) =>
          itemIndex === getRealIndex(index)
            ? { ...item, platform: newValue }
            : item,
      );

      setArtifactoryProperties(updatedProperties);
    }
  };

  const artifactoryPropsHandlePermissionChange = (
    index: number,
    newValue: string,
  ) => {
    if (setArtifactoryProperties) {
      const updatedProperties = artifactoryProperties.map(
        (item: any, itemIndex: number) =>
          itemIndex === getRealIndex(index)
            ? { ...item, type: newValue }
            : item,
      );

      setArtifactoryProperties(updatedProperties);
    }
  };

  const artifactoryPropsAddNewRow = () => {
    if (setArtifactoryProperties) {
      const newRow = { name: '', platform: 'EMU', type: 'Read-only' };

      if (!artifactoryProperties || artifactoryProperties.length === 0) {
        setArtifactoryProperties([newRow]);
      } else {
        const newArtifactoryProperties = [...artifactoryProperties, newRow];
        setArtifactoryProperties(newArtifactoryProperties);
        goToLastPage(newArtifactoryProperties);
      }
    }
  };

  // Component renderers
  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow className={classes.tableHeaderRow}>
          <TableCell className={classes.tableHeaderCell}>
            {t('resource.githubWorkflowAccess.artifactoryTable.headerOrgRepo')}
          </TableCell>
          <TableCell className={classes.tableHeaderCell}>
            {t(
              'resource.githubWorkflowAccess.artifactoryTable.headerGithubType',
            )}
          </TableCell>
          <TableCell className={classes.tableHeaderCell}>
            {t(
              'resource.githubWorkflowAccess.artifactoryTable.headerPermissions',
            )}
          </TableCell>
          {editMode ? (
            <TableCell className={classes.tableHeaderCell}>
              {t(
                'resource.githubWorkflowAccess.artifactoryTable.headerActions',
              )}
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBody = () => {
    return (
      <TableBody>
        {rowsToDisplay &&
          rowsToDisplay.map((item: any, index: number) => (
            <TableRow key={index}>
              <TableCell>
                {editMode ? (
                  <Input
                    fullWidth
                    value={item.name}
                    onChange={e =>
                      artifactoryPropsHandleRepoOrgChange(index, e.target.value)
                    }
                  />
                ) : (
                  <Typography>{item.name}</Typography>
                )}
              </TableCell>
              <TableCell>
                {editMode ? (
                  <Select
                    className={classes.selectMenu}
                    fullWidth
                    value={item.platform}
                    onChange={e =>
                      artifactoryPropsHandleTypeChange(
                        index,
                        String(e.target.value),
                      )
                    }
                    displayEmpty
                  >
                    <MenuItem
                      id={`projects-menuitem-platformdisabled-${index}`}
                      disabled
                    >
                      <i>select</i>
                    </MenuItem>
                    {TABLE_OPTIONS.githubTypes.map(option => (
                      <MenuItem
                        id={`projects-menuitem-platform${option.label}-${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Typography>{item.platform}</Typography>
                )}
              </TableCell>
              <TableCell>
                {editMode ? (
                  <Select
                    className={classes.selectMenu}
                    fullWidth
                    value={item.type}
                    onChange={e =>
                      artifactoryPropsHandlePermissionChange(
                        index,
                        String(e.target.value),
                      )
                    }
                    displayEmpty
                  >
                    <MenuItem
                      id={`projects-menuitem-typedisabled-${index}`}
                      disabled
                    >
                      <i>select</i>
                    </MenuItem>
                    {TABLE_OPTIONS.permissions.map(option => (
                      <MenuItem
                        id={`projects-menuitem-type${option.label}-${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Typography>{item.type}</Typography>
                )}
              </TableCell>
              {editMode ? (
                <TableCell>
                  <IconButton
                    id={`projects-iconbutton-deleteaction-${index}`}
                    aria-label="delete-action"
                    onClick={() => artifactoryPropsHandleDelete(index)}
                    className={classes.deleteButton}
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
      </TableBody>
    );
  };

  const renderTableFooter = () => {
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
            colSpan={editMode ? 3 : 2}
            rowsPerPage={rowsPerPage}
            count={artifactoryProperties ? artifactoryProperties.length : 0}
            page={page}
            variant="body"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            className={classes.tableFooterNavigation}
          />
          <TableCell align="right" size="small">
            {editMode ? (
              <Button
                id="projects-button-artsaasaddicon"
                className={classes.tableFooterAction}
                startIcon={<AddIcon />}
                onClick={artifactoryPropsAddNewRow}
                disableRipple
              >
                {t(
                  'resource.githubWorkflowAccess.artifactoryTable.addRowButton',
                )}
              </Button>
            ) : null}
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  };

  return (
    <Typography component="div" className={classes.viewTag}>
      <ArtifactoryGitHubWorkflowAccessNoteSection name={name} />
      <TableContainer component={Paper}>
        <Table style={{ width: '100%', borderCollapse: 'collapse' }}>
          {renderTableHead()}
          {renderTableBody()}
          {renderTableFooter()}
        </Table>
      </TableContainer>
    </Typography>
  );
};
