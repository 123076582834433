import { ProjectOwnerProps } from '../../../../ProjectOwners/ProjectOwners';

export type VaultViewEditComponentProps = {
  owner: ProjectOwnerProps;
};

export type TabLabel = {
  label: string;
};

export type TabsProps = {
  onChange: (event: any, newValue: number) => void;
  value: number;
  formik: any;
  setIsEdit: any;
  resourceData: any;
  projectId: any;
  fetchContent?: any;
  setIsContentVisible?: any;
  isAddEnvEnabled?: boolean;
  resourceIdForCreatingNewEnv: () => number;
};

export type LinkedResource = {
  id: number;
  environment: string;
  url: string;
};

export type VaultViewEditTabProps = {
  projectId: string;
  resourceData: any;
  attachableUserGroups: Array<any>;
  fetchContent?: any;
  setIsContentVisible?: any;
  isContentVisible?: boolean;
  isAdmin?: boolean;
  isProjectOwner: (projectId: number) => boolean;
  isResourceManager: (resourceId: number) => boolean;
  isResourceCreator: (projectId: number, dev_tool_id: number) => boolean;
  resourceManagerData?: any;
  isAddUserEnable?: any;
  setIsAddUserEnable?: any;
  setIsAddUserLoading?: any;
  formik?: any;
  isAddUserLoading?: any;
  resourceManagerList?: any;
  fetchResourceManagers?: any;
  userEmail?: any;
  refresh: () => Promise<void>;
  value: number;
  setValue: (value: number) => void;
};

export const ENVIRONMENTS = [
  {
    name: 'prod',
    label: 'Production',
  },
  {
    name: 'stg',
    label: 'Staging',
  },
  {
    name: 'dev',
    label: 'Development',
  },
];

export type UserGroup = {};

export type ResultData = {
  data: UserGroup[];
  page: number;
  totalCount: number;
};
