import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  InputLabel,
  TextField,
  Button,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { RESOURCE_NAME_MAX_LENGTH, RESOURCE_NAME_MIN_LENGTH } from 'usg-types';
import { useTranslation } from '../../../hooks/useTranslation';
import { useStyles } from '../styles';

interface Props {
  onSubmitCreateVault: (values: any) => Promise<void>;
  vaultNameDirty: boolean;
  projectData: any;
  onVaultNameClick: () => void;
  enviromentsData: any;
  apiLoader: boolean;
  handleCreateResource: () => void;
  isDisabled?: boolean;
}
export const CreateVaultResourceForm = ({
  onSubmitCreateVault,
  vaultNameDirty,
  projectData,
  onVaultNameClick,
  enviromentsData,
  apiLoader,
  handleCreateResource,
  isDisabled,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const helperText = t('resource.create.vault.helperText', {
    /** @ts-ignore */
    resourceNameMinLength: RESOURCE_NAME_MIN_LENGTH.vault,
    resourceNameMaxLength: RESOURCE_NAME_MAX_LENGTH.vault,
  });
  const vaultNameValidation = (formik: any) => {
    return (
      (formik.values.vault_namespace.length < RESOURCE_NAME_MIN_LENGTH.vault &&
        vaultNameDirty) ||
      (formik.values.vault_namespace.length > RESOURCE_NAME_MAX_LENGTH.vault &&
        vaultNameDirty) ||
      (formik.values.vault_namespace.length > 0 &&
        formik.values.vault_namespace.startsWith('-')) ||
      (formik.values.vault_namespace.length > 0 &&
        formik.values.vault_namespace.startsWith('ns-')) ||
      (formik.values.vault_namespace.length > 0 &&
        !formik.values.vault_namespace.match('^[a-zA-Z0-9-]*$'))
    );
  };

  const submitDisable = (formik: any) => {
    return (
      formik.values.vault_namespace.length < RESOURCE_NAME_MIN_LENGTH.vault ||
      formik.values.vault_namespace.length > RESOURCE_NAME_MAX_LENGTH.vault ||
      formik.values.vaultEnvironments.length === 0 ||
      (formik.values.vault_namespace.length > 0 &&
        formik.values.vault_namespace.startsWith('-')) ||
      (formik.values.vault_namespace.length > 0 &&
        formik.values.vault_namespace.startsWith('ns-')) ||
      (formik.values.vault_namespace.length > 0 &&
        !formik.values.vault_namespace.match('^[a-zA-Z0-9-]*$')) ||
      apiLoader ||
      isDisabled
    );
  };

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={{
          vault_namespace: projectData?.key
            ? projectData?.key?.toLowerCase()
            : '',
          vaultEnvironments: [] as any,
        }}
        onSubmit={onSubmitCreateVault}
      >
        {formik => (
          <Form>
            <Paper className={classes.formContainer}>
              <Grid item xs={6} className={classes.selectedResourceItem}>
                <Typography
                  variant="subtitle2"
                  className={classes.selectedResourceText}
                >
                  {t('resource.create.vault.title')}
                </Typography>
                <InputLabel
                  htmlFor="vault_namespace"
                  className={classes.inputLabelText}
                >
                  {t('resource.view.vault.namespaceNameLabel')}*
                </InputLabel>
                <TextField
                  style={{ paddingBottom: '0%' }}
                  type="text"
                  id="vault_namespace"
                  name="vault_namespace"
                  required
                  error={vaultNameValidation(formik)}
                  onChange={formik.handleChange}
                  value={formik.values.vault_namespace}
                  onClick={onVaultNameClick}
                  fullWidth
                  helperText={helperText}
                />
                <br />
                <br />

                <InputLabel
                  htmlFor="vault_namespace"
                  className={classes.inputLabelText}
                >
                  {t('resource.view.vault.namespaceKeyLabel')}*
                </InputLabel>

                <FormHelperText className={classes.enviField}>
                  {t('resource.create.vault.selectEnvironmentPlaceholder')}
                </FormHelperText>
                {enviromentsData?.map((item: any) => {
                  return (
                    <>
                      <FormControlLabel
                        aria-label="Acknowledge"
                        label={item?.name}
                        control={
                          <Checkbox
                            onChange={(e: any) => {
                              const selectedResource = {
                                id: item.id,
                                environment: item?.key,
                              };
                              if (e.target.checked) {
                                formik.setFieldValue('vaultEnvironments', [
                                  ...formik.values.vaultEnvironments,
                                  selectedResource,
                                ]);
                              } else {
                                formik.setFieldValue(
                                  'vaultEnvironments',
                                  formik.values.vaultEnvironments.filter(
                                    (v: any) => v.id !== item.id,
                                  ),
                                );
                              }
                            }}
                          />
                        }
                      />
                    </>
                  );
                })}
              </Grid>
            </Paper>
            <Paper className={classes.btnContainer}>
              <Grid item xs={12} className={classes.btnItem}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      type="button"
                      id="cancel-vault"
                      variant="outlined"
                      onClick={handleCreateResource}
                      className={classes.newBtnOutlined}
                    >
                      {t('common.form.cancelLabel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      id="submit-vault"
                      disabled={submitDisable(formik)}
                      variant="contained"
                      className={classes.newBtnContained}
                    >
                      {t('common.form.submitLabel')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
