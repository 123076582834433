import { createTheme } from './createThemeUtil';
import { USGPalette } from './themeOverrides';

export const usgNewTheme = createTheme({
  palette: USGPalette,
  fontFamily: 'ToyotaType',
  defaultPageTheme: 'home',
  pageTheme: {
    home: {
      colors: ['#1059CD', '#2E78EF'],
      shape: '', // pageTheme returns colors, shape, backgroundImage and fontColor, code uses shape to create backgroundImage but as we are creating our own backgroundImage we are keeping it blank, check https://github.com/backstage/backstage/blob/1a1e6e9eb91fe8e08cc36b8c671b8536752a3c74/packages/theme/src/base/pageTheme.ts#L68
      backgroundImage: `linear-gradient(to left, rgb(145, 185, 250) -14%, rgba(0, 0, 0, 0.76) 78%), url("/assets/welcome_bg.webp")`,
      fontColor: '#ffffff',
    },
  },
});
