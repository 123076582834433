import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { projectApiRef, TGetProjectByIdParams } from '../api';

export default function useProject(
  projectId: string,
  params: TGetProjectByIdParams = { manipulators: [] },
) {
  const authRef = useApi(microsoftAuthApiRef);
  const projectApi = useApi(projectApiRef);
  const errorApi = useApi(errorApiRef);

  const {
    value: project,
    loading,
    error,
  } = useAsync(async (): Promise<any> => {
    const token = await authRef.getIdToken();

    try {
      return await projectApi.getProjectByID(projectId, token, params);
    } catch (err: any) {
      errorApi.post(new Error(`${err?.message}`));
      return {};
    }
  }, []);

  return {
    isFetchingProject: loading,
    project,
    error,
  };
}
