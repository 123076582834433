import {
  BackstageIdentityApi,
  ErrorApi,
  OAuthApi,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';
import { ProjectsApiInterface } from '../api';

type AuthRef = OAuthApi &
  OpenIdConnectApi &
  ProfileInfoApi &
  BackstageIdentityApi &
  SessionApi;

/**
 * Get total user count in a group by group ID and project ID.
 * @param {string} projectId - The project ID.
 * @param {string} groupName - The group name.
 * @returns {Promise<number>} - Total number of users in the group.
 */
export async function getTotalUserCountByGroupAndProject(
  projectApi: ProjectsApiInterface,
  authRef: AuthRef,
  errorApi: ErrorApi,
  projectId: string,
  groupName: string,
): Promise<number> {
  try {
    const idToken = await authRef.getIdToken();

    const pageSize = 20;
    const startIndex = 0;

    const getUserMemberListRes: any = await projectApi.getUserMemberList(
      projectId,
      groupName,
      idToken,
      pageSize,
      startIndex,
      'username',
      false,
    );

    if (getUserMemberListRes) {
      if (getUserMemberListRes.error) {
        // Ignore the Not Found error here, because the data is not yet ready on Noren, but already on Core. We could display 0
        if (getUserMemberListRes.error.status === 404) return 0;
        throw new Error(getUserMemberListRes.error.message);
      }

      return getUserMemberListRes.total_results;
    }

    return 0;
  } catch (err: any) {
    errorApi.post(new Error(`Error fetching members: ${err.message}`));
    return 0;
  }
}

/**
 * check of user has group permission sharing settigns
 * @param {any} currentUserGroup - user group
 * @param {boolean} isAdmin - is user admin
 * @param {boolean} isProjectOwner - is user group owner
 * @returns {boolean} does user has group permission sharing setting
 */
export function hasUserGroupPermissionSharingSettings(
  groupRestrictions: any,
  isAdmin: boolean,
  isProjectOwner: boolean,
): boolean {
  return groupRestrictions?.length > 0 && (isAdmin || isProjectOwner);
}
