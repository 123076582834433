import { paths, definitions } from './api';
import {
  ConfigApi,
  createApiRef,
  OAuthApi,
  OpenIdConnectApi,
} from '@backstage/core-plugin-api';
import { Fetcher } from 'openapi-typescript-fetch';

export type ConfigResponse = definitions['handlers.ConfigResponse'];
export type KubeconfigRequest = definitions['handlers.KubeconfigRequest'];
export type NamespaceRequest = definitions['handlers.NamespaceRequest'];
export type NamespaceResponse = definitions['handlers.NamespaceResponse'];
export type NamespaceAutocompletionRequest =
  definitions['handlers.NamespaceAutocompletionRequest'];
export type NamespaceAutocompletionResponse =
  definitions['handlers.NamespaceAutocompletionResponse'];
export type NamespacesResponse = definitions['handlers.NamespacesResponse'];
export type NamespacesRequest = definitions['handlers.NamespacesRequest'];
export type ApplicationResponse = definitions['handlers.ApplicationResponse'];
export type ApplicationRequest = definitions['handlers.ApplicationRequest'];
export type NamespaceClustersResponse = definitions['handlers.Cluster'][];

type MtfujiApiOptions = {
  configApi: ConfigApi;
  microsoftAuthApi: OAuthApi & OpenIdConnectApi;
};

export interface MtfujiApiInterface {
  getConfig(): Promise<ConfigResponse>;
  getKubeConfNonProd(): Promise<string>;
  getKubeConfProd(): Promise<string>;
  getNamespaces(): Promise<NamespacesResponse[]>;
  getClusters(): Promise<NamespaceClustersResponse>;
  createNamespace(
    namespaceRequest: NamespaceRequest,
  ): Promise<NamespaceResponse>;
  getNamespaceAutocompletions(
    projectId: number,
  ): Promise<NamespaceAutocompletionResponse>;
  getApplication(
    projectId: number,
    applicationName: string,
  ): Promise<ApplicationResponse>;
  createApplication(
    projectId: number,
    applicationRequest: ApplicationRequest,
  ): Promise<ApplicationResponse>;
  updateApplication(
    projectId: number,
    applicationName: string,
    applicationRequest: ApplicationRequest,
  ): Promise<ApplicationResponse>;
}

const fetcher = Fetcher.for<paths>();
const getConfig = fetcher.path('/config').method('get').create();
const getNamespaces = fetcher.path('/namespaces').method('put').create();
const getApplication = fetcher
  .path('/projects/{project_id}/applications/{application_name}')
  .method('get')
  .create();
const createApplication = fetcher
  .path('/projects/{project_id}/applications')
  .method('post')
  .create();
const updateApplication = fetcher
  .path('/projects/{project_id}/applications/{application_name}')
  .method('put')
  .create();
const getKubeconfig = fetcher.path('/kubeconfig/{env}').method('put').create();
const createNamespace = fetcher.path('/namespace').method('post').create();
const getClusters = fetcher.path('/clusters').method('get').create();
const getNamespaceAutocompletions = fetcher
  .path('/namespace/autocompletions')
  .method('put')
  .create();

export const mtfujiApiRef = createApiRef<MtfujiApi>({
  id: 'plugin.mtfuji.service',
});

export class MtfujiApi implements MtfujiApiInterface {
  private configApi: ConfigApi;
  private microsoftAuthApi: OAuthApi & OpenIdConnectApi;

  constructor(options: MtfujiApiOptions) {
    this.configApi = options.configApi;
    this.microsoftAuthApi = options.microsoftAuthApi;
  }

  private configure = async (): Promise<void> => {
    const apiUrl = this.configApi.getString('mtfuji.apiUrl');
    const token = await this.microsoftAuthApi.getIdToken();

    fetcher.configure({
      baseUrl: apiUrl,
      init: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  };

  getConfig = async (): Promise<ConfigResponse> => {
    await this.configure();
    const { data } = await getConfig({});
    return data;
  };

  getClusters = async () => {
    await this.configure();
    const { data } = await getClusters({});
    return data;
  };
  getNamespaces = async (): Promise<NamespacesResponse[]> => {
    await this.configure();
    const arg = {
      access_token: await this.microsoftAuthApi.getAccessToken(),
    } as NamespacesRequest;
    const { data } = await getNamespaces(arg);
    return data;
  };

  getKubeConfNonProd = async (): Promise<string> => {
    await this.configure();
    const { data } = await getKubeconfig({
      env: 'non-prod',
      access_token: await this.microsoftAuthApi.getAccessToken(),
    });
    return data;
  };

  getKubeConfProd = async (): Promise<string> => {
    await this.configure();
    const { data } = await getKubeconfig({
      env: 'prod',
      access_token: await this.microsoftAuthApi.getAccessToken(),
    });
    return data;
  };

  createNamespace = async (
    namespaceRequest: NamespaceRequest,
  ): Promise<NamespaceResponse> => {
    await this.configure();
    const { data } = await createNamespace(namespaceRequest);
    return data;
  };

  getNamespaceAutocompletions = async (
    stargateProjectId: number | null,
  ): Promise<NamespaceAutocompletionResponse> => {
    await this.configure();

    const arg = {
      access_token: await this.microsoftAuthApi.getAccessToken(),
    } as NamespaceAutocompletionRequest;

    if (stargateProjectId !== null) {
      arg.stargate_project_id = stargateProjectId;
    }

    const { data } = await getNamespaceAutocompletions(arg);
    return data;
  };

  getApplication = async (
    projectId: number,
    applicationName: string,
  ): Promise<ApplicationResponse> => {
    await this.configure();
    const { data } = await getApplication({
      project_id: projectId,
      application_name: applicationName,
    });
    return data;
  };

  createApplication = async (
    projectId: number,
    applicationRequest: ApplicationRequest,
  ): Promise<ApplicationResponse> => {
    await this.configure();
    const { data } = await createApplication({
      project_id: projectId,
      ...applicationRequest,
    });
    return data;
  };

  updateApplication = async (
    projectId: number,
    applicationName: string,
    applicationRequest: ApplicationRequest,
  ): Promise<ApplicationResponse> => {
    await this.configure();
    const { data } = await updateApplication({
      project_id: projectId,
      application_name: applicationName,
      ...applicationRequest,
    });
    return data;
  };
}
