import React from 'react';

import { usePermissions } from '../../hooks/usePermissions';
import {
  GROUP_ROLE_MANAGER,
  GROUP_ROLE_MEMBER,
  OWNER,
  RESOURCE_CREATOR,
} from 'usg-types';
import { useTranslation } from '../../hooks/useTranslation';

interface Props {
  children: React.ReactNode;
  projectId?: any;
  groupId?: any;
  resourceId?: any;
  devtoolId?: any;
  roles?: string[];
}
/* If you specify a certain role you will need to specify the Ids related to the role.
Group Manager  → groupId

Resource Manager → resourceId

Project Owner → projectId

Group Creator → projectId

Resource Creator → devtoolId and projectId */

export const ProtectedComponent = ({
  children,
  projectId,
  resourceId,
  groupId,
  devtoolId,
  roles,
}: Props) => {
  const { t } = useTranslation();
  const {
    isLoading,
    isAdmin,
    isGroupManager,
    isProjectOwner,
    isResourceCreator,
    isGroupMember,
  } = usePermissions(false);

  if (isLoading) {
    return <div>{t('common.loading')}</div>;
  }

  const hasRole = () => {
    return roles?.some((role: any) => {
      switch (role) {
        case GROUP_ROLE_MANAGER:
          return groupId !== undefined && isGroupManager(parseInt(groupId, 10));
        case RESOURCE_CREATOR:
          return (
            resourceId !== undefined &&
            devtoolId !== undefined &&
            isResourceCreator(parseInt(resourceId, 10), parseInt(devtoolId, 10))
          );
        case GROUP_ROLE_MEMBER:
          return groupId !== undefined && isGroupMember(parseInt(groupId, 10));
        case OWNER:
          return (
            projectId !== undefined && isProjectOwner(parseInt(projectId, 10))
          );
        default:
          return (
            projectId !== undefined && isProjectOwner(parseInt(projectId, 10))
          );
      }
    });
  };

  const hasRoleRes = hasRole();

  return (isAdmin || hasRoleRes) && <>{children}</>;
};
