import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { devToolSupportsDynamicGroup, isDynamicGroup } from 'sg-utils-frontend';
import { DEV_TOOLS_TAG_MAP, VAULT_DEV_TOOL_ID } from 'usg-types';

import { projectApiRef } from '../../../../api';
import { SortedUserGroupsArrayType } from '../types';

export type UserGroupEntity = {
  id: number;
  name: string;
  role: string;
};

export const EMPTY_USER_GROUP = {
  id: 0,
  name: '',
  role: '',
};

// we use environment id here and not resource id because this hook is especially for vault
export const useUserGroupsHook = (projectData: any, environmentId: string) => {
  const projectApi = useApi(projectApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);

  const [assignedUserGroups, setAssignedUserGroups] = useState<
    UserGroupEntity[] | undefined
  >(undefined);
  const [supportsDynamicGroupsFlag, setSupportsDynamicGroupFlag] = useState<
    boolean | undefined
  >(undefined);
  const [groupsWithExcludedTags, setGroupsWithExcludedTags] = useState<any>([]);

  const [resourceRoles, setResourceRoles] = useState([] as any);

  const getResourceRolesAndSupportDynamicGroupFlag = useCallback(async () => {
    const idToken = await authref.getIdToken();
    let devtoold: any = await projectApi.getDevelopmentToolsData(idToken);
    devtoold = devtoold.development_tools;
    // for now this hook is only for new vault UI
    devtoold = devtoold.find((dt: any) => dt.id === VAULT_DEV_TOOL_ID);
    setSupportsDynamicGroupFlag(devToolSupportsDynamicGroup(devtoold));
    setResourceRoles(devtoold?.roles ?? []);
  }, [authref, projectApi]);

  const refreshAssignedGroups = useCallback(async () => {
    try {
      const token = await authref.getIdToken();
      const res: any = await projectApi.getUserGroupsOfResource(
        projectData.id,
        environmentId,
        token,
        {},
      );

      let userGroupsData = res?.data.user_groups_roles;
      const groupIdToObjectMapping: any = {};
      const userGroupsArray = projectData.user_groups;
      userGroupsArray.forEach((group: any) => {
        if (!(group.id in groupIdToObjectMapping))
          groupIdToObjectMapping[group.id] = group;
      });

      userGroupsData = userGroupsData.map((group: any) => {
        const groupData = groupIdToObjectMapping[group.id];

        if (!groupData) {
          throw new Error(
            `Couldn't find group ${group.id} under project ${projectData.id}`,
          );
        }

        return {
          ...group,
          name: groupData.name,
          rule_based: groupData.rule_based,
          archived: groupData.deleted_on ? true : false,
        };
      });
      setAssignedUserGroups(userGroupsData);
    } catch (error) {
      errorApi.post(new Error(`${error?.message}`));
    }
  }, [authref, errorApi, projectApi, projectData, environmentId]);

  const availableUserGroups: SortedUserGroupsArrayType = useMemo(() => {
    const userGroupsArray = projectData.user_groups;
    let filteredUserGroup = userGroupsArray?.filter((ug: any) => {
      return (
        !ug.deleted_on &&
        !assignedUserGroups?.some((fug: any) => fug.id === ug.id)
      );
    });

    if (supportsDynamicGroupsFlag) {
      // TODO: tags: remove feature flag
      filteredUserGroup = filteredUserGroup?.filter((el: any) => {
        return groupsWithExcludedTags?.every((f: any) => {
          return f.entity_id !== el.id;
        });
      });
    } else {
      filteredUserGroup = filteredUserGroup?.filter(
        (ug: any) => !isDynamicGroup(ug),
      );
    }

    return filteredUserGroup;
  }, [
    projectData,
    supportsDynamicGroupsFlag,
    assignedUserGroups,
    groupsWithExcludedTags,
  ]);

  const getGroupsWithExcludedTags = useCallback(async () => {
    const { excluded, allowed } =
      DEV_TOOLS_TAG_MAP[VAULT_DEV_TOOL_ID] || DEV_TOOLS_TAG_MAP.default;

    // Get the source project id of attached usergroups
    const sourceProjectsList: [] =
      projectData.user_groups?.reduce((acc: any, ug: any) => {
        if (
          ug.sharing_properties.source_project_id !== Number(projectData.id) &&
          !acc.includes(ug.sharing_properties.source_project_id)
        ) {
          acc.push(ug.sharing_properties.source_project_id);
        }
        return acc;
      }, []) || [];

    const params = {
      project_id_filters: [...sourceProjectsList, Number(projectData.id)],
      entity_type_filters: ['USER_GROUP'],
      tag_filters: [
        {
          tag_key: 'platform-limit-tool',
          tag_values: excluded,
        },
      ],
      include_tags_in_response: true,
      size: 100,
    };

    let currentPage = 1;
    const excludedGroups: any[] = [];
    let hasNextPage = true;

    while (hasNextPage) {
      const res: any = await projectApi.getTagsOfEntity({
        ...params,
        page: currentPage,
      });
      if (res?.error) {
        errorApi.post(new Error(`${res?.error?.message}`));
        break;
      }

      const taggedEntities = res?.data.tagged_entities ?? [];
      excludedGroups.push(...taggedEntities);

      hasNextPage = !!res?.data?.next_page;
      currentPage++;
    }

    setGroupsWithExcludedTags(
      excludedGroups.filter(
        (tag: any) => !tag.tags['platform-limit-tool'].includes(allowed),
      ),
    );
  }, [errorApi, projectApi, projectData]);

  useEffect(() => {
    (async () => {
      try {
        await getGroupsWithExcludedTags();
      } catch (err) {
        errorApi.post(new Error(`${err?.message}`));
      }
    })();
  }, [getGroupsWithExcludedTags, errorApi]);

  useEffect(() => {
    getResourceRolesAndSupportDynamicGroupFlag();
    refreshAssignedGroups();
  }, [getResourceRolesAndSupportDynamicGroupFlag, refreshAssignedGroups]);

  return {
    refreshAssignedGroups,
    assignedUserGroups,
    availableUserGroups,
    resourceRoles,
  };
};
