import React, { useEffect, useMemo, useRef, useState, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Preview } from './Preview';
import { useAsyncFn } from 'react-use';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { PageTitleBreadcrumbs } from '../../../ViewEditResources/PageTitleBreadcrumbs';
import {
  configApiRef,
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import PendingIcon from '@mui/icons-material/Pending';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  GridSize,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, Skeleton } from '@material-ui/lab';
import { Progress, CodeSnippet, LinkButton } from '@backstage/core-components';
import { definitions } from '../api';
import { mtfujiApiRef } from '../fetcher';
import './Styles.css';
import { ApplicationName, generateRandomName } from './ApplicationName';
import { GithubRepository } from './GithubRepository';
import { ArtifactoryRepository } from './ArtifactoryRepository';
import { ActiveClusters } from './Clusters';
import { Pending } from './Pending';
import {
  getArtifactoryRegistryFQDN,
  getRepo,
  isEmptyOrNil,
  normalizeName,
  valueForVarNameAndEnv,
} from './FormHelpers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { ProjectSelector } from './ProjectSelector';
import { projectApiRef } from '@internal/plugin-projects';
import {
  ADMIN,
  ARTIFACTORY_DEV_TOOL_ID,
  ARTIFACTORY_SAAS_DEV_TOOL_ID,
  GITHUB_DEV_TOOL_ID,
  GITHUBEMU_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  OWNER,
} from 'usg-types';
import { getRoles } from '@internal/sg-utils-common';
import { isEmpty, isNil, isEqual, isObject } from 'lodash';
import {
  AAD_CACHE_PREFIX,
  ARTIFACTORY_NON_PROD_HOST,
  ARTIFACTORY_PROD_HOST,
  ARTIFACTORY_SAAS_NAME,
  ARTIFACTORY_SAAS_NON_PROD_HOST,
  ARTIFACTORY_SAAS_PROD_HOST,
  GITHUB_HOST,
  PRODUCTION_ENVIRONMENTS,
  SLACK_CHANNEL_URL,
} from './Constants';
import retry from 'async-retry';
import EnvironmentConfiguration, {
  errorMessagesForAppEnvs,
} from './EnvironmentConfiguration';
import EnvironmentConfigurationEdit from './EnvironmentConfigurationEdit';
import {
  AADGroup,
  ApiConfigClusterType,
  AppEnvs,
  ApplicationEnv,
  ApplicationGitHubRepository,
  ApplicationRequest,
  ApplicationRequestProps,
  ArtifactoryRegistry,
  GroupRoleType,
  KeyValue,
} from './types';
import {
  getDefaultReadOnlyGroupName,
  getDefaultReadWriteGroupName,
} from '../CreateUserGroupDialog/CreateUserGroupDialog';
import BottomNavigation from '@mui/material/BottomNavigation';
import { FormLayout } from '../Layout/Layout';
import { ProjectDetails } from '../../../ViewEditResources/ViewEditResourcePage/types';
import { useStyles } from './styles';

interface UserGroupRole {
  id: number;
  role: 'read-write' | 'read-only';
}

interface Group {
  name: string;
  uuid: string;
}

export type OverviewItemGroup = {
  name: string;
  size?: GridSize;
  renderItems?: (index: number) => OverviewItem[];
  items?: OverviewItem[];
  actionsHidden?: boolean;
};

export type OverviewItem = {
  name?: string;
  values?: Array<string>;
  valueComponent?: ReactNode;
  emptyDescription?: string;
  hasErrors: boolean;
  description?: string;
  editComponent: ReactNode;
};

type Operation = {
  op: 'remove' | 'add';
  value: { id: number; role: string }[];
};

function removeDuplicates(operations: any) {
  const idMap = new Map();

  for (const op of operations) {
    const { value } = op;
    for (const entry of value) {
      const { id, role } = entry;

      if (!idMap.has(id)) {
        idMap.set(id, { op, entry });
      } else {
        const existing = idMap.get(id);
        if (existing.entry.role !== role) {
          if (existing.entry.role === 'read') {
            idMap.set(id, { op, entry });
          }
        }
      }
    }
  }

  return Array.from(idMap.values()).map(item => item.op);
}

function hasObjectChanged(
  oldObj: Record<string, any>,
  newObj: Record<string, any>,
): boolean {
  function removeIgnoredKeys(obj: Record<string, any>): Record<string, any> {
    const keysToIgnore = [
      'aad_developer_group_id',
      'aad_readonly_group_id',
      'aad_readonly_groups',
      'aad_readwrite_groups',
    ];
    if (!isObject(obj)) return obj;

    const freshObj = { ...obj };
    keysToIgnore.forEach(key => delete freshObj[key]);

    // Remove empty strings, empty arrays, and empty objects
    Object.keys(freshObj).forEach(key => {
      if (
        freshObj[key] === '' ||
        (Array.isArray(freshObj[key]) && freshObj[key].length === 0) ||
        (typeof freshObj[key] === 'object' &&
          Object.keys(freshObj[key]).length === 0)
      ) {
        delete freshObj[key];
      }
    });

    return freshObj;
  }

  function sanitizeObject(obj: Record<string, any>): Record<string, any> {
    if (!isObject(obj)) return obj;
    /* No need to check for these keys: app_name, refUrl, sha,stargate_project_id, stargate_resource_key, status */

    const allowedKeys = [
      'active_clusters',
      'artifactory_registries',
      'configuration',
      'configuration_base_key',
      'github_repositories',
    ];
    const sanitizedObj: Record<string, any> = {};

    allowedKeys.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        sanitizedObj[key] = obj[key];
      }
    });

    if (sanitizedObj.configuration) {
      sanitizedObj.configuration = Object.fromEntries(
        Object.entries(sanitizedObj.configuration).map(([key, value]) => [
          key,
          removeIgnoredKeys(value as Record<string, any>),
        ]),
      );
    }

    return sanitizedObj;
  }

  const sanitizedOldObj = sanitizeObject(oldObj);
  const sanitizedNewObj = sanitizeObject(newObj);
  /* In active_clusters sorting based on id so that obj in array are in same order before comparision */
  sanitizedOldObj.active_clusters.sort((a: { id: string }, b: { id: any }) =>
    a.id.localeCompare(b.id),
  );
  sanitizedNewObj.active_clusters.sort((a: { id: string }, b: { id: any }) =>
    a.id.localeCompare(b.id),
  );
  return !isEqual(sanitizedOldObj, sanitizedNewObj);
}

function filterRemoveOps(operations: Operation[]): Operation[] {
  // Step 1: Collect all IDs present in "add" operations
  const addedIds = new Set<number>();
  for (const op of operations) {
    if (op.op === 'add') {
      for (const entry of op.value) {
        addedIds.add(entry.id);
      }
    }
  }

  // Step 2: Filter out "remove" operations if their value contains an ID present in "add"
  return operations.filter(
    op => op.op !== 'remove' || !op.value.some(entry => addedIds.has(entry.id)),
  );
}

function getLabelAndProvider(
  oidc_issuer: string,
  region: string,
  workload_prefix: string,
) {
  let prefix = workload_prefix === 'gc' ? 'General Compute' : '';
  prefix = workload_prefix === 'ml' ? 'Machine Learning' : prefix;
  if (oidc_issuer.includes('amazonaws') && oidc_issuer.includes('eks')) {
    return {
      label: `${prefix} - EKS - AWS ${region}`,
      provider: 'aws',
    };
  } else if (oidc_issuer.includes('googleapis')) {
    return {
      label: `${prefix} - GKE - GCP ${region}`,
      provider: 'gcp',
    };
  }
  return {};
}

const CICD_SECTION = 1;
const USERGROUP_SECTION = 2;
// Define resource manager here since it is not available in usg-types yet
const RESOURCE_MANAGER = 'resource_manager';

type NamespaceRequestArtifactoryRepositoryType =
  definitions['handlers.NamespaceRequestArtifactoryRepository'];
type NamespaceAutocompletionResponseProjectType =
  definitions['handlers.NamespaceAutocompletionResponseProject'];
type ApplicationResponse = definitions['handlers.ApplicationResponse'];
type Cluster = definitions['handlers.ActiveCluster'];

type ActiveCategory = { index: number; props?: KeyValue };

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

// Form default value query param names
const appNameParam = 'name';
const githubHostParam = 'gh_h';
const githubOwnerParam = 'gh_o';
const githubRepoParam = 'gh_r';
const artifactoryHostParam = 'rt_h';
const artifactoryRepoParam = 'rt_r';
const clustersParam = 'cl';
const developerGroupsParam = 'dg';
const readonlyGroupsParam = 'rg';

// props schema
export type WizardProps = {
  isNew?: boolean;
  stargateProjectId?: number;
  projectName?: string;
  projectId?: string;
  viewMode?: boolean;
  projectData?: ProjectDetails;
};

function canEditResource(
  userData: any,
  projectId: number,
  resourceName: string,
): boolean {
  const userRoles = getRoles(userData?.roles);
  const projectDetails: any = userData?.projects.find(
    (p: any) => p.id === projectId,
  );
  return (
    projectDetails?.is_owner ||
    (userRoles && (userRoles?.includes(ADMIN) || userRoles?.includes(OWNER))) ||
    projectDetails?.resources?.some(
      (resource: any) =>
        resource?.dev_tool_id === MTFUJI_DEV_TOOL_ID &&
        resource?.name === resourceName &&
        resource?.resource_roles?.some(
          (role: string) => role === RESOURCE_MANAGER,
        ),
    )
  );
}

const cleanFromArtifactorySelfHosted = (
  input: ApplicationResponse,
): ApplicationResponse => {
  input.artifactory_registries = input.artifactory_registries.filter(
    a => a.dev_tool_id !== ARTIFACTORY_DEV_TOOL_ID,
  );
  return input;
};

export const Wizard = (props: WizardProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const mtfujiApi = useApi(mtfujiApiRef);
  const projectApi = useApi(projectApiRef);
  const authApi = useApi(microsoftAuthApiRef);
  const configApi = useApi(configApiRef);
  const errorApi = useApi(errorApiRef);
  const mtfujiApiUrl = configApi.getOptionalString('mtfuji.apiUrl');
  const overviewRef = useRef<null | HTMLDivElement>(null);
  const pendingRef = useRef<null | HTMLDivElement>(null);
  const [activeCategory, setActiveCategory] = useState<ActiveCategory>({
    index: -1,
  });
  const [appResIntialDataDuringUpdate, setAppResIntialDataDuringUpdate] =
    useState<ApplicationResponse>({} as ApplicationResponse);
  const [smcView, setSmcView] = React.useState(!!props.viewMode);
  const [envEditOpen, setEnvEditOpen] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [isAppNameDefault, setIsAppNameDefault] = useState<boolean>(true);
  const [maybeNewGithub, setMaybeNewGithub] = useState<boolean>(false);
  const [applicationRequestDestroy, setApplicationRequestDestroy] =
    useState<boolean>(false);
  const [applicationRequestSha, setApplicationRequestSha] =
    useState<string>('');
  const [
    applicationRequestConfigurationBaseKey,
    setApplicationRequestConfigurationBaseKey,
  ] = useState<string>('prod');
  const [getAppProgress, setGetAppProgress] = React.useState(0);
  const [confirmProjectSelection, setConfirmProjectSelection] =
    useState<NamespaceAutocompletionResponseProjectType>();
  const [attachResourceUserGroups, setAttachResourceUserGroups] =
    useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const isPrevPageNewPage = searchParams.get('prevNew');

  let { sgProjectId, applicationName } = useParams() as {
    sgProjectId: string;
    applicationName: string;
  };

  const { resourceId } = useParams() as {
    resourceId: string;
  };

  if (
    resourceId &&
    props.projectData &&
    props.projectData.resources[0]?.id?.toString() === resourceId
  ) {
    applicationName =
      applicationName || (resourceId && props.projectData.resources[0].key);
  }

  sgProjectId = sgProjectId || props.projectId || '';

  const Operations = {
    Create: 'create',
    Update: 'update',
  };
  const operation: string = isEmptyOrNil(applicationName)
    ? Operations.Create
    : Operations.Update;

  // This state is an object of form {"config_name": boolean} which stores each configuration validity
  // we need this because formState cannot capture the validation of each control after it has
  // been un-rendered from DOM. We pass the setter to each component and make them responsible for
  // setting their own validation. Using the isConfigurationValid func, we can now verify the validity.
  const [configurationValidation, setConfigurationValidation] = React.useState(
    new Map(operation === Operations.Create ? [['app_envs', false]] : []),
  );

  const [currentStargateProjectId, setCurrentStargateProjectId] =
    useState<number>(
      (!isEmpty(sgProjectId)
        ? parseInt(sgProjectId, 10)
        : props.stargateProjectId) || 0,
    );

  const [projectOptions, setProjectOptions] = useState<
    NamespaceAutocompletionResponseProjectType[]
  >([]);

  const query = useQuery();

  const getDefaultArtifactoryHost = (flavor: string) => {
    const isSaasFlavor = flavor === ARTIFACTORY_SAAS_NAME;

    if (mtfujiApiUrl?.includes('mtfuji-api-prod')) {
      return isSaasFlavor ? ARTIFACTORY_SAAS_PROD_HOST : ARTIFACTORY_PROD_HOST;
    }

    return isSaasFlavor
      ? ARTIFACTORY_SAAS_NON_PROD_HOST
      : ARTIFACTORY_NON_PROD_HOST;
  };

  const defaultValues: ApplicationRequestProps = {
    app_name: (query.get(appNameParam) as string) || '',
    github_repository: {
      host: (query.get(githubHostParam) as string) || GITHUB_HOST,
      owner: (query.get(githubOwnerParam) as string) || '',
      name: (query.get(githubRepoParam) as string) || '',
    } as ApplicationGitHubRepository,
    artifactory_registries: [
      {
        key: (query.get(artifactoryRepoParam) as string) || '',
        name: '',
        host:
          (query.get(artifactoryHostParam) as string) ||
          getDefaultArtifactoryHost(ARTIFACTORY_SAAS_NAME),
        dev_tool_id: ARTIFACTORY_SAAS_DEV_TOOL_ID,
      },
    ],
    active_clusters: [],
    app_envs: {
      prod: {} as ApplicationEnv,
      stage: {} as ApplicationEnv,
      dev: {} as ApplicationEnv,
    },
  };

  const queryClusterIDs = query.get(clustersParam)?.split(',') as string[];
  const queryReadWriteGroups = query
    .get(developerGroupsParam)
    ?.split(',') as string[];
  const queryReadOnlyGroups = query
    .get(readonlyGroupsParam)
    ?.split(',') as string[];

  const methods = useForm<ApplicationRequestProps>({
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const [autocompletionsLoading, setAutocompletionsLoading] =
    useState<boolean>(true);
  const [formError, setFormError] = useState<Error>();
  const [clusterOptions, setClusterOptions] = useState<ApiConfigClusterType[]>(
    [],
  );
  const [repositoryOptions, setRepositoryOptions] = useState<
    ApplicationGitHubRepository[]
  >([]);
  const [groupOptions, setGroupOptions] = useState<AADGroup[]>([]);
  const [artifactoryRepositoryOptions, setArtifactoryRepositoryOptions] =
    useState<NamespaceRequestArtifactoryRepositoryType[]>([]);
  const [currentStargateProject, setCurrentStargateProject] =
    useState<NamespaceAutocompletionResponseProjectType>(
      {} as NamespaceAutocompletionResponseProjectType,
    );
  const [applicationResponse, setApplicationResponse] =
    useState<ApplicationResponse>({} as ApplicationResponse);
  const [applicationResponseLoading, setApplicationResponseLoading] =
    useState<boolean>(false);
  const [applicationResponseError, setApplicationResponseError] = useState<
    Error | undefined
  >(undefined);
  const [pendingChanges, setPendingChanges] = useState<boolean>(false);
  const [enableEditableButton, setEnableEditableButton] =
    useState<boolean>(true);
  const [showEditUI, setShowEditUI] = useState<boolean>(
    operation === Operations.Create,
  );
  const [raiseDestroyConfirmationDialog, setRaiseDestroyConfirmationDialog] =
    useState<boolean>(false);
  const [deletionsList, setDeletionsList] = useState<string[]>([]);
  const [enableDestroyButton, setEnableDestroyButton] =
    useState<boolean>(false);
  const [selectedGithubResourceID, setSelectedGithubResourceID] =
    useState<number>(0);
  const [selectedArtifactoryResourceIDs, setSelectedArtifactoryResourceIDs] =
    useState<number[]>([]);
  const [selectedROUserGroupResourceIDs, setSelectedROUserGroupResourceIDs] =
    useState<number[]>([]);
  const [selectedRWUserGroupResourceIDs, setSelectedRWUserGroupResourceIDs] =
    useState<number[]>([]);

  const [projResData, setProjResData] = useState<any[]>([]);
  const getSGResources = async (): Promise<
    [ArtifactoryRegistry[], ApplicationGitHubRepository[], AADGroup[], {}]
  > => {
    const idToken = await authApi.getIdToken();
    const params = {
      manipulators: [],
    };
    const ghResources: ApplicationGitHubRepository[] = [];
    const artResources: ArtifactoryRegistry[] = [];
    const userGroups: AADGroup[] = [];

    try {
      const res = await projectApi.getProjectByID(
        currentStargateProjectId.toString(),
        idToken,
        params,
      );
      res.resources.forEach((resource: any) => {
        // do not load deleted/archived resources
        if (!isEmptyOrNil(resource.deleted_on)) {
          return;
        }
        switch (resource.dev_tool_id) {
          case GITHUB_DEV_TOOL_ID:
          case GITHUBEMU_DEV_TOOL_ID:
            ghResources.push({
              dev_tool_id: resource.dev_tool_id,
              host: new URL(resource.url).host,
              key: resource.key,
              name: resource.name,
              owner: resource.key,
            });
            break;
          case ARTIFACTORY_SAAS_DEV_TOOL_ID:
            artResources.push({
              dev_tool_id: resource.dev_tool_id,
              host: new URL(resource.url).host,
              key: resource.key,
              name: resource.name,
            });
            break;
          default:
            // not interested in any other resources
            // default case required for the linter
            break;
        }
      });
      res?.user_groups.forEach(
        (ug: { id: Number; name: String; deleted_on: String }) => {
          // do not load deleted/archived user groups
          if (!isEmptyOrNil(ug.deleted_on)) {
            return;
          }
          userGroups.push({
            uuid: ug.id.toString(),
            name: ug.name,
          } as AADGroup);
        },
      );

      if (operation === Operations.Update) {
        try {
          const userRes: any = await projectApi.getUserDetails(
            { idToken: idToken },
            { include: 'all' },
          );
          if (userRes.response?.status !== 200) {
            throw new Error('failed to get user details');
          }
          setShowEditUI(
            canEditResource(
              userRes.response.data,
              currentStargateProjectId,
              res.key,
            ),
          );
        } catch (error) {
          // if there's some error disable edit but allow user to see details
          setShowEditUI(false);
          // TODO: show error so user knows to reload if they need to edit
        }
      }
      setProjResData(res?.resources);
      return [artResources, ghResources, userGroups, res];
    } catch (err: any) {
      throw Error(
        `Error getting Github and Artifactory resources: ${err?.response}`,
      );
    }
  };

  const [{ value: _ = {} as any }, fetchProjectData] = useAsyncFn(
    async (resourceIdParam, isCombined = false) => {
      let projectDataRes;
      try {
        const idToken = await authApi.getIdToken();

        const params = {
          manipulators: [
            'resources',
            'resources_duplicates',
            `resource_${resourceIdParam}`,
          ],
        };
        if (isCombined) {
          params.manipulators.push('is_combined');
        }
        projectDataRes = await projectApi.getProjectByID(
          currentStargateProjectId?.toString(),
          idToken,
          params,
        );
      } catch (err: any) {
        errorApi.post(new Error(err?.message));
      }
      return projectDataRes;
    },
    [currentStargateProjectId],
  );

  const getGroupsAttachedToResourcesAndMapThem = async (
    mainResourceId: any,
    appResp: any,
  ) => {
    let projDataRes;

    try {
      projDataRes = await fetchProjectData(mainResourceId);

      if (projDataRes && projDataRes.error) {
        errorApi.post(new Error(projDataRes.error?.message));
      }
    } catch (err) {
      errorApi.post(new Error(err?.message));
    }
    const linkedResources = projDataRes?.resources[0]?.linkedResources || [];
    const mainUserGroupsData = projDataRes?.user_groups;

    const token = await authApi.getIdToken();
    for (let i = 0; i < linkedResources.length; i++) {
      try {
        const res: any = await projectApi.getUserGroupsOfResource(
          currentStargateProjectId.toString(),
          linkedResources[i].id.toString(),
          token,
          {},
        );
        if (res?.status !== 200) {
          throw Error(
            `Error fetching groups attached to environment: ${res?.data.message}`,
          );
        } else {
          const userGroups = res.data.user_groups_roles;

          const aad_readwrite_groups: Group[] = [];
          const aad_readonly_groups: Group[] = [];

          userGroups.forEach(({ id, role }: UserGroupRole) => {
            const foundItem = mainUserGroupsData.find(
              (item: any) => item.id === id,
            );

            const groupObj: Group = {
              name: foundItem ? foundItem.name : '',
              uuid: id.toString(),
            };

            if (role === 'read-write') {
              aad_readwrite_groups.push(groupObj);
            } else if (role === 'read-only') {
              aad_readonly_groups.push(groupObj);
            }
          });
          let keyName: string = (
            linkedResources[i].environment || ''
          ).toString();

          if (
            keyName === 'prod' &&
            appResp.configuration[keyName] === undefined
          ) {
            keyName =
              PRODUCTION_ENVIRONMENTS.find(
                key => key in appResp.configuration,
              ) || keyName;
          }

          if (aad_readwrite_groups.length > 0) {
            appResp.configuration[keyName].aad_readwrite_groups =
              aad_readwrite_groups;
          }
          if (aad_readonly_groups.length > 0) {
            appResp.configuration[keyName].aad_readonly_groups =
              aad_readonly_groups;
          }
        }
      } catch (err) {
        errorApi.post(new Error(err?.message));
      }
    }
    setAppResIntialDataDuringUpdate(appResp);
    return appResp;
  };

  function findRemovedGroups(
    oldData: any,
    newReadWriteGroups: Group[],
    newReadOnlyGroups: Group[],
  ): any[] {
    const removedOperations: any = [];

    // Extract UUIDs from the new groups
    const newReadWriteUUIDs = new Set(
      newReadWriteGroups.map(group => group.uuid),
    );
    const newReadOnlyUUIDs = new Set(
      newReadOnlyGroups.map(group => group.uuid),
    );

    // Find uuids that are in old data but NOT in new aad_readwrite_groups
    oldData?.aad_readwrite_groups?.forEach((group: { uuid: string }) => {
      if (!newReadWriteUUIDs.has(group.uuid)) {
        removedOperations.push({
          op: 'remove',
          value: [{ id: Number(group.uuid), role: 'read-write' }],
        });
      }
    });

    // Find uuids that are in old data but NOT in new aad_readonly_groups
    oldData?.aad_readonly_groups?.forEach((group: { uuid: string }) => {
      if (!newReadOnlyUUIDs.has(group.uuid)) {
        removedOperations.push({
          op: 'remove',
          value: [{ id: Number(group.uuid), role: 'read-only' }],
        });
      }
    });

    return removedOperations;
  }

  const attachGroupsToResources = async (
    linkedResources: any,
    appDataSubmitted: any,
    isUpdate: boolean = false,
  ) => {
    const idToken = await authApi.getIdToken();

    for (let i = 0; i < linkedResources.length; i++) {
      let oldEnvData = isUpdate
        ? appResIntialDataDuringUpdate.configuration[
            linkedResources[i].environment
          ]
        : null;

      if (
        linkedResources[i].environment === 'prod' &&
        isUpdate &&
        appResIntialDataDuringUpdate.configuration[
          linkedResources[i].environment
        ] === undefined
      ) {
        const actualKey =
          PRODUCTION_ENVIRONMENTS.find(
            key => key in appResIntialDataDuringUpdate.configuration,
          ) || 'prod';

        oldEnvData = isUpdate
          ? appResIntialDataDuringUpdate.configuration[actualKey]
          : null;
      }

      let currentEnvData =
        appDataSubmitted.configuration[linkedResources[i].environment];

      if (
        linkedResources[i].environment === 'prod' &&
        appDataSubmitted.configuration[linkedResources[i].environment] ===
          undefined
      ) {
        const actualKey =
          PRODUCTION_ENVIRONMENTS.find(
            key => key in appDataSubmitted.configuration,
          ) || 'prod';
        currentEnvData = appDataSubmitted.configuration[actualKey];
      }

      if (currentEnvData !== undefined) {
        const aad_readwrite_groups =
          (currentEnvData.aad_readwrite_groups !== undefined
            ? currentEnvData.aad_readwrite_groups
            : []) || [];
        const aad_readonly_groups =
          (currentEnvData.aad_readonly_groups !== undefined
            ? currentEnvData.aad_readonly_groups
            : []) || [];

        const removedArr = findRemovedGroups(
          oldEnvData,
          aad_readwrite_groups,
          aad_readonly_groups,
        );
        let userGroupRoles = [];
        for (let j = 0; j < aad_readwrite_groups.length; j++) {
          userGroupRoles.push({
            op: 'add',
            value: [
              { id: Number(aad_readwrite_groups[j].uuid), role: 'read-write' },
            ],
          });
        }
        for (let j = 0; j < aad_readonly_groups.length; j++) {
          userGroupRoles.push({
            op: 'add',
            value: [
              { id: Number(aad_readonly_groups[j].uuid), role: 'read-only' },
            ],
          });
        }
        userGroupRoles = [...removedArr, ...userGroupRoles];
        if (userGroupRoles.length) {
          userGroupRoles = filterRemoveOps(userGroupRoles);
        }
        const rtParamsData = {
          idToken: idToken,
          userGroupRoles: userGroupRoles,
        };
        if (userGroupRoles.length) {
          try {
            const rtres = await projectApi.assignUserGroupToResources(
              rtParamsData,
              currentStargateProjectId.toString(),
              linkedResources[i].id.toString(),
            );

            if (rtres?.status !== 200) {
              throw Error(
                `Error assigning groups to environments: ${rtres?.data.message}`,
              );
            }
          } catch (err) {
            errorApi.post(new Error(err?.message));
          }
        }
      }
    }
  };

  const attachResources = async () => {
    const idToken = await authApi.getIdToken();
    const ghOwner = methods.getValues('github_repository.owner');

    // prepare groups with corresponding roles
    const roGroups = selectedROUserGroupResourceIDs.reduce(
      (a, g) => {
        a.artifactory.push({
          op: 'add',
          value: [{ id: g, role: 'read' }],
        });
        a.github.push({
          op: 'add',
          value: [{ id: g, role: '', name: ghOwner }],
        });
        return a;
      },
      { artifactory: [], github: [] } as {
        artifactory: GroupRoleType[];
        github: GroupRoleType[];
      },
    );

    const rwGroups = selectedRWUserGroupResourceIDs.reduce(
      (a, g) => {
        a.artifactory.push({
          op: 'add',
          value: [{ id: g, role: 'delete' }],
        });
        a.github.push({
          op: 'add',
          value: [{ id: g, role: '', name: ghOwner }],
        });
        return a;
      },
      { artifactory: [], github: [] } as {
        artifactory: GroupRoleType[];
        github: GroupRoleType[];
      },
    );

    // Attach groups to artifactory
    const totalGrpsForArtifactory = [
      ...roGroups.artifactory,
      ...rwGroups.artifactory,
    ];
    const consolidatedGrpsForArtifactory = removeDuplicates(
      totalGrpsForArtifactory,
    );
    const rtParamsData = {
      idToken: idToken,
      userGroupRoles: consolidatedGrpsForArtifactory,
    };

    for (const id of selectedArtifactoryResourceIDs) {
      if (id) {
        const rtres = await projectApi.assignUserGroupToResources(
          rtParamsData,
          currentStargateProjectId.toString(),
          id.toString(),
        );

        if (rtres?.status !== 200) {
          throw Error(
            `Error assigning groups for Artifactory: ${rtres?.data.message}`,
          );
        }
      }
    }
    if (selectedGithubResourceID) {
      // Attach groups to github org

      const totalGrpsForGithub = [...roGroups.github, ...rwGroups.github];
      const consolidatedGrpsForGithub = removeDuplicates(totalGrpsForGithub);

      const ghParamsData = {
        idToken: idToken,
        userGroupRoles: consolidatedGrpsForGithub,
      };

      const ghres = await projectApi.assignUserGroupToResources(
        ghParamsData,
        currentStargateProjectId.toString(),
        selectedGithubResourceID.toString(),
      );
      if (ghres?.status !== 200) {
        throw Error(
          `Error assigning groups for Github Organization: ${ghres?.data.message}`,
        );
      }
    }
  };

  const generateApplicationRequest = (): ApplicationRequest => {
    const clusters = methods.getValues('active_clusters').flatMap(c => {
      const [prefix, index] = c.name_prefix.split('-');
      return ['apps', 'apps-prod'].map(
        env =>
          ({
            id: `${prefix}-${index}-${env}-${c.region}`,
            infra_env: env,
            pair_index: parseInt(index, 10),
            region: c.region,
            workload_prefix: prefix,
          } as Cluster),
      );
    });

    const githubRepo = methods.getValues('github_repository');
    githubRepo.dev_tool_id =
      githubRepo.host === GITHUB_HOST
        ? GITHUBEMU_DEV_TOOL_ID
        : GITHUB_DEV_TOOL_ID;
    githubRepo.key = githubRepo.owner;

    const artifactoryRegistry = methods
      .getValues('artifactory_registries')
      .filter(o => !isEmptyOrNil(o.key));

    const appEnvs = methods.getValues('app_envs');
    Object.entries(appEnvs).forEach(([envName, envValues]) => {
      Object.keys(envValues).forEach(envProp => {
        switch (envProp) {
          case 'aad_readonly_groups':
          case 'aad_readwrite_groups':
            appEnvs[envName][envProp] = appEnvs[envName][envProp].map(
              (g: { name: any; uuid: any }) => ({
                name: g.name,
                uuid: g.uuid,
              }),
            );
            break;
          default:
            break;
        }
      });
    });

    const prodEnvName = Object.getOwnPropertyNames(appEnvs).find(envName =>
      PRODUCTION_ENVIRONMENTS.includes(envName),
    ) as 'production' | 'prod' | 'prd' | 'apps-prod';

    return {
      app_name: methods.getValues('app_name'),
      active_clusters: clusters,
      artifactory_registries: artifactoryRegistry,
      configuration_base_key: prodEnvName,
      configuration: appEnvs,
      github_repositories: [githubRepo],
      stargate_project_id: currentStargateProjectId,
      stargate_resource_key: methods.getValues('app_name'),
      destroy: applicationRequestDestroy,
      preview: true,
      sha: applicationRequestSha,
    };
  };

  const sendRequest = async (
    preview: boolean = true,
    destroy: boolean = false,
    callback?: any,
  ) => {
    const appReq = generateApplicationRequest();
    appReq.preview = preview;
    appReq.destroy = destroy;

    if (isAppNameDefault) setMaybeNewGithub(true);
    if (!preview) setFormSubmitted(true);
    setEnableEditableButton(false);
    if (!appReq.preview) {
      setApplicationResponseLoading(true);
    }
    setApplicationResponseError(undefined);
    let appResp: any = {};
    try {
      const idToken = await authApi.getIdToken();
      appReq.idToken = idToken;
      if (operation === Operations.Update) {
        if (!appReq.preview) {
          const needToCallSMCUpdate = hasObjectChanged(
            appResIntialDataDuringUpdate,
            appReq,
          );

          if (needToCallSMCUpdate) {
            appResp = await projectApi.updateProjectResourceSMC(
              currentStargateProjectId.toString(),
              resourceId.toString(),
              appReq,
            );
            if (appResp && appResp.error) {
              throw new Error(appResp.error.message);
            }
          }
          const projDataRes = await fetchProjectData(resourceId.toString());

          if (projDataRes && projDataRes.error) {
            errorApi.post(new Error(projDataRes.error?.message));
          } else {
            await attachGroupsToResources(
              projDataRes.resources[0]?.linkedResources || [],
              appReq,
              true,
            );
          }

          if (!preview && attachResourceUserGroups) {
            await attachResources();
          }
          const url = new URL(window.location.href);
          url.searchParams.set('redirect', url.pathname);

          navigate(`/projects/smc/redirect${url.search}`, { replace: true });
          return;
        }
      } else {
        if (!appReq.preview) {
          appResp = await projectApi.createNewProjectResourceSMC(
            currentStargateProjectId.toString(),
            appReq,
          );
          if (appResp && appResp.error) {
            throw new Error(appResp.error.message);
          }

          const projDataRes = await fetchProjectData(appResp.id);

          if (projDataRes && projDataRes.error) {
            errorApi.post(new Error(projDataRes.error?.message));
          } else {
            await attachGroupsToResources(
              projDataRes.resources[0]?.linkedResources || [],
              appReq,
              false,
            );
          }

          if (!preview && attachResourceUserGroups) {
            await attachResources();
          }
          setApplicationResponse(
            cleanFromArtifactorySelfHosted(appResp.config),
          );
        }
      }
      setApplicationResponseLoading(false);
      if (preview) {
        setEnableEditableButton(true);
        setFormSubmitted(false);
      }
      if (callback !== undefined)
        callback(appResp.config?.stargate_project_id, appResp.id);
    } catch (error: any) {
      setApplicationResponseError(error as Error);
      setFormSubmitted(false);
      setApplicationResponseLoading(false);
      setEnableEditableButton(true);
      if (preview) {
        setFormSubmitted(false);
      }
    }
  };

  const isConfigurationValid = () => {
    const values = Array.from(configurationValidation.values());
    return values.every(Boolean) && values.length > 0;
  };

  const handlePreview = () => {
    methods.trigger();
    if (!isConfigurationValid()) return;
    sendRequest(true);
  };

  const handleEdit = (step: number, properties?: { [key: string]: string }) => {
    setActiveCategory({ index: step, props: properties });
  };

  const handleEnvChange = (appEnvs: AppEnvs, mainEnvName: string) => {
    setApplicationRequestConfigurationBaseKey(mainEnvName);
    methods.setValue('app_envs', appEnvs);
    if (!configurationValidation.has('app_envs')) {
      configurationValidation.set('app_envs', true);
    }
    handlePreview();
  };

  const handleFinishedPending = async (appResp: ApplicationResponse) => {
    const newAppRespData = await getGroupsAttachedToResourcesAndMapThem(
      Number(resourceId),
      appResp,
    );
    setApplicationResponse(cleanFromArtifactorySelfHosted(newAppRespData));
    setApplicationRequestSha(newAppRespData.sha);
    setEnableEditableButton(true);
    setPendingChanges(false);
  };

  const setArtifactoryIntegration = async (
    idToken: string,
    artifactoryDevToolID: number,
    artifactoryKey: string,
  ) => {
    const artifactoryResp: any = await projectApi.getProjectResources(
      currentStargateProjectId.toString(),
      {
        idToken: idToken,
        dev_tool_id: artifactoryDevToolID,
        key: artifactoryKey,
        name: artifactoryKey,
        order_by: 'name',
        order: 'asc',
        include_archived: true,
      },
    );
    if (artifactoryResp?.response?.status !== 200) {
      throw Error(
        `Error fetching Artifactory ID: ${artifactoryResp?.response?.error?.message}`,
      );
    }
    const artifactoryDevTool = artifactoryResp?.response?.data?.resources.find(
      (a: any) => a.key === artifactoryKey,
    );
    if (isNil(artifactoryDevTool)) {
      throw Error(
        `Could not find Image Registry '${artifactoryKey}', please try again`,
      );
    }

    // the checking for artifactory deleted is no longer applies for artifactory self-hosted as it is deprecated
    if (
      artifactoryDevTool?.deleted_on &&
      artifactoryDevToolID !== ARTIFACTORY_DEV_TOOL_ID
    ) {
      const index =
        artifactoryDevToolID === ARTIFACTORY_SAAS_DEV_TOOL_ID ? 0 : 1;
      methods.setError(`artifactory_registries.${index}`, {
        type: 'custom',
        message: `Artifactory Registry ${artifactoryKey} is archived. Please choose a different one or remove the host.`,
      });
    }

    setSelectedArtifactoryResourceIDs([
      ...new Set([...selectedArtifactoryResourceIDs, artifactoryDevTool.id]),
    ]);
  };

  const setGithubIntegration = async (
    idToken: string,
    gh: ApplicationGitHubRepository,
  ) => {
    const githubResp: any = await projectApi.getProjectResources(
      currentStargateProjectId.toString(),
      {
        idToken: idToken,
        dev_tool_id:
          gh.host === GITHUB_HOST ? GITHUBEMU_DEV_TOOL_ID : GITHUB_DEV_TOOL_ID,
        key: gh.owner,
        name: gh.owner,
        order_by: 'name',
        order: 'asc',
        include_archived: true,
      },
    );
    if (githubResp?.response?.status !== 200) {
      throw Error(
        `Error fetching GitHub Organization ID: ${githubResp?.response?.error?.message}`,
      );
    }
    const githubOrgDevTool = githubResp?.response?.data?.resources.find(
      (g: any) => g.key === gh.owner,
    );
    if (isNil(githubOrgDevTool)) {
      throw Error(
        `Could not find GitHub Organization '${gh.owner}', please try again`,
      );
    }

    if (githubOrgDevTool?.deleted_on) {
      methods.setError('github_repository.owner', {
        type: 'custom',
        message: `Github organization ${githubOrgDevTool.key} is archived. Please choose a different one.`,
      });
    }

    setSelectedGithubResourceID(githubOrgDevTool.id);
  };

  const setCICDIntegration = async () => {
    const idToken = await authApi.getIdToken();
    const artifactory = methods.getValues('artifactory_registries');
    setSelectedArtifactoryResourceIDs([]);
    setSelectedGithubResourceID(0);

    for (const art of artifactory) {
      if (!isEmpty(art.key)) {
        await setArtifactoryIntegration(idToken, art.dev_tool_id, art.key);
      }
    }

    const githubRepo = methods.getValues('github_repository');
    if (!isEmpty(githubRepo.host) && !isEmpty(githubRepo.owner))
      setGithubIntegration(idToken, githubRepo);
  };

  const setUserGroups = async () => {
    const appEnvs = methods.getValues('app_envs');
    const roGroup: AADGroup[] = [];
    const rwGroup: AADGroup[] = [];
    Object.getOwnPropertyNames(appEnvs).forEach(envName => {
      roGroup.push(
        ...(appEnvs[envName]?.aad_readonly_groups?.filter(
          (newGroup: { uuid: any }) =>
            !roGroup.some(g => g.uuid === newGroup.uuid),
        ) || []),
      );
      rwGroup.push(
        ...(appEnvs[envName]?.aad_readwrite_groups?.filter(
          (newGroup: { uuid: any }) =>
            !rwGroup.some(g => g.uuid === newGroup.uuid),
        ) || []),
      );
    });

    // groups are initially returned as UUIDS but can only be attached to Art/GH as resource IDs
    // so filter out any non-numeric values first
    setSelectedROUserGroupResourceIDs(
      roGroup
        .filter(o => !isNaN(Number(o.uuid)))
        .map(o => parseInt(o.uuid, 10)),
    );

    setSelectedRWUserGroupResourceIDs(
      rwGroup
        .filter(o => !isNaN(Number(o.uuid)))
        .map(o => parseInt(o.uuid, 10)),
    );
  };

  const handleSet = () => {
    if (
      activeCategory.index === CICD_SECTION ||
      activeCategory.index === USERGROUP_SECTION
    ) {
      try {
        setCICDIntegration();
        setUserGroups();
      } catch (error: any) {
        setApplicationResponseError(error as Error);
      }
    }
    handlePreview();
    setActiveCategory({ index: -1 });
  };

  useEffect(
    () => {
      if (
        props.viewMode &&
        applicationName &&
        sgProjectId &&
        projResData &&
        projResData.length
      ) {
        let resId = '';
        for (let i = 0; i < projResData.length; i++) {
          if (
            projResData[i].key === applicationName &&
            projResData[i].environment === 'prod'
          ) {
            resId = projResData[i].id;
            break;
          }
        }
        if (
          sgProjectId &&
          resId &&
          (!resourceId || resourceId !== resId.toString())
        ) {
          navigate(
            `/projects/smc/redirect?redirect=/projects/${sgProjectId}/manageresource/${resId}`,
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationName, sgProjectId, projResData],
  );

  useEffect(() => {
    async function cacheAADUUIDs(groups: AADGroup[]) {
      const idToken = await authApi.getIdToken();
      const params = {
        idToken: idToken,
        manipulators: [],
      };
      for (const g of groups) {
        if (isEmptyOrNil(g.uuid)) {
          continue;
        }
        const uuid4regex =
          /^[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}$/g;
        if (g.uuid.match(uuid4regex)) {
          localStorage.setItem(`${AAD_CACHE_PREFIX}-${g.uuid}`, g.uuid);
          continue;
        }
        const cached = localStorage.getItem(`${AAD_CACHE_PREFIX}-${g.uuid}`);
        if (isEmptyOrNil(cached)) {
          const res: any = await projectApi.getGroupByIDFromProject(
            g.uuid,
            currentStargateProjectId.toString(),
            params,
          );
          if (res?.data && res?.status === 200) {
            localStorage.setItem(
              `${AAD_CACHE_PREFIX}-${g.uuid}`,
              res.data.external_id,
            );
          }
        }
      }
    }

    const appEnvs = methods.getValues('app_envs');
    const readonlyGroups: AADGroup[] = [];
    const developerGroups: AADGroup[] = [];
    Object.getOwnPropertyNames(appEnvs).forEach(envName => {
      readonlyGroups.push(
        ...(appEnvs[envName]?.aad_readonly_groups?.filter(
          (newGroup: { uuid: any }) =>
            !readonlyGroups.some(g => g.uuid === newGroup.uuid),
        ) || []),
      );
      developerGroups.push(
        ...(appEnvs[envName]?.aad_readwrite_groups?.filter(
          (newGroup: { uuid: any }) =>
            !developerGroups.some(g => g.uuid === newGroup.uuid),
        ) || []),
      );
    });
    Promise.all([
      cacheAADUUIDs(developerGroups),
      cacheAADUUIDs(readonlyGroups),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, methods.watch(['app_envs']));

  const setValidation = (configName: string) => {
    return (isValid: boolean) => {
      setConfigurationValidation(
        configurationValidation.set(configName, isValid),
      );
    };
  };

  const overviewItemGroups: OverviewItemGroup[] = [
    {
      name: 'Application Settings',
      items: [
        {
          name: 'Name',
          values: !!methods.watch('app_name')
            ? [methods.watch('app_name')]
            : [],
          description:
            operation !== Operations.Create
              ? ''
              : 'The application name must be unique platform wide and will be part of all your namespaces. A random name was generated for convenience, but the name can not be changed later. Please change it now if required.',
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any name set to it.'
              : 'Please set an application name.',
          hasErrors: methods.getFieldState('app_name').invalid,
          editComponent: (
            <ApplicationName
              defaultAppName={methods.getValues('app_name')}
              stargateProject={currentStargateProject}
              validationSetter={setValidation('application_name')}
              setIsAppNameDefault={setIsAppNameDefault}
              enableEdit={operation === Operations.Create}
            />
          ),
        },
        {
          name: 'Clusters',
          values: Array.from(
            methods
              .watch('active_clusters')
              .reduce((a, c) => a.add(c.label), new Set<string>()),
          ),
          description:
            'One EKS cluster in ap-northeast-1 region was preselected. Multiple EKS and GKE cluster options are available. At least one is required, more can be added at any time.',
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any clusters assigned to it.'
              : 'Please select at least one cluster to deploy on.',
          hasErrors: methods.getFieldState('active_clusters').invalid,
          editComponent: (
            <ActiveClusters
              defaultClusters={methods.watch('active_clusters')}
              clusterOptions={clusterOptions}
              autocompletionsLoading={autocompletionsLoading}
              validationSetter={setValidation('active_clusters')}
            />
          ),
        },
      ],
    },
    {
      name: 'CI/CD Integration',
      items: [
        {
          name: 'GitHub Repository',
          values:
            getRepo(methods.watch('github_repository')) !== ''
              ? [getRepo(methods.watch('github_repository'))]
              : [],
          description:
            "The linked repository's CI/CD workflows will be granted read-write access to deploy to your namespaces.",
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any GitHub repository set to it.'
              : 'Could not preselect a GitHub organization. Please select an existing one or create a new one to link to your application and enable seamless CI/CD integration.',
          hasErrors: methods.getFieldState('github_repository').invalid,
          editComponent: (
            <GithubRepository
              stargateProject={currentStargateProject}
              defaultRepo={methods.watch('github_repository')}
              autocompletionsLoading={autocompletionsLoading}
              repositoryOptions={repositoryOptions}
              setRepositoryOptions={setRepositoryOptions}
              validationSetter={setValidation('github_repository')}
            />
          ),
        },
        {
          name: 'Container Registry',
          values: [
            getArtifactoryRegistryFQDN(
              methods.watch('artifactory_registries.0'),
            ),
            getArtifactoryRegistryFQDN(
              methods.watch('artifactory_registries.1'),
            ),
          ].filter(o => !isEmptyOrNil(o)),
          description:
            "The linked repository's CI/CD workflows will be granted read-write and your Kubernetes namespaces will be granted read-only access to the linked container registry.",
          emptyDescription:
            operation !== Operations.Create
              ? 'The application does not have any container registry set to it.'
              : 'Could not preselect a container registry. Please select an existing one or create a new one to link it to your application and enable seamless push/pull authorization.',
          hasErrors: methods.getFieldState('artifactory_registries').invalid,
          editComponent: (
            <>
              <ArtifactoryRepository
                stargateProject={currentStargateProject}
                defaultArtifactoryRepo={methods.watch('artifactory_registries')}
                artifactoryRepositoryOptions={artifactoryRepositoryOptions}
                autocompletionsLoading={autocompletionsLoading}
                validationSetter={setValidation('artifactory_registries')}
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attachResourceUserGroups}
                    onChange={e => {
                      setAttachResourceUserGroups(e.target.checked);
                    }}
                    name="attach_user_groups_resrouce"
                    color="primary"
                  />
                }
                label="When checked, the same user groups that grant access to the namespaces also grant access to the repository and registry"
              />
            </>
          ),
        },
      ],
    },
    {
      name: 'Environment Configuration',
      size: 12,
      renderItems: (index: number) => [
        {
          valueComponent: (
            <EnvironmentConfiguration
              appEnvs={methods.watch('app_envs')}
              mainEnvName={applicationRequestConfigurationBaseKey}
              showEditUI={showEditUI}
              enableEditableButton={enableEditableButton}
              onEdit={(envVarName, envName, mainEnvName) =>
                handleEdit(index, { envVarName, envName, mainEnvName })
              }
              onEnvChange={handleEnvChange}
              envEditOpen={envEditOpen}
              setEnvEditOpen={setEnvEditOpen}
            />
          ),
          hasErrors: methods.getFieldState('app_envs').invalid,
          editComponent: (
            <EnvironmentConfigurationEdit
              stargateProject={currentStargateProject}
              appName={methods.watch('app_name')}
              envVarName={activeCategory.props?.envVarName || ''}
              envName={activeCategory.props?.envName || ''}
              mainEnvName={applicationRequestConfigurationBaseKey}
              value={valueForVarNameAndEnv(
                methods.getValues('app_envs'),
                activeCategory.props?.envVarName,
                activeCategory.props?.envName,
              )}
              defaultValue={valueForVarNameAndEnv(
                methods.getValues('app_envs'),
                activeCategory.props?.envVarName,
                applicationRequestConfigurationBaseKey,
              )}
              autocompletionsLoading={autocompletionsLoading}
              groupOptions={groupOptions}
              onGroupOptionsChange={setGroupOptions}
              isDefaultEnv={
                activeCategory.props?.envName ===
                applicationRequestConfigurationBaseKey
              }
              validationSetter={setValidation('app_envs')}
            />
          ),
        },
      ],
      actionsHidden: true,
    },
  ];
  const [isCreateBtnDisabled, setIsCreateBtnDisabled] = useState(true);
  const [isUpdateBtnDisabled, setIsUpdateBtnDisabled] = useState(true);
  const canCreate = () => {
    const appEnvs = methods.getValues('app_envs');
    const errorInEnv = errorMessagesForAppEnvs(
      appEnvs,
      applicationRequestConfigurationBaseKey,
    );

    const canCreateVal =
      isConfigurationValid() &&
      !formSubmitted &&
      errorInEnv.length === 0 &&
      !applicationResponseLoading &&
      formError === undefined;
    if (isCreateBtnDisabled !== !canCreateVal) {
      setIsCreateBtnDisabled(!canCreateVal);
    }
    return canCreateVal;
  };

  const canEdit = () => {
    const { status } = applicationResponse;
    const appEnvs = methods.getValues('app_envs');
    const errorInEnv = errorMessagesForAppEnvs(
      appEnvs,
      applicationRequestConfigurationBaseKey,
    );
    const canEditVal =
      isConfigurationValid() &&
      !formSubmitted &&
      errorInEnv.length === 0 &&
      !applicationResponseLoading &&
      formError === undefined &&
      (status === 'applied' || status === 'unknown');
    if (isUpdateBtnDisabled !== !canEditVal) {
      setIsUpdateBtnDisabled(!canEditVal);
    }
    return canEditVal;
  };

  const handleCreateAppNS = () => {
    if (!canCreate()) return;
    sendRequest(
      false,
      false,
      (stargate_project_id: any, stargate_resource_id: any) => {
        navigate(
          `/projects/${stargate_project_id}/manageresource/${stargate_resource_id}`,
        );
        setActiveCategory({ index: overviewItemGroups.length });
        window.scrollTo({ top: 0 });
      },
    );
  };

  const checkApplicationStatus = async (): Promise<boolean> => {
    try {
      const idToken = await authApi.getIdToken();
      const appResp = await projectApi.getSpecificResource(
        currentStargateProjectId,
        Number(resourceId),
        idToken,
      );
      if (appResp && appResp.error) {
        throw new Error(appResp.error.message);
      }
      let newAppRespData = appResp.config;
      if (appResp.config.status === 'applied') {
        newAppRespData = await getGroupsAttachedToResourcesAndMapThem(
          Number(resourceId),
          appResp.config,
        );
      }

      setApplicationResponse(cleanFromArtifactorySelfHosted(newAppRespData));
      setApplicationRequestSha(newAppRespData.sha);
      if (newAppRespData.status === 'applied') {
        return true;
      }
    } catch (error: any) {
      setApplicationResponseError(error as Error);
      return true;
    }
    return false;
  };

  const handleConfirmDestroyInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    if (value === deletionsList.join(',')) {
      setEnableDestroyButton(true);
    } else {
      setEnableDestroyButton(false);
    }
  };

  const refreshInterval = 15000; // milliseconds
  const sendEditRequest = async () => {
    setApplicationResponseLoading(true);
    const pollID: any = setInterval(() => {
      checkApplicationStatus().then(done => {
        if (done) {
          setApplicationResponseLoading(false);
          setEnableEditableButton(true);
          clearInterval(pollID);
        }
      });
      return () => clearInterval(pollID);
    }, refreshInterval);
  };

  const handleDestroy = () => {
    try {
      setRaiseDestroyConfirmationDialog(false);
      sendRequest(false, true, sendEditRequest);
    } catch (error: any) {
      setApplicationResponseError(error as Error);
    } finally {
      // Once update is complete, reset destroy flag to false
      // so confirmation is raised if the user immediately requests to destroy again
      setApplicationRequestDestroy(false);
    }
  };

  const handleEditAppNS = () => {
    if (!canEdit()) return;

    const appReq = generateApplicationRequest();
    const missingKeys =
      Object.keys(appResIntialDataDuringUpdate?.configuration || {}).filter(
        key => !(key in appReq.configuration),
      ) || [];

    if (missingKeys?.length) {
      setDeletionsList(missingKeys);

      setRaiseDestroyConfirmationDialog(true);

      return;
    }
    setDeletionsList([]);

    sendRequest(false, false, sendEditRequest);
  };

  const getEditTitle = (ac: ActiveCategory) => {
    if (ac.props && ac.props.envName && ac.props.envVarName) {
      return `Edit ${normalizeName(ac.props.envVarName)} in ${normalizeName(
        ac.props.envName,
      )}`;
    }
    if (overviewItemGroups[ac.index]) {
      return `Configure ${overviewItemGroups[ac.index].name}`;
    }
    return 'Configure';
  };

  const getEditItems = (step: number): OverviewItem[] => {
    const overviewItemGroup = overviewItemGroups.at(step);
    if (overviewItemGroup?.renderItems) {
      return overviewItemGroup.renderItems(step);
    }
    return overviewItemGroup?.items || [];
  };

  const selectProject = (
    project: NamespaceAutocompletionResponseProjectType,
  ) => {
    methods.reset();
    setApplicationResponse({} as ApplicationResponse);
    setCurrentStargateProject(project);
    setConfirmProjectSelection(undefined);
    setCurrentStargateProjectId(project.id);
  };

  const projectOnSelect = (
    project: NamespaceAutocompletionResponseProjectType,
  ) => {
    if (currentStargateProject.id === project.id) return;
    if (Object.keys(methods.formState.touchedFields).length > 0) {
      setConfirmProjectSelection(project);
      return;
    }
    navigate(
      `/projects/${project.id}/create-resource?selected=104&prevNew=true`,
    );
  };

  const addAadGroupsToEnvironmentConfig = (
    aadGroups: AADGroup[],
    environmentConfigs: AppEnvs,
    configurationBaseKey: string,
    appName: string,
  ) => {
    const defaultReadWriteGroupName = getDefaultReadWriteGroupName(appName);
    const mainEnvironmentConfig =
      environmentConfigs[configurationBaseKey] || {};

    if (
      (mainEnvironmentConfig.aad_readwrite_groups || []).length === 0 &&
      queryReadWriteGroups
    ) {
      // set read-write group based on URL param
      mainEnvironmentConfig.aad_readwrite_groups = aadGroups.filter(
        g => g.uuid in queryReadWriteGroups,
      );
    }
    if ((mainEnvironmentConfig.aad_readwrite_groups || []).length === 0) {
      mainEnvironmentConfig.aad_readwrite_groups = aadGroups.filter(
        g => g.name === defaultReadWriteGroupName,
      );
    }

    const defaultReadOnlyGroupName = getDefaultReadOnlyGroupName(
      methods.getValues('app_name'),
    );

    if (
      (mainEnvironmentConfig.aad_readonly_groups || []).length === 0 &&
      queryReadOnlyGroups
    ) {
      // set read-only group based on URL param
      mainEnvironmentConfig.aad_readonly_groups = aadGroups.filter(
        g => g.uuid in queryReadOnlyGroups,
      );
    }
    if ((mainEnvironmentConfig.aad_readonly_groups || []).length === 0) {
      mainEnvironmentConfig.aad_readonly_groups = aadGroups.filter(
        g => g.name === defaultReadOnlyGroupName,
      );
    }

    if (!mainEnvironmentConfig.user_labels) {
      mainEnvironmentConfig.user_labels = {};
    }

    if (!mainEnvironmentConfig.resource_quotas) {
      mainEnvironmentConfig.resource_quotas = {};
    }

    if (!mainEnvironmentConfig.service_account_admins) {
      mainEnvironmentConfig.service_account_admins = [];
    }
  };

  useEffect(() => {
    async function refresh() {
      setAutocompletionsLoading(true);

      try {
        const projId = currentStargateProjectId;
        let resp;
        /*
        autocompletions call is going to happen on mtfuji/new page only, 
        clusters call will be happening on other pages - it takes ~500ms
        */
        if (!currentStargateProjectId) {
          resp = await mtfujiApi.getNamespaceAutocompletions(projId);
          // Uses autocompletions to set project options
          // as it already accounts for user create permissions
          setProjectOptions(resp.projects);
        } else {
          const respClusters = await mtfujiApi.getClusters();
          const clustersToBeUsed = respClusters
            .filter(item => item.infra_env === 'apps-prod') // Filter objects with infra_env = "apps-prod"
            .map(item => {
              const { workload_prefix, pair_index, region, oidc_issuer } = item;

              const { label, provider } = getLabelAndProvider(
                oidc_issuer,
                region,
                workload_prefix,
              );

              return {
                id: `${workload_prefix}-${pair_index}-${region}`,
                label: label || '',
                name_prefix: `${workload_prefix}-${pair_index}`,
                region: region || '',
                provider: provider || '',
              };
            });
          resp = {
            clusters: clustersToBeUsed || [],
            projects: [],
          };
        }

        // projId is 0 when called from mtfuji/new
        // anything requiring a valid projId must be set in this block
        if (!!projId) {
          const [artAc, ghAc, ugAc, projDataRes] = await getSGResources();
          setRepositoryOptions(ghAc);
          setArtifactoryRepositoryOptions(artAc);
          setGroupOptions(ugAc);
          setClusterOptions(resp.clusters);
          const freshIdToken = await authApi.getIdToken();
          const userRes: any = await projectApi.getUserDetails(
            { idToken: freshIdToken },
            { include: 'all' },
          );
          if (userRes.response?.status !== 200) {
            throw new Error('failed to get user details');
          }
          const userData = userRes.response.data;
          const userRoles = getRoles(userData?.roles);
          // Uses all projects from user details to set current project details
          let currentProj: any = userData?.projects.find(
            (p: any) => p.id === projId,
          );
          if (currentProj === undefined && userRoles?.includes(ADMIN)) {
            currentProj = projDataRes;
          } else {
            if (currentProj === undefined) {
              throw Error(`Project ID '${projId}' does not exist`);
            }
          }
          setCurrentStargateProject(currentProj);

          // checking if this is a preview page
          if (operation !== Operations.Create && !isNaN(Number(resourceId))) {
            // trying to view application namespace, get application from api
            try {
              const retries = 10;
              const applicationRes = await retry(
                async (___, retryCount) => {
                  setGetAppProgress(retryCount * 10);
                  const idToken = await authApi.getIdToken();
                  return await projectApi.getSpecificResource(
                    currentStargateProjectId,
                    Number(resourceId),
                    idToken,
                  );
                },
                { retries, maxTimeout: 5000, maxRetryTime: 50000 },
              );
              if (applicationRes && applicationRes.error) {
                throw new Error(applicationRes.error.message);
              }
              const newAppRespData =
                await getGroupsAttachedToResourcesAndMapThem(
                  Number(resourceId),
                  applicationRes.config,
                );

              const application: ApplicationResponse = newAppRespData;
              setApplicationResponse(
                cleanFromArtifactorySelfHosted(application),
              );

              // application found, setValue all fields
              addAadGroupsToEnvironmentConfig(
                ugAc,
                application.configuration,
                application.configuration_base_key,
                application.app_name,
              );

              methods.setValue('app_envs', application.configuration);

              methods.setValue('app_name', application.app_name);

              setApplicationRequestConfigurationBaseKey(
                application.configuration_base_key,
              );

              methods.setValue(
                'active_clusters',
                resp.clusters.filter(c =>
                  application?.active_clusters.some(
                    o =>
                      `${o.workload_prefix}-${o.pair_index}-${o.region}` ===
                      c.id,
                  ),
                ),
              );

              if (!isEmptyOrNil(application.github_repositories)) {
                methods.setValue(
                  'github_repository',
                  application.github_repositories[0],
                );
              }

              application.artifactory_registries.forEach(r => {
                // Set the name of the dev tool based on data from CORE as mtfuji-api does not have this information
                const artDevTool = artAc.find(
                  x => x.key === r.key && x.dev_tool_id === r.dev_tool_id,
                );
                if (artDevTool) {
                  r.name = artDevTool?.name || '';
                }

                if (r.dev_tool_id === ARTIFACTORY_SAAS_DEV_TOOL_ID) {
                  methods.setValue('artifactory_registries.0', r);
                }
              });

              setApplicationRequestSha(application.sha);

              // Refresh Github and Artifactory integration to ensure that all selected clusters are included
              await setCICDIntegration();

              // Does not show Pending component if application has already been created or is being updated
              if (!['applied', 'pending_change'].includes(application.status)) {
                setActiveCategory({ index: overviewItemGroups.length });
              }

              // Disable edit button if application is being created or updated
              if (
                ['pending_create', 'pending_change'].includes(
                  application.status,
                )
              ) {
                setEnableEditableButton(false);
              }

              // Set pending changes only if existing application is being updated
              if (['pending_change'].includes(application.status)) {
                setPendingChanges(true);
              }
            } catch (error: any) {
              setEnableEditableButton(false);
              setFormError(error as Error);
              return;
            }
          }

          // generate default app name
          const appName = methods.getValues('app_name');
          if (appName === '') {
            const name = generateRandomName(currentProj);
            methods.setValue('app_name', name);
          }

          let clusters = methods.getValues('active_clusters');
          if (clusters.length === 0) {
            // set cluster(s) based on URL params
            resp.clusters.forEach(c => {
              if (queryClusterIDs && c.id in queryClusterIDs) {
                clusters.push(c);
              }
            });

            // default to latest EKS in ap-northeast-1
            // not great option because if users follow the default
            // ap-northeast-1 becomes a major SPOF
            // but we have no data point to make a better decision
            if (clusters.length === 0) {
              let gcLatest: ApiConfigClusterType | undefined = undefined;
              resp.clusters.forEach(c => {
                if (c.region !== 'ap-northeast-1') {
                  return;
                }

                if (c.name_prefix.startsWith('gc-')) {
                  if (!gcLatest) {
                    gcLatest = c;
                  }

                  const curr = Number(c.name_prefix.substring(3));
                  const latest = Number(gcLatest.name_prefix.substring(3));

                  if (curr > latest) {
                    gcLatest = c;
                  }
                }
              });
              if (gcLatest) {
                clusters = [gcLatest];
              }
            }

            methods.setValue('active_clusters', clusters);
          }

          const gitRepo = methods.getValues('github_repository');
          if (gitRepo.host === '') {
            gitRepo.host = 'github.com';
          }

          if (gitRepo.owner === '') {
            // find GitHub EMU org to use
            ghAc.forEach(r => {
              // if form has values set (from URL params)
              // and a matching entry exists in response
              if (r.host === gitRepo.host && r.owner === gitRepo.owner) {
                return;
              }

              // find org matching project key
              if (
                r.host === gitRepo.host &&
                r.owner === currentProj.key.toLowerCase()
              ) {
                gitRepo.owner = r.owner;
                return;
              }
            });

            // if there is more than one org but only one is on EMU
            if (gitRepo.owner === '' && ghAc.length > 0) {
              const emuOptions = ghAc.filter(r => r.host === 'github.com');
              if (emuOptions.length === 1) {
                gitRepo.dev_tool_id = GITHUBEMU_DEV_TOOL_ID;
                gitRepo.owner = emuOptions[0].owner;
                gitRepo.key = emuOptions[0].owner;
                gitRepo.host = emuOptions[0].host;
                gitRepo.name = emuOptions[0].name;
              }
            }

            // if there is only one org, use it
            if (gitRepo.owner === '' && ghAc.length === 1) {
              gitRepo.dev_tool_id =
                ghAc[0].host === 'github.com'
                  ? GITHUBEMU_DEV_TOOL_ID
                  : GITHUB_DEV_TOOL_ID;
              gitRepo.owner = ghAc[0].owner;
              gitRepo.key = ghAc[0].owner;
              gitRepo.host = ghAc[0].host;
              gitRepo.name = ghAc[0].name;
            }
          }

          if (gitRepo.name === '') {
            // set app_name as default repository.name
            gitRepo.name = methods.getValues('app_name');
          }

          methods.setValue('github_repository', gitRepo);

          if (
            methods.getValues('artifactory_registries.0.key') === '' &&
            operation === Operations.Create
          ) {
            // find or create container registry
            artAc.forEach(r => {
              // if form has values set (from URL params)
              // and a matching entry exists in response
              if (r.key === methods.getValues('artifactory_registries.0.key')) {
                methods.setValue('artifactory_registries.0', r);
                return;
              }

              // find registry matching project key
              if (r.key === currentProj.key.toLowerCase()) {
                methods.setValue('artifactory_registries.0', r);
                return;
              }
            });

            // if there is only one image registry, use it
            if (
              methods.getValues('artifactory_registries.0.key') === '' &&
              artAc.length === 1
            ) {
              methods.setValue('artifactory_registries.0', artAc[0]);
            }
          }

          if (operation === Operations.Create) {
            const appEnvs: AppEnvs = methods.getValues('app_envs') || {};
            addAadGroupsToEnvironmentConfig(
              ugAc,
              appEnvs,
              applicationRequestConfigurationBaseKey,
              appName,
            );
            methods.setValue('app_envs', appEnvs);
          }
        }
      } catch (error: any) {
        setFormError(error as Error);
      } finally {
        setAutocompletionsLoading(false);
        overviewRef.current?.scrollIntoView({ behavior: 'smooth' });
        handlePreview();
      }
    }

    refresh();
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStargateProjectId]);

  if (!props.isNew && currentStargateProject.name === undefined) {
    return <Progress />;
  }

  return (
    <>
      {props.viewMode && props.projectData && (
        <PageTitleBreadcrumbs
          projectData={props.projectData}
          resourceData={{
            name: props.projectData.resources[0].name,
            id: props.projectData.resources[0].id.toString(),
          }}
        />
      )}
      <Paper className={classes.formContainer}>
        <FormProvider {...methods}>
          {formError && (
            <Alert style={{ whiteSpace: 'pre-line' }} severity="error">
              {formError?.message === 'Not Found' ? (
                <div>
                  The application you are trying to access cannot be found.
                  <br />
                  <br />
                  Please verify the name of the application in the URL and
                  contact support if the issue persists.
                  <br />
                  <br />
                  <LinkButton
                    color="secondary"
                    variant="contained"
                    to={SLACK_CHANNEL_URL}
                  >
                    Get help on Slack
                  </LinkButton>
                </div>
              ) : (
                <div>
                  An unforeseen error has occurred when trying to load
                  application details.
                  <br />
                  <CodeSnippet
                    text={JSON.stringify(formError?.message, null, 2)}
                    language="json"
                  />
                  Please contact support with the above message for help.
                  <br />
                  <br />
                  <LinkButton
                    color="secondary"
                    variant="contained"
                    to={SLACK_CHANNEL_URL}
                  >
                    Get help on Slack
                  </LinkButton>
                </div>
              )}
            </Alert>
          )}
          <FormLayout
            title={
              operation === Operations.Create && currentStargateProjectId
                ? '- Create Application Namespaces'
                : ''
            }
            className="mtfuji_wizard"
            contentClass={
              !currentStargateProjectId || props.isNew || props.viewMode
                ? classes.mtfujiContentProjSel
                : classes.mtfujiContent
            }
            hideHeader={!!(props.viewMode && props.projectData)}
            operation={operation}
            projectId={sgProjectId}
            projectName={currentStargateProject.name}
            applicationName={applicationName}
            subtitle={
              activeCategory.index === overviewItemGroups.length ||
              operation !== Operations.Create ||
              !currentStargateProjectId
                ? 'Kubernetes Platform'
                : `Default configuration values have been set to help
          you get started. Please verify the default values
          and modify them as required.`
            }
          >
            {autocompletionsLoading ? (
              <>
                <Grid container direction="column">
                  {/* Overview grid */}
                  <Grid item>
                    <Grid container>
                      <Grid item xs={12}>
                        <Progress
                          {...(operation !== Operations.Create
                            ? {
                                variant: 'determinate',
                                value: getAppProgress,
                              }
                            : {})}
                        />
                        <Alert severity="info" key="pendingEl">
                          Loading application details...
                        </Alert>
                      </Grid>
                      <Grid item xs={12}>
                        <Skeleton animation="wave" height={80} />
                      </Grid>

                      {overviewItemGroups.map((__, i) => {
                        return (
                          <Grid item xs={12} key={`skeleton-${i}`}>
                            <Skeleton animation="wave" height={50} />
                            <Skeleton
                              animation="wave"
                              variant="rect"
                              height={200}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {!currentStargateProjectId && (
                  <Grid
                    container
                    direction="column"
                    id="mtfuji_wizard_select_project"
                  >
                    <Grid item xs={12}>
                      <ProjectSelector
                        stargateProject={currentStargateProject}
                        onSelect={projectOnSelect}
                        projectOptions={projectOptions}
                        autocompletionsLoading={autocompletionsLoading}
                        enableEditableButton={!enableEditableButton}
                      />
                    </Grid>
                  </Grid>
                )}

                {pendingChanges && (
                  <Alert
                    severity="info"
                    icon={<PendingIcon fontSize="large" color="disabled" />}
                    variant="outlined"
                  >
                    <Typography align="center">
                      This application has pending updates. Please wait for
                      updates to be applied before making additional changes.
                    </Typography>
                  </Alert>
                )}
                {currentStargateProject.id !== undefined && (
                  <>
                    <Grid
                      container
                      direction="column"
                      id="mtfuji_wizard_overview"
                    >
                      {/* Overview grid */}
                      <Grid item>
                        {activeCategory.index === overviewItemGroups.length && (
                          <Grid container ref={pendingRef}>
                            <Grid item xs={12}>
                              <Pending
                                projectId={currentStargateProjectId}
                                resourceId={resourceId}
                                applicationResponse={applicationResponse}
                                applicationResponseError={
                                  applicationResponseError
                                }
                                maybeNewGithub={maybeNewGithub}
                                onFinishedPending={handleFinishedPending}
                                pendingChanges={pendingChanges}
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid container className={classes.appSettingContainer}>
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography variant="h5">
                                  Application Settings
                                </Typography>
                              </Grid>
                              <Grid item>
                                {smcView && showEditUI && (
                                  <Button
                                    id="smc-edit-btn"
                                    variant="text"
                                    onClick={() => {
                                      setSmcView(false);
                                    }}
                                    disabled={!enableEditableButton}
                                  >
                                    EDIT
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={smcView ? 3 : 6}>
                            <ApplicationName
                              defaultAppName={methods.getValues('app_name')}
                              stargateProject={currentStargateProject}
                              validationSetter={setValidation(
                                'application_name',
                              )}
                              setIsAppNameDefault={setIsAppNameDefault}
                              enableEdit={operation === Operations.Create}
                              smcView={smcView}
                            />
                          </Grid>
                          {smcView && (
                            <Divider
                              orientation="vertical"
                              flexItem
                              className={classes.dividerVertical}
                            />
                          )}
                          <Grid item xs={smcView ? 7 : 6}>
                            <ActiveClusters
                              defaultClusters={methods.watch('active_clusters')}
                              clusterOptions={clusterOptions}
                              autocompletionsLoading={autocompletionsLoading}
                              validationSetter={setValidation(
                                'active_clusters',
                              )}
                              smcView={smcView}
                            />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                CI/CD Integration
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <GithubRepository
                                stargateProject={currentStargateProject}
                                defaultRepo={methods.watch('github_repository')}
                                autocompletionsLoading={autocompletionsLoading}
                                repositoryOptions={repositoryOptions}
                                setRepositoryOptions={setRepositoryOptions}
                                validationSetter={setValidation(
                                  'github_repository',
                                )}
                                smcView={smcView}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <>
                                <ArtifactoryRepository
                                  stargateProject={currentStargateProject}
                                  defaultArtifactoryRepo={methods.watch(
                                    'artifactory_registries',
                                  )}
                                  artifactoryRepositoryOptions={
                                    artifactoryRepositoryOptions
                                  }
                                  autocompletionsLoading={
                                    autocompletionsLoading
                                  }
                                  validationSetter={setValidation(
                                    'artifactory_registries',
                                  )}
                                  smcView={smcView}
                                />
                                {!smcView && <br />}
                                {!smcView && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={attachResourceUserGroups}
                                        onChange={e => {
                                          setAttachResourceUserGroups(
                                            e.target.checked,
                                          );
                                        }}
                                        name="attach_user_groups_resrouce"
                                        color="primary"
                                      />
                                    }
                                    label="When checked, the same user groups that grant access to the namespaces also grant access to the repository and registry"
                                  />
                                )}
                              </>
                            </Grid>
                          </>
                        </Grid>

                        <Grid container>
                          <Grid item xs={6}>
                            <Typography
                              variant="h5"
                              className={classes.envText}
                            >
                              Environment Configuration
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            className={classes.editEnvContainer}
                          >
                            {showEditUI && (
                              <>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  marginRight="16px"
                                >
                                  <Box
                                    width={10}
                                    height={10}
                                    borderRadius="50%"
                                    bgcolor="red"
                                    marginRight="10px"
                                  />
                                  <Typography variant="body1">
                                    Overwritten Value
                                  </Typography>
                                </Box>
                                <Button
                                  id="smc-edit-env-btn"
                                  variant="text"
                                  color="primary"
                                  onClick={() => setEnvEditOpen(true)}
                                  disabled={!enableEditableButton}
                                >
                                  Manage Environments
                                </Button>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12} className={classes.envTable}>
                            <EnvironmentConfiguration
                              appEnvs={methods.watch('app_envs')}
                              mainEnvName={
                                applicationRequestConfigurationBaseKey
                              }
                              showEditUI={showEditUI}
                              enableEditableButton={enableEditableButton}
                              onEdit={(envVarName, envName, mainEnvName) =>
                                handleEdit(2, {
                                  envVarName,
                                  envName,
                                  mainEnvName,
                                })
                              }
                              onEnvChange={handleEnvChange}
                              setEnvEditOpen={setEnvEditOpen}
                              envEditOpen={envEditOpen}
                              fetchProjectData={fetchProjectData}
                              resourceId={resourceId}
                              currentStargateProjectId={
                                currentStargateProjectId
                              }
                              setAppResIntialDataDuringUpdate={
                                setAppResIntialDataDuringUpdate
                              }
                              appResIntialDataDuringUpdate={
                                appResIntialDataDuringUpdate
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {operation === Operations.Create && (
                        <>
                          <Grid item xs={12}>
                            <Preview
                              loading={applicationResponseLoading}
                              error={applicationResponseError}
                              createProcess
                              setSmcView={setSmcView}
                            />
                          </Grid>
                        </>
                      )}
                      {showEditUI && operation === Operations.Update && (
                        <>
                          <Grid item xs={12}>
                            <Preview
                              loading={applicationResponseLoading}
                              error={applicationResponseError}
                              updateProcess
                              setSmcView={setSmcView}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>

                    {/* Edit grid */}
                    <Dialog
                      maxWidth="lg"
                      open={
                        activeCategory.index !== -1 &&
                        activeCategory.index < overviewItemGroups.length
                      }
                      scroll="paper"
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                      className="mtfuji_wizard"
                      fullWidth
                    >
                      <DialogTitle id="scroll-dialog-title">
                        <div className={classes.dialogHeader}>
                          <Typography variant="h3">
                            {getEditTitle(activeCategory)}
                          </Typography>
                          {/* Commented code is req. for future changes */}
                          {/* <Tooltip
                            classes={{
                              tooltip: classes.tooltip,
                              arrow: classes.tooltipArrow,
                            }}
                            arrow
                            placement="top"
                            title="Close Button"
                          >
                            <IconButton
                              id="dialog-active-category-close-icon-btn"
                              aria-label="close"
                              className={classes.iconContainer}
                              onClick={handleClose}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip> */}
                        </div>
                      </DialogTitle>

                      <DialogContent dividers>
                        <Grid
                          container
                          className={classes.dialogGrid}
                          direction="column"
                          id="mtfuji_wizard_edit"
                        >
                          {/* Overview grid */}
                          <Grid item xs>
                            <Grid container>
                              <Grid container>
                                {getEditItems(activeCategory.index).map(
                                  item => {
                                    return (
                                      item.editComponent && (
                                        <Grid item xs={12}>
                                          {item.editComponent}
                                        </Grid>
                                      )
                                    );
                                  },
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        {/* Commented code is req. for future changes */}
                        {/* <Button
                          variant="outlined"
                          id="cancel-changes-button"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button> */}
                        <Button
                          variant="contained"
                          id="stage-changes-button"
                          onClick={handleSet}
                          disabled={
                            pendingChanges ||
                            (!!!overviewItemGroups.at(activeCategory.index)
                              ?.actionsHidden &&
                              getEditItems(activeCategory.index)?.reduce(
                                (hasErrors, item) =>
                                  hasErrors || item.hasErrors,
                                false,
                              ))
                          }
                        >
                          Stage Changes
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* Destroy confirmation */}
                    <Dialog
                      maxWidth="sm"
                      aria-labelledby="confirmation-dialog-delete-resources"
                      open={raiseDestroyConfirmationDialog}
                      className="confirmation-dialog"
                    >
                      <DialogTitle id="confirm-delete-resource">
                        <div className={classes.dialogHeader}>
                          <Typography variant="h3">
                            Delete Resources?
                          </Typography>
                          <Tooltip
                            classes={{
                              tooltip: classes.tooltip,
                              arrow: classes.tooltipArrow,
                            }}
                            arrow
                            placement="top"
                            title="Close Button"
                          >
                            <IconButton
                              id="dialog-delete-resources-close-icon-btn"
                              aria-label="close"
                              className={classes.iconContainer}
                              onClick={() => {
                                setRaiseDestroyConfirmationDialog(false);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </DialogTitle>

                      <DialogContent dividers>
                        <Alert severity="warning" color="error">
                          <Typography variant="body2" paragraph>
                            You are about to delete the following
                            environment(s):
                            <br />
                            <code style={{ color: '#f44336' }}>
                              {deletionsList.join(',')}
                            </code>
                            <br />
                            <br />
                            This will destroy all application namespaces for
                            these environment(s) in every cluster, along with{' '}
                            <b>all</b> resources deployed in them.
                            <br />
                            <br />
                            By typing the list of environments exactly as shown
                            and clicking the 'I understand' button, you confirm
                            you agree to the destruction of these environments,
                            and acknowledge that this action is irreversible.
                            <br />
                            <br />
                            <FormControl
                              variant="outlined"
                              fullWidth
                              style={{ marginRight: '16px' }}
                            >
                              <input
                                type="text"
                                placeholder={deletionsList.join(',')}
                                defaultValue=""
                                onChange={handleConfirmDestroyInput}
                              />
                            </FormControl>
                          </Typography>
                        </Alert>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          id="cancel-destroy-button"
                          onClick={() => {
                            setRaiseDestroyConfirmationDialog(false);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Go back
                        </Button>
                        <Button
                          id="confirm-destroy-button"
                          onClick={() => handleDestroy()}
                          variant="contained"
                          color="secondary"
                          disabled={!enableDestroyButton}
                        >
                          I understand
                        </Button>
                      </DialogActions>
                    </Dialog>

                    {/* Select project confirmation */}
                    <Dialog
                      maxWidth="sm"
                      aria-labelledby="confirmation-dialog-project-select"
                      open={!!confirmProjectSelection}
                      className="confirmation-dialog"
                    >
                      <DialogTitle id="confirmation-dialog-project-select">
                        <div className={classes.dialogHeader}>
                          <Typography variant="h3">Switch Project?</Typography>
                          <Tooltip
                            classes={{
                              tooltip: classes.tooltip,
                              arrow: classes.tooltipArrow,
                            }}
                            arrow
                            placement="top"
                            title="Close Button"
                          >
                            <IconButton
                              id="switch-project-close-icon-btn"
                              aria-label="close"
                              className={classes.iconContainer}
                              onClick={() =>
                                setConfirmProjectSelection(undefined)
                              }
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </DialogTitle>

                      <DialogContent dividers>
                        <Typography>
                          Switching projects from{' '}
                          <code style={{ color: '#f44336' }}>
                            {currentStargateProject.name}
                          </code>{' '}
                          to{' '}
                          <code style={{ color: '#4caf50' }}>
                            {confirmProjectSelection?.name}
                          </code>{' '}
                          will reset the form and preset new defaults. The
                          changes you made will be lost.
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          id="cancel-switch-project-button"
                          onClick={() => {
                            setConfirmProjectSelection(undefined);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Nevermind
                        </Button>
                        <Button
                          id="confirm-switch-project-button"
                          onClick={() => {
                            return (
                              !!confirmProjectSelection &&
                              selectProject(confirmProjectSelection)
                            );
                          }}
                          color="secondary"
                        >
                          Yes, switch project
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </>
            )}
          </FormLayout>
        </FormProvider>
      </Paper>

      {currentStargateProjectId ? (
        <Paper elevation={3} className={classes.bottomNav}>
          <BottomNavigation showLabels>
            <Grid
              container
              style={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: '0 48px',
              }}
            >
              <Grid item>
                <Button
                  type="button"
                  id="mtfuji-wizard-button-cancel"
                  variant="outlined"
                  onClick={() => {
                    if (isPrevPageNewPage) {
                      navigate(`/mtfuji/new`);
                    } else {
                      navigate(
                        `/projects/${currentStargateProjectId}?tab=resources`,
                      );
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                {operation === Operations.Update ? (
                  <Button
                    variant="contained"
                    id="mtfuji-wizard-button-update"
                    className="submit-button"
                    onClick={handleEditAppNS}
                    style={{ float: 'right' }}
                    disabled={!canEdit()}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    id="mtfuji-wizard-button-create"
                    variant="contained"
                    className="submit-button"
                    onClick={handleCreateAppNS}
                    disabled={!canCreate()}
                    style={{ float: 'right' }}
                  >
                    Submit
                  </Button>
                )}
              </Grid>
            </Grid>
          </BottomNavigation>
        </Paper>
      ) : (
        <></>
      )}
    </>
  );
};
