import React, { Dispatch, SetStateAction } from 'react';
import { TransitionGroup } from 'react-transition-group';

import { Collapse, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { getTotalUserCountByGroupAndProject } from '../../common/getTotalUserCountByGroupAndProject';
import { TUserGroupAttachment } from './types';
import { UserGroupSelect } from './UserGroupSelect';
import { ResourceRoleSelect } from './ResourceRoleSelect';

import { projectApiRef } from '../../api';

import { useStyles } from './styles';

type ResourceSelectRowProps = {
  userGroups: any;
  userGroupAttachments: TUserGroupAttachment[];
  isLoadingUserGroup: boolean;
  setTotalUsersCount: Dispatch<SetStateAction<number>>;
  setDuplicateError: Dispatch<SetStateAction<string>>;
  setUserGroupAttachments: Dispatch<SetStateAction<TUserGroupAttachment[]>>;
  projectId: string;
  attachError: string;
  resourceData: any;
  setAttachError: Dispatch<SetStateAction<string>>;
};

export const ResourceSelectRow = ({
  resourceData,
  userGroups,
  userGroupAttachments,
  isLoadingUserGroup,
  setTotalUsersCount,
  setDuplicateError,
  setUserGroupAttachments,
  projectId,
  setAttachError,
}: ResourceSelectRowProps) => {
  const classes = useStyles();

  const projectApi = useApi(projectApiRef);
  const authRef = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);

  const handleResourceTypeChange = async (event: any, i: number) => {
    const updatedUserGroupAttachmentRequest = [...userGroupAttachments];
    updatedUserGroupAttachmentRequest[i] = {
      ...updatedUserGroupAttachmentRequest[i],
      [event.target.name]: event.target.value,
    };
    if (event.target.name === 'usergroup_id') {
      setUserGroupAttachments(updatedUserGroupAttachmentRequest);

      const nonEmptyUserGroups = userGroupAttachments.map(u => u.usergroup_id);
      setDuplicateError('');
      if (nonEmptyUserGroups.includes(event.target.value)) {
        setDuplicateError('You cannot assign the same resource twice.');
      } else {
        const isDynamicGroup =
          event.currentTarget.getAttribute('data-isdynamicgroup') === 'true';
        const groupName = event.currentTarget.getAttribute('title');

        const currentUserCount = userGroupAttachments[i].usersCount;
        let usersCount = 0;

        let isGithubEMUDynamicGroup = false;

        if (isDynamicGroup) {
          const idToken = await authRef.getIdToken();
          const tags = await projectApi.getUserGroupPlatformLimitTags(
            projectId,
            event.target.value,
            idToken,
          );

          isGithubEMUDynamicGroup =
            (tags.data.tags &&
              tags.data.tags['platform-limit-tool']?.includes('githubemu')) ||
            groupName.includes('githubemu');
        }

        if (!isGithubEMUDynamicGroup) {
          usersCount = await getTotalUserCountByGroupAndProject(
            projectApi,
            authRef,
            errorApi,
            projectId,
            groupName,
          );
        }

        setTotalUsersCount(
          prevTotalUsersCount =>
            prevTotalUsersCount - currentUserCount + usersCount,
        );

        updatedUserGroupAttachmentRequest[i] = {
          ...updatedUserGroupAttachmentRequest[i],
          usersCount,
        };
        setUserGroupAttachments(prevUserGroupAttachments => {
          prevUserGroupAttachments[i] = {
            ...prevUserGroupAttachments[i],
            usersCount,
          };

          return prevUserGroupAttachments;
        });
      }
    } else {
      setUserGroupAttachments(updatedUserGroupAttachmentRequest);
    }
  };

  return (
    <TransitionGroup>
      {userGroupAttachments.map(({ usergroup_id, role }, userGroupIndex) => (
        <Collapse>
          <div
            key={userGroupIndex}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '16px',
            }}
          >
            <UserGroupSelect
              userGroups={userGroups}
              usergroup_id={usergroup_id}
              handleResourceTypeChange={handleResourceTypeChange}
              userGroupIndex={userGroupIndex}
            />
            {resourceData?.roles?.length !== 0 && (
              <ResourceRoleSelect
                resourceData={resourceData}
                role={role}
                usergroup_id={usergroup_id}
                userGroupIndex={userGroupIndex}
                handleResourceTypeChange={handleResourceTypeChange}
              />
            )}

            <IconButton
              disabled={isLoadingUserGroup}
              className={classes.deleteIcon}
              id="projects-resource-attach-delete-button"
              onClick={() => {
                const { usersCount: deletedUserGroupCount } =
                  userGroupAttachments[userGroupIndex];
                setTotalUsersCount(
                  prevTotalUserCount =>
                    prevTotalUserCount - deletedUserGroupCount,
                );
                const updatedUserGroupAttachmentRequest = [
                  ...userGroupAttachments,
                ].filter((_, fi) => userGroupIndex !== fi);

                // checking if duplicates exists
                const userGroupIds = updatedUserGroupAttachmentRequest.map(
                  u => u.usergroup_id,
                );
                // if set size and usergroup ids length is the same it means no duplicates
                if (new Set(userGroupIds).size === userGroupIds.length) {
                  setDuplicateError('');
                }

                setUserGroupAttachments(updatedUserGroupAttachmentRequest);
                setAttachError('');
              }}
            >
              <DeleteIcon
                className={[
                  isLoadingUserGroup
                    ? classes.disabledIconColor
                    : classes.iconColor,
                ].join(' ')}
              />
            </IconButton>
          </div>
        </Collapse>
      ))}
    </TransitionGroup>
  );
};
