import React, { useEffect, useState } from 'react';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import useAsyncFn from 'react-use/esm/useAsyncFn';
import { useNavigate } from 'react-router';
import { PageLayout, PageTitle } from '@internal/sg-ui-kit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { ROOT_URL_LABEL_BREADCRUMBS } from '../ViewEditResources/ViewEditResourcePage/types';
import { Progress } from '@backstage/core-components';
import { Form, Formik } from 'formik';
import { useTranslation } from '../../hooks/useTranslation';
import { management_type, projectApiRef } from '../../api';
import { usePermissions } from '../../hooks/usePermissions';
import { useCompaniesApi } from '../../hooks/useCompaniesApi';
import { getTechDocsLink } from 'sg-utils-frontend';
import {
  PROJECT_KEY_REGEX,
  PROJECT_TEMPLATE_TAGS,
} from '../../common/SGConstants';
import { USE_NON_BETA_CREATE_PROJECT_API } from 'usg-types';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { useStyles } from './styles';

const MAX_DESC_LENGTH = 2000;
const MIN_PROJECT_NAME_LENGTH = 2;
const MIN_PROJECT_KEY_LENGTH = 3;
const MAX_PROJECT_KEY_LENGTH = 8;

export function CreateProject() {
  const navigate = useNavigate();
  const classes = useStyles();
  const projectApi = useApi(projectApiRef);
  const authRef = useApi(microsoftAuthApiRef);
  const errorApi = useApi(errorApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);

  const { t } = useTranslation();
  const [projectTemplateData, setprojectTemplateData] = useState<Array<any>>(
    [],
  );
  const [pNameDirty, setPNameDirty] = useState(false);
  const [descDirty, setDescDirty] = useState(false);
  const [pKeyDirty, setPKeyDirty] = useState(false);
  const tagKeys = Object.keys(PROJECT_TEMPLATE_TAGS);
  const [tagsDirty, setTagsDirty] = useState(
    tagKeys.reduce((prev: any, tagKey: string) => {
      return {
        ...prev,
        [tagKey]: false,
      };
    }, {}),
  );
  const { permissions } = usePermissions();
  const { allCompanies, isLoading: isLoadingCompanies } = useCompaniesApi();
  const [idToCompanyMapping, setIdToCompanyMapping] = useState<any>({});
  const [participantCompanies, setParticipantCompanies] = useState([] as any);
  const [ipOwnerCompanies, setIpOwnerCompanies] = useState([] as any);
  const [open, setOpen] = useState(false);
  const [createdProjectId, setCreatedProjectId] = useState();
  const [isNotUsingBeta, setIsNotUsingBeta] = useState<boolean>(false);

  const breadcrumbs = [
    {
      path: 'projects',
      display: ROOT_URL_LABEL_BREADCRUMBS,
    },
    {
      isAbsolute: true,
      path: location.pathname,
      display: t('createproject.heading'),
    },
  ];

  // Success dailog box
  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = (reason: any) => {
    if (reason.type === 'click') {
      setOpen(false);
      navigate(`/projects`);
    }
  };

  const onSubmit = async (values: any) => {
    const ipOwnerCompaniesReq = projectTemplateData[0].ip_owner_companies.map(
      (company: any) => {
        return {
          company_id: company.id,
          started_on: idToCompanyMapping[company.id].created_on,
          ended_on: idToCompanyMapping[company.id]?.ended_on,
          percentage: company.percentage,
        };
      },
    );
    const participantCompaniesReq =
      projectTemplateData[0].participant_companies.map((company: any) => {
        return {
          company_id: company.id,
          started_on: idToCompanyMapping[company.id].created_on,
          ended_on: idToCompanyMapping[company.id]?.ended_on,
        };
      });

    const tags: any = {};
    projectTemplateData[0].tags.forEach((tag: any) => {
      tags[tag.tag_key] = [values[tag.tag_key]];
    });
    try {
      const params = {
        name: values.projectName?.trim(),
        key: values.projectKey?.trim(),
        description: values.description?.trim(),
        management_type: management_type.PROJECT_OWNERS_END_USERS,
        owners: [
          {
            user_email: permissions.email,
            role: 'PRIMARY',
          },
        ],
        ip_owner_companies: ipOwnerCompaniesReq,
        participant_companies: participantCompaniesReq,
        // as current AHA and UX doesn't support, core only sends 1 template and we only use first template
        project_template_id: projectTemplateData[0].id,
        tags: tags,
      };

      const idToken = await authRef.getIdToken();
      const createProjectData = await projectApi.createProject(
        params,
        idToken,
        isNotUsingBeta,
      );
      setCreatedProjectId(createProjectData.project_id);
      handleDialogOpen();
    } catch (err: any) {
      errorApi.post(
        new Error(`Couldn't create project due to ${err?.message}`),
      );
    }
  };

  const cancelCreateProject = () => {
    navigate(`/projects`);
  };

  const checkFieldIsInvalid = (
    fieldName: string,
    currentValue: any,
    forSubmitButton: boolean = false,
  ) => {
    let isInValid = false;
    switch (fieldName) {
      case 'projectName':
        isInValid =
          (pNameDirty || forSubmitButton) &&
          currentValue?.trim().length < MIN_PROJECT_NAME_LENGTH;
        break;
      case 'projectKey':
        isInValid =
          (pKeyDirty || forSubmitButton) &&
          (currentValue?.trim().length < MIN_PROJECT_KEY_LENGTH ||
            !PROJECT_KEY_REGEX.test(currentValue) ||
            currentValue?.trim().length > MAX_PROJECT_KEY_LENGTH);
        break;
      case 'description':
        isInValid =
          (descDirty || forSubmitButton) &&
          currentValue?.trim().length > MAX_DESC_LENGTH;
        break;
      default:
        break;
    }
    return isInValid;
  };

  const [{ loading: templatesLoading }, fetchAvailableProjectTemplates] =
    useAsyncFn(async () => {
      try {
        const idToken = await authRef.getIdToken();

        const projectData = await projectApi.getAvailableProjectTemplates(
          idToken,
        );

        if (projectData?.project_templates) {
          setprojectTemplateData(projectData.project_templates);
        }
        return projectData.project_templates;
      } catch (err: any) {
        errorApi.post(new Error(err?.message));
        navigate(`/projects`);
      }
      return [];
    }, []);

  useEffect(() => {
    (async () => {
      const templates = await fetchAvailableProjectTemplates();
      if (templates.length === 0) {
        navigate(`/projects`);
      }
    })();
  }, [fetchAvailableProjectTemplates, navigate]);

  useEffect(() => {
    (async () => {
      const notUsingBeta = await featureFlagsApi.getBinaryFlag(
        USE_NON_BETA_CREATE_PROJECT_API,
      );
      setIsNotUsingBeta(notUsingBeta);
    })();
  }, [featureFlagsApi]);

  useEffect(() => {
    if (isLoadingCompanies || projectTemplateData.length === 0) return;
    const idToCompanyMap: any = {};
    allCompanies.forEach((company: any) => {
      idToCompanyMap[company.id] = {
        name: company?.name,
        short_name: company?.short_name,
        created_on: company?.created_on,
      };
    });

    const pCompanies = projectTemplateData[0]?.participant_companies;
    const ipCompnies = projectTemplateData[0]?.ip_owner_companies;

    const participantsList = pCompanies.map(
      (pCompany: any) => idToCompanyMap[pCompany.id].short_name,
    );
    const ipCompaniesList = ipCompnies.map(
      (ipCompany: any) => idToCompanyMap[ipCompany.id].short_name,
    );

    setParticipantCompanies(participantsList);
    setIpOwnerCompanies(ipCompaniesList);
    setIdToCompanyMapping(idToCompanyMap);
  }, [projectTemplateData, allCompanies, errorApi, isLoadingCompanies]);

  const checkTagIsInvalid = (
    tag: any,
    currentValue: any,
    forSubmitButton: boolean = false,
  ) => {
    const isInValid =
      (tagsDirty[tag.tag_key] || forSubmitButton) &&
      // if required then has to have some value
      ((tag.required && currentValue === '') ||
        // if required or not required, if field has some values, should match with validation
        (currentValue.length > 0 &&
          ((PROJECT_TEMPLATE_TAGS[tag.tag_key]?.maxLength &&
            currentValue.length >
              PROJECT_TEMPLATE_TAGS[tag.tag_key]?.maxLength) ||
            (PROJECT_TEMPLATE_TAGS[tag.tag_key]?.pattern &&
              !PROJECT_TEMPLATE_TAGS[tag.tag_key].pattern.test(
                currentValue,
              )))));
    return isInValid;
  };

  const checkIfAllTagsAreValid = (
    formik: any,
    forSubmitButton: boolean = false,
  ): boolean => {
    let isInValid = false;
    if (projectTemplateData.length === 0) return isInValid;
    projectTemplateData[0].tags.forEach((tag: any) => {
      isInValid =
        isInValid ||
        checkTagIsInvalid(
          tag,
          formik.values[tag.tag_key] || '',
          forSubmitButton,
        );
    });
    return isInValid;
  };

  const createTagFields = (formik: any): any => {
    if (projectTemplateData.length === 0) return <></>;
    const tagsFields = projectTemplateData[0].tags.map((tag: any) => {
      return (
        <>
          <InputLabel
            htmlFor={tag.tag_key}
            className={
              checkTagIsInvalid(tag, formik.values[tag.tag_key] || '')
                ? classes.invalidField
                : ''
            }
          >
            {
              /* @ts-ignore */
              t(`createproject.tags.${tag.tag_key}.label`)
            }
            {tag.required && '*'}
          </InputLabel>
          <TextField
            type="text"
            id={tag.tag_key}
            name={tag.tag_key}
            inputProps={{ 'data-testid': tag.tag_key }}
            required={tag.required}
            value={formik.values[tag.tag_key] || ''}
            fullWidth
            onChange={formik.handleChange}
            error={checkTagIsInvalid(tag, formik.values[tag.tag_key] || '')}
            onClick={() =>
              setTagsDirty({
                ...tagsDirty,
                [tag.tag_key]: true,
              })
            }
            helperText={
              <>
                {
                  /* @ts-ignore */
                  t(`createproject.tags.${tag.tag_key}.helperText.text1`)
                }
                <Link
                  target="_blank"
                  to={`${PROJECT_TEMPLATE_TAGS[tag.tag_key]?.helpLink}`}
                >
                  {t(
                    /* @ts-ignore */
                    `createproject.tags.${tag.tag_key}.helperText.text2AsLink`,
                  )}
                </Link>
                {
                  /* @ts-ignore */
                  t(`createproject.tags.${tag.tag_key}.helperText.text3`, {
                    defaultValue: '',
                  })
                }
              </>
            }
          />
        </>
      );
    });
    return tagsFields;
  };

  if (templatesLoading || isLoadingCompanies) {
    return <Progress />;
  }

  return (
    <PageLayout
      type="entity"
      title={t('createproject.heading')}
      subtitle={t('createproject.subHeading')}
      backToLink={breadcrumbs}
    >
      <PageTitle customPageTitle="Create Project Workspace | Stargate" />
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              projectName: '',
              projectKey: '',
              description: '',
            }}
            onSubmit={onSubmit}
          >
            {formik => (
              <Form>
                <InputLabel
                  htmlFor="projectName"
                  className={
                    checkFieldIsInvalid(
                      'projectName',
                      formik.values.projectName,
                    )
                      ? classes.invalidField
                      : ''
                  }
                >
                  {t('createproject.projectName.label')}*
                </InputLabel>
                <TextField
                  type="text"
                  id="project-name"
                  inputProps={{ 'data-testid': 'project-name' }}
                  name="projectName"
                  required
                  value={formik.values.projectName}
                  fullWidth
                  error={checkFieldIsInvalid(
                    'projectName',
                    formik.values.projectName,
                  )}
                  onChange={formik.handleChange}
                  onClick={() => setPNameDirty(true)}
                  helperText={t('createproject.projectName.helperText')}
                />

                <InputLabel
                  htmlFor="projectKey"
                  className={
                    checkFieldIsInvalid('projectKey', formik.values.projectKey)
                      ? classes.invalidField
                      : ''
                  }
                >
                  {t('createproject.projectKey.label')}*
                </InputLabel>
                <TextField
                  type="text"
                  id="projectKey"
                  inputProps={{ 'data-testid': 'projectKey' }}
                  name="projectKey"
                  required
                  value={formik.values.projectKey}
                  fullWidth
                  error={checkFieldIsInvalid(
                    'projectKey',
                    formik.values.projectKey,
                  )}
                  onChange={formik.handleChange}
                  onClick={() => setPKeyDirty(true)}
                  helperText={t('createproject.projectKey.helperText')}
                />

                <InputLabel
                  htmlFor="description"
                  className={
                    checkFieldIsInvalid(
                      'description',
                      formik.values.description,
                    )
                      ? classes.invalidField
                      : ''
                  }
                >
                  {t('createproject.description.label')}
                </InputLabel>
                <TextField
                  type="text"
                  id="description"
                  name="description"
                  value={formik.values.description}
                  fullWidth
                  error={checkFieldIsInvalid(
                    'description',
                    formik.values.description,
                  )}
                  onChange={formik.handleChange}
                  onClick={() => setDescDirty(true)}
                  helperText={t('createproject.description.helperText')}
                />

                {createTagFields(formik)}

                <InputLabel htmlFor="collabratingCompanies">
                  {t('createproject.collabratingCompanies.label')}*
                </InputLabel>
                <Select
                  id="collabratingCompanies"
                  value={participantCompanies}
                  multiple
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected: any) => selected.join(', ')}
                  disabled
                >
                  {Object.values(idToCompanyMapping).map((company: any) => (
                    <MenuItem
                      id={company.short_name}
                      value={company.short_name}
                      key={company.short_name}
                      disabled
                    >
                      {company.short_name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography className={classes.helperText}>
                  {t('createproject.collabratingCompanies.helperText.text1')}

                  <Link
                    target="_blank"
                    to={`${getTechDocsLink(
                      'add-collaborating-companies',
                    )}#collaborating-companies`}
                  >
                    {t(
                      'createproject.collabratingCompanies.helperText.text2AsLink',
                    )}
                    .
                  </Link>
                </Typography>

                <InputLabel htmlFor="ipOwner">
                  {t('createproject.ipOwner.label')}*
                </InputLabel>
                <Select
                  id="ipOwner"
                  value={ipOwnerCompanies}
                  multiple
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected: any) => selected.join(', ')}
                  disabled
                >
                  {Object.values(idToCompanyMapping).map((company: any) => (
                    <MenuItem
                      id={company.short_name}
                      value={company.short_name}
                      disabled
                      key={company.short_name}
                    >
                      {company.short_name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography className={classes.helperText}>
                  {t('createproject.ipOwner.helperText.text1')}

                  <Link
                    target="_blank"
                    to={`${getTechDocsLink('creating-project')}`}
                  >
                    {t('createproject.ipOwner.helperText.text2AsLink')}.
                  </Link>
                </Typography>

                <Grid item xs={6} className={classes.buttons}>
                  <Button
                    type="reset"
                    id="cancel-create-project"
                    variant="outlined"
                    onClick={cancelCreateProject}
                  >
                    {t('common.form.cancelLabel')}
                  </Button>
                  <Button
                    type="submit"
                    id="submit-create-project"
                    data-testid="submit-create-project"
                    variant="contained"
                    disabled={
                      checkFieldIsInvalid(
                        'projectName',
                        formik.values.projectName,
                        true,
                      ) ||
                      checkFieldIsInvalid(
                        'projectKey',
                        formik.values.projectKey,
                        true,
                      ) ||
                      checkFieldIsInvalid(
                        'description',
                        formik.values.description,
                        true,
                      ) ||
                      checkIfAllTagsAreValid(formik, true)
                    }
                  >
                    {t('common.form.createLabel')}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleDialogClose} maxWidth="xs">
        <DialogContent
          id="project-create-confirmed-dialog"
          style={{ fontWeight: 'bold' }}
        >
          {t('createproject.dialog.successMessage')}
          <Link to={`/projects/${createdProjectId}`}>
            {t('createproject.dialog.linkText')}.
          </Link>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            id="dialog-close-button"
            size="small"
            onClick={(reason: any) => handleDialogClose(reason)}
            style={{ backgroundColor: 'black' }}
          >
            {t('common.dialog.closeLabel')}
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}
