import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import useProjectHook from './hooks/useProjectHook';
import { NewVaultPageComponent } from './NewVaultPage';
import { NewViewEditResourceComponent } from './NewViewEditResourceComponent';
import {
  VAULT_DEV_TOOL_ID,
  MTFUJI_DEV_TOOL_ID,
  SMC_NEW_UI_CANARY,
  ARTIFACTORY_DEV_TOOL_ID,
} from 'usg-types';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { useEffectOnce } from 'react-use';
import { Wizard } from '@internal/plugin-projects';
import { useRemoveArtifactorySelfHosted } from '../../../hooks/useRemoveArtifactorySelfHosted';

export const ViewEditResourcePage = () => {
  const { projectId } = useParams() as { projectId: string };
  const { resourceId } = useParams() as { resourceId: string };
  const navigate = useNavigate();
  const [isNewSMCEnabled, setIsNewSMCEnabled] = useState(false);
  const [isFeatureFlagCalled, setIsFeatureFlagCalled] = useState(false);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const { isArtifactorySelfHostedRemoved } = useRemoveArtifactorySelfHosted();

  const projectDetails = useProjectHook(projectId, resourceId);

  useEffectOnce(() => {
    (async () => {
      const getSmcNewUIFlagRes = await featureFlagsApi.getCanaryFeatureFlag(
        SMC_NEW_UI_CANARY,
      );
      let enableSMCForProjectId = false;

      if (
        (Array.isArray(getSmcNewUIFlagRes.data) &&
          getSmcNewUIFlagRes.data.length &&
          getSmcNewUIFlagRes.data.indexOf(projectId) >= 0) ||
        getSmcNewUIFlagRes.data === true
      ) {
        enableSMCForProjectId = true;
      }
      setIsNewSMCEnabled(enableSMCForProjectId);
      setIsFeatureFlagCalled(true);
    })();
  });

  if (projectDetails.loading || isArtifactorySelfHostedRemoved === null) {
    return <Progress />;
  }

  // if project data is null means the data we got is not valid
  if (
    projectDetails.data === null ||
    (isArtifactorySelfHostedRemoved &&
      projectDetails.data?.resources[0].dev_tool_id === ARTIFACTORY_DEV_TOOL_ID)
  ) {
    // cannot get correct project data, return back to project
    navigate(`/projects/${projectId}`);
  }

  if (!isFeatureFlagCalled) {
    return <></>;
  }

  // if it is vault got to UI specific vault
  if (projectDetails.data?.resources[0].dev_tool_id === VAULT_DEV_TOOL_ID) {
    return <NewVaultPageComponent projectData={projectDetails.data} />;
  } else if (
    projectDetails.data?.resources[0].dev_tool_id === MTFUJI_DEV_TOOL_ID &&
    isNewSMCEnabled
  ) {
    return (
      <Wizard
        projectId={projectId}
        viewMode
        projectData={projectDetails.data}
      />
    );
  }

  return (
    <NewViewEditResourceComponent
      projectId={projectId}
      resourceId={resourceId}
      projectData={projectDetails.data}
    />
  );
};
