import React from 'react';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';

interface PageTitleProps {
  customPageTitle?: string;
}

/**
 * A wrapper component to set the title dynamically.
 *
 * @param PageTitleProps
 * @returns Helmet component.
 */
export const PageTitle = ({ customPageTitle }: PageTitleProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  const PAGE_TITLES: Record<string, string> = {
    '/': 'Stargate',
    '/projects': t('common.page.titles.projects'),
    '/notifications': t('common.page.titles.notifications'),
  };

  const title = PAGE_TITLES[location.pathname] || customPageTitle || 'Stargate';
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
