import { OWNER, Role } from 'usg-types';
import { v4 as uuidv4 } from 'uuid';
import { Headers, HeadersInit } from 'node-fetch';
import { has } from 'lodash';

interface LocationMapData {
  mainItem: any;
  duplicates: any[];
}

export function setLocalStorageWithExpiry(
  key: string,
  value: string,
  ttlDate: Date,
) {
  // item is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: ttlDate,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > new Date(item.expiry).getTime()) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function getLocale() {
  const locale = getLocalStorageWithExpiry('locale') === 'ja' ? 'ja' : '';
  return locale;
}

export function getRoles(data: any) {
  const roles: any[] = [];
  data.filter((i: any) => {
    // if expired_on is null or undefined or not present, role has infinite expiry date
    if (
      !has(i, 'expired_on') ||
      i.expired_on === null ||
      i.expired_on === undefined
    ) {
      return roles.push(i?.role);
    }
    if (i?.expired_on && new Date() > new Date(i?.expired_on)) {
      return false;
    }
    return roles.push(i?.role);
  });
  return roles;
}

export function getAllRoles(userData: any, projectId: string) {
  let roles: string[] = getRoles(userData?.roles);

  const foundProject: any = userData?.projects.find(
    (p: any) => p.id.toString() === projectId,
  );
  roles = roles.concat(foundProject?.is_owner ? [OWNER] : []);
  const flatRoles: string[] = foundProject?.user_groups.flatMap(
    (g: any) => g.group_roles,
  );
  roles = roles.concat([...new Set(flatRoles)]);
  const resourceRoles: string[] = foundProject?.resources.flatMap(
    (g: any) => g.resource_roles,
  );
  roles = roles.concat([...new Set(resourceRoles)]);
  const devToolsRoles: string[] = foundProject?.development_tools.flatMap(
    (g: any) => g.dev_tool_roles,
  );
  roles = roles.concat([...new Set(devToolsRoles)]);
  const groupCreaterRoles: string[] = foundProject?.project_roles;
  roles = roles.concat([...new Set(groupCreaterRoles)]);
  return roles;
}

export function getNonExpiredRoles(roles: Array<Role>) {
  const nonExpiredRoles: Array<Role> = roles.filter((i: any) => {
    // if expired_on is null or undefined or not present, role has infinite expiry date
    if (
      !has(i, 'expired_on') ||
      i.expired_on === null ||
      i.expired_on === undefined
    ) {
      return true;
    }
    return i?.expired_on && new Date(i?.expired_on) > new Date();
  });
  return nonExpiredRoles;
}

export function isValidUrl(url: any) {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

export function searchResultsData(results: any) {
  const locationMap: Record<string, LocationMapData> = {};
  // Loop through the results
  results.forEach((item: any) => {
    const location = item.document?.location?.split('#')[0];
    // Check if location is valid
    if (!location) {
      return; // Skip this iteration if location is undefined or empty
    }

    if (!locationMap[location]) {
      locationMap[location] = {
        mainItem: item,
        duplicates: [],
      };
    } else {
      locationMap[location].duplicates.push(item);
    }
  });

  // final array without duplicates
  const finalResults = Object.values(locationMap).map(
    ({ mainItem, duplicates }: LocationMapData) => {
      if (duplicates.length > 0) {
        mainItem.duplicates = duplicates; // Add duplicates to the main item
      }
      return mainItem;
    },
  );
  return finalResults as any;
}

export interface MetaSGRequest {
  gacAccessToken?: string;
  gacIdToken?: string;
}

/**
 * Generate the common headers to be sent from client.
 *
 * @param init Initial headers specified from client.
 * @param meta Metadata for SG Request
 * @returns Headers with added metadata, uuidv4 string for tracking the request and with paage url for better debugging (req. in case of error logs)
 */
export function generateHeaders(
  init?: HeadersInit,
  meta?: MetaSGRequest,
): Headers {
  const headers = new Headers(init);
  headers.set('x-sg-gac-accesstoken', meta?.gacAccessToken || 'none');
  headers.set('x-sg-gac-idtoken', meta?.gacIdToken || 'none');
  headers.set('x-sg-requestid', uuidv4());
  headers.set('x-sg-pageurl', window.location.href);
  return headers;
}

export interface DevtoolsRoleMapping {
  [key: string]: {
    [key: string]: string;
  };
}

/**
 * Create a devtool roles mapping from name to display names
 *
 * @param devtools Devtools data
 * @returns mapping
 */
export function createDevtoolRoleNameMapping(
  devTools: any,
): DevtoolsRoleMapping {
  const devtoolsRolesMapping = devTools.reduce((mapping: any, devtool: any) => {
    mapping[devtool.id] = devtool.roles.reduce(
      (roleMapping: any, role: any) => {
        roleMapping[role.name] = role.display_name;
        return roleMapping;
      },
      {},
    );
    return mapping;
  }, {});
  return devtoolsRolesMapping;
}

/**
 * Converts a file size in bytes to a human-readable string
 *
 * @param {number} bytes - The file size in bytes.
 * @param {number} [decimals=2] - The number of decimal places to include in the formatted output. Defaults to 2.
 * @returns {string} A string representing the formatted file size with the appropriate unit (bytes, KB, MB, etc.).
 */
export function formatFileSize(bytes: number, decimals: number = 2) {
  if (bytes === 0) return '0 bytes';

  let sizeInBytes: number = bytes;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  const k = 1000; // in decimal
  let i = 0;
  while (sizeInBytes >= k && i < sizes.length - 1) {
    sizeInBytes /= k;
    i++;
  }
  return `${sizeInBytes.toFixed(decimals)} ${sizes[i]}`;
}
