import { useEffect, useState } from 'react';

import {
  errorApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  projectApiRef,
  ProjectSettingResourceStatus,
  ProjectSettingsRequestParams,
} from '@internal/plugin-projects';
import { featureFlagsApiRef } from '@internal/plugin-feature-flags';
import { useEffectOnce } from 'react-use';
import { ROVER_INDEXING } from 'usg-types';
import useRoverIndexingEnabledResourceIds from './useRoverIndexingEnabledResourceIds';

export type ResourceStatus = {
  [key: string]: ProjectSettingResourceStatus;
};

export default function useProjectSettings(
  projectId: string,
  params: ProjectSettingsRequestParams = {
    with_resources: false,
  },
) {
  const authRef = useApi(microsoftAuthApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const errorApi = useApi(errorApiRef);
  const projectApi = useApi(projectApiRef);
  const {
    resourcesToggleState,
    setResourcesToggleState,
    fetchRoverIndexingEnabledResources,
  } = useRoverIndexingEnabledResourceIds(projectId);

  const [isRoverIndexingFlagEnabled, setIsRoverIndexingFlagEnabled] =
    useState(false);
  const [isRoverIndexingEnabled, setIsRoverIndexingEnabled] = useState(false);
  const [resourcesStatus, setResourcesStatus] = useState<ResourceStatus>({});

  useEffectOnce(() => {
    (async () => {
      const roverIndexingFlagRes = await featureFlagsApi.getBinaryFlag(
        ROVER_INDEXING,
      );
      setIsRoverIndexingFlagEnabled(roverIndexingFlagRes.data);

      if (roverIndexingFlagRes.data) {
        fetchRoverIndexingEnabledResources();
      }
    })();
  });

  useEffect(() => {
    const fetchProjectSettings = async () => {
      try {
        const idToken = await authRef.getIdToken();
        const projectSettingsData = await projectApi.getProjectSettings(
          projectId,
          idToken,
          params,
        );
        const { genai_index_enabled, resources = [] } = projectSettingsData;

        const _resourcesStatus = resources.reduce(
          (accumulation, { id, status }) => ({ ...accumulation, [id]: status }),
          {},
        );

        setIsRoverIndexingEnabled(genai_index_enabled);
        setResourcesStatus(_resourcesStatus);
      } catch (err: any) {
        errorApi.post(
          new Error(
            err?.message ||
              'Failed to fetch resources that enabled rover indexing',
          ),
        );
      }
    };

    if (isRoverIndexingFlagEnabled) {
      fetchProjectSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoverIndexingFlagEnabled]);

  return {
    isRoverIndexingEnabled:
      isRoverIndexingFlagEnabled && isRoverIndexingEnabled,
    setIsRoverIndexingEnabled,
    resourcesStatus,
    resourcesToggleState,
    setResourcesToggleState,
  };
}
